import React, {ChangeEvent, useRef, useState} from 'react';

import stylesLayout from '../Layout.module.scss';
import Layout from 'pages/Layout';
import { ICustomer } from 'model/interfaces/ICustomer';
import * as actions from 'pages/Customers/store/actions';
import initialState from 'pages/Customers/CustomerForm/initialState';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import { useAdminGrid } from 'model/hooks/useAdminGrid';
import CustomerForm from 'pages/Customers/CustomerForm';
import BaseModal from 'components/modals/BaseModal';
import SearchFulltext from 'components/SearchFulltext';
import EuFooter from 'components/EuFooter';
import CustomerCard from 'components/cards/CustomerCard';
import { Marker } from 'react-mapbox-gl';
import mapMarker from 'images/map-marker-generic.svg';
import styles from './index.module.scss';
import CustomersMap from 'pages/Customers/CustomersMap';
import { useSearchedEntity } from 'model/hooks/useSearchedEntity';
import { IOrder } from 'model/interfaces/IOrder';
import { IOrderRequest } from 'model/services/Api/interfaces/requests/IOrderRequest';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IGenericSearchRequest } from 'model/services/Api/interfaces/requests/IGenericSearchRequest';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { RoutesEnum } from 'model/enums/RoutesEnum';
import * as authActions from 'store/auth/actions';
import Tippy from '@tippyjs/react';
import Dropdown from 'components/Popover';
import {useTranslation} from "react-i18next";
import MyPagination from "../../components/Paginator";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

type AddCustomersMenuProps = {
	openForm: () => void,
	hide: (event: React.MouseEvent<Element, MouseEvent>) => void
}

const AddCustomersMenu = (props: AddCustomersMenuProps) => {
	const dispatch = useDispatch();
	const fileRef = useRef<HTMLInputElement>(null);

	const onAddCustomer = (e: React.MouseEvent<HTMLButtonElement>) => {
		props.hide(e);
		props.openForm()
	}

	const onUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch(actions.importCustomers(e.target.files));
		fileRef.current!.value = ''
	};

	const { t } = useTranslation();

	return (
		<>
			<button
				className="dropdown-item"
				onClick={ onAddCustomer }
			>
				{t('pages.addCustomerMan')}
			</button>
			<div className="dropdown-divider"/>
			<input
				type="file"
				id="import-customers"
				className="d-none"
				onChange={ onUploadFile }
				ref={fileRef}
			/>
			<label htmlFor="import-customers" className="dropdown-item mb-0">
				{t('pages.importCustomers')}
			</label>
		</>
	);
};

const Customers: React.FC<Props> = props => {

	const { t } = useTranslation();

	const {
		isFormOpened,
		openForm,
		closeForm,
		formInitialValues,
		editAction,
		deleteAction,
	} = useAdminGrid<ICustomer>(
		initialState,
		t('pages.deleteCustomer'),
		actions.deleteCustomer,
		actions.fetchCustomers,
	);

	const { customers, isLoading, error } = useSelector((state: ReduxState) => ({
		customers: state.customers.data?.results ?? [],
		isLoading: state.customers.loading,
		error: state.customers.error,
	}));
	const [page, setPage] = useState(1);

	const [ searchedCustomers, onSearch ] = useSearchedEntity<ICustomer, IGenericSearchRequest>(
		UrlEnum.CUSTOMER,
		customers,
	);

	const chunkSize = 15;

	const itemsToPages = (items: ICustomer[], limit: number) => {
		let chunks = [];
		for (let i = 0; i < items.length; i += limit) {
			chunks.push(items.slice(i, i + limit));
		}
		return chunks;
	}

	const getCurrentCustomers = () => {
		let chunked = itemsToPages(searchedCustomers, chunkSize);
		return chunked.length === 0 ? [] : chunked[page-1];
	}

	const getPages = () => {
		return itemsToPages(searchedCustomers, chunkSize).length;
	}

	let searchedCustomersChunked = itemsToPages(searchedCustomers, chunkSize);

	return (
		<>
			<BaseModal
				isOpen={ isFormOpened }
				onRequestClose={ closeForm }
				title={t('pages.customerForm')}
			>
				<CustomerForm
					onSuccess={ closeForm }
					initialValues={ formInitialValues }
				/>
			</BaseModal>

			<Layout className={stylesLayout.layoutSettingsWithAside}>
				<section className={` ${stylesLayout.asideDashboard} panel over-map `}>
					<div className="panel-header">
						<div className="row mb-2">
							{/*<div className="col">*/}
							{/*	<FiltersButton onClick={ () => {} }/>*/}
							{/*</div>*/}
							<div className="col text-right">
								<Dropdown
									menu={ ({hide}) => <AddCustomersMenu
										hide={ hide }
										openForm={openForm}
									/> }
								>
									{ ({onClick}) => (
										<button
											className={ `btn btn-sm btn-primary dropdown-toggle` }
											onClick={onClick}
										>
											{t('pages.addCustomer')}
										</button>

									) }
								</Dropdown>
							</div>
						</div>

						<SearchFulltext placeholder="" onSearch={ onSearch }/>
					</div>
					<div className="panel-body">
						{ getCurrentCustomers().map((customer, index) => (
							<CustomerCard
								customer={customer}
								key={index}
								number={index + 1}
							/>
						))}
					</div>
					{getCurrentCustomers().length > 0 &&
						<div className="panel-footer">
							<div style={{height: 50}}>
								<MyPagination
									totPages={getPages()}
									currentPage={page} pageClicked={(ele) => {
										setPage(ele);
									}}
								>

								</MyPagination>
							</div>
						</div>
					}
				</section>

				<CustomersMap customers={getCurrentCustomers()}/>

				<footer className={ `${stylesLayout.footer} over-map` }>
					<EuFooter/>
				</footer>
			</Layout>
		</>
	);
};

export default Customers as React.FC<BaseProps>;
