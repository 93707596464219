import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as ordersActions from 'pages/Orders/store/actions';
import * as ordersSelectors from 'pages/Orders/store/selectors';
import Layout from '../Layout';
import stylesLayout from '../Layout.module.scss';
import SearchFulltext from '../../components/SearchFulltext';
import NewOrderModal from 'components/modals/NewOrder';
import { ReduxState } from 'store/reducer';
import OrderCard from 'components/cards/OrderCard';
import EuFooter from 'components/EuFooter';
import { initialValues } from 'components/modals/NewOrder/initialValues';
import { isoDayFromUTCDatetime } from 'model/helpers/datetime';
import OrdersMap from 'pages/Orders/OrdersMap';
import OrderBadges from 'components/OrderTypeBadge/OrderBadges';
import OrderFilters from 'components/OrderFilters';
import FiltersButton from 'components/Buttons/FiltersButton';
import { useSearchedEntity } from 'model/hooks/useSearchedEntity';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IOrderRequest } from 'model/services/Api/interfaces/requests/IOrderRequest';
import { IOrder } from 'model/interfaces/IOrder';
import { OrderStatusEnum } from 'model/enums/OrderStatusEnum';
import NoPlanPage from 'components/NoPlanPage';
import { PlanStatusEnum } from 'model/enums/PlanStatusEnum';
import * as planActions from 'store/plan/actions';
import {useTranslation} from "react-i18next";

const Orders = () => {
	const [ isNewOrdeModalOpen, setNewOrderModalOpen ] = useState(false);
	const [ formInitialValues, setFormInitialValues ] = useState(initialValues);
	const [ areFiltersVisible, setFiltersVisible ] = useState(false);
	const dispatch = useDispatch();
	const { isLoading, orders, executionDate, shift, plan } = useSelector(({ orders, executionDate, shift, plan }: ReduxState) => ({
		isLoading: ordersSelectors.isLoading(orders),
		orders: ordersSelectors.getOrders(orders),
		executionDate,
		shift,
		plan,
	}));

	const [ searchedOrders, onSearch, activeFiltersCount, isFiltersLoading ] = useSearchedEntity<IOrder, IOrderRequest>(
		UrlEnum.ORDER,
		orders,
		{
			plan_date: isoDayFromUTCDatetime(plan.data?.date),
			plan_shift: plan.data?.shift.id,
		}
	);

	React.useEffect(() => {
		dispatch(ordersActions.fetchOrders());

		// Disabling polling because of complications with filtering
		//
		// dispatch(ordersActions.stopPolling());
		// dispatch(ordersActions.startPolling(executionDate));
		//
		// const newFormInitialValues = Object.assign({}, initialValues);
		// newFormInitialValues.execution_date = isoDayFromUTCDatetime(executionDate);
		// setFormInitialValues(newFormInitialValues);
		//
		// return () => {
		// 	dispatch(ordersActions.stopPolling());
		// }
		// eslint-disable-next-line
	}, [executionDate, shift]);

	const { t } = useTranslation();

	if (!plan.data?.id) {
		return <NoPlanPage />;
	}

	return (
		<Layout className={ stylesLayout.layoutDashboard }>
			<aside className={ `${ stylesLayout.aside }` }>
				<div className="panel panel--flex over-map">
					<div className="panel-header">
						<div className="row mb-2">
							<div className="col flex-grow-0 text-nowrap">
								<FiltersButton
									onClick={() => setFiltersVisible(!areFiltersVisible) }
									activeFiltersCount={ activeFiltersCount }
									isLoading={ isFiltersLoading }
								/>
							</div>
							<div className="col text-right">
								{plan.data && plan.data.status === PlanStatusEnum.COMPLETED ?
									<button
										className="btn btn-sm btn-primary"
										onClick={() => dispatch(planActions.exportUnexecutedOrders(plan.data!))}
									>
										<i className="fa fa-download mr-2" />
										{t('pages.orders.downloadUnexecOrders')}
									</button>
									:
									<button
										className="btn btn-sm btn-primary"
										onClick={() => setNewOrderModalOpen(true)}
									>
										{t('pages.orders.addOrder')}
									</button>
								}
							</div>
						</div>

						<div className={`bg-gray-200 rounded pt-3 px-3 mb-3 ${!areFiltersVisible ? 'd-none' : ''}`}>
							<OrderFilters
								onSearch={ onSearch }
							/>
						</div>

						<div className="mb-1">
							<OrderBadges
								orders={ orders }
								onSearch={ onSearch }
							/>
						</div>

						<SearchFulltext
							placeholder={t('pages.orders.deliveriesPickupsAddresses')}
							onSearch={ onSearch }
						/>
					</div>
					<div className="panel-body">
						{ searchedOrders.map((order, orderIndex) => (
							<OrderCard
								key={ orderIndex }
								order={ order }
								number={ orderIndex + 1 }
								showEdit
								showDelete={ order.status.status === OrderStatusEnum.NEW }
								fetchAction={ ordersActions.fetchOrders }
								planId={ plan.data!.id }
								shift={ plan.data!.shift }
							/>
						)) }
					</div>
				</div>
			</aside>

			<footer className={ `${stylesLayout.footer} over-map` }>
				<EuFooter/>
			</footer>

			<NewOrderModal
				isOpen={ isNewOrdeModalOpen }
				onRequestClose={ () => setNewOrderModalOpen(false) }
				title={t('pages.orders.newOrder')}
				initialValues={formInitialValues}
				fetchAction={ ordersActions.fetchOrders }
				planId={ plan.data.id }
				shift={ plan.data.shift }
			/>

			<OrdersMap orders={orders} plan={plan} />
		</Layout>
	);
};

export default Orders;
