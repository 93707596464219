import { createActionCreator } from 'deox';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IOrder } from 'model/interfaces/IOrder';
import { IApiError } from 'model/interfaces/IApiError';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';
import { OrderFetchActionType } from 'model/types/OrderFetchAction';

export enum OrderActionsEnum {
	FETCH = '@ORDER_FETCH',
	FETCH_SUCCESS = '@ORDER_FETCH_SUCCESS',
	FETCH_FAIL = '@ORDER_FETCH_FAIL',

	START_POLLING = '@ORDER_START_POLLING',
	STOP_POLLING = '@ORDER_STOP_POLLING',

	CREATE = '@ORDER_CREATE',
	UPDATE = '@ORDER_UPDATE',
	DELETE = '@ORDER_DELETE',
	FORM_SUCCESS = '@ORDER_FORM_SUCCESS',
	FORM_FAIL = '@ORDER_FORM_FAIL',
	FORM_RESTART = '@ORDER_FORM_RESTART',
}

export const startPolling = createActionCreator(
	OrderActionsEnum.START_POLLING, resolve => (date: string) => resolve(date),
);

export const stopPolling = createActionCreator(OrderActionsEnum.STOP_POLLING);

export const fetchOrdersSuccess = createActionCreator(
	OrderActionsEnum.FETCH_SUCCESS, resolve => (orders: IPaginatedData<IOrder>) => resolve(orders)
);

export const fetchOrdersFail = createActionCreator(
	OrderActionsEnum.FETCH_FAIL, resolve => (error: IApiError) => resolve(error),
);

export const fetchOrders = createActionCreator(OrderActionsEnum.FETCH);

export const createOrder = createActionCreator(
	OrderActionsEnum.CREATE,
	resolve => (order: IOrder, planId: number, fetchAction?: OrderFetchActionType) => resolve({ order, fetchAction, planId }),
);

export const updateOrder = createActionCreator(
	OrderActionsEnum.UPDATE,
	resolve => (order: IOrder, planId: number, fetchAction?: OrderFetchActionType) => resolve( { order, fetchAction, planId }),
);

export const deleteOrder = createActionCreator(
	OrderActionsEnum.DELETE,
	resolve => (order: IOrder, fetchAction?: OrderFetchActionType) => resolve({ order, fetchAction }),
);

export const formOrderSuccess = createActionCreator(
	OrderActionsEnum.FORM_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success));

export const formOrderFail = createActionCreator(
	OrderActionsEnum.FORM_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const formOrderRestart = createActionCreator(OrderActionsEnum.FORM_RESTART);