import { IApiError } from 'model/interfaces/IApiError';

export const errorToApiError = (error: any): IApiError => {
	if (typeof error !== 'object' || typeof error.response !== 'object' || typeof error.response.data !== 'object') {
		console.error(error);
		throw new Error('helpers.errorUnexpectedError');
	}

	// if (typeof error !== 'object') {
	// 	return { message: 'Unexpected error: Error is not an object; ' + error.message };
	// }
	//
	// if (typeof error.response !== 'object') {
	// 	return  { message: 'No response object: ' + error.message };
	// }
	//
	// if (typeof error.response.data !== 'object') {
	// 	return  { message: 'Unexpected error: Server response is not an object.' };
	// }

	console.log(error.response.data);

	const data = error.response.data;

	const errors: string[] = [];

	for (const [ key, value ] of Object.entries(data)) {
		if (key === 'status_code') continue;

		addToErrorsArray(key, value, errors);
	}

	return {
		message: errors.join('\n\n'),
		status: error.response.status,
		toast: true
	};
};

const addToErrorsArray = (key: string, value: any, errors: string[]) => {
	if (['string', 'number', 'boolean'].includes(typeof value) || value === null) {
		// This is used otherwise
		errors.push(`${ value }`);

	} else if (Array.isArray(value)) {
		for (let valueItem of value) {
			if (typeof valueItem === 'object') {
				for (const [ subKey, subValue ] of Object.entries(valueItem)) {
					if (subKey === 'row_details') continue;
					addToErrorsArray(`${key}.${subKey}`, subValue, errors);
				}
			} else {
				addToErrorsArray(key, valueItem, errors);
			}
		}

	} else if (typeof value === 'object') {
		for (const [ subKey, subValue ] of Object.entries(value)) {
			addToErrorsArray(subKey, subValue, errors);
		}

	} else {
		console.error('addToErrorsArray unexpected value', key, value);
		throw new Error('helpers.errorUnexpectedError');
	}
};

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
