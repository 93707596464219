import React, { useState } from 'react';
import { IRoute } from 'model/interfaces/IRoute';
import KeyValueRows from 'components/VehicleRouteCard/KeyValueRows';
import styles from './index.module.scss';
import { getEmissionsWithUnits } from 'model/helpers/emissions';
import { useTranslation } from 'react-i18next';

type Props = {
	route: IRoute,
}

const MoreInfo: React.FC<Props> = ({ route }) => {
	const [ show, setShow ] = useState(false);

	const emissions = getEmissionsWithUnits(route.total_emissions);
	const { t } = useTranslation();

	if (Object.keys(emissions).length === 0) return null;

	return (
		<>
			{show &&
				<div className="py-4">
					<h4>{t('components.vehicle.totalEmissions')}</h4>
					<KeyValueRows items={emissions} />
				</div>
			}

			<div className="text-right position-relative">
				<button
					onClick={ () => setShow(!show) }
					className={`btn bg-white rounded border font-weight-normal py-0 px-2 text-small text-primary position-absolute ${styles.moreInformation}`}
				>
					{ show ? t('cards.lessInformation') : t('cards.moreInformation') }
					<i className={`far ${show ? 'fa-angle-up' : 'fa-angle-down'} ml-2`} />
				</button>
			</div>
		</>
	)
}

export default MoreInfo;