import { createReducer } from 'deox';

import * as actions from 'pages/TimeWindows/store/actions';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';
import { ITimeWindow } from 'model/interfaces/ITimeWindow';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';
import { IApiError } from 'model/interfaces/IApiError';

export interface ITimeWindowsState extends IReduxFetchedState<IPaginatedData<ITimeWindow>> {
	formSuccess?: IReduxActionState | boolean | undefined,
	formError?: (IReduxActionState & IApiError) | undefined,

	deleteSuccess?: IReduxActionState | boolean,
	deleteError?: IReduxActionState & IApiError,
}

const initialState: ITimeWindowsState = {};

export default createReducer(initialState, handle => [
	handle(actions.fetchTimeWindows, (state) => ({
		...state,
		loading: true,
	})),
	handle(actions.fetchTimeWindowsSuccess, (state, { payload }) => ({
		...state,
		error: undefined,
		data: { ...payload },
		loading: false,
	})),
	handle(actions.fetchTimeWindowsFail, (state, { payload }) => ({
		...state,
		data: undefined,
		error: { ...payload },
		loading: false,
	})),
	handle(actions.createTimeWindow, (state) => ({
		...state,
		loading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.updateTimeWindow, (state) => ({
		...state,
		loading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.formTimeWindowSuccess, (state, { payload }) => ({
		...state,
		loading: false,
		formSuccess: { ...payload },
		formError: undefined,
	})),
	handle(actions.formTimeWindowFail, (state, { payload }) => ({
		...state,
		loading: false,
		formSuccess: undefined,
		formError: { ...payload },
	})),
	handle(actions.formTimeWindowRestart, (state) => ({
		...state,
		loading: false,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.deleteTimeWindow, (state) => ({
		...state,
		loading: true,
		deleteSuccess: undefined,
		deleteError: undefined,
	})),
	handle(actions.deleteTimeWindowSuccess, (state, { payload }) => ({
		...state,
		loading: false,
		deleteSuccess: { ...payload },
		deleteError: undefined,
	})),
	handle(actions.deleteTimeWindowFail, (state, { payload }) => ({
		...state,
		loading: false,
		success: false,
		error: { ...payload },
	})),
]);
