import React from 'react';

import styles from './index.module.scss';

type BaseProps = {
	width: string
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const LoaderText: React.FC<Props> = (props) => {
	return (
		<span className={styles.mockupText} style={{ width: props.width }}/>
	);
};

export default LoaderText;
