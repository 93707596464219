import {NavLink as RouterNavLink, match, LinkProps} from 'react-router-dom';
import React from "react";
import styles from './index.module.scss';
import {RouteConfig} from "react-router-config";
import * as H from 'history';

type Props<S = H.LocationState> = {
	route: RouteConfig,
	label: string,
	subnav?: boolean
	isActive?<Params extends { [K in keyof Params]?: string }>(
		match: match<Params>,
		location: H.Location<S>,
	): boolean
}

const NavLink: React.FC<Props> = ( props ) => {
	const dynamicProps = {
		isActive: props.isActive
	};

	return <li className="nav-item">
		<RouterNavLink
			to={props.route.path as string}
			className={props.subnav ? styles.linkSubnav : styles.link}
			activeClassName={styles.active}
			exact={props.route.exact}
			{ ...dynamicProps }
		>
			{props.label}
		</RouterNavLink>
	</li>;
};

export default NavLink;