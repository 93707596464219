export enum RoutesEnum {
	HOMEPAGE = '/',
	DETAIL = '/detail/:id',
	DETAIL_REROUTING = '/detail/:id/rerouting',
	LOGIN = '/login',
	ORDERS = '/orders',
	REROUTING = '/rerouting',
	REROUTING_SELECTED = '/rerouting/selected',
	PLANNING = '/planning',
	PROFILE = '/profile',
	CUSTOMERS = '/customers',
	DEPOTS = '/depots',
	VEHICLES = '/vehicles',
	SEMITRAILERS = '/semitrailers',
	DRIVERS = '/drivers',
	PARAMETERS = '/parameters',
	TIME_WINDOWS = '/time-windows',
	SHIFTS = '/shifts',
	PACKAGES = '/packages',
	VEHICLE_ABILITIES = '/vehicle-abilities',
	VEHICLE_RESTRICTIONS = '/vehicle-restrictions',
	MATERIAL_TAGS = '/material-tags',
	ADDRESSES = '/addresses',
}

export const SettingsRoutes: string[] = [
	RoutesEnum.PROFILE,
	RoutesEnum.CUSTOMERS,
	RoutesEnum.DEPOTS,
	RoutesEnum.VEHICLES,
	RoutesEnum.SEMITRAILERS,
	RoutesEnum.DRIVERS,
	RoutesEnum.PARAMETERS,
	RoutesEnum.TIME_WINDOWS,
	RoutesEnum.SHIFTS,
	RoutesEnum.PACKAGES,
	RoutesEnum.VEHICLE_ABILITIES,
	RoutesEnum.VEHICLE_RESTRICTIONS,
	RoutesEnum.MATERIAL_TAGS,
	RoutesEnum.ADDRESSES,
];

