export enum AddressTypeEnum {
	DEPOT, SERVICE_ADDRESS, OFFICE, AGENCY, HOME,
}

export const AddressTypePairs = {
	[AddressTypeEnum.DEPOT]: 'model.enums.depot',
	[AddressTypeEnum.SERVICE_ADDRESS]: 'model.enums.service',
	[AddressTypeEnum.OFFICE]: 'model.enums.office',
	[AddressTypeEnum.AGENCY]: 'model.enums.agency',
	[AddressTypeEnum.HOME]: 'model.enums.home',
};