import { createActionCreator } from 'deox';
import { IApiError } from 'model/interfaces/IApiError';
import { IDriver } from 'model/interfaces/IDriver';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';

export enum DriverActionsEnum {
	FETCH = '@DRIVERS_FETCH',
	FETCH_SUCCESS = '@DRIVERS_FETCH_SUCCESS',
	FETCH_FAIL = '@DRIVERS_FETCH_FAIL',

	CREATE = '@DRIVERS_CREATE',
	UPDATE = '@DRIVERS_UPDATE',
	FORM_SUCCESS = '@DRIVERS_FORM_SUCCESS',
	FORM_FAIL = '@DRIVERS_FORM_FAIL',
	FORM_RESTART = '@DRIVERS_FORM_RESTART',

	DELETE = '@DRIVERS_DELETE',
	DELETE_SUCCESS = '@DRIVERS_DELETE_SUCCESS',
	DELETE_FAIL = '@DRIVERS_DELETE_FAIL',
}

export const fetchDrivers = createActionCreator(DriverActionsEnum.FETCH);

export const fetchDriversFail = createActionCreator(
	DriverActionsEnum.FETCH_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const fetchDriversSuccess = createActionCreator(
	DriverActionsEnum.FETCH_SUCCESS,
	resolve => (data: IPaginatedData<IDriver>) => resolve(data),
);

export const createDriver = createActionCreator(
	DriverActionsEnum.CREATE,
	resolve => (driver: IDriver) => resolve(driver),
);

export const updateDriver = createActionCreator(
	DriverActionsEnum.UPDATE,
	resolve => (driver: IDriver) => resolve(driver),
);

export const formDriverSuccess = createActionCreator(
	DriverActionsEnum.FORM_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success));

export const formDriverFail = createActionCreator(
	DriverActionsEnum.FORM_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const formDriverRestart = createActionCreator(DriverActionsEnum.FORM_RESTART);

export const deleteDriver = createActionCreator(
	DriverActionsEnum.DELETE,
	resolve => (driver: IDriver) => resolve(driver),
);

export const deleteDriverSuccess = createActionCreator(
	DriverActionsEnum.DELETE_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success)
);

export const deleteDriverFail = createActionCreator(
	DriverActionsEnum.DELETE_FAIL,
	resolve => (error: IReduxActionState & IApiError) => resolve(error),
);
