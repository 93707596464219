import { createActionCreator } from 'deox';
import { IApiError } from 'model/interfaces/IApiError';
import { IVehicleRestrictionEndpoint } from 'model/interfaces/IVehicleRestrictionEndpoint';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';

export enum VehicleRestrictionActionsEnum {
	FETCH = '@VEHICLERESTRICTIONS_FETCH',
	FETCH_SUCCESS = '@VEHICLERESTRICTIONS_FETCH_SUCCESS',
	FETCH_FAIL = '@VEHICLERESTRICTIONS_FETCH_FAIL',

	CREATE = '@VEHICLERESTRICTIONS_CREATE',
	UPDATE = '@VEHICLERESTRICTIONS_UPDATE',
	FORM_SUCCESS = '@VEHICLERESTRICTIONS_FORM_SUCCESS',
	FORM_FAIL = '@VEHICLERESTRICTIONS_FORM_FAIL',
	FORM_RESTART = '@VEHICLERESTRICTIONS_FORM_RESTART',

	DELETE = '@VEHICLERESTRICTIONS_DELETE',
	DELETE_SUCCESS = '@VEHICLERESTRICTIONS_DELETE_SUCCESS',
	DELETE_FAIL = '@VEHICLERESTRICTIONS_DELETE_FAIL',
}

export const fetchVehicleRestrictions = createActionCreator(VehicleRestrictionActionsEnum.FETCH);

export const fetchVehicleRestrictionsFail = createActionCreator(
	VehicleRestrictionActionsEnum.FETCH_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const fetchVehicleRestrictionsSuccess = createActionCreator(
	VehicleRestrictionActionsEnum.FETCH_SUCCESS,
	resolve => (data: IPaginatedData<IVehicleRestrictionEndpoint>) => resolve(data),
);

export const createVehicleRestriction = createActionCreator(
	VehicleRestrictionActionsEnum.CREATE,
	resolve => (vehicleRestrictionItem: IVehicleRestrictionEndpoint) => resolve(vehicleRestrictionItem),
);

export const updateVehicleRestriction = createActionCreator(
	VehicleRestrictionActionsEnum.UPDATE,
	resolve => (vehicleRestrictionItem: IVehicleRestrictionEndpoint) => resolve(vehicleRestrictionItem),
);

export const formVehicleRestrictionsuccess = createActionCreator(
	VehicleRestrictionActionsEnum.FORM_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success));

export const formVehicleRestrictionFail = createActionCreator(
	VehicleRestrictionActionsEnum.FORM_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const formVehicleRestrictionRestart = createActionCreator(VehicleRestrictionActionsEnum.FORM_RESTART);

export const deleteVehicleRestriction = createActionCreator(
	VehicleRestrictionActionsEnum.DELETE,
	resolve => (vehicleRestrictionItem: IVehicleRestrictionEndpoint) => resolve(vehicleRestrictionItem),
);

export const deleteVehicleRestrictionsuccess = createActionCreator(
	VehicleRestrictionActionsEnum.DELETE_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success)
);

export const deleteVehicleRestrictionFail = createActionCreator(
	VehicleRestrictionActionsEnum.DELETE_FAIL,
	resolve => (error: IReduxActionState & IApiError) => resolve(error),
);
