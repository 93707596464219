import React, { useEffect } from 'react';
import { Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import TextInput from 'components/Form/TextInput';
import NumberInput from 'components/Form/NumberInput';
import TextAreaInput from 'components/Form/TextAreaInput';
import { ISemitrailer } from 'model/interfaces/ISemitrailer';
import DemonstratorAsyncSelect from 'components/Form/DemonstratorAsyncSelect';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import * as semitrailerActions from 'pages/Semitrailers/store/actions';
import AbilityAsyncMultiSelect from 'components/Form/AbilityAsyncMultiSelect';
import validationSchema from 'pages/Semitrailers/SemitrailerForm/validationSchema';
import {useTranslation} from "react-i18next";

type BaseProps = {
	onSuccess: () => void,
	initialValues: ISemitrailer,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const SemitrailerForm: React.FC<Props> = props => {

	const dispatch = useDispatch();

	const createUpdateSuccess = useSelector<ReduxState>(state => state.semitrailers.formSuccess);

	const onSubmit = async (values: ISemitrailer) => {
		if (values.id) {
			dispatch(semitrailerActions.updateSemitrailer(values));
		} else {
			dispatch(semitrailerActions.createSemitrailer(values));
		}
	};

	useEffect(() => {
		if (createUpdateSuccess) {
			dispatch(semitrailerActions.formSemitrailerRestart());
			props.onSuccess();
		}
	}, [ createUpdateSuccess ]);

	const { t } = useTranslation();

	return (
		<Formik<ISemitrailer>
			initialValues={ props.initialValues }
			onSubmit={ onSubmit }
			validationSchema={ validationSchema }
		>
			{ formikBag => (
				<Form>
					<div className="form-row">
						<div className="col">
							<Field
								name="brand"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.brand')} { ...props } /> }
							</Field>
						</div>
						<div className="col">
							<Field
								name="plate"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.plate')} { ...props }/> }
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="capacity_weight"
							>
								{
									(props: FieldProps) => <NumberInput label={t('pages.capacityWeight')} { ...props } />
								}
							</Field>
						</div>
						<div className="col">
							<Field
								name="capacity_volume"
							>
								{
									(props: FieldProps) => <NumberInput label={<>{t('pages.capacityVolume')} (m<sup>3</sup>)</>} { ...props } />
								}
							</Field>
						</div>
						<div className="col">
							<Field
								name="capacity_pieces"
							>
								{
									(props: FieldProps) => <NumberInput label={t('pages.capacityPieces')} { ...props } />
								}
							</Field>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field
								name="description"
							>
								{
									(props: FieldProps) => <TextAreaInput label={t('pages.descriptionSemitrailer')} { ...props } />
								}
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="abilities"
							>
								{
									(props: FieldProps) => <AbilityAsyncMultiSelect label={t('pages.abilitiesSemitrailer')} { ...props } />
								}
							</Field>
						</div>
					</div>
					<div className="text-right">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={ ! formikBag.isValid || formikBag.isSubmitting }
						>
							{t('pages.saveSemitrailer')}
						</button>
					</div>
				</Form>
			) }
		</Formik>
	);
};

export default SemitrailerForm;
