import { IOrder } from 'model/interfaces/IOrder';
import dayjs from 'dayjs';
import { OrderTypeEnum } from 'model/enums/OrderTypeEnum';
import { initialValues as orderStatusInitialValues } from 'model/interfaces/IOrderStatus';
import { initialState as packageInitialValues } from 'model/interfaces/IPackage';

export const initialValues: IOrder = {
	id: 0,
	erp_id: '',
	created: '',
	last_updated: '',
	execution_date: dayjs().format('YYYY-MM-DD'),
	order_type: OrderTypeEnum.PICKUP,
	address_to: undefined,
	address_from: undefined,
	status: orderStatusInitialValues,
	package: packageInitialValues,
	demonstrator: 0,
	restriction_from: undefined,
	restriction_to: undefined,
	tags: [],
	comments: '',
	plan: 0,
};
