import React from 'react';
import { Marker as MapboxMarker } from 'react-mapbox-gl';

import * as ordersActions from 'pages/Orders/store/actions';
import { hasOrderLocation } from 'model/helpers/orders';
import { OrderTypeEnum } from 'model/enums/OrderTypeEnum';
import imgMapMarkerPickupUnassigned from 'images/map-marker-pickup-unassigned.svg';
import imgMapMarkerPickupCompleted from 'images/map-marker-pickup-completed.svg';
import imgMapMarkerPickupCancelled from 'images/map-marker-pickup-cancelled.svg';
import imgMapMarkerPickup from 'images/map-marker-pickup.svg';
import imgMapMarkerDeliveryUnassigned from 'images/map-marker-delivery-unassigned.svg';
import imgMapMarkerDeliveryCompleted from 'images/map-marker-delivery-completed.svg';
import imgMapMarkerDeliveryCancelled from 'images/map-marker-delivery-cancelled.svg';
import imgMapMarkerDelivery from 'images/map-marker-delivery.svg';
import imgMapMarkerRequisitionPickupUnassigned from 'images/map-marker-requisition-pickup-unassigned.svg';
import imgMapMarkerRequisitionPickupCompleted from 'images/map-marker-requisition-pickup-completed.svg';
import imgMapMarkerRequisitionPickupCancelled from 'images/map-marker-requisition-pickup-cancelled.svg';
import imgMapMarkerRequisitionPickup from 'images/map-marker-requisition-pickup.svg';
import imgMapMarkerRequisitionDeliveryUnassigned from 'images/map-marker-requisition-delivery-unassigned.svg';
import imgMapMarkerRequisitionDeliveryCompleted from 'images/map-marker-requisition-delivery-completed.svg';
import imgMapMarkerRequisitionDeliveryCancelled from 'images/map-marker-requisition-delivery-cancelled.svg';
import imgMapMarkerRequisitionDelivery from 'images/map-marker-requisition-delivery.svg';
import { IOrder } from 'model/interfaces/IOrder';
import { OrderStatusEnum } from 'model/enums/OrderStatusEnum';
import styles from 'pages/Homepage/index.module.scss';
import { IAssignment } from 'model/interfaces/IAssignment';
import { resolveDuplicateCoords } from 'pages/Orders/helpers';
import { Coords } from 'model/types/Coords';
import NewOrderModal from 'components/modals/NewOrder';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import { IPlan } from 'model/interfaces/IPlan';
import {useTranslation} from "react-i18next";

type MarkerProps = {
	coordinates: [number, number],
	order: IOrder,
	sequenceNumber?: number,
	unassignedImg: string,
	completedImg: string,
	cancelledImg: string,
	defaultImg: string,
	plan?: IPlan
};

const Marker: React.FC<MarkerProps> = (props) => {
	const [ isFormOpened, setIsFormOpened ] = React.useState(false);
	const closeForm = () => setIsFormOpened(false);

	const getPlan = (props: React.PropsWithChildren<MarkerProps>) => (state: ReduxState) => {
		return props.plan ?? state.plan.data!;
	}
	const plan = useSelector(getPlan(props));
	const { t } = useTranslation();

	return (
		<MapboxMarker
			coordinates={props.coordinates}
			anchor="bottom"
			className="cursor-pointer"
		>
			<>
				<NewOrderModal
					isOpen={ isFormOpened }
					onRequestClose={ closeForm }
					title={t('pages.customers.editOrder')}
					initialValues={ props.order }
					fetchAction={ ordersActions.fetchOrders }
					planId={ plan.id }
					shift={ plan.shift }
				/>

				<div className="position-relative" onClick={ () => setIsFormOpened(true) }>
					{(() => {
						switch (props.order.status.status) {
							case OrderStatusEnum.NEW:
								return <img
									src={props.unassignedImg}
									alt=""
								/>;
							case OrderStatusEnum.COMPLETED:
								return <img
									src={props.completedImg}
									alt=""
								/>;
							case OrderStatusEnum.CANCELLED:
								return <img
									src={props.cancelledImg}
									alt=""
								/>;
							default:
								return <img
									src={props.defaultImg}
									alt=""
								/>;
						}
					})()}
					{props.sequenceNumber &&
					<div className={styles.mapMarkerSequenceNumber}>{props.sequenceNumber}</div>
					}
				</div>
			</>
		</MapboxMarker>
	);
};

type Props = {
	orders: IOrder[],
	assignments?: IAssignment[],
	plan?: IPlan
}

const Markers: React.FC<Props> = ({ plan, orders, assignments }) => {
	const usedCoords: Coords[] = [];
	let orderSequenceNumber = 0;

	return (
		<>
			{orders.map((order, index) => {
				orderSequenceNumber++;

				if (!hasOrderLocation(order)) return undefined;

				switch (order.order_type) {
					case OrderTypeEnum.PICKUP:
						return (
							<Marker
								coordinates={resolveDuplicateCoords(order.address_from!.location!.coordinates, usedCoords)}
								order={order}
								unassignedImg={imgMapMarkerPickupUnassigned}
								completedImg={imgMapMarkerPickupCompleted}
								cancelledImg={imgMapMarkerPickupCancelled}
								defaultImg={imgMapMarkerPickup}
								key={index}
								sequenceNumber={assignments ? assignments[index].sequence_number : orderSequenceNumber}
								plan={plan}
							/>
						);

					case OrderTypeEnum.DELIVERY:
						return (
							<Marker
								coordinates={resolveDuplicateCoords(order.address_to!.location!.coordinates, usedCoords)}
								order={order}
								unassignedImg={imgMapMarkerDeliveryUnassigned}
								completedImg={imgMapMarkerDeliveryCompleted}
								cancelledImg={imgMapMarkerDeliveryCancelled}
								defaultImg={imgMapMarkerDelivery}
								key={index}
								sequenceNumber={assignments ? assignments[index].sequence_number : orderSequenceNumber}
								plan={plan}
							/>
						);

					case OrderTypeEnum.REQUISITION:
						return (
							<React.Fragment key={index}>
								<Marker
									coordinates={resolveDuplicateCoords(order.address_from!.location!.coordinates, usedCoords)}
									order={order}
									unassignedImg={imgMapMarkerRequisitionPickupUnassigned}
									completedImg={imgMapMarkerRequisitionPickupCompleted}
									cancelledImg={imgMapMarkerRequisitionPickupCancelled}
									defaultImg={imgMapMarkerRequisitionPickup}
									sequenceNumber={assignments ? assignments[index].sequence_number : orderSequenceNumber}
									plan={plan}
								/>

								<Marker
									coordinates={resolveDuplicateCoords(order.address_to!.location!.coordinates, usedCoords)}
									order={order}
									unassignedImg={imgMapMarkerRequisitionDeliveryUnassigned}
									completedImg={imgMapMarkerRequisitionDeliveryCompleted}
									cancelledImg={imgMapMarkerRequisitionDeliveryCancelled}
									defaultImg={imgMapMarkerRequisitionDelivery}
									sequenceNumber={assignments ? assignments[index].sequence_number : orderSequenceNumber}
									plan={plan}
								/>
							</React.Fragment>
						);

					default:
						return undefined;
				}
			})}
		</>
	);
};

export default Markers;