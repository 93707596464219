import { IShift } from 'model/interfaces/IShift';

const initialState: IShift = {
	id: 0,
	name: '',
	time_start: '',
	time_end: '',
	demonstrator: 0,
	demonstrator_settings: 0,
	days: [],
};

export default initialState;
