import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';

import Footer from 'pages/Planning/AssignedOrders/Footer';
import styles from './index.module.scss';
import { IPlanningRoute } from 'model/interfaces/IRoute';
import stylesLayout from 'pages/Layout.module.scss';
import Layout from 'pages/Layout';
import Droppable from './Droppable';
import { getVehicleRemainingVolume } from './utils';
import * as actions from 'pages/Planning/store/actions';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import { ModificationStatusEnum } from 'model/enums/ModificationStatusEnum';
import { IAssignment } from 'model/interfaces/IAssignment';
import { usePlanningRerouting } from 'model/hooks/usePlanningRerouting';
import ListOfOrders from 'pages/Planning/AssignedOrders/ListOfOrders';
import { ReduxState } from 'store/reducer';
import * as selectors from 'pages/Planning/store/selectors';
import { roundToMaxTwoDecimalPlaces } from 'model/helpers/numbers';
import {useTranslation} from "react-i18next";

type Props = {};

export type IRoutePartialAssignmentsState = {
	route?: IPlanningRoute,
	assignments: Array<Partial<IAssignment>>,
};

const AssignedOrders: React.FC<Props> = (props) => {
	const { initialRouteAssignments, plan } = useSelector((state: ReduxState) => ({
		initialRouteAssignments: selectors.getInitialRouteAssignments(state),
		plan: state.planning.plan.data!,
	}));

	const dispatch = useDispatch();

	const { routeAssignments, onDragEnd, isPristine, setPristine } = usePlanningRerouting(initialRouteAssignments);

	const { t } = useTranslation();

	return (
		<DragDropContext onDragEnd={ onDragEnd }>
			<Layout className={ stylesLayout.layoutPlanningAssignedOrders }>
				{props.children}

				<ListOfOrders
					assignments={routeAssignments[0].assignments}
					plan={plan}
				/>

				<div className={`${styles.vehicles} d-flex align-items-start`}>
					{ routeAssignments.slice(1).map((route, routeIndex) => (
						<div className={`${styles.vehicle} panel mr-3 flex-shrink-0 pb-1`} key={routeIndex}>
							<div className="panel-header">
								<div className="row">
									<div className="col-9">
										<div className="form-check">
											<input
												type="checkbox"
												className="form-check-input"
												id={`modification-status-checkbox-${routeIndex}`}
												checked={route.route!.modification_status === ModificationStatusEnum.ALGORITHMIC}
												onChange={() => dispatch(actions.setModificationStatus(route.route!.id!))}
											/>
											<label
												className="form-check-label"
												htmlFor={`modification-status-checkbox-${routeIndex}`}
											>
												<h2>
													{route.route?.vehicle?.brand} {route.route?.vehicle?.plate}
												</h2>
											</label>
										</div>
									</div>
									{(!route.route!.starting_trip || !route.route!.total_distance || !route.route!.ending_trip) &&
									<div className="col-3 text-right text-muted">
										{/*{getVehicleRemainingVolume(route.route!.vehicle!, route.assignments)} m<sup>3</sup>*/}
										<abbr title={t('pages.planning.assigned.vehicleTotalVolume')}>{ route.route?.vehicle?.capacity_volume ?? '?'} m<sup>3</sup></abbr>
									</div>
									}
								</div>
								{ route.route!.starting_trip && route.route!.total_distance && route.route!.ending_trip &&
								<div className="row mb-2">
									<div className={`col-4 ${route.route!.starting_trip?.load?.volume && route.route!.total_capacity_volume && route.route!.starting_trip?.load?.volume > route.route!.total_capacity_volume ? 'text-danger' : ''}`}>
										<abbr title={t('pages.planning.assigned.atBeginning')}>{typeof route.route!.starting_trip?.load?.volume !== 'undefined' ? roundToMaxTwoDecimalPlaces(route.route!.starting_trip?.load?.volume) : '?'} / {route.route!.total_capacity_volume} m<sup>3</sup></abbr>
									</div>
									<div className="col-4 text-center">
										<abbr title={t('pages.planning.assigned.totalDistance')}>{route.route!.total_distance ?? '?'} km</abbr>
									</div>
									<div className={`col-4 text-right ${route.route!.ending_trip?.load?.volume && route.route!.total_capacity_volume && route.route!.ending_trip?.load?.volume > route.route!.total_capacity_volume ? 'text-danger' : ''}`}>
										<abbr title={t('pages.planning.assigned.theEnd')}>{typeof route.route!.ending_trip?.load?.volume !== 'undefined' ? roundToMaxTwoDecimalPlaces(route.route!.ending_trip?.load?.volume) : '?'} / {route.route!.total_capacity_volume} m<sup>3</sup></abbr>
									</div>
								</div>
								}
							</div>

							<Droppable droppableId={`${routeIndex+1}`} assignments={route.assignments} />
						</div>
					))}
				</div>

				<Footer
					back={PlanningStepsEnum.ASSIGNED_ORDERS_SELECTION}
					isPristine={isPristine}
					setPristine={setPristine}
					routeAssignments={routeAssignments}
				/>
			</Layout>

		</DragDropContext>

	)


};

export default AssignedOrders;