import { IDay } from 'model/interfaces/IDay';

export enum DaysInWeekEnum {
	MONDAY = 1, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY
}

const DaysInWeek = [
	DaysInWeekEnum.MONDAY,
	DaysInWeekEnum.TUESDAY,
	DaysInWeekEnum.WEDNESDAY,
	DaysInWeekEnum.THURSDAY,
	DaysInWeekEnum.FRIDAY,
	DaysInWeekEnum.SATURDAY,
	DaysInWeekEnum.SUNDAY,
];

const DaysInWeekPairs = {
	[DaysInWeekEnum.MONDAY]: 'model.enums.monday',
	[DaysInWeekEnum.TUESDAY]: 'model.enums.tuesday',
	[DaysInWeekEnum.WEDNESDAY]: 'model.enums.wednesday',
	[DaysInWeekEnum.THURSDAY]: 'model.enums.thursday',
	[DaysInWeekEnum.FRIDAY]: 'model.enums.friday',
	[DaysInWeekEnum.SATURDAY]: 'model.enums.saturday',
	[DaysInWeekEnum.SUNDAY]: 'model.enums.sunday',
};

export const daysInWeekCheckboxList: IDay[] = DaysInWeek.map((day) => ({
	id: day,
	day: DaysInWeekPairs[day],
}));