import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import Orders from 'pages/Planning/Orders';
import Vehicles from 'pages/Planning/Vehicles';
import Parameters from 'pages/Planning/Parameters';
import Solution from 'pages/Planning/Solution';
import Finished from 'pages/Planning/Finished';
import NewPlan from 'pages/Planning/NewPlan';
import AssignedOrders from 'pages/Planning/AssignedOrders';
import RerouteSelected from 'pages/Planning/RerouteSelected';
import AssignedOrdersVehicleSelection from 'pages/Planning/AssignedOrders/VehicleSelection';
import RerouteSingle from 'pages/Planning/RerouteSingle';

export default {
	[PlanningStepsEnum.NEW_PLAN]: NewPlan,
	[PlanningStepsEnum.ORDERS]: Orders,
	[PlanningStepsEnum.VEHICLES]: Vehicles,
	[PlanningStepsEnum.PARAMETERS]: Parameters,
	[PlanningStepsEnum.CLUSTERING]: Orders,
	[PlanningStepsEnum.ASSIGNED_ORDERS_SELECTION]: AssignedOrdersVehicleSelection,
	[PlanningStepsEnum.ASSIGNED_ORDERS]: AssignedOrders,
	[PlanningStepsEnum.SCHEDULING_CRITERIA]: Orders,
	[PlanningStepsEnum.SOLUTION]: Solution,
	[PlanningStepsEnum.REROUTE_SELECTED]: RerouteSelected,
	[PlanningStepsEnum.REROUTE_SINGLE]: RerouteSingle,
	[PlanningStepsEnum.FINISHED]: Finished,
};