import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as planSelector from 'store/route/selectors';
import stylesLayout from 'pages/Layout.module.scss';
import VehicleRouteCard from 'components/VehicleRouteCard';
import Layout from 'pages/Layout';
import { ReduxState } from 'store/reducer';
import { IRoute } from 'model/interfaces/IRoute';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import EuFooter from 'components/EuFooter';
import DetailMap from 'pages/Homepage/DetailMap';
import { useHistory, useRouteMatch } from 'react-router';
import { useApi } from 'model/hooks/useApi';
import * as actions from 'store/route/actions';
import { useRerouting } from 'model/hooks/useRerouting';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import ListOfOrders from 'pages/Planning/AssignedOrders/ListOfOrders';
import Droppable from 'pages/Planning/AssignedOrders/Droppable';
import { RouteStatusEnum } from 'model/enums/RouteStatusEnum';
import Loader from 'components/Loader';
import NoPlanPage from 'components/NoPlanPage';
import { useCancelToken } from 'model/hooks/useCancelToken';
import * as ordersActions from 'pages/Orders/store/actions';
import {useTranslation} from "react-i18next";

type MatchParams = {
	id: string,
};

const Rerouting: React.FC = () => {
	const routeMatch = useRouteMatch<MatchParams>();

	const id = parseInt(routeMatch.params.id, 10);

	const { initialRoute, isLoading, plan } = useSelector((state: ReduxState) => ({
		initialRoute: planSelector.getRoute(state.routes, id),
		isLoading: state.routes.loading || false,
		plan: state.plan.data,
	}));

	const [ listOfOrdersHigherZindex, setListOfOrdersHigherZindex ] = useState(false);
	const [ route, setRoute ] = useState(initialRoute);
	const [ error, setError ] = useState();
	const { createNewToken, isCancel } = useCancelToken();
	// const [ areFiltersVisible, setFiltersVisible ] = useState(false);

	const api = useApi();
	const dispatch = useDispatch();
	const history = useHistory();

	const fetchRoute = async () => {
		try {
			const response = await api.getRequest<IRoute>(`${ UrlEnum.ROUTE }${ id }/`, {
				cancelToken: createNewToken(),
			});

			setRoute(response.data);
			setError(undefined);

		} catch (err) {
			if (isCancel(err)) return;
			console.error(err);

			setRoute(undefined);
			setError(err);
		}
	};

	useEffect(() => {
		fetchRoute();
		dispatch(ordersActions.fetchOrders());

	}, []);

	const { routeAssignments, onDragEnd, isPristine, setPristine } = useRerouting([{
		route: initialRoute,
		assignments: initialRoute!.assignments
	}]);

	const onDragStart = (result: DropResult) => {
		if (result.source.droppableId === '0') {
			setListOfOrdersHigherZindex(true);
		}
	}

	// useInterval(fetchRoute, POLLING_RATE);
	const { t } = useTranslation();

	if (typeof id === 'undefined') {
		throw new Error('No ID for detail provided');
	}

	if (!plan?.id) {
		return <NoPlanPage />;
	}

	return (
		<DragDropContext
			onDragEnd={ result => {
				onDragEnd(result);
				setListOfOrdersHigherZindex(false);
			} }
			onDragStart={ onDragStart }
		>
			<Loader visible={isLoading} />
			<Layout className={ stylesLayout.layoutDashboardRerouting }>
				<ListOfOrders
					assignments={routeAssignments[0].assignments}
					className={ `pb-4 ${listOfOrdersHigherZindex ? 'z-index-draggable' : ''}` }
					plan={plan}
				/>

				<aside className={ `${stylesLayout.aside}` }>
					<div className="panel panel--flex">
						<div className="panel-header mb-2">
							<button
								onClick={ () => history.goBack() }
								className="btn btn-sm btn-outline-secondary"
							>
								<i className="fa fa-angle-left mr-2"/>
								<span>{t('pages.homepageRe.back')}</span>
							</button>
						</div>
						<div className="panel-body px-0">
							{ route &&
							<>
								<div className="px-3 mb-2">
									<VehicleRouteCard route={ routeAssignments[1].route! }/>
								</div>

								{/*<FiltersButton*/}
								{/*	onClick={() => setFiltersVisible(!areFiltersVisible) }*/}
								{/*	activeFiltersCount={ activeFiltersCount }*/}
								{/*/>*/}

								{/*{areFiltersVisible &&*/}
								{/*<div className="bg-gray-200 rounded pt-3 px-3 mb-3">*/}
								{/*	<OrderFilters*/}
								{/*		onSearch={onSearch}*/}
								{/*	/>*/}
								{/*</div>*/}
								{/*}*/}

								{/*<div className="mt-2 mb-1">*/}
								{/*	<OrderBadges*/}
								{/*		orders={route.assignments.map(assignment => assignment.order)}*/}
								{/*		onSearch={ onSearch }*/}
								{/*		showDone*/}
								{/*	/>*/}
								{/*</div>*/}

								{/*<SearchFulltext*/}
								{/*	placeholder="Deliveries, pickups, addresses..."*/}
								{/*	onSearch={ onSearch }*/}
								{/*/>*/}

								<Droppable droppableId="1" assignments={routeAssignments[1].assignments} />
							</>
							}
						</div>
						<div className="text-right px-3 my-3">
							{ initialRoute?.status === RouteStatusEnum.UNDER_MODIFICATION && isPristine ?
								<>
									<button
										className="btn text-danger mr-2"
										onClick={ () => dispatch(actions.cancelRecalculate(routeAssignments)) }
									>
										{t('pages.homepageRe.cancel')}
									</button>
									<button
										className="btn btn-primary px-4"
										onClick={ () => dispatch(actions.saveRecalculate(routeAssignments)) }
									>
										{t('pages.homepageRe.save')}
									</button>
								</>
								:
								<>
									<button
										className="btn btn-primary"
										onClick={ () => {
											setPristine(true);
											dispatch(actions.recalculate(routeAssignments));
										}}
									>
										{t('pages.homepageRe.recalculate')}
									</button>
								</>
							}
						</div>
					</div>
				</aside>

				<footer className={ `${stylesLayout.footer} over-map` }>
					<EuFooter/>
				</footer>

				<DetailMap route={route} />
			</Layout>
		</DragDropContext>
	);
}

export default Rerouting;