import { createReducer } from 'deox';

import * as actions from 'pages/Addresses/store/actions';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';
import { IAddress } from 'model/interfaces/IAddress';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';

export interface IAddressesState extends IReduxFetchedState<IPaginatedData<IAddress>> {}

const initialState: IAddressesState = {};

export default createReducer(initialState, handle => [
	handle(actions.fetchAddresses, (state) => ({
		...state,
		loading: true,
	})),
	handle(actions.fetchAddressesSuccess, (state, { payload }) => ({
		...state,
		error: undefined,
		data: { ...payload },
		loading: false,
	})),
	handle(actions.fetchAddressesFail, (state, { payload }) => ({
		...state,
		data: undefined,
		error: { ...payload },
		loading: false,
	})),
	handle(actions.fetchMoreAddresses, (state) => ({
		...state,
		loading: true,
	})),
	handle(actions.fetchMoreAddressesSuccess, (state, { payload }) => ({
		...state,
		error: undefined,
		data: {
			...payload,
			results: [
				...state.data!.results,
				...payload.results,
			]
		},
		loading: false,
	})),
	handle(actions.fetchMoreAddressesFail, (state, { payload }) => ({
		...state,
		error: { ...payload },
		loading: false,
	})),
	handle(actions.createAddress, (state) => ({
		...state,
		formLoading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.updateAddress, (state) => ({
		...state,
		formLoading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.formAddressesuccess, (state, { payload }) => ({
		...state,
		formLoading: false,
		formSuccess: { ...payload },
		formError: undefined,
	})),
	handle(actions.formAddressFail, (state, { payload }) => ({
		...state,
		formLoading: false,
		formSuccess: undefined,
		formError: { ...payload },
	})),
	handle(actions.formAddressRestart, (state) => ({
		...state,
		formLoading: false,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.deleteAddress, (state) => ({
		...state,
		loading: true,
		deleteSuccess: undefined,
		deleteError: undefined,
	})),
	handle(actions.deleteAddressesuccess, (state, { payload }) => ({
		...state,
		loading: false,
		deleteSuccess: { ...payload },
		deleteError: undefined,
	})),
	handle(actions.deleteAddressFail, (state, { payload }) => ({
		...state,
		loading: false,
		success: false,
		error: { ...payload },
	})),
]);
