import { ISelectOptions } from 'model/interfaces/ISelectOptions';
import i18n from '../../i18n';

export const returnEnumPairsValue = (pairs: { [key: number]: string }, key: number) => i18n.t(pairs[key]);

export const returnEnumPairsOptions = (items: number[], pairs: { [key: number]: string } ): ISelectOptions[] => {
	return items.map((item) => ({
		label: i18n.t(pairs[item]),
		value: item
	}));
};