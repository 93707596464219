export enum OwnerTypeEnum {
	CUSTOM_USER = 11, CUSTOMER = 14, DEMONSTRATOR = 15,
}

export const OwnerTypes = [
	OwnerTypeEnum.CUSTOM_USER,
	OwnerTypeEnum.CUSTOMER,
	OwnerTypeEnum.DEMONSTRATOR,
];

export const OwnerTypePairs = {
	[OwnerTypeEnum.CUSTOM_USER]: 'model.enums.user',
	[OwnerTypeEnum.CUSTOMER]: 'model.enums.customer',
	[OwnerTypeEnum.DEMONSTRATOR]: 'model.enums.demonstrator',
};