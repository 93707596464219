import React from 'react';

import * as constants from './constants';
import stylesLayout from '../Layout.module.scss';
import Layout from 'pages/Layout';
import Grid from 'components/Grid';
import DriverFormModal from 'pages/Drivers/DriverFormModal';
import { IDriver } from 'model/interfaces/IDriver';
import * as actions from 'pages/Drivers/store/actions';
import initialState from 'pages/Drivers/DriverForm/initialState';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import { useAdminGrid } from 'model/hooks/useAdminGrid';
import { useSearchedEntity } from 'model/hooks/useSearchedEntity';
import { IGenericSearchRequest } from 'model/services/Api/interfaces/requests/IGenericSearchRequest';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { UserTypeEnum } from 'model/enums/UserTypeEnum';
import {useTranslation} from "react-i18next";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const Drivers: React.FC<Props> = props => {

	const {
		isFormOpened,
		openForm,
		closeForm,
		formInitialValues,
		editAction,
		deleteAction,
	} = useAdminGrid<IDriver>(
		initialState,
		"Do you want to delete this semitrailer?",
		actions.deleteDriver,
		actions.fetchDrivers,
	);

	const { drivers, isLoading, error } = useSelector((state: ReduxState) => ({
		drivers: state.drivers.data?.results ?? [],
		isLoading: state.drivers.loading,
		error: state.drivers.error,
	}));

	const [ searchedDrivers, onSearch ] = useSearchedEntity<IDriver, IGenericSearchRequest>(
		`${UrlEnum.USER}?user_type=${UserTypeEnum.DRIVER}`,
		drivers,
	);

	const { t } = useTranslation();

	return (
		<>
			<DriverFormModal
				isOpen={ isFormOpened }
				close={ closeForm }
				initialValues={ formInitialValues }
			/>
			<Layout className={stylesLayout.layoutSettings}>
				<section className={ stylesLayout.content }>
					<Grid<IDriver>
						data={ searchedDrivers }
						columns={ constants.COLUMNS }
						rowSelectors={ constants.SELECTORS }
						rowActions={ [editAction, deleteAction] }
						loading={ isLoading }
						searchCallback={ onSearch }
						buttons={ () => (
							<>
								<button
									className="btn btn-primary"
									onClick={ openForm }
								>
									{t('pages.drivers.addDriver')}
								</button>
							</>
						) }
					/>
				</section>
			</Layout>
		</>
	);
};

export default Drivers as React.FC<BaseProps>;
