import React from 'react';

import * as constants from './constants';
import stylesLayout from '../Layout.module.scss';
import Layout from 'pages/Layout';
import Grid from 'components/Grid';
import { IVehicleRestriction } from 'model/interfaces/IVehicleRestriction';
import * as actions from 'pages/VehicleRestrictions/store/actions';
import initialState from 'pages/VehicleRestrictions/VehicleRestrictionForm/initialState';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import { useAdminGrid } from 'model/hooks/useAdminGrid';
import VehicleRestrictionForm from 'pages/VehicleRestrictions/VehicleRestrictionForm';
import BaseModal from 'components/modals/BaseModal';
import { IVehicleRestrictionEndpoint } from 'model/interfaces/IVehicleRestrictionEndpoint';
import {useTranslation} from "react-i18next";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const VehicleRestrictions: React.FC<Props> = props => {

	const { t } = useTranslation();

	const {
		isFormOpened,
		openForm,
		closeForm,
		formInitialValues,
		editAction,
		deleteAction,
	} = useAdminGrid<IVehicleRestrictionEndpoint>(
		initialState,
		t('pages.deleteThisRestriction'),
		actions.deleteVehicleRestriction,
		actions.fetchVehicleRestrictions,
	);

	const { vehicleRestrictions, isLoading, error } = useSelector((state: ReduxState) => ({
		vehicleRestrictions: state.vehicleRestrictions.data?.results ?? [],
		isLoading: state.vehicleRestrictions.loading,
		error: state.vehicleRestrictions.error,
	}));

	return (
		<>
			<BaseModal
				isOpen={ isFormOpened }
				onRequestClose={ closeForm }
				title={t('pages.vehicleRestrictionForm')}
			>
				<VehicleRestrictionForm
					onSuccess={ closeForm }
					initialValues={ formInitialValues }
				/>
			</BaseModal>

			<Layout className={stylesLayout.layoutSettings}>
				<section className={ stylesLayout.content }>
					<Grid<IVehicleRestrictionEndpoint>
						data={ vehicleRestrictions }
						columns={ constants.COLUMNS }
						rowSelectors={ constants.SELECTORS }
						rowActions={ [editAction, deleteAction] }
						loading={ isLoading }
						buttons={ () => (
							<>
								<button
									className="btn btn-primary"
									onClick={ openForm }
								>
									{t('pages.addRestriction')}
								</button>
							</>
						) }
					/>
				</section>
			</Layout>
		</>
	);
};

export default VehicleRestrictions as React.FC<BaseProps>;
