import { IOrdersState } from 'pages/Orders/store/reducer';
import { IOrder } from 'model/interfaces/IOrder';
import { IAssignment } from 'model/interfaces/IAssignment';
import { OrderStatusEnum } from 'model/enums/OrderStatusEnum';

export const isLoading = (state: IOrdersState): boolean => !! state.loading;

export const getOrders = (state: IOrdersState): IOrder[] =>
	state.data?.results ?? [];

export const getUnassignedAssignments = (state: IOrdersState): Partial<IAssignment>[] =>
	state.data?.results.filter(order => order.status.status === OrderStatusEnum.NEW).map(order => ({ order })) ?? [];
