import React from 'react';

import BaseModal from 'components/modals/BaseModal';
import { IDriver } from 'model/interfaces/IDriver';
import DriverForm from 'pages/Drivers/DriverForm';
import {useTranslation} from "react-i18next";

type BaseProps = {
	isOpen: boolean,
	close: () => void,
	initialValues: IDriver,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const DriverFormModal: React.FC<Props> = props => {

	const onSuccess = () => {
		props.close();
	};

	const { t } = useTranslation();

	return (
		<BaseModal
			isOpen={ props.isOpen }
			onRequestClose={ props.close }
			title={t('pages.drivers.driverForm')}
		>
			<DriverForm
				onSuccess={ onSuccess }
				initialValues={ props.initialValues }
			/>
		</BaseModal>
	);
};

export default DriverFormModal;
