import React, { MouseEventHandler, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Instance } from 'tippy.js';

type InjectedProps = {
	hide: MouseEventHandler,
	onClick: MouseEventHandler,
}

type Props = {
	menu(props: InjectedProps): React.ReactChild | React.ReactChild[],
	children(props: InjectedProps): JSX.Element,
};

export type DropdownMenuProps = {
	hide: MouseEventHandler,
}

const Dropdown: React.FC<Props> = (props) => {

	const [ visible, setVisible ] = useState(false);

	const hide: MouseEventHandler = (e) => {
		e.stopPropagation();
		setVisible(false);
	}

	const onClickOutside = (instance: Instance, e: Event) => {
		setVisible(false);
	}

	const onClick: MouseEventHandler = (e) => {
		e.stopPropagation();
		setVisible(!visible);
	}

	return (
		<Tippy
			content={
				<div className="dropdown-menu dropdown-menu-right show position-static">
					{ props.menu({ onClick, hide }) }
				</div>
			}
			offset={ [0, 0] }
			arrow={ false }
			interactive={ true }
			visible={ visible }
			onClickOutside={ onClickOutside }
			appendTo={ document.body }
		>
			{ props.children({ onClick, hide }) }
		</Tippy>
	);

};

export default Dropdown;