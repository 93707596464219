import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Footer from "pages/Planning/Footer";
import * as actions from 'pages/Planning/store/actions';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import * as vehicleActions from 'pages/Vehicles/store/actions';
import Grid from 'components/Grid';
import { ReduxState } from 'store/reducer';
import { IPlanningRoute } from 'model/interfaces/IRoute';
import stylesLayout from 'pages/Layout.module.scss';
import Layout from 'pages/Layout';
import Dropdown from 'components/Popover';
import Row from './Row';
import ManageMenu from 'pages/Planning/Vehicles/ManageMenu';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { useSearchedEntity } from 'model/hooks/useSearchedEntity';
import { IGenericSearchRequest } from 'model/services/Api/interfaces/requests/IGenericSearchRequest';
import { IVehicle } from 'model/interfaces/IVehicle';
import {useTranslation} from "react-i18next";
import i18n from '../../../i18n';

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const COLUMNS: Array<string> = [
	"", "", "pages.planning.vehicles.semitrailer", "pages.planning.vehicles.driver", "pages.planning.vehicles.startEndPoint", "pages.planning.vehicles.wrkinHrs"
];

const Vehicles: React.FC < Props > = props => {
	const dispatch = useDispatch();

	const { rows, vehicles, isLoading, error, state, planId } = useSelector((state: ReduxState) => ({
		rows: state.planning.routes?.map(route => route.data as IPlanningRoute) ?? [],
		vehicles: state.vehicles.data?.results ?? [],
		isLoading: state.vehicles.loading,
		error: state.vehicles.error,
		state,
		planId: state.planning.plan.data?.id
	}));

	useEffect(() => {
		dispatch(vehicleActions.fetchVehicles());

		// Only run once while mounted
		// eslint-disable-next-line
	}, []);

	const { t } = useTranslation();

	const createRoutes = async () => {
		if (rows.filter(row => row.enabled).some(row => !row.starting_point || !row.ending_point)) {
			toast.error(i18n.t('pages.startEndPointAllVehicles'));
			return;
		}

		dispatch(actions.createRoutes(PlanningStepsEnum.PARAMETERS));
	};

	const someRoutesDisabled = rows.some(route => !route.enabled);

	const onSelectAllHandle = () => {
		dispatch(actions.setRoutesEnabled(someRoutesDisabled));
	};

	const [ searchedVehicles, onSearch ] = useSearchedEntity<IVehicle, IGenericSearchRequest>(UrlEnum.VEHICLE, vehicles);

	const searchVehiclesIds = searchedVehicles.map(vehicle => vehicle.id);
	const searchedRows = rows.filter(row => searchVehiclesIds.includes(row.vehicle!.id));

	return (
		<Layout className={ stylesLayout.layoutPlanning }>
			{props.children}

			<div className="d-flex justify-content-center overflow-hidden">
				<Grid<IPlanningRoute>
					data={ searchedRows }
					columns={ COLUMNS }
					rowRender={ props => <Row {...props} /> }
					loading={ isLoading }
					selectAllLabel={someRoutesDisabled ? t('pages.selectAll') : t('pages.deselectAll')}
					onSelectAllHandle={onSelectAllHandle}
					searchCallback={ onSearch }
					buttons={ () => (
						<>
							{/*<button*/}
							{/*	className="btn btn-sm btn-outline-primary"*/}
							{/*>*/}
							{/*	Reset to defaults*/}
							{/*	<i className="fa fa-angle-down ml-2" />*/}
							{/*</button>*/}
							<Dropdown menu={ ({ hide }) => <ManageMenu /> }>
								{ ({ onClick }) => (
									<button
										className="btn btn-sm btn-outline-primary"
										onClick={ onClick }
									>
										{t('pages.manage')}
										<i className="fa fa-angle-down ml-2" />
									</button>
								)}
							</Dropdown>
						</>
					) }
				/>
			</div>

			<Footer>
				<button
					className="btn"
					onClick={() => dispatch(actions.setStep(PlanningStepsEnum.ORDERS))}
				>
					{t('pages.backVehicle')}
				</button>
				<button
					className="btn btn-primary"
					onClick={ createRoutes }
				>
					{t('pages.nextVehicles')}
				</button>
			</Footer>
		</Layout>
	);
};

const ResetToDefaultsMenu = () => {
	const { t } = useTranslation();
	return (
		<div className="dropdown-menu dropdown-menu-right show position-static">
			<a className="dropdown-item" href="#TODO">
				{t('pages.semitrailerVehicle')}
			</a>
		</div>
	)
};

export default Vehicles;