import { IOrder } from 'model/interfaces/IOrder';
import { OrderTypeEnum } from 'model/enums/OrderTypeEnum';
import { OrderStatusEnum } from 'model/enums/OrderStatusEnum';
import { getDefinedLocations } from 'model/helpers/location';

export const hasOrderLocation = (order: IOrder): boolean => {
	switch (order.order_type) {
		case OrderTypeEnum.PICKUP:
			return !!order.address_from?.location;

		case OrderTypeEnum.DELIVERY:
			return !!order.address_to?.location;

		case OrderTypeEnum.REQUISITION:
			return !!order.address_from?.location && !!order.address_to?.location;

		default:
			return false;
	}
};

export const getAllOrdersLocations = (orders: IOrder[]): Array<[number, number]|undefined> => {
	const locations = [];

	for (const order of orders) {
		if ([OrderTypeEnum.PICKUP, OrderTypeEnum.REQUISITION].includes(order.order_type)) {
			locations.push(order.address_from?.location?.coordinates);
		}

		if ([OrderTypeEnum.DELIVERY, OrderTypeEnum.REQUISITION].includes(order.order_type)) {
			locations.push(order.address_to?.location?.coordinates);
		}
	}

	return locations;
};

export const getDefinedOrdersLocations = (orders: IOrder[]): Array<[number, number]> =>
	getDefinedLocations( getAllOrdersLocations(orders) );

export const getNoOfOrders = (
	orders: IOrder[]|undefined,
	status: Array<OrderStatusEnum>|undefined = undefined,
	orderType: OrderTypeEnum|undefined = undefined): number =>
{
	if (!orders) return 0;

	return orders
		.filter(order => status === undefined || status.includes(order.status.status) )
		.filter(order => orderType === undefined || order.order_type === orderType)
		.length;
};