import { isUnderModification } from 'pages/Planning/AssignedOrders/utils';
import * as actions from 'pages/Planning/store/actions';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import PlanningFooter from 'pages/Planning/Footer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import * as selectors from 'pages/Planning/store/selectors';
import { IRoutePartialAssignmentsState } from 'pages/Planning/AssignedOrders/index';
import {useTranslation} from "react-i18next";

type Props = {
	back: PlanningStepsEnum,
	isPristine: boolean,
	setPristine: React.Dispatch<React.SetStateAction<boolean>>,
	routeAssignments: IRoutePartialAssignmentsState[],
}

const Footer = (props: Props) => {

	const allEnabledAndSelectedVehicles = useSelector((state: ReduxState) =>
		selectors.getAllEnabledAndSelectedVehicles(state)
	);

	const dispatch = useDispatch();
	const { t } = useTranslation();

	return (
		<PlanningFooter>
			{isUnderModification(allEnabledAndSelectedVehicles) && props.isPristine ?
				<>
					<button
						className="btn text-danger mr-2"
						onClick={() => dispatch(actions.cancelRecalculate(props.routeAssignments))}

					>
						{t('pages.planning.assigned.cancel')}
					</button>
					<button
						className="btn btn-primary px-4"
						onClick={() => dispatch(actions.saveRecalculate(props.routeAssignments))}
					>
						{t('pages.planning.assigned.save')}
					</button>
				</>
				:
				<>
					<button
						className="btn"
						onClick={() => dispatch(actions.setStep(props.back))}
					>
						{t('pages.planning.assigned.back')}
					</button>
					<button
						className="btn btn-primary"
						onClick={() => {
							props.setPristine(true);
							dispatch(actions.recalculate(props.routeAssignments));
						}}
					>
						{t('pages.planning.assigned.recalculate')}
					</button>
				</>
			}
		</PlanningFooter>
	);
}

export default Footer;