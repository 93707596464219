import React from 'react';
import { FieldProps } from 'formik';

import BaseAsyncSelect from 'components/Form/BaseAsyncSelect';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IEngineType } from 'model/interfaces/IEngineType';

type BaseProps = {} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const EngineAsnycSelect: React.FC<Props> = props => (
	<BaseAsyncSelect<IEngineType>
		url={ `${UrlEnum.ENGINE_TYPE}` }
		getOptionLabel={ option => `${option.name}` }
		{ ...props }
	/>
);

export default EngineAsnycSelect;
