import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';

import * as actions from 'pages/Planning/store/actions';
import stylesLayout from 'pages/Layout.module.scss';
import Layout from 'pages/Layout';
import ShiftPicker from 'components/Header/ShiftPicker';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import cs from "date-fns/locale/cs";
import el from "date-fns/locale/el";
import it from "date-fns/locale/it";
import { IShift } from 'model/interfaces/IShift';
import {useTranslation} from "react-i18next";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const NewPlan: React.FC < Props > = props => {
	const [ storeDate, storeShift ] = useSelector((state: ReduxState) => [
		state.executionDate,
		state.shift,
	]);

	const [ date, setDate ] = useState(storeDate as string);
	const [ shift, setShift ] = useState(storeShift as IShift);

	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();

	registerLocale("cs", cs);
	registerLocale("it", it);
	registerLocale("el", el);

	return (
		<Layout className={ stylesLayout.layoutPlanningWithoutAside }>
			<div className="d-flex align-items-center justify-content-center">
				<div className="panel p-5 mb-9">
					<strong className="d-block pb-2">{t('pages.selectDate')}</strong>
					<ReactDatePicker
						locale={ i18n.language }
						onChange={ (date: Date) => setDate(date.toISOString()) }
						selected={ new Date(date) }
						todayButton={t('pages.today')}
						inline
					/>

					<div className="py-5">
						<strong className="d-block pb-2">{t('pages.selectShift')}</strong>
						<ShiftPicker
							shift={ shift }
							setShift={ setShift }
						/>
					</div>

					<button className="btn btn-primary btn-lg w-100" onClick={ () => dispatch(actions.createPlan(date, shift.id)) }>
						<i className="fa fa-sparkles text-white mr-2" />
						{t('pages.createNewPlan')}
					</button>
				</div>
			</div>
		</Layout>
	);
};

export default NewPlan;