import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useApi } from 'model/hooks/useApi';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import queryString from 'query-string';
import i18n from '../../i18n';

type ReturnType<IEntity, IEntityRequest> = [
	IEntity[], (request: IEntityRequest) => Promise<void>, number, boolean,
]

export function useSearchedEntity<IEntity extends object, IEntityRequest extends object>
	(url: string, entities: IEntity[], initialParams: IEntityRequest|URLSearchParams|{} = {}): ReturnType<IEntity, IEntityRequest> {

	const api = useApi();

	const [ searchedEntities, setSearchedEntities ] = useState(entities);
	const [ params, setParams ] = useState<IEntityRequest|{}>({});
	const [ isLoading, setLoading ] = useState(false);

	useEffect(() => {
		if (!isEqual(entities, searchedEntities)) {
			setSearchedEntities(entities);
		}

	}, [ entities ]);

	const onSearch = async (paramsRequest: IEntityRequest) => {

		const newParams: IEntityRequest = Object.assign({}, params, paramsRequest);

		for (const [ key, value ] of Object.entries(newParams)) {
			if (value === null) {
				delete newParams[key as keyof IEntityRequest];
			}
		}

		setParams(newParams);

		const config: AxiosRequestConfig = {
			params: {
				...initialParams,
				...newParams,
			},
			paramsSerializer: params => queryString.stringify(params),
		};

		setLoading(true);

		try {
			const response: AxiosResponse<IPaginatedData<IEntity>> = await api.getRequest(url, config);
			setSearchedEntities(response.data.results);

		} catch (err) {
			console.error(err);
			toast.error(i18n.t('hooks.FetchingDataFailed'));
		}

		setLoading(false);
	};

	return [ searchedEntities, onSearch, Object.keys(params).length, isLoading ];
}