import { IVehicleAbility } from 'model/interfaces/IVehicleAbility';

const initialState: IVehicleAbility = {
	id: 0,
	name: '',
	demonstrator: 0,
	description: '',
};

export default initialState;
