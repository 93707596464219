import { all, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as authActions from 'store/auth/actions';
import authSagas from 'store/auth/sagas';
import vehiclesSagas from 'pages/Vehicles/store/sagas';
import { ActionType } from 'deox';
import { StatusCodeEnum } from 'model/services/Api/enums/StatusCodeEnum';
import routeSagas from 'store/route/sagas';
import ordersSagas from 'pages/Orders/store/sagas';
import semitrailersSagas from 'pages/Semitrailers/store/sagas';
import driversSagas from 'pages/Drivers/store/sagas';
import timeWindowsSagas from 'pages/TimeWindows/store/sagas';
import shiftsSagas from 'pages/Shifts/store/sagas';
import packagesSagas from 'pages/Packages/store/sagas';
import vehicleAbilitiesSagas from 'pages/VehicleAbilities/store/sagas';
import materialTagsSagas from 'pages/MaterialTags/store/sagas';
import customersSagas from 'pages/Customers/store/sagas';
import addressesSagas from 'pages/Addresses/store/sagas';
import loggedInUserSagas from 'store/loggedInUser/sagas';
import planningSagas from 'pages/Planning/store/sagas';
import vehicleRestrictionsSagas from 'pages/VehicleRestrictions/store/sagas';
import planSagas from 'store/plan/sagas';
import executionDateSagas from 'store/executionDate/sagas';
import shiftSagas from 'store/shift/sagas';
import { resetMemoryState } from 'model/hooks/useMemoryState';
import i18n from '../i18n';

function* handleFailedRequest(action: ActionType<any>) {
	const payload = action.payload;
	const statusCode = payload?.status;

	if (typeof statusCode !== 'undefined') {
		const isToast = payload?.toast
		if (typeof isToast !== 'undefined' && isToast) {
			toast.error(payload.message);
		}

		switch (statusCode) {
			case StatusCodeEnum.FORBIDDEN:
				yield put(authActions.destroy());
				break;
		}
	} else {
		toast.error(i18n.t(payload.message));
	}
}

function handleSuccessRequest(action: ActionType<any>) {
	const payload = action.payload;
	const isToast = payload?.toast;

	if (typeof isToast !== 'undefined' && isToast) {
		toast.success(i18n.t(payload.message));
	}
}

/**
 * Will catch every failed request
 * Requires every failed request actions to end with "_FAIL" to work
 */
function* watchFailedRequest() {
	yield takeLatest((action: ActionType<any>) => /_FAIL$/.test(action.type), handleFailedRequest)
}

function *watchSuccessReuqest() {
	yield takeEvery((action: ActionType<any>) => /_SUCCESS$/.test(action.type), handleSuccessRequest);
}

function *watchCreateUpdateDelete() {
	yield takeEvery((action: ActionType<any>) => /CREATE|UPDATE|DELETE/.test(action.type), resetMemoryState);
}

export default function* rootSaga() {
	yield all([
		watchFailedRequest(),
		watchSuccessReuqest(),
		...authSagas,
		...vehiclesSagas,
		...routeSagas,
		...ordersSagas,
		...semitrailersSagas,
		...driversSagas,
		...timeWindowsSagas,
		...shiftsSagas,
		...packagesSagas,
		...vehicleAbilitiesSagas,
		...vehicleRestrictionsSagas,
		...materialTagsSagas,
		...customersSagas,
		...addressesSagas,
		...loggedInUserSagas,
		...planningSagas,
		...executionDateSagas,
		...shiftSagas,
		...planSagas,
		watchCreateUpdateDelete(),
	]);
}
