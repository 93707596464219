import React from 'react';
import {useTranslation} from "react-i18next";

type BaseProps = {
	onClick: () => void,
	activeFiltersCount?: number,
	className?: string,
	isLoading?: boolean,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const FiltersButton: React.FC<Props> = ({ activeFiltersCount = 0, ...props }) => {
	const { t } = useTranslation();

	return (
		<>
			<button
				className={`btn btn-sm ${props.className ?? ''}`}
				onClick={ props.onClick }
			>
				<i className="fa fa-sliders-h mr-2"/>
				<span>{t('components.buttons.filters')}</span>
				{activeFiltersCount > 0 &&
					<span className="badge badge-primary badge-pill ml-2 text-white">{activeFiltersCount}</span>
				}
			</button>
			{ props.isLoading &&
				<i className="fas fa-spinner fa-pulse text-large ml-2 align-middle" />
			}
		</>
	);
};

export default FiltersButton;
