import React from 'react';
import { Marker } from 'react-mapbox-gl';

import Map, { BaseProps as MapBaseProps } from 'components/Map';
import { IRoute } from 'model/interfaces/IRoute';
import mapMarkerVehicleStart from 'images/map-marker-vehicle-start.svg';
import mapMarkerVehicleInProgress from 'images/map-marker-vehicle-in-progress.svg';
import mapMarkerVehicleEnd from 'images/map-marker-vehicle-end.svg';
import { usePrevious } from 'model/hooks/usePrevious';
import { getPlanVehicleLocations } from 'model/helpers/route';
import { LngLatBounds } from 'mapbox-gl';
import { FitBounds } from 'react-mapbox-gl/lib/map';


type Props = {
	routes?: IRoute[],
};

export const HomepageMap: React.FC<Props> = (props) => {

	const routes = props.routes;
	const prevRoutes = usePrevious(routes);

	const mapProps: MapBaseProps = {};

	if (!prevRoutes && routes) {
		const locations = getPlanVehicleLocations(routes);

		// https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
		// Pass the first coordinates in the LineString to `lngLatBounds` &
		// wrap each coordinate pair in `extend` to include them in the bounds
		// result. A variation of this technique could be applied to zooming
		// to the bounds of multiple Points or Polygon geomteries - it just
		// requires wrapping all the coordinates with the extend method.
		if (locations.length > 0) {
			mapProps.fitBounds = locations.reduce(function (bounds, coord) {
				return bounds.extend(coord);
			}, new LngLatBounds(locations[0], locations[0])).toArray() as FitBounds;
		}
	}

	return (
		<Map { ...mapProps }>
			{ props.routes && props.routes.map((route, index) => {
				const coordinates = route.starting_point?.location?.coordinates;
				if (!coordinates) return undefined;

				return (
					<Marker coordinates={coordinates} anchor="bottom" key={index}>
						<img src={mapMarkerVehicleStart} alt="" />
					</Marker>
				)
			})}
		</Map>
	);


};

export default HomepageMap;