import { createReducer } from 'deox';

import * as actions from 'pages/Shifts/store/actions';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';
import { IShift } from 'model/interfaces/IShift';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';
import { IApiError } from 'model/interfaces/IApiError';

export interface IShiftsState extends IReduxFetchedState<IPaginatedData<IShift>> {
	formSuccess?: IReduxActionState | boolean | undefined,
	formError?: (IReduxActionState & IApiError) | undefined,

	deleteSuccess?: IReduxActionState | boolean,
	deleteError?: IReduxActionState & IApiError,
}

const initialState: IShiftsState = {};

export default createReducer(initialState, handle => [
	handle(actions.fetchShifts, (state) => ({
		...state,
		loading: true,
	})),
	handle(actions.fetchShiftsSuccess, (state, { payload }) => ({
		...state,
		error: undefined,
		data: { ...payload },
		loading: false,
	})),
	handle(actions.fetchShiftsFail, (state, { payload }) => ({
		...state,
		data: undefined,
		error: { ...payload },
		loading: false,
	})),
	handle(actions.createShift, (state) => ({
		...state,
		loading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.updateShift, (state) => ({
		...state,
		loading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.formShiftSuccess, (state, { payload }) => ({
		...state,
		loading: false,
		formSuccess: { ...payload },
		formError: undefined,
	})),
	handle(actions.formShiftFail, (state, { payload }) => ({
		...state,
		loading: false,
		formSuccess: undefined,
		formError: { ...payload },
	})),
	handle(actions.formShiftRestart, (state) => ({
		...state,
		loading: false,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.deleteShift, (state) => ({
		...state,
		loading: true,
		deleteSuccess: undefined,
		deleteError: undefined,
	})),
	handle(actions.deleteShiftSuccess, (state, { payload }) => ({
		...state,
		loading: false,
		deleteSuccess: { ...payload },
		deleteError: undefined,
	})),
	handle(actions.deleteShiftFail, (state, { payload }) => ({
		...state,
		loading: false,
		success: false,
		error: { ...payload },
	})),
]);
