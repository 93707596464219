import React from 'react';
import { ErrorMessage as FormikErrorMessage } from 'formik';
import i18n from '../../i18n';

type Props = {
	name: string
}

const ErrorMessage = ({ name }: Props) => (
	<FormikErrorMessage name={ name }>
		{ (msg) => <div className="alert alert-danger">{ i18n.t(msg) }</div> }
	</FormikErrorMessage>
);

export default ErrorMessage;