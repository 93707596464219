import { createActionCreator } from 'deox';
import { IApiError } from 'model/interfaces/IApiError';
import { IAddress } from 'model/interfaces/IAddress';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';

export enum AddressActionsEnum {
	FETCH = '@ADDRESSES_FETCH',
	FETCH_SUCCESS = '@ADDRESSES_FETCH_SUCCESS',
	FETCH_FAIL = '@ADDRESSES_FETCH_FAIL',

	FETCH_MORE = '@ADDRESSES_FETCH_MORE',
	FETCH_MORE_SUCCESS = '@ADDRESSES_FETCH_MORE_SUCCESS',
	FETCH_MORE_FAIL = '@ADDRESSES_FETCH_MORE_FAIL',

	CREATE = '@ADDRESSES_CREATE',
	UPDATE = '@ADDRESSES_UPDATE',
	FORM_SUCCESS = '@ADDRESSES_FORM_SUCCESS',
	FORM_FAIL = '@ADDRESSES_FORM_FAIL',
	FORM_RESTART = '@ADDRESSES_FORM_RESTART',

	DELETE = '@ADDRESSES_DELETE',
	DELETE_SUCCESS = '@ADDRESSES_DELETE_SUCCESS',
	DELETE_FAIL = '@ADDRESSES_DELETE_FAIL',
}

export const fetchAddresses = createActionCreator(AddressActionsEnum.FETCH);

export const fetchAddressesFail = createActionCreator(
	AddressActionsEnum.FETCH_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const fetchAddressesSuccess = createActionCreator(
	AddressActionsEnum.FETCH_SUCCESS,
	resolve => (data: IPaginatedData<IAddress>) => resolve(data),
);

export const fetchMoreAddresses = createActionCreator(AddressActionsEnum.FETCH_MORE);

export const fetchMoreAddressesFail = createActionCreator(
	AddressActionsEnum.FETCH_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const fetchMoreAddressesSuccess = createActionCreator(
	AddressActionsEnum.FETCH_MORE_SUCCESS,
	resolve => (data: IPaginatedData<IAddress>) => resolve(data),
);

export const createAddress = createActionCreator(
	AddressActionsEnum.CREATE,
	resolve => (address: IAddress) => resolve(address),
);

export const updateAddress = createActionCreator(
	AddressActionsEnum.UPDATE,
	resolve => (address: IAddress) => resolve(address),
);

export const formAddressesuccess = createActionCreator(
	AddressActionsEnum.FORM_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success));

export const formAddressFail = createActionCreator(
	AddressActionsEnum.FORM_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const formAddressRestart = createActionCreator(AddressActionsEnum.FORM_RESTART);

export const deleteAddress = createActionCreator(
	AddressActionsEnum.DELETE,
	resolve => (address: IAddress) => resolve(address),
);

export const deleteAddressesuccess = createActionCreator(
	AddressActionsEnum.DELETE_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success)
);

export const deleteAddressFail = createActionCreator(
	AddressActionsEnum.DELETE_FAIL,
	resolve => (error: IReduxActionState & IApiError) => resolve(error),
);
