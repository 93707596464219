import { IVehicle } from 'model/interfaces/IVehicle';
import { LicenceTypeEnum } from 'model/enums/LicenceTypeEnum';
import { ContractTypeEnum } from 'model/enums/ContractTypeEnum';
import { DeletedEnum } from 'model/enums/DeletedEnum';

const initialState: IVehicle = {
	id: 0,
	abilities: [],
	demonstrator: 0,
	plate: '',
	brand: '',
	license_type: LicenceTypeEnum.AMATEUR,
	contract_type: ContractTypeEnum.OWNED,
	comments: '',
	engine: undefined,
	fuel: undefined,
	description: undefined,
	capacity_volume: undefined,
	capacity_weight: undefined,
	deleted: DeletedEnum.NORMAL,
};

export default initialState;
