import React, { Suspense } from 'react';
import { compose } from 'redux';
import { connect, DispatchProp, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, Switch as RouterSwitch, withRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import * as authSelectors from 'store/auth/selectors';
import routes from 'routes';
import { ReduxState } from 'store/reducer';
import { RoutesEnum, SettingsRoutes } from 'model/enums/RoutesEnum';
import Loader from 'components/Loader';
import * as planActions from 'store/plan/actions';
import { useTranslation } from 'react-i18next';
import {MapContextProvider} from "./contexts/MapContext";

type BaseProps = {};

type InjectedProps = {
	isLoggedIn: boolean,
} & RouteComponentProps & DispatchProp;

type Props = BaseProps & InjectedProps;

const App: React.FC<Props> = props => {
	const { isLoggedIn, history, location: { pathname }  } = props;

	const isPlanLoading = useSelector((state: ReduxState) => state.plan.loading ?? false);

	const dispatch = useDispatch();
	const { i18n } = useTranslation();

	React.useEffect(() => {
		if (pathname === RoutesEnum.LOGIN || isLoggedIn) {
		  return;
		}

		history.push(RoutesEnum.LOGIN);
	}, [isLoggedIn, pathname, history]);

	React.useEffect(() => {
		// To update the plan state in header - only on reload and only when we're not in Planning/Settings/Login section
		if (pathname !== RoutesEnum.PLANNING && pathname !== RoutesEnum.LOGIN && !SettingsRoutes.includes(pathname))  {
			dispatch(planActions.fetchPlan());
		}
	}, []);

	React.useEffect(() => {
		const language = localStorage.getItem('language')
		if (i18n.isInitialized && language) {
			i18n.changeLanguage(language)
		}
	}, [i18n.isInitialized]);

	// Without it, a homepage would load for a split of seconds and its effect function would run before running
	// effect in this file and redirecting to login screen
	if (pathname !== RoutesEnum.LOGIN && !isLoggedIn) {
		history.push(RoutesEnum.LOGIN);
		return null;
	}

	if (!i18n.isInitialized) {
		return null;
	}

	return (
		<Suspense fallback={null}>
			<RouterSwitch>
				<MapContextProvider>
					{ renderRoutes(routes) }
				</MapContextProvider>
			</RouterSwitch>
			<Loader visible={isPlanLoading} />
		</Suspense>
	)
};

export default compose(
	withRouter,
	connect((state: ReduxState) => ({
		isLoggedIn: authSelectors.isLoggedIn(state.auth),
	})),
)(App) as React.FC<BaseProps>;
