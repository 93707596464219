import { OrderTypeEnum } from 'model/enums/OrderTypeEnum';
import React  from 'react';
import { IOrder } from 'model/interfaces/IOrder';
import KeyValueRows from 'components/VehicleRouteCard/KeyValueRows';
import { useTranslation } from 'react-i18next';

type OrderInfoProps = {
	order: IOrder,
	showMore: boolean,
};

const OrderMoreInfo: React.FC<OrderInfoProps> = (props) => {

	const { t } = useTranslation();

	const ordersInformation: any = {};

	ordersInformation[t('cards.executionDate')] = <>{props.order.execution_date}</>;
	ordersInformation[t('cards.volumePerPiece')] = <>{props.order.status.volume?.toFixed(2) } m<sup>3</sup> (total { (props.order.status.volume * props.order.status.quantity).toFixed(2)} m<sup>3</sup>)</>;
	ordersInformation[t('cards.weightPerPiece')] = <>{ props.order.status.weight?.toFixed(2) } kg (total { (props.order.status.weight * props.order.status.quantity).toFixed(2)} kg)</>;
	ordersInformation[t('cards.dimensionPerPiece')] = <>{ props.order.package.length?.toFixed(2) || '0.00' } x { props.order.package.width?.toFixed(2) || '0.00' } x { props.order.package.height?.toFixed(2) || '0.00' } m</>;
	ordersInformation[t('cards.ordersId')] = <>{ props.order.erp_id }</>;

	if ( props.order.order_type === OrderTypeEnum.PICKUP || props.order.order_type === OrderTypeEnum.REQUISITION ) {
		ordersInformation[t('cards.loadingServiceTime')] = props.order.status.loading_service_time;
	}

	if ( props.order.order_type === OrderTypeEnum.DELIVERY || props.order.order_type === OrderTypeEnum.REQUISITION ) {
		ordersInformation[t('cards.unloadingServiceTime')] = props.order.status.unloading_service_time;
	}

	if (!props.showMore) return null;

	return (

	<div className="mt-2">
			<strong>{t('cards.ordersInfo')}</strong><br />
			<KeyValueRows items={ordersInformation} />

			{props.order.comments &&
			<div className="my-2">
				<strong>{t('cards.operatorsComments')}</strong><br />
				{props.order.comments}
			</div>
			}

			{props.order.status.description &&
			<div className="my-2">
				<strong>{t('cards.driversComments')}</strong><br />
				{props.order.status.description}
			</div>
			}
		</div>
	);
};

export default OrderMoreInfo;