import React from 'react';
import { getContractType } from 'model/enums/ContractTypeEnum';
import { IVehicle } from 'model/interfaces/IVehicle';

type Props = {
	vehicle: IVehicle
}

const VehicleDescription: React.FC<Props> = ({ vehicle }) => (
	<>
		<strong>{vehicle.brand} {vehicle.plate}</strong><br />
		{getContractType(vehicle.contract_type)}, {vehicle.engine?.name}, {vehicle.capacity_volume}m<sup>3</sup>
	</>
);

export default VehicleDescription;