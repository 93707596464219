import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { isEmpty, cloneDeep } from 'lodash';

import * as actions from 'pages/Shifts/store/actions';
import * as shiftActions from 'store/shift/actions';
import { apiFactory } from 'model/services/Api/Api';
import { IShiftsResponse } from 'model/services/Api/interfaces/responses/IShiftsResponse';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { ActionType } from 'deox';
import { ReduxState } from 'store/reducer';
import { IDay } from 'model/interfaces/IDay';
import { IShift } from 'model/interfaces/IShift';

function * fetchShifts() {
	const state: ReduxState = yield select();
	const api = apiFactory(state);

	try {
		const response: IShiftsResponse = yield call(api.getRequest, UrlEnum.SHIFT);
		yield put(actions.fetchShiftsSuccess(response.data));

		if ( (isEmpty(state.shift) || !response.data.results.map(shift => shift.id).includes(state.shift.id)) &&
			response.data.results[0]
		) {
			yield put(shiftActions.set(response.data.results[0]))
		}
	} catch (err) {
		yield put(actions.fetchShiftsFail(err));
	}
}

function * createShift(action: ActionType<typeof actions.createShift>) {
	const api = apiFactory(yield select());

	const payload = preparePayloadForCreateOrUpload(action.payload);

	try {
		yield call(api.postRequest, UrlEnum.SHIFT, payload);
		yield put(actions.fetchShifts());
		yield put(actions.formShiftSuccess({
			message: 'pages.shifts.created',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formShiftFail(err));
	}
}

function * updateShift(action: ActionType<typeof actions.updateShift>) {
	const api = apiFactory(yield select());

	const payload = preparePayloadForCreateOrUpload(action.payload);

	try {
		yield call(api.patchRequest, `${UrlEnum.SHIFT}${action.payload.id}/`, payload);
		yield put(actions.fetchShifts());
		yield put(actions.formShiftSuccess({
			message: 'pages.shifts.updated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formShiftFail(err));
	}
}

function preparePayloadForCreateOrUpload(_payload: IShift) {
	const payload = cloneDeep(_payload) as any;

	if (payload.days) {
		payload.days = payload.days.map((day: IDay) => day.id);
	}

	return payload;
}

function * deleteShift(action: ActionType<typeof actions.deleteShift>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.deleteRequest, `${UrlEnum.SHIFT}${action.payload.id}/`);
		yield put(actions.fetchShifts());
		yield put(actions.deleteShiftSuccess({
			message: 'pages.shifts.removed',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.deleteShiftFail(err));
	}
}

function * watchFetchShifts() {
	yield takeLatest(actions.fetchShifts, fetchShifts);
}

function * watchDeleteShift() {
	yield takeEvery(actions.deleteShift, deleteShift);
}

function * watchCreateShift() {
	yield takeEvery(actions.createShift, createShift);
}

function * watchUpdateShift() {
	yield takeEvery(actions.updateShift, updateShift);
}

const shiftsSagas = [
	watchFetchShifts(),
	watchDeleteShift(),
	watchCreateShift(),
	watchUpdateShift(),
];

export default shiftsSagas;
