import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const localTimeFromUTCDatetime = (datetime?: string): string => {
	const datetimeMoment = dayjs(datetime);
	return datetimeMoment.format('H:mm');
};

export const timeWithoutSeconds = (time?: string): string => {
	const timeMoment = dayjs(time, 'HH:mm:ss');
	return timeMoment.format('H:mm');
};

export const isoDayFromUTCDatetime = (datetime?: string): string => {
	const datetimeMoment = dayjs(datetime);
	return datetimeMoment.format('YYYY-MM-DD');
};