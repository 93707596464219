import React from 'react';
import { Field, FieldArray, FieldProps } from 'formik';

import TextInput from 'components/Form/TextInput';
import TextAreaInput from 'components/Form/TextAreaInput';
import SelectInput from 'components/Form/SelectInput';
import {OwnerTypePairs, OwnerTypes} from 'model/enums/OwnerTypeEnum';
import * as Yup from 'yup';
import CoordinatesInput from 'components/Form/CoordinatesInput';
import {useTranslation} from "react-i18next";
import {returnEnumPairsOptions} from "../../model/helpers/enums";

type BaseProps = {
	name?: string,
	removeHandler?: () => void,
	showContentTypeAndObjectId?: boolean,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

export const validationSchema = Yup.object().shape({
	address: Yup.string().required('form.streetIsRequired'),
	city: Yup.string().required('form.cityIsRequired'),
	postal_code: Yup.string().required('form.postalCodeIsRequired'),
	// location: Yup.object().shape({
	// 	coordinates: Yup.array().min(2).max(2).of( Yup.number() ).required('Coordinates are required.'),
	// })
});

const AddressFormComponent: React.FC<Props> = ({ name, removeHandler, showContentTypeAndObjectId = false }) => {

	const fieldName = (fieldName: string) => name ? `${name}.${fieldName}` : fieldName;

	const OptionalFieldset = name ? 'fieldset' : 'div';

	const { t } = useTranslation();

	return (
		<OptionalFieldset>
			{ name && <legend>{t('form.addressLegend')}</legend> }
			{ removeHandler &&
			<div className="text-right">
				<button
					className="btn btn-sm btn-outline-secondary"
					type="button"
					onClick={ removeHandler }
				>
					<i className="fa fa-times mr-2" /> {t('form.remove')}
				</button>
			</div>
			}
			<div className="form-row">
				<div className="col-6">
					<Field name={ fieldName(t('form.address')) }>
						{ (props: FieldProps) => <TextInput label={t('form.street')} { ...props }/> }
					</Field>
				</div>
				<div className="col-6">
					<Field name={ fieldName('city') }>
						{ (props: FieldProps) => <TextInput label={t('form.city')} { ...props }/> }
					</Field>
				</div>
			</div>
			<div className="form-row">
				<div className="col-6">
					<Field name={ fieldName('postal_code') }>
						{ (props: FieldProps) => <TextInput label={t('form.postalCode')} { ...props }/> }
					</Field>
				</div>
				<div className="col-6">
					<FieldArray name={ fieldName('location.coordinates') }>
						{ (props) => <CoordinatesInput { ...props }/> }
					</FieldArray>

					{/*<div className="form-row">*/}
					{/*	<div className="col-6">*/}
					{/*		<Field name={ fieldName('location.coordinates.1') }>*/}
					{/*			{ (props: FieldProps) => <TextInput label="Latitude" type="number" { ...props }/> }*/}
					{/*		</Field>*/}
					{/*	</div>*/}
					{/*	<div className="col-6">*/}
					{/*		<Field name={ fieldName('location.coordinates.0') }>*/}
					{/*			{ (props: FieldProps) => <TextInput label="Longitude" type="number" { ...props }/> }*/}
					{/*		</Field>*/}
					{/*	</div>*/}
					{/*</div>*/}
				</div>
			</div>
			<div className="form-row">
				<div className="col-6">
				<Field name={ fieldName('erp_id') }>
						{ (props: FieldProps) => <TextInput label={t('form.erpId')} { ...props }/> }
					</Field>
				</div>
			</div>
			<div className="form-row">
				<div className="col">
					<Field name={ fieldName('comments') }>
						{ (props: FieldProps) => <TextAreaInput label={t('form.addressComments')} { ...props }/> }
					</Field>
				</div>
			</div>
			{/*<div className="form-row">*/}
			{/*	<div className="col">*/}
			{/*		<Field name={ fieldName('address_type') }>*/}
			{/*			{ (props: FieldProps) =>*/}
			{/*				<SelectInput*/}
			{/*					label="Address Type"*/}
			{/*					options={ addressTypeOptions }*/}
			{/*					noEmptyValue*/}
			{/*					{ ...props }*/}
			{/*				/>*/}
			{/*			}*/}
			{/*		</Field>*/}
			{/*	</div>*/}
			{/*	<div className="col">*/}
			{/*		<Field name={ fieldName('verification_type') }>*/}
			{/*			{ (props: FieldProps) =>*/}
			{/*				<SelectInput*/}
			{/*					label="Verification Type"*/}
			{/*					options={ verificationTypeOptions }*/}
			{/*					noEmptyValue*/}
			{/*					{ ...props }*/}
			{/*				/>*/}
			{/*			}*/}
			{/*		</Field>*/}
			{/*	</div>*/}
			{/*</div>*/}
			{ showContentTypeAndObjectId &&
			<div className="form-row">
				<div className="col">
					<Field name={ fieldName('content_type') }>
						{ (props: FieldProps) =>
							<SelectInput
								label={t('form.ownerType')}
								options={ returnEnumPairsOptions(OwnerTypes, OwnerTypePairs) }
								noEmptyValue
								{ ...props }
							/>
						}
					</Field>
				</div>
				<div className="col">
					<Field name={ fieldName('object_id') }>
						{ (props: FieldProps) => <TextInput label={t('form.ownerId')} { ...props } /> }
					</Field>
				</div>
			</div>
			}
		</OptionalFieldset>
	);
};

export default AddressFormComponent;
