import { createReducer } from 'deox';

import * as actions from './actions';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';
import { IUser } from 'model/interfaces/IUser';

export interface ILoggedInUserState extends IReduxFetchedState<IUser> {}

const initialState: ILoggedInUserState = {};

export default createReducer(initialState, handle => [
	handle(actions.fetchLoggedInUser, (state) => ({
		...state,
		loading: true,
	})),
	handle(actions.fetchLoggedInUserSuccess, (state, { payload }) => ({
		...state,
		data: { ...payload },
		loading: false,
	})),
	handle(actions.fetchLoggedInUserFail, (state, { payload }) => ({
		...state,
		data: undefined,
		loading: false,
		error: { ...payload }
	})),
	handle(actions.updateLoggedInUser, (state, { payload }) => ({
		...state,
		formLoading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.updateLoggedInUserSuccess, (state, { payload }) => ({
		...state,
		formLoading: false,
		formSuccess: { ...payload },
		formError: undefined,
	})),
	handle(actions.updateLoggedInUserFail, (state, { payload }) => ({
		...state,
		formLoading: false,
		formSuccess: undefined,
		formError: { ...payload },
	})),
]);
