import React, { ReactNode } from 'react';
import { get as lodashGet } from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component';
import convertHtmlToReact from '@hedgedoc/html-to-react';
import styles from './index.module.scss';
import { IGridRowRender } from 'model/interfaces/grid/IGridRowRender';
import { IGridCellRender } from 'model/interfaces/grid/IGridCellRender';
import { IGridRowAction } from 'model/interfaces/grid/IGridRowAction';
import Dropdown, { DropdownMenuProps } from 'components/Popover';
import SearchFulltext from 'components/SearchFulltext';
import { IGenericSearchRequest } from 'model/services/Api/interfaces/requests/IGenericSearchRequest';
import {withTranslation, WithTranslation} from 'react-i18next';

type BaseProps<RowType extends object> = {
	data: Array<RowType>,

	// Either columns or columnsRenderer have to be provided
	columns?: string[], // column names to be rendered in thead
	columnsRender?: () => React.ReactNode, // own renderer for column names in thead

	// Either rowSelectors or rowRenderer have to be provided
	rowSelectors?: Array<string>, // selectors for all columns of the row
	rowActions?: Array<IGridRowAction<RowType>>, // only when you are using rowSelectors
	rowRender?: (rowProps: IGridRowRender<RowType>) => React.ReactNode, // own renderer for <tr>
	cellRender?: IGridCellRender<RowType>,

	buttons?: () => React.ReactNode,

	loading?: boolean,

	totalNoOfRows?: number,
	fetchMore?: () => void,
	hasMore?: boolean,

	selectAllLabel?: string,
	onSelectAllHandle?: () => void,

	searchPlaceholder?: string,
	searchCallback?(params: IGenericSearchRequest): void,
};

type RowActionsMenuProps<RowType> = {
	rowActions: Array<IGridRowAction<RowType>>,
} & DropdownMenuProps;

type InjectedProps = {};

type Props<RowType extends object> = BaseProps<RowType> & InjectedProps;

class Grid<RowType extends object> extends React.Component<Props<RowType> & WithTranslation> {
	private getCellValue = (selector: string, row: RowType): any => {
		return lodashGet(row, selector, '');
	}

	render () {
		const { t } = this.props;
		if (typeof this.props.columnsRender === 'undefined' && typeof this.props.columns === 'undefined') {
			throw new Error(t('grid.columnsRenderProp'));
		}

		if (typeof this.props.rowRender === 'undefined' && typeof this.props.rowSelectors === 'undefined') {
			throw new Error(t('grid.rowRenderSelectorsProp'));
		}

		return (
			<div className="container d-flex flex-column justify-content-center">
				{/* this justify-content-center ensures that the table is vertically centered, remove if not desired */}
				{/* I addedd position-relative because of the map in Customers */}
				<div className="panel p-3 d-flex flex-column overflow-hidden position-relative">
					<div className="row mb-3 align-items-center">
						<div className="col">
							{/*<button className="btn btn-sm">*/}
							{/*	<i className="fa fa-sliders-h mr-2" />*/}
							{/*	<span>Filters</span>*/}
							{/*</button>*/}
						</div>
						<div className="col d-flex align-items-center justify-content-end">
							{ this.props.loading && <i className="fas fa-spinner fa-pulse text-large mr-4" /> }
							{ typeof this.props.buttons !== 'undefined' && <> { this.props.buttons() } </> }
						</div>
					</div>

					{this.props.searchCallback &&
					<SearchFulltext
						placeholder={this.props.searchPlaceholder || t('grid.search')}
						onSearch={this.props.searchCallback}
					/>
					}

					<div className="w-100 overflow-auto position-relative" id="table-overflow">
						{!!this.props.selectAllLabel && this.props.data.length > 0 &&
						<button
							className={`btn btn-sm btn-outline-primary ${styles.selectAll}`}
							onClick={this.props.onSelectAllHandle}
						>
							{this.props.selectAllLabel}
						</button>
						}
						<InfiniteScroll
							dataLength={ this.props.totalNoOfRows || 0 }
							next={ this.props.fetchMore ?? (() => {}) }
							hasMore={ this.props.hasMore || false }
							loader={<h4>Loading...</h4>}
							scrollableTarget="table-overflow"
						>
							<table className={ `table table-grid mb-0` }>
								<thead>
								<tr>
									{ this.props.columnsRender ? (
										<> { this.props.columnsRender } </>
									) : (
										<>
											{ this.props.columns!.map((col, colIndex) => (
												<th key={ colIndex } className="border-0">
													<span>{ convertHtmlToReact(t(col), {}) }</span>
												</th>
											)) }
										</>
									) }
								</tr>
								</thead>
								<tbody className="bg-white border-bottom">
								{ this.props.data.length === 0 &&
									<tr>
										<td colSpan={this.props.columns?.length ?? 0}>
											<em className="text-muted">{t('components.grid.noItems')}</em>
										</td>
									</tr>
								}

								{ this.props.data.map((row, rowIndex) => {

									const RowActionsMenu = ({ rowActions, hide }: RowActionsMenuProps<RowType>) => (
										<>
											{rowActions.map((rowAction, rowActionIndex) => (
												<button
													onClick={ (e) => { hide(e); rowAction.action(row, rowIndex); } }
													className="dropdown-item"
													key={ rowActionIndex }
												>
													{ rowAction.label }
												</button>
											))}
										</>
									);

									return (
										<React.Fragment key={ rowIndex }>
											{ this.props.rowRender ?
												this.props.rowRender({row, rowIndex})
											 : (
												<tr>
													{ this.props.rowSelectors!.map((selector, selectorIndex) => {
														if (this.props.cellRender && this.props.cellRender[selector]) {
															return (
																<React.Fragment key={ selectorIndex }>
																	{ this.props.cellRender[selector](row) }
																</React.Fragment>
															);
														}

														return (
															<td key={ selectorIndex }>
																{ this.getCellValue(selector, row) }
															</td>
														);
													}) }
													{ this.props.rowActions &&
														<td className="py-0" style={{ width: '1px' }}>
															<Dropdown
																menu={ ({ hide }) =>
																	<RowActionsMenu
																		rowActions={ this.props.rowActions! }
																		hide={ hide }
																	/>
																}
															>
																{ ({ onClick }) => (
																	<button
																		className="btn btn-sm"
																		onClick={ onClick }
																	>
																		<i className="fa fa-ellipsis-h" />
																	</button>
																)}
															</Dropdown>
														</td>
													}
												</tr>
											) }
										</React.Fragment>
									);
								} ) }
								</tbody>
							</table>
						</InfiniteScroll>
					</div>
				</div>
			</div>
		);
	}
}

//export default Grid;
export default withTranslation()(Grid) as <RowType extends object>(props: Props<RowType>) => any;