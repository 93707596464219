import React, { useEffect } from 'react';
import { Field, FieldArray, FieldArrayRenderProps, FieldProps, Form, Formik } from 'formik';
import TextInput from 'components/Form/TextInput';
import { ICustomer } from 'model/interfaces/ICustomer';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import * as customerActions from 'pages/Customers/store/actions';
import TextAreaInput from 'components/Form/TextAreaInput';
import AddressFormComponent from 'components/Form/AddressFormComponent';
import validationSchema from 'pages/Customers/CustomerForm/validationSchema';
import { initialState as addressInitialState } from 'model/interfaces/IAddress';
import {useTranslation} from "react-i18next";

type BaseProps = {
	onSuccess: () => void,
	initialValues: ICustomer,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const CustomerForm: React.FC<Props> = props => {

	const dispatch = useDispatch();

	const createUpdateSuccess = useSelector<ReduxState>(state => state.customers.formSuccess);

	const onSubmit = async (values: ICustomer) => {
		if (values.id) {
			dispatch(customerActions.updateCustomer(values));
		} else {
			dispatch(customerActions.createCustomer(values));
		}
	};

	useEffect(() => {
		if (createUpdateSuccess) {
			dispatch(customerActions.formCustomerRestart());
			props.onSuccess();
		}
	}, [ createUpdateSuccess ]);

	const { t } = useTranslation();

	return (
		<Formik<ICustomer>
			initialValues={ props.initialValues }
			validationSchema={ validationSchema }
			onSubmit={ onSubmit }
			validateOnChange={false}
		>
			{ formikBag => (
				<Form>
					<div className="row">
						<div className="col">
							<Field
								name="name"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.nameCustomer')} { ...props } /> }
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="email"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.emailCustomer')} { ...props }/> }
							</Field>
						</div>
						<div className="col">
							<Field
								name="telephone"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.phoneCustomer')} { ...props }/> }
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="col-6">
							<Field
								name="fax"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.faxCustomer')} { ...props }/> }
							</Field>
						</div>
						<div className="col-6">
							<Field
								name="erp_id"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.erpIdCustomer')} { ...props }/> }
							</Field>
						</div>
					</div>

					<div className="row mb-3">
						<div className="col">
							<Field
								name="comments"
							>
								{ (props: FieldProps) => <TextAreaInput label={t('pages.customersCommentsCustomer')} { ...props }/> }
							</Field>
						</div>
					</div>

					<FieldArray
						name="addresses"
					>
						{(arrayHelpers) => (
							<>
								<div className="row">
									<div className="col">
										{formikBag.values.addresses && formikBag.values.addresses.length > 0 && formikBag.values.addresses.map((address, addressIndex) => (
											<div className="row" key={ addressIndex }>
												<div className="col">
													<AddressFormComponent
														name={ `addresses[${addressIndex}]` }
														removeHandler={() => arrayHelpers.remove(addressIndex)}
													/>
												</div>
											</div>
										))}
									</div>
								</div>
								<button
									type="button"
									className="btn btn-sm btn-secondary mb-5"
									onClick={ () => arrayHelpers.push(addressInitialState) }
								>
									{t('pages.addAddressCustomer')}
								</button>
							</>)}
					</FieldArray>

					<div className="text-right">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={ ! formikBag.isValid || formikBag.isSubmitting }
						>
							{t('pages.saveCustomer')}
						</button>
					</div>
				</Form>
			) }
		</Formik>
	);
};

export default CustomerForm;
