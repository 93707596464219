import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';

import 'styles/index.scss';
import * as serviceWorker from './serviceWorker';
import App from './App';
import createStore from 'store';
import './i18n'

const history = createBrowserHistory();

const store = createStore();

ReactDOM.render(
	<Provider store={ store }>
		<Router history={ history }>
			<ToastContainer
				position="bottom-right"
				autoClose={10000}
				pauseOnHover
			/>
			<App/>
		</Router>
	</Provider>,
	document.getElementById('root'),
);

Modal.setAppElement('#root');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export { history };
