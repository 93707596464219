import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { usePrevious } from 'model/hooks/usePrevious';

type Props<T> = {
	onChange(values: T): void
}

function FormikOnChange<T>(props: Props<T>) {
	const context = useFormikContext();
	const prevValues = usePrevious(context.values);

	useEffect(() => {
		// Do NOT run on first render
		if (typeof prevValues === 'undefined') return;

		const values = context.values as T;
		props.onChange(values);

	}, [context.values]);

	return null;
}

export default FormikOnChange;