import { IEmissions } from "model/interfaces/IEmissions";

export const getEmissionsWithUnits = (emissions?: IEmissions | null) => {
    const emissionsWithUnits: { [key: string]: string } = {}

    if (!emissions) return emissionsWithUnits

    Object.keys(emissions).forEach(key => {
		emissionsWithUnits[key] = `${emissions[key].toFixed(3)} g/km`
	})

    return emissionsWithUnits
}