import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from 'pages/Semitrailers/store/actions';
import { apiFactory } from 'model/services/Api/Api';
import { ISemitrailersResponse } from 'model/services/Api/interfaces/responses/ISemitrailersResponse';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { ActionType } from 'deox';
import { IVehicle } from 'model/interfaces/IVehicle';
import { cloneDeep } from 'lodash';
import { IVehicleAbility } from 'model/interfaces/IVehicleAbility';
import { ISemitrailer } from 'model/interfaces/ISemitrailer';

function * fetchSemitrailers() {
	const api = apiFactory(yield select());

	try {
		const response: ISemitrailersResponse = yield call(api.getRequest, UrlEnum.SEMITRAILER);
		yield put(actions.fetchSemitrailersSuccess(response.data));
	} catch (err) {
		yield put(actions.fetchSemitrailersFail(err));
	}
}

function * createSemitrailer(action: ActionType<typeof actions.createSemitrailer>) {
	const api = apiFactory(yield select());

	const payload = preparePayloadForCreateOrUpload(action.payload);

	try {
		yield call(api.postRequest, UrlEnum.SEMITRAILER, payload);
		yield put(actions.fetchSemitrailers());
		yield put(actions.formSemitrailerSuccess({
			message: 'pages.semitrailers.created',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formSemitrailerFail(err));
	}
}

function * updateSemitrailer(action: ActionType<typeof actions.updateSemitrailer>) {
	const api = apiFactory(yield select());

	const payload = preparePayloadForCreateOrUpload(action.payload);

	try {
		yield call(api.patchRequest, `${UrlEnum.SEMITRAILER}${action.payload.id}/`, payload);
		yield put(actions.fetchSemitrailers());
		yield put(actions.formSemitrailerSuccess({
			message: 'pages.semitrailers.updated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formSemitrailerFail(err));
	}
}

function preparePayloadForCreateOrUpload(_payload: ISemitrailer) {
	const payload = cloneDeep(_payload) as any;

	delete payload.id;
	delete payload.demonstrator;

	if (payload.abilities) {
		payload.abilities = payload.abilities.map((ability: IVehicleAbility) => ability.id);
	}

	return payload;
}

function * deleteSemitrailer(action: ActionType<typeof actions.deleteSemitrailer>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.deleteRequest, `${UrlEnum.SEMITRAILER}${action.payload.id}/`);
		yield put(actions.fetchSemitrailers());
		yield put(actions.deleteSemitrailerSuccess({
			message: 'pages.semitrailers.removed',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.deleteSemitrailerFail(err));
	}
}

function * watchFetchSemitrailers() {
	yield takeLatest(actions.fetchSemitrailers, fetchSemitrailers);
}

function * watchDeleteSemitrailer() {
	yield takeEvery(actions.deleteSemitrailer, deleteSemitrailer);
}

function * watchCreateSemitrailer() {
	yield takeEvery(actions.createSemitrailer, createSemitrailer);
}

function * watchUpdateSemitrailer() {
	yield takeEvery(actions.updateSemitrailer, updateSemitrailer);
}

const semitrailersSagas = [
	watchFetchSemitrailers(),
	watchDeleteSemitrailer(),
	watchCreateSemitrailer(),
	watchUpdateSemitrailer(),
];

export default semitrailersSagas;
