import { IVehicleRestrictionEndpoint } from 'model/interfaces/IVehicleRestrictionEndpoint';

const initialState: IVehicleRestrictionEndpoint = {
	id: 0,
	name: '',
	restriction_type: 1,
	demonstrator: 0,
};

export default initialState;
