import { DeletedEnum } from 'model/enums/DeletedEnum';

export interface IPackage {
	readonly id: number,
	name: string,
	readonly demonstrator: number,
	description: string,
	height?: number,
	length?: number,
	width?: number,
	weight?: number,
	volume?: number,
	service_time: string,
	loading_service_time?: string,
	unloading_service_time?: string,
	deleted: number,
}

export const initialState: IPackage = {
	id: 0,
	name: '',
	demonstrator: 0,
	description: '',
	weight: undefined,
	volume: undefined,
	loading_service_time: undefined,
	unloading_service_time: undefined,
	service_time: '',
	deleted: DeletedEnum.NORMAL,
};
