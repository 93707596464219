import { returnEnumPairsValue } from 'model/helpers/enums';
import i18n from '../../i18n';

export enum ContractTypeEnum {
	OWNED = 1, LEASED,
}

export const ContractTypes = [
	ContractTypeEnum.OWNED,
	ContractTypeEnum.LEASED,
];

export const ContractTypePairs = {
	[ContractTypeEnum.OWNED]: 'model.enums.owned',
	[ContractTypeEnum.LEASED]: 'model.enums.leased',
};

export const getContractType = (key: ContractTypeEnum|undefined): string => {
	if (typeof key === 'undefined') {
		return (i18n.t('model.enums.unknown'));
	}
	return returnEnumPairsValue(ContractTypePairs, key);
};