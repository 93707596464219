import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './index.module.scss';
import * as actions from 'pages/Planning/store/actions';
import * as routeActions from 'store/route/actions';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import stylesLayout from 'pages/Layout.module.scss';
import Layout from 'pages/Layout';
import { IRoute } from 'model/interfaces/IRoute';
import Row from './Row';
import Dropdown from 'components/Popover';
import ManageMenu from 'pages/Planning/Vehicles/ManageMenu';
import Grid from 'components/Grid';
import { ReduxState } from 'store/reducer';
import * as selectors from 'store/route/selectors';
import { RoutesEnum } from 'model/enums/RoutesEnum';
import { useHistory } from 'react-router';
import { fetchOrders } from 'pages/Orders/store/actions';
import NoPlanPage from 'components/NoPlanPage';
import BaseModal from 'components/modals/BaseModal';
import EnableVehiclesForm from './EnableVehiclesForm';
import Loader from 'components/Loader';
import { PlanStatusEnum } from 'model/enums/PlanStatusEnum';
import {useTranslation} from "react-i18next";
import i18n from '../../i18n';

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const COLUMNS: Array<string> = [
	"", "", "pages.rerouting.start", "pages.rerouting.now", "pages.rerouting.end", "pages.rerouting.total", "pages.rerouting.delivered", "pages.rerouting.pickups", "pages.rerouting.requisitions", "pages.rerouting.completed", "pages.rerouting.canceled", ""
];

const Rerouting: React.FC < Props > = props => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [ showEnableVehiclesModal, setShowEnableVehiclesModal ] = useState(false)
	const { t } = useTranslation();

	const { rows, isLoading, error, meta, plan } = useSelector((state: ReduxState) => ({
		rows: selectors.getRoutesInProgress(state),
		isLoading: state.routes.loading ?? false,
		error: state.routes.error,
		meta: state.routes.meta,
		plan: state.plan.data,
	}));

	useEffect(() => {
		dispatch(routeActions.fetchRoutes());
	}, []);

	const onContinue = () => {
		if (meta.every(route => !route.selected)) {
			toast.error(i18n.t('pages.rerouting.oneRouteSelected'));
			return;
		}

		history.push(RoutesEnum.REROUTING_SELECTED);
	};

	const someRoutesDisabled = meta.some(meta => !meta.selected);

	const onSelectAllHandle = () => {
		dispatch(routeActions.setAllSelected(someRoutesDisabled));
	};

	if (!plan?.id) {
		return <NoPlanPage />;
	}

	return (
		<>
			<Loader visible={isLoading} />
			<Layout className={ stylesLayout.layoutRerouting }>
				<BaseModal
					isOpen={showEnableVehiclesModal}
					onRequestClose={() => setShowEnableVehiclesModal(false)}
					title={t('pages.rerouting.enableVehicle')}
				>
					<EnableVehiclesForm close={() => setShowEnableVehiclesModal(false)} />	
				</BaseModal>

				<div className={`d-flex overflow-hidden justify-content-center ${styles.content}`}>
					<Grid<IRoute>
						data={ rows }
						columns={ COLUMNS }
						rowRender={ props => <Row {...props} /> }
						loading={ isLoading }
						selectAllLabel={someRoutesDisabled ? t('pages.rerouting.selectAll') : t('pages.rerouting.deselectAll')}
						onSelectAllHandle={onSelectAllHandle}
						buttons={ () => (
							<>
								<Dropdown menu={ ({ hide }) => <ManageMenu /> }>
									{ ({ onClick }) => (
										<button
											className="btn btn-sm btn-outline-primary"
											onClick={ onClick }
										>
											{t('pages.rerouting.manage')}
											<i className="fa fa-angle-down ml-2" />
										</button>
									)}
								</Dropdown>
							</>
						) }
					/>
				</div>
				<footer className={styles.footer}>
					<div className={`panel bg-white h-100 px-3 justify-content-end d-flex align-items-center ${styles.footerPanel}`}>
						{plan.status !== PlanStatusEnum.COMPLETED &&
						<button
							className="btn mr-3"
							onClick={ () => setShowEnableVehiclesModal(true) }
						>
							{t('pages.rerouting.enableVehicles')}
						</button>
						}
						<button
							className="btn btn-primary"
							onClick={ onContinue }
						>
							{t('pages.rerouting.rerouteSelected')}
						</button>
					</div>
				</footer>
			</Layout>
		</>
	);
};

export default Rerouting;