import * as Yup from 'yup';

const validationSchema = Yup.object({
	name: Yup.string().required('pages.packages.nameRequired'),
	weight: Yup.number().min(0, 'pages.packages.weightNotNegative').required('pages.packages.weightRequired'),
	volume: Yup.number().min(0, 'pages.packages.volumeNotNegative').required('pages.packages.volumeRequired'),
	loading_service_time: Yup.string().required('pages.packages.loadingRequired'),
	unloading_service_time: Yup.string().required('pages.packages.unloadingRequired'),
});

export default validationSchema;