import { IDriver } from 'model/interfaces/IDriver';
import { UserTypeEnum } from 'model/enums/UserTypeEnum';
import { initialState as addressInitialState } from 'model/interfaces/IAddress';

const initialState: IDriver = {
	id: 0,
	password: '',
	username: '',
	first_name: '',
	last_name: '',
	email: '',
	telephone: '',
	user_type: UserTypeEnum.DRIVER,
	demonstrator: 0,
	address: addressInitialState,
};

export default initialState;
