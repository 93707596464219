import React from 'react';
import { FieldProps } from 'formik';

import BaseAsyncSelect from 'components/Form/BaseAsyncSelect';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IPackage } from 'model/interfaces/IPackage';

type BaseProps = {} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const PackageAsyncSelect: React.FC<Props> = props => (
	<BaseAsyncSelect<IPackage>
		url={ UrlEnum.PACKAGE }
		getOptionLabel={ option => option.name }
		getOptionValue={ option => option }
		{ ...props }
	/>
);

export default PackageAsyncSelect;
