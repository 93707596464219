import PackageAsyncSelect from 'components/Form/PackageAsyncSelect';
import React  from 'react';
import { Formik, Field, Form, FieldProps } from 'formik';
import { IOrderRequest } from 'model/services/Api/interfaces/requests/IOrderRequest';
import { IOrderFilters } from 'components/OrderFilters/IOrderFilters';
import FormikOnChange from 'components/Form/FormikOnChange';
import TagAsyncSelect from 'components/Form/TagAsyncSelect';
import { useTranslation } from 'react-i18next';

type Props = {
	onSearch(request: IOrderRequest): void,
}

const OrderFilters: React.FC<Props> = (props) => {
	const onChange = (values: IOrderFilters) => {
		const request: IOrderRequest = {};

		request.package = values.package?.id ?? null;
		request.tag = values.tag?.id ?? null;

		props.onSearch(request)
	};

	const { t } = useTranslation();

	return (
		<Formik<IOrderFilters>
			initialValues={{}}
			onSubmit={ () => {} }
		>
			{formikBag => {
				return (
					<Form>
						<FormikOnChange<IOrderFilters> onChange={onChange} />
						<div className="form-row">
							<div className="col">
								<Field name="package">
									{(props: FieldProps) =>
										<PackageAsyncSelect
											label={t('order.packageType')}
											{...props}
										/>
									}
								</Field>
							</div>
							<div className="col">
								<Field name="tag">
									{(props: FieldProps) =>
										<TagAsyncSelect
											label={t('order.tag')}
											{...props}
										/>
									}
								</Field>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);


}

export default OrderFilters;