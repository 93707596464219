import { IRoute } from 'model/interfaces/IRoute';
import { getAllOrdersLocations } from 'model/helpers/orders';
import { getDefinedLocations } from 'model/helpers/location';

export const getAllRouteLocations = (route: IRoute): Array<[number, number]|undefined> => {
	const locations = [];

	locations.push(route?.starting_point?.location?.coordinates);
	locations.push( ...getAllOrdersLocations(route.assignments.map(assignment => assignment.order)) );
	locations.push(route?.ending_point?.location?.coordinates);

	return locations;
};

export const getDefinedRouteLocations = (route: IRoute): Array<[number, number]> =>
	getDefinedLocations( getAllRouteLocations(route) );

export const getPlanVehicleLocations = (routes: IRoute[]): Array<[number, number]> => {
	const locations = [];

	for (const route of routes) {
		if (route.starting_point?.location?.coordinates) {
			locations.push(route.starting_point.location.coordinates);
		}
	}

	return locations;
};
