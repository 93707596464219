import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from 'pages/VehicleRestrictions/store/actions';
import { apiFactory } from 'model/services/Api/Api';
import { IVehicleRestrictionsResponse } from 'model/services/Api/interfaces/responses/IVehicleRestrictionsResponse';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { ActionType } from 'deox';

function * fetchVehicleRestrictions() {
	const api = apiFactory(yield select());

	try {
		const response: IVehicleRestrictionsResponse = yield call(api.getRequest, UrlEnum.VEHICLE_RESTRICTION);
		yield put(actions.fetchVehicleRestrictionsSuccess(response.data));
	} catch (err) {
		yield put(actions.fetchVehicleRestrictionsFail(err));
	}
}

function * createVehicleRestriction(action: ActionType<typeof actions.createVehicleRestriction>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.postRequest, UrlEnum.VEHICLE_RESTRICTION, action.payload);
		yield put(actions.fetchVehicleRestrictions());
		yield put(actions.formVehicleRestrictionsuccess({
			message: 'pages.VRcreated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formVehicleRestrictionFail(err));
	}
}

function * updateVehicleRestriction(action: ActionType<typeof actions.updateVehicleRestriction>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.patchRequest, `${UrlEnum.VEHICLE_RESTRICTION}${action.payload.id}/`, action.payload);
		yield put(actions.fetchVehicleRestrictions());
		yield put(actions.formVehicleRestrictionsuccess({
			message: 'pages.VRupdated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formVehicleRestrictionFail(err));
	}
}

function * deleteVehicleRestriction(action: ActionType<typeof actions.deleteVehicleRestriction>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.deleteRequest, `${UrlEnum.VEHICLE_RESTRICTION}${action.payload.id}/`);
		yield put(actions.fetchVehicleRestrictions());
		yield put(actions.deleteVehicleRestrictionsuccess({
			message: 'pages.VRremoved',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.deleteVehicleRestrictionFail(err));
	}
}

function * watchFetchVehicleRestrictions() {
	yield takeLatest(actions.fetchVehicleRestrictions, fetchVehicleRestrictions);
}

function * watchDeleteVehicleRestriction() {
	yield takeEvery(actions.deleteVehicleRestriction, deleteVehicleRestriction);
}

function * watchCreateVehicleRestriction() {
	yield takeEvery(actions.createVehicleRestriction, createVehicleRestriction);
}

function * watchUpdateVehicleRestriction() {
	yield takeEvery(actions.updateVehicleRestriction, updateVehicleRestriction);
}

const vehicleRestrictionsSagas = [
	watchFetchVehicleRestrictions(),
	watchDeleteVehicleRestriction(),
	watchCreateVehicleRestriction(),
	watchUpdateVehicleRestriction(),
];

export default vehicleRestrictionsSagas;
