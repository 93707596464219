import React from 'react';
import { useDispatch } from 'react-redux';
import BaseModal, { BaseModalProps } from 'components/modals/BaseModal';
import * as actions from 'pages/Planning/store/actions';
import {useTranslation} from "react-i18next";

type BaseProps = {};

type InjectedProps = BaseModalProps;

type Props = BaseProps & InjectedProps;

const CalculateModal: React.FC<Props> = (props) => {

	const dispatch = useDispatch();

	const onClick = () => {
		props.onRequestClose();
		dispatch(actions.calculate());
	}

	const { t } = useTranslation();

	return (
		<BaseModal {...props}>
			<div className="text-center">
				<div className="h3 mb-4">{t('pages.continueStartAssignment')}<br />{t('pages.wait')}</div>
				<button
					className="btn btn-primary"
					onClick={ onClick }
				>
					{t('pages.continue')}
				</button>
			</div>
		</BaseModal>
	);
};

export default CalculateModal;
