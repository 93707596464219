import React from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import TextInput from 'components/Form/TextInput';
import { useDispatch } from 'react-redux';
import * as loggedInUserActions from 'store/loggedInUser/actions';
import TextAreaInput from 'components/Form/TextAreaInput';
import { IUser } from 'model/interfaces/IUser';
import AddressFormComponent from 'components/Form/AddressFormComponent';
import {useTranslation} from "react-i18next";

type BaseProps = {
	initialValues: IUser,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const ProfileForm: React.FC<Props> = props => {

	const dispatch = useDispatch();

	const onSubmit = async (values: IUser) => {
		dispatch(loggedInUserActions.updateLoggedInUser(values));
	};

	const { t } = useTranslation();

	return (
		<Formik<IUser>
			initialValues={ props.initialValues }
			onSubmit={ onSubmit }
		>
			{ formikBag => (
				<Form>
					<div className="form-row">
						<div className="col">
							<Field
								name="username"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.usernameUser')} { ...props } /> }
							</Field>
						</div>
						<div className="col">
							<Field
								name="password"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.passwordUser')} { ...props }/> }
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="first_name"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.firstNameUser')} { ...props }/> }
							</Field>
						</div>
						<div className="col">
							<Field
								name="last_name"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.lastNameUser')} { ...props }/> }
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="email"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.emailUser')} { ...props }/> }
							</Field>
						</div>
					</div>
					<AddressFormComponent name="address" />
					<div className="text-right">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={ ! formikBag.isValid || formikBag.isSubmitting }
						>
							{t('pages.saveUser')}
						</button>
					</div>
				</Form>
			) }
		</Formik>
	);
};

export default ProfileForm;
