import KeyValueRows from 'components/VehicleRouteCard/KeyValueRows';
import React from 'react';
import { IDriver } from 'model/interfaces/IDriver';
import {useTranslation} from "react-i18next";

type DriverPopoverProps = {
	driver: IDriver,
}

const DriverPopover: React.FC<DriverPopoverProps> = ({ driver }) => {

	const { t } = useTranslation();

	const items = {
		[t('components.vehicle.email')] : driver.email,
		[t('components.vehicle.phone')] : driver.telephone,
	};

	return (
		<div className="dropdown-menu show position-static px-3">
			<KeyValueRows items={items} />
		</div>
	);
};

export default DriverPopover;