export enum OrderTypeEnum {
	PICKUP ,
	DELIVERY ,
	REQUISITION = 3,
}

export const OrderTypes = [
	OrderTypeEnum.PICKUP,
	OrderTypeEnum.DELIVERY,
	OrderTypeEnum.REQUISITION,
];

export const OrderTypePairs = {
	[OrderTypeEnum.PICKUP]: 'model.enums.pickup',
	[OrderTypeEnum.DELIVERY]: 'model.enums.delivery',
	[OrderTypeEnum.REQUISITION]: 'model.enums.requisition',
};