import React from 'react';
import { FieldProps } from 'formik';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import ErrorMessage from 'components/Form/ErrorMessage';

type BaseProps = {
	id?: string,
} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const InputWrapper: React.FC<Props> = props => {
	return (
		<div className="form-group">
			{ props.label &&
			<>
				<label htmlFor={ props.id }>
					{ props.label }
				</label>
				<br />
			</>
			}
			{ props.children }

			<ErrorMessage name={ props.field.name } />
		</div>
	);
};

export default InputWrapper;
