import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from 'pages/MaterialTags/store/actions';
import { apiFactory } from 'model/services/Api/Api';
import { IMaterialTagsResponse } from 'model/services/Api/interfaces/responses/IMaterialTagsResponse';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { ActionType } from 'deox';

function * fetchMaterialTags() {
	const api = apiFactory(yield select());

	try {
		const response: IMaterialTagsResponse = yield call(api.getRequest, UrlEnum.MATERIAL_TAG);
		yield put(actions.fetchMaterialTagsSuccess(response.data));
	} catch (err) {
		yield put(actions.fetchMaterialTagsFail(err));
	}
}

function * createMaterialTag(action: ActionType<typeof actions.createMaterialTag>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.postRequest, UrlEnum.MATERIAL_TAG, action.payload);
		yield put(actions.fetchMaterialTags());
		yield put(actions.formMaterialTagsuccess({
			message: 'pages.material.created',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formMaterialTagFail(err));
	}
}

function * updateMaterialTag(action: ActionType<typeof actions.updateMaterialTag>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.patchRequest, `${UrlEnum.MATERIAL_TAG}${action.payload.id}/`, action.payload);
		yield put(actions.fetchMaterialTags());
		yield put(actions.formMaterialTagsuccess({
			message: 'pages.material.updated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formMaterialTagFail(err));
	}
}

function * deleteMaterialTag(action: ActionType<typeof actions.deleteMaterialTag>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.deleteRequest, `${UrlEnum.MATERIAL_TAG}${action.payload.id}/`);
		yield put(actions.fetchMaterialTags());
		yield put(actions.deleteMaterialTagsuccess({
			message: 'pages.material.removed',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.deleteMaterialTagFail(err));
	}
}

function * watchFetchMaterialTags() {
	yield takeLatest(actions.fetchMaterialTags, fetchMaterialTags);
}

function * watchDeleteMaterialTag() {
	yield takeEvery(actions.deleteMaterialTag, deleteMaterialTag);
}

function * watchCreateMaterialTag() {
	yield takeEvery(actions.createMaterialTag, createMaterialTag);
}

function * watchUpdateMaterialTag() {
	yield takeEvery(actions.updateMaterialTag, updateMaterialTag);
}

const materialTagsSagas = [
	watchFetchMaterialTags(),
	watchDeleteMaterialTag(),
	watchCreateMaterialTag(),
	watchUpdateMaterialTag(),
];

export default materialTagsSagas;
