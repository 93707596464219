import { IMaterialTag } from 'model/interfaces/IMaterialTag';

const initialState: IMaterialTag = {
	id: 0,
	name: '',
	demonstrator: 0,
	description: '',
};

export default initialState;
