import React from 'react';
import KeyValueRows from 'components/VehicleRouteCard/KeyValueRows';
import { IAssignment } from 'model/interfaces/IAssignment';
import { OrderTypeEnum } from 'model/enums/OrderTypeEnum';
import { useTranslation } from 'react-i18next';

type Props = {
	assignment: IAssignment,
};

const TripsInformation: React.FC<Props> = ({ assignment }) => {
	let items:any = {};

	const { t } = useTranslation();

	switch (assignment.order.order_type) {
		case OrderTypeEnum.PICKUP:
			items[t('cards.distancePickup')] = <>{assignment.previous_trip.distance} km</>;
			items[t('cards.timePickup')] = <>{assignment.previous_trip.travel_time} min</>;
			items[t('cards.volumeOnTruckPickup')] = <>{assignment.previous_trip.load?.volume} m<sup>3</sup></>;
			items[t('cards.weightOnTruckPickup')] = <>{assignment.previous_trip.load?.weight} kg</>;
			break;

		case OrderTypeEnum.DELIVERY:
			items[t('cards.distanceDelivery')] = <>{assignment.previous_trip.distance} km</>;
			items[t('cards.timeDelivery')] = <>{assignment.previous_trip.travel_time} min</>;
			items[t('cards.volumeOnTruckDelivery')] = <>{assignment.previous_trip.load?.volume} m<sup>3</sup></>;
			items[t('cards.weightOnTruckDelivery')] = <>{assignment.previous_trip.load?.weight} kg</>;
			break;

		case OrderTypeEnum.REQUISITION:
			items[t('cards.distanceRequisition')] = <>{assignment.previous_trip.distance} {t('cards.kmAndR')} {assignment.order_trip?.distance ||
				<em>{t('components.cards.unknown')}</em>} km</>;
				items[t('cards.timeRequisition')] = <>{assignment.previous_trip.travel_time} {t('cards.minAndR')} {assignment.order_trip?.travel_time ||
					<em>{t('components.cards.unknown2')}</em>} min</>;

			break;
	}

	return (
		<>
			{ assignment.order.order_type !== OrderTypeEnum.REQUISITION
				?
				<div>
					From {assignment.previous_trip.leg.from_address.address} to {assignment.previous_trip.leg.to_address.address}
				</div>
				:
				<div>
					{assignment.previous_trip.leg.from_address.address} - {assignment.previous_trip.leg.to_address.address} - {assignment.order_trip?.leg.to_address.address || <em>{t('components.cards.unknown3')}</em>}
				</div>
			}
			<KeyValueRows items={items} />
		</>
	)
};

export default TripsInformation;