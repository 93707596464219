import { put, takeLatest } from 'redux-saga/effects';
import * as actions from 'store/shift/actions';
import * as planActions from 'store/plan/actions';

function * fetchPlan() {
	yield put(planActions.fetchPlan());
}

function * watchSet() {
	yield takeLatest(actions.set, fetchPlan);
}

const shiftSagas = [
	watchSet(),
];

export default shiftSagas;