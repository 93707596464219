import { localTimeFromUTCDatetime } from 'model/helpers/datetime';
import React from 'react';
import { IPlanningRoute, IRoute } from 'model/interfaces/IRoute';
import { roundToMaxTwoDecimalPlaces } from 'model/helpers/numbers';

type Props = {
	route: IRoute|IPlanningRoute
}

const StartingPoint: React.FC<Props> = ({ route }) =>
	<>
		{ localTimeFromUTCDatetime(route.starting_time) }<br/>
		{ route.starting_trip?.load && route.total_capacity_volume &&
		<span className={ route.starting_trip.load.volume > route.total_capacity_volume ? 'text-danger' : '' }>
			{ roundToMaxTwoDecimalPlaces(route.starting_trip.load.volume) } / { route.total_capacity_volume } m<sup>3</sup>
			<br />
		</span>
		}
		{ route.starting_point?.address }, { route.starting_point?.postal_code } - { route.starting_point?.city }
	</>
;

export default StartingPoint;