import { takeLatest, put } from 'redux-saga/effects';
import * as actions from 'store/executionDate/actions';
import * as planActions  from 'store/plan/actions';
import { ActionType } from 'deox';

function * fetchPlan(action: ActionType<typeof actions.set>) {
	if (action.payload.update) {
		yield put(planActions.fetchPlan());
	}
}

function * watchSet() {
	yield takeLatest(actions.set, fetchPlan);
}

const executionDateSagas = [
	watchSet(),
];

export default executionDateSagas;