import React from 'react';
import Select from 'react-select';
import { FieldProps } from 'formik';
import { uniqueId as lodashUniqueId } from 'lodash';

import InputWrapper from 'components/Form/InputWrapper';
import { ISelectOptions } from 'model/interfaces/ISelectOptions';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import { StylesConfig } from 'react-select/src/styles';

type BaseProps = {
	options: ISelectOptions[],
	noEmptyValue?: boolean,
} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

// TODO: IS-INVALID CLASS IF NOT VALID
// TODO: STYLE - GREEN BORDER WHEN ACTIVE
const SelectInput: React.FC<Props> = props => {
	const id: string = lodashUniqueId(`select-${ props.field.name }`)

	const styles: StylesConfig = {
		menu: (provided) => ({
			...provided,
			zIndex: 1000,	// Bootstrap variable $zindex-dropdown
		})
	}

	return (
		<InputWrapper { ...props } id={ id }>
			<Select<ISelectOptions>
				options={ [...props.options] }
				id={ id }
				value={ props.options.find(option => option.value === props.field.value) }
				getOptionValue={ option => option.value }
				getOptionLabel={ option => option.label ?? option.value }
				isClearable={ ! props.noEmptyValue }
				onChange={ (option: ISelectOptions | any) => props.form.setFieldValue(props.field.name, option?.value ?? undefined) }
				styles={ styles }
			/>
		</InputWrapper>
	);
};

export default SelectInput;
