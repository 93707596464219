import { OrderTypeBadgeEnum } from 'components/OrderTypeBadge/OrderTypeBadgeEnum';
import { OrderTypeEnum } from 'model/enums/OrderTypeEnum';
import { OrderStatusEnum } from 'model/enums/OrderStatusEnum';

export const orderTypeBadgeEnumToOrderType = (type?: OrderTypeBadgeEnum) => {
	switch (type) {
		case OrderTypeBadgeEnum.DELIVERIES:
			return OrderTypeEnum.DELIVERY;
		case OrderTypeBadgeEnum.PICKUPS:
			return OrderTypeEnum.PICKUP;
		case OrderTypeBadgeEnum.REQUISITIONS:
			return OrderTypeEnum.REQUISITION;
		default:
			return null;
	}
}

export const orderTypeBadgeEnumToOrderStatus = (type?: OrderTypeBadgeEnum) => {
	switch (type) {
		case OrderTypeBadgeEnum.UNASSIGNED:
			return OrderStatusEnum.NEW;
		case OrderTypeBadgeEnum.COMPLETED:
			return OrderStatusEnum.COMPLETED;
		case OrderTypeBadgeEnum.CANCELLED:
			return OrderStatusEnum.CANCELLED;
		default:
			return null;
	}
}