import { createActionCreator } from 'deox';
import { IApiError } from 'model/interfaces/IApiError';
import { ICustomer } from 'model/interfaces/ICustomer';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';
import { PlanningActionsEnum } from 'pages/Planning/store/actions';

export enum CustomerActionsEnum {
	FETCH = '@CUSTOMERS_FETCH',
	FETCH_SUCCESS = '@CUSTOMERS_FETCH_SUCCESS',
	FETCH_FAIL = '@CUSTOMERS_FETCH_FAIL',

	CREATE = '@CUSTOMERS_CREATE',
	UPDATE = '@CUSTOMERS_UPDATE',
	FORM_SUCCESS = '@CUSTOMERS_FORM_SUCCESS',
	FORM_FAIL = '@CUSTOMERS_FORM_FAIL',
	FORM_RESTART = '@CUSTOMERS_FORM_RESTART',

	DELETE = '@CUSTOMERS_DELETE',
	DELETE_SUCCESS = '@CUSTOMERS_DELETE_SUCCESS',
	DELETE_FAIL = '@CUSTOMERS_DELETE_FAIL',

	IMPORT = '@CUSTOMERS_IMPORT',
	IMPORT_SUCCESS = '@CUSTOMERS_IMPORT_SUCCESS',
	IMPORT_FAIL = '@CUSTOMERS_IMPORT_FAIL'
}

export const fetchCustomers = createActionCreator(CustomerActionsEnum.FETCH);

export const fetchCustomersFail = createActionCreator(
	CustomerActionsEnum.FETCH_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const fetchCustomersSuccess = createActionCreator(
	CustomerActionsEnum.FETCH_SUCCESS,
	resolve => (data: IPaginatedData<ICustomer>) => resolve(data),
);

export const createCustomer = createActionCreator(
	CustomerActionsEnum.CREATE,
	resolve => (customerItem: ICustomer) => resolve(customerItem),
);

export const updateCustomer = createActionCreator(
	CustomerActionsEnum.UPDATE,
	resolve => (customerItem: ICustomer) => resolve(customerItem),
);

export const formCustomerSuccess = createActionCreator(
	CustomerActionsEnum.FORM_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success));

export const formCustomerFail = createActionCreator(
	CustomerActionsEnum.FORM_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const formCustomerRestart = createActionCreator(CustomerActionsEnum.FORM_RESTART);

export const deleteCustomer = createActionCreator(
	CustomerActionsEnum.DELETE,
	resolve => (customerItem: ICustomer) => resolve(customerItem),
);

export const deleteCustomerSuccess = createActionCreator(
	CustomerActionsEnum.DELETE_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success)
);

export const deleteCustomerFail = createActionCreator(
	CustomerActionsEnum.DELETE_FAIL,
	resolve => (error: IReduxActionState & IApiError) => resolve(error),
);

export const importCustomers = createActionCreator(CustomerActionsEnum.IMPORT, resolve => (fileList: FileList | null) => resolve(fileList));

export const importCustomersSuccess = createActionCreator(
	CustomerActionsEnum.IMPORT_SUCCESS, resolve => () => resolve()
);

export const importCustomersFail = createActionCreator(
	CustomerActionsEnum.IMPORT_FAIL, resolve => (error: IApiError) => resolve(error),
);