import { IAssignment } from 'model/interfaces/IAssignment';
import { OrderStatusEnum } from 'model/enums/OrderStatusEnum';
import { OrderTypeEnum } from 'model/enums/OrderTypeEnum';

export const getCompletedOrderPercentage = (assignments: IAssignment[]): number => {
	if (assignments.length === 0) {
		// Divison by zero is not permitted
		return 0;
	}

	const noOfCompletedOrders = getNoOfOrders(assignments, [OrderStatusEnum.COMPLETED, OrderStatusEnum.CANCELLED]);
	const noOfAllOrders = getNoOfOrders(assignments);

	return (noOfCompletedOrders / noOfAllOrders) * 100;
};

export const getNoOfOrders = (
	assignments: IAssignment[]|undefined,
	status: OrderStatusEnum[]|OrderStatusEnum|undefined = undefined,
	orderType: OrderTypeEnum|undefined = undefined): number =>
{
	if (!assignments) return 0;

	const statusArr = Array.isArray(status) ? status : [ status ];

	return assignments
		.filter(assignment => status === undefined || statusArr.includes(assignment.order.status.status))
		.filter(assignment => orderType === undefined || assignment.order.order_type === orderType)
		.length;
}

export const sortAssignments = (a: IAssignment, b: IAssignment): number => (a.sequence_number - b.sequence_number);