import { createReducer } from 'deox';
import { cloneDeep } from 'lodash';

import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import * as actions from './actions'
import { IPlan } from 'model/interfaces/IPlan';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';
import { IPlanningRoute } from 'model/interfaces/IRoute';
import { IOrder } from 'model/interfaces/IOrder';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';

export interface IPlanningState {
	step: PlanningStepsEnum,
	rerouteRouteId?: number,
	plan: IReduxFetchedState<IPlan>,
	routes: Array<IReduxFetchedState<IPlanningRoute>>,
	orders: IReduxFetchedState<IPaginatedData<IOrder>>,
}

const initialState: IPlanningState = {
	step: PlanningStepsEnum.NEW_PLAN,
	rerouteRouteId: undefined,
	plan: {
		data: undefined,
		loading: false,
		error: undefined,
	},
	routes: [],
	orders: {
		data: undefined,
		loading: false,
		error: undefined,
	}
};

export default createReducer(initialState, handle => [
	handle(actions.setStep, (state, { payload }) => ({
		...state,
		step: payload,
	})),
	handle(actions.createPlan, (state) => ({
		...state,
		plan: {
			...state.plan,
			loading: true,
			error: undefined,
		},
	})),
	handle(actions.createPlanSuccess, (state, { payload }) => ({
		...state,
		plan: {
			...state.plan,
			loading: false,
			data: { ...payload },
			error: undefined,
		},
	})),
	handle(actions.createPlanFail, (state, { payload} ) => ({
		...state,
		plan: {
			...state.plan,
			loading: false,
			data: undefined,
			error: { ...payload },
		},
	})),
	handle(actions.cancelPlan, (state) => ({
		...state,
		plan: {
			...state.plan,
			loading: true,
			error: undefined,
		}
	})),
	handle(actions.setInitialState, (state) => ({
		...initialState
	})),
	handle(actions.cancelPlanFail, (state, { payload } ) => ({
		...state,
		plan: {
			...state.plan,
			loading: false,
			error: { ...payload },
		},
	})),
	handle(actions.fetchOrders, (state) => ({
		...state,
		orders: {
			...state.orders,
			loading: true,
			error: undefined
		}
	})),
	handle(actions.fetchOrdersSuccess, (state, { payload }) => ({
		...state,
		orders: {
			...state.orders,
			loading: false,
			data: { ...payload },
			error: undefined,
		}
	})),
	handle(actions.fetchOrdersFail, (state, { payload }) => ({
		...state,
		orders: {
			...state.orders,
			loading: false,
			data: undefined,
			error: { ...payload }
		}
	})),
	handle(actions.importOrders, (state) => ({
		...state,
		orders: {
			...state.orders,
			loading: true,
			error: undefined
		}
	})),
	handle(actions.importOrdersSuccess, (state) => ({
		...state,
		orders: {
			...state.orders,
			loading: false,
			error: undefined
		}
	})),
	handle(actions.importOrdersFail, (state, { payload }) => ({
		...state,
		orders: {
			...state.orders,
			loading: false,
			error: { ...payload },
		}
	})),
	handle(actions.initRoutes, (state, { payload } ) => {
		const oldRoutes = state.routes.map(route => route.data!);

		return {
			...state,
			routes: payload.map(route => {
				// Find out if route with this vehicle exists...
				const oldRoute = oldRoutes.find(oldRoute => oldRoute.vehicle!.id === route.vehicle!.id);

				// If it exists, use it and just update the vehicle part
				// If it doesn't exist, use the route from payload
				const newRoute = oldRoute ? {
					...oldRoute,
					vehicle: route.vehicle,
				} : route;

				return {
					data: newRoute,
					error: undefined,
					loading: false,
				};
			}),
		};
	}),
	handle(actions.setRoute, (state, { payload, meta: vehicleId }) => {
		if ( !state.routes ) {
			return { ...state };
		}

		const index = state.routes.findIndex(route => route.data?.vehicle?.id === vehicleId);

		if (index === -1 || !state.routes[index] || !state.routes[index].data) {
			return { ...state };
		}

		const routes = cloneDeep(state.routes);

		routes[index].data = {
			...routes[index].data,
			...payload,
		};

		return {
			...state,
			routes: [ ...routes ],
		};
	}),
	handle(actions.setRoutesEnabled, (state, { payload: enabled }) => {
		const newState = cloneDeep(state);

		newState.routes.forEach(route => {
			if (route.data) {
				route.data.enabled = enabled;
			}
		});

		return newState;
	}),
	handle(actions.createRoute, (state, { meta }) => {
		if ( !state.routes || !state.routes[meta] || !state.routes[meta].data ) {
			return { ...state };
		}

		const routes = [ ...state.routes ];
		routes[meta] = {
			...routes[meta],
			loading: true,
			error: undefined,
		};

		return {
			...state,
			routes: [ ...routes ],
		};
	}),
	handle(actions.createRouteSuccess, (state, { payload, meta } ) => {
		if ( !state.routes || !state.routes[meta] || !state.routes[meta].data ) {
			return { ...state };
		}

		const routes = [ ...state.routes ];
		routes[meta] = {
			data: {
				...payload,
				enabled: routes[meta].data!.enabled,
				selected: routes[meta].data!.selected,
				time_start: routes[meta].data!.time_start,
				time_end: routes[meta].data!.time_end,
			},
			loading: false,
			error: undefined,
		};

		return {
			...state,
			routes: [ ...routes ],
		};
	}),
	handle(actions.createRouteFail, (state, { payload, meta }) => {
		if ( !state.routes || !state.routes[meta] || !state.routes[meta].data ) {
			return { ...state };
		}

		const routes = [ ...state.routes ];
		routes[meta] = {
			...routes[meta],
			loading: false,
			error: { ...payload },
		};

		return {
			...state,
			routes: [ ...routes ],
		};
	}),
	handle(actions.deleteRoute, (state, { meta }) => {
		if ( !state.routes || !state.routes[meta] || !state.routes[meta].data ) {
			return { ...state };
		}

		const routes = [ ...state.routes ];
		routes[meta] = {
			...routes[meta],
			loading: true,
			error: undefined,
		};

		return {
			...state,
			routes: [ ...routes ],
		};
	}),
	handle(actions.deleteRouteSuccess, (state, { meta } ) => {
		if ( !state.routes || !state.routes[meta] || !state.routes[meta].data ) {
			return { ...state };
		}

		const routes = [ ...state.routes ];
		routes[meta] = {
			data: {
				...routes[meta].data!,
				id: undefined
			},
			loading: false,
			error: undefined,
		};

		return {
			...state,
			routes: [ ...routes ],
		};
	}),
	handle(actions.deleteRouteFail, (state, { payload, meta }) => {
		if ( !state.routes || !state.routes[meta] || !state.routes[meta].data ) {
			return { ...state };
		}

		const routes = [ ...state.routes ];
		routes[meta] = {
			...routes[meta],
			loading: false,
			error: { ...payload },
		};

		return {
			...state,
			routes: [ ...routes ],
		};
	}),
	handle(actions.setRerouteRouteId, (state, { payload }) => ({
		...state,
		rerouteRouteId: payload,
	})),
	handle(actions.setModificationStatus, (state, { payload: id }) => {
		const index = state.routes.findIndex(route => route.data?.id === id);

		if (index === -1 || !state.routes[index] || !state.routes[index].data) {
			return state;
		}

		const newState = cloneDeep(state);


		if (newState.routes[index].data) {
			newState.routes[index].data!.modification_status = newState.routes[index].data!.modification_status === 0
				? 1 : 0;
		}

		return newState;
	}),
	handle(actions.calculate, (state) => ({
		...state,
		plan: {
			...state.plan,
			loading: true,
			error: undefined,
		},
	})),
	handle(actions.calculateSuccess, (state) => ({
		...state,
		plan: {
			...state.plan,
			loading: false,
			error: undefined,
		},
	})),
	handle(actions.calculateFail, (state, { payload } ) => ({
		...state,
		plan: {
			...state.plan,
			loading: false,
			error: { ...payload },
		},
	})),
	handle(actions.loadingStart, (state) => ({
		...state,
		plan: {
			...state.plan,
			loading: true,
		},
	})),
	handle(actions.loadingEnd, (state) => ({
		...state,
		plan: {
			...state.plan,
			loading: false,
		},
	})),
]);