import { createActionCreator } from 'deox';
import { IApiError } from 'model/interfaces/IApiError';
import { ISemitrailer } from 'model/interfaces/ISemitrailer';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';

export enum SemitrailerActionsEnum {
	FETCH = '@SEMITRAILERS_FETCH',
	FETCH_SUCCESS = '@SEMITRAILERS_FETCH_SUCCESS',
	FETCH_FAIL = '@SEMITRAILERS_FETCH_FAIL',

	CREATE = '@SEMITRAILERS_CREATE',
	UPDATE = '@SEMITRAILERS_UPDATE',
	FORM_SUCCESS = '@SEMITRAILERS_FORM_SUCCESS',
	FORM_FAIL = '@SEMITRAILERS_FORM_FAIL',
	FORM_RESTART = '@SEMITRAILERS_FORM_RESTART',

	DELETE = '@SEMITRAILERS_DELETE',
	DELETE_SUCCESS = '@SEMITRAILERS_DELETE_SUCCESS',
	DELETE_FAIL = '@SEMITRAILERS_DELETE_FAIL',
}

export const fetchSemitrailers = createActionCreator(SemitrailerActionsEnum.FETCH);

export const fetchSemitrailersFail = createActionCreator(
	SemitrailerActionsEnum.FETCH_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const fetchSemitrailersSuccess = createActionCreator(
	SemitrailerActionsEnum.FETCH_SUCCESS,
	resolve => (data: IPaginatedData<ISemitrailer>) => resolve(data),
);

export const createSemitrailer = createActionCreator(
	SemitrailerActionsEnum.CREATE,
	resolve => (semitrailer: ISemitrailer) => resolve(semitrailer),
);

export const updateSemitrailer = createActionCreator(
	SemitrailerActionsEnum.UPDATE,
	resolve => (semitrailer: ISemitrailer) => resolve(semitrailer),
);

export const formSemitrailerSuccess = createActionCreator(
	SemitrailerActionsEnum.FORM_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success));

export const formSemitrailerFail = createActionCreator(
	SemitrailerActionsEnum.FORM_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const formSemitrailerRestart = createActionCreator(SemitrailerActionsEnum.FORM_RESTART);

export const deleteSemitrailer = createActionCreator(
	SemitrailerActionsEnum.DELETE,
	resolve => (semitrailer: ISemitrailer) => resolve(semitrailer),
);

export const deleteSemitrailerSuccess = createActionCreator(
	SemitrailerActionsEnum.DELETE_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success)
);

export const deleteSemitrailerFail = createActionCreator(
	SemitrailerActionsEnum.DELETE_FAIL,
	resolve => (error: IReduxActionState & IApiError) => resolve(error),
);
