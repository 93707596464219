import React from 'react';
import { Form, Formik, Field, FieldProps } from 'formik';

import { IRouteFilters } from 'pages/Homepage/IRouteFilters';
import FormikOnChange from 'components/Form/FormikOnChange';
import SelectInput from 'components/Form/SelectInput';
import {HomepageFilterRouteStatuses, RouteStatusPairs} from 'model/enums/RouteStatusEnum';
import { IRouteRequest } from 'model/services/Api/interfaces/requests/IRouteRequest';
import {returnEnumPairsOptions} from "../../model/helpers/enums";

type Props = {
	onSearch(request: IRouteRequest): void,
}

const RouteFilters = (props: Props) => {
	const onChange = (values: IRouteFilters) => {
		const request: IRouteRequest = {};

		request.status = typeof values.status !== 'undefined' ? values.status : null;

		props.onSearch(request);
	};

	return (
		<Formik<IRouteFilters>
			initialValues={ {} }
			onSubmit={ () => {}}
		>
			{formikBag => {
				return (
					<Form>
						<FormikOnChange<IRouteFilters> onChange={onChange} />
						<div className="form-row">
							<div className="col">
								<Field name="status">
									{(props: FieldProps) =>
										<SelectInput
											label="Status"
											options={ returnEnumPairsOptions(HomepageFilterRouteStatuses, RouteStatusPairs) }
											{...props}
										/>
									}
								</Field>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>

	)

};

export default RouteFilters;