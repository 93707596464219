import { Draggable, Droppable as ReactBeautifulDndDroppable } from 'react-beautiful-dnd';
import React from 'react';
import styles from './index.module.scss';
import { IAssignment } from 'model/interfaces/IAssignment';
import TripAssignmentCard from 'components/cards/TripAssignmentCard';
import { OrderStatusEnum } from 'model/enums/OrderStatusEnum';
import OrderCard from 'components/cards/OrderCard';
import {useTranslation} from "react-i18next";

type Props = {
	droppableId: string,
	assignments: Array<Partial<IAssignment>>,
}

type CardProps = {
	assignment: Partial<IAssignment>,
	className?: string,
};

const Droppable: React.FC<Props> = props => {

	const Card = ({ assignment, className }: CardProps) => assignment.sequence_number
		? <TripAssignmentCard assignment={ assignment as IAssignment} className={ className } />
		: <OrderCard order={ assignment.order! } className={ className } />;

	const isDraggable = (assignment: Partial<IAssignment>) =>
		assignment.order?.status &&
		[ OrderStatusEnum.NEW, OrderStatusEnum.SCHEDULED, OrderStatusEnum.PENDING_EXECUTION].includes(assignment.order?.status.status);
	const { t } = useTranslation();

	return (
		<ReactBeautifulDndDroppable droppableId={props.droppableId}>
			{ (provided, snapshot) => (
				<div className="panel-body" ref={provided.innerRef} style={{ minHeight: '100px' }}>
					{ props.assignments.length === 0 &&
					<div className={ styles.noOrders }>
						<span>{t('pages.planning.dragDrop')}</span>
					</div>
					}
					{ props.assignments.map((assignment, orderIndex) =>
						<Draggable
							draggableId={ `order-${assignment.order!.id}` }
							index={ orderIndex }
							key={ assignment.order!.id }
							isDragDisabled={!isDraggable(assignment)}
						>
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
								>
									<Card
										assignment={ assignment }
										className={ !isDraggable(assignment) ? 'opacity-50 user-select-none' : '' }
									/>
								</div>
							)}
						</Draggable>
					) }
					{ props.assignments.length > 0 && provided.placeholder }
				</div>
			)}
		</ReactBeautifulDndDroppable>
	);
};

export default Droppable;