export enum PlanningStepsEnum {
	NEW_PLAN = 'new-plan',
	ORDERS = 'orders',
	VEHICLES = 'vehicles',
	PARAMETERS = 'parameters',
	CLUSTERING = 'clustering',
	ASSIGNED_ORDERS_SELECTION = 'assigned-orders-selection',
	ASSIGNED_ORDERS = 'assigned-orders',
	SCHEDULING_CRITERIA = 'scheduling-criteria',
	SOLUTION = 'solution',
	REROUTE_SELECTED = 'reroute-selected',
	REROUTE_SINGLE = 'reroute-single',
	FINISHED = 'finished',
}