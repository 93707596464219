import { Field, FieldProps, Formik, Form as FormikForm } from 'formik';
import Checkbox from 'components/Form/Checkbox';
import DriverAsyncSelect from 'components/Form/DriverAsyncSelect';
import React  from 'react';
import { useDispatch } from 'react-redux';
import * as actions from 'pages/Planning/store/actions';
import { IPlanningRoute } from 'model/interfaces/IRoute';
import { IGridRowRender } from 'model/interfaces/grid/IGridRowRender';
import FormikOnChange from 'components/Form/FormikOnChange';

const Form = (props: IGridRowRender<IPlanningRoute>) => {
	const dispatch = useDispatch();

	const onChange = (values: IPlanningRoute) => {
		dispatch(actions.setRoute(values, props.row.vehicle!.id));
	};

	return (
		<Formik initialValues={ props.row } onSubmit={ () => {} }>
			<FormikForm>
				<FormikOnChange<IPlanningRoute> onChange={onChange} />
				<Field name="selected">
					{ (props: FieldProps) =>
						<Checkbox
							label="Selected for rerouting"
							className="form-check form-group d-inline-block"
							{...props}
						/>
					}
				</Field>
				<Field name="driver">
					{ (props: FieldProps) => <DriverAsyncSelect label="Driver" {...props} /> }
				</Field>
			</FormikForm>
		</Formik>
	);
}

export default Form;