import React, {useState} from 'react';

import styles from './index.module.scss';
import { IAssignment } from 'model/interfaces/IAssignment';
import { OrderTypeEnum } from 'model/enums/OrderTypeEnum';
import OrderMoreInfo from 'components/cards/OrderMoreInfo';
import EditOrderButton from 'components/cards/EditOrderButton';
import KeyValueRows from 'components/VehicleRouteCard/KeyValueRows';
import TripsInformation from 'components/cards/TripsInformation';
import OrderHeader from 'components/cards/OrderHeader';
import { OrderFetchActionType } from 'model/types/OrderFetchAction';
import { IShift } from 'model/interfaces/IShift';
import { getEmissionsWithUnits } from 'model/helpers/emissions';
import { useTranslation } from 'react-i18next';
import MapTargetButton from "../Buttons/MapTargetButton";

type BaseProps = {
	assignment: IAssignment,
	shift?: IShift,
	planId?: number,
	showEdit?: boolean,
	showDelete?: boolean,
	fetchAction?: OrderFetchActionType,
	className?: string,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const TripAssignmentCard: React.FC<Props> = ({ assignment, showEdit= false, showDelete = false, ...props }) => {
	const [ showMore, setShowMore ] = useState(false);

	const ordersEmissions = getEmissionsWithUnits(assignment.emissions?.values)

	const { t } = useTranslation();

	return (
		<div className={ `${styles.container} ${styles[`orderStatus${assignment.order.status.status}`]} ${props.className ?? ''} `}>
			<div className={ styles[`number${assignment.order.order_type}`] }>{ assignment.sequence_number }</div>

			{ assignment.order.order_type === OrderTypeEnum.PICKUP &&
				<>
				<OrderHeader
					address={ assignment.order.address_from! }
					restriction={ assignment.order.restriction_from }
					erpId={ assignment.order.erp_id }
					order={ assignment.order }
					time={ assignment.arrival_times.previous_arrival_time }
				/>
				<OrderMoreInfo
					order={ assignment.order }
					showMore={ showMore }
				/>
				</>
			}
			{ assignment.order.order_type === OrderTypeEnum.DELIVERY &&
				<>
					<OrderHeader
						address={ assignment.order.address_to! }
						restriction={ assignment.order.restriction_to }
						erpId={ assignment.order.erp_id }
						order={ assignment.order }
						time={ assignment.arrival_times.previous_arrival_time }
					/>
					<OrderMoreInfo
						order={ assignment.order }
						showMore={ showMore }
					/>
				</>
			}
			{ assignment.order.order_type === OrderTypeEnum.REQUISITION &&
				<>
					<OrderHeader
						address={ assignment.order.address_from! }
						restriction={ assignment.order.restriction_from }
						erpId={ assignment.order.erp_id }
						requisitionType={OrderTypeEnum.PICKUP}
						time={ assignment.arrival_times.previous_arrival_time }
						order={ assignment.order }
					/>
					<OrderHeader
						address={ assignment.order.address_to! }
						restriction={ assignment.order.restriction_to }
						erpId={ assignment.order.erp_id }
						requisitionType={OrderTypeEnum.DELIVERY}
						time={ assignment.arrival_times.order_arrival_time }
						order={ assignment.order }
					/>
					<OrderMoreInfo
						order={ assignment.order }
						showMore={ showMore }
					/>
				</>
			}

			{ showMore &&
				<>
					<div className="mt-2">
						<strong>{t('components.cards.tripInfo')}</strong><br />
						<TripsInformation assignment={assignment} />
					</div>
					{Object.keys(ordersEmissions).length > 0 && (
						<div className="mt-2">
							<strong>{t('cards.ordersEmissions')}</strong><br />
							<KeyValueRows items={ordersEmissions} />
						</div>
					)}
				</>
			}

			<button
				className="btn font-weight-normal p-0 text-small text-primary mt-1"
				onClick={ () => setShowMore(!showMore) }
			>
				{showMore ? t('cards.lessInformationTrip') : t('cards.moreInformationTrip')}
				<i className={`far ${showMore ? 'fa-angle-up' : 'fa-angle-down'} ml-2`} />
			</button>

			{(showEdit || showDelete) && props.planId && props.shift &&
			<EditOrderButton
				order={assignment.order}
				showEdit={showEdit}
				showDelete={showDelete}
				fetchAction={ props.fetchAction }
				planId={ props.planId }
				shift={ props.shift }
			/>
			}

			<MapTargetButton order={assignment.order} />

		</div>
	);
};

export default TripAssignmentCard;
