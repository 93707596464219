import React from 'react';

import * as constants from './constants';
import stylesLayout from '../Layout.module.scss';
import Layout from 'pages/Layout';
import Grid from 'components/Grid';
import { IVehicleAbility } from 'model/interfaces/IVehicleAbility';
import * as actions from 'pages/VehicleAbilities/store/actions';
import initialState from 'pages/VehicleAbilities/VehicleAbilityForm/initialState';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import { useAdminGrid } from 'model/hooks/useAdminGrid';
import VehicleAbilityForm from 'pages/VehicleAbilities/VehicleAbilityForm';
import BaseModal from 'components/modals/BaseModal';
import {useTranslation} from "react-i18next";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const VehicleAbilities: React.FC<Props> = props => {

	const { t } = useTranslation();

	const {
		isFormOpened,
		openForm,
		closeForm,
		formInitialValues,
		editAction,
		deleteAction,
	} = useAdminGrid<IVehicleAbility>(
		initialState,
		t('pages.VA.wantDeleteVA'),
		actions.deleteVehicleAbility,
		actions.fetchVehicleAbilities,
	);

	const { vehicleAbilities, isLoading, error } = useSelector((state: ReduxState) => ({
		vehicleAbilities: state.vehicleAbilities.data?.results ?? [],
		isLoading: state.vehicleAbilities.loading,
		error: state.vehicleAbilities.error,
	}));

	return (
		<>
			<BaseModal
				isOpen={ isFormOpened }
				onRequestClose={ closeForm }
				title={t('pages.VA.vehicleAbilityForm')}
			>
				<VehicleAbilityForm
					onSuccess={ closeForm }
					initialValues={ formInitialValues }
				/>
			</BaseModal>

			<Layout className={stylesLayout.layoutSettings}>
				<section className={ stylesLayout.content }>
					<Grid<IVehicleAbility>
						data={ vehicleAbilities }
						columns={ constants.COLUMNS }
						rowSelectors={ constants.SELECTORS }
						rowActions={ [editAction, deleteAction] }
						loading={ isLoading }
						buttons={ () => (
							<>
								<button
									className="btn btn-primary"
									onClick={ openForm }
								>
									{t('pages.VA.addAbility')}
								</button>
							</>
						) }
					/>
				</section>
			</Layout>
		</>
	);
};

export default VehicleAbilities as React.FC<BaseProps>;
