import React from 'react';
import Modal from 'react-modal';
import styles from './BaseModal.module.scss';

export type BaseModalProps = {
	isOpen: boolean,
	onRequestClose: () => void,
	title: string,
};

type InjectedProps = {
	children: React.ReactNode
};

type Props = BaseModalProps & InjectedProps;

const BaseModal: React.FC < Props > = ({ isOpen, onRequestClose, title, children }) => {
	return (
		<Modal
			className={{
				base: `${styles.modal} panel`,
				afterOpen: styles.modalAfterOpen,
				beforeClose: styles.modalBeforeClose,
			}}
			overlayClassName={{
				base: styles.overlay,
				afterOpen: styles.overlayAfterOpen,
				beforeClose: styles.overlayBeforeClose,
			}}
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			closeTimeoutMS={150}
		>
			<h2>{title}</h2>
			<button onClick={onRequestClose} className={`${styles.close} btn`}>
				<i className="far fa-times" />
			</button>
			{children}
		</Modal>
	);
};

export default BaseModal;
