import { load, save } from 'redux-localstorage-simple';
import { applyMiddleware, createStore as createReduxStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import * as constants from './constants';
import createReducers from 'store/reducer';
import rootSaga from 'store/rootSaga';

export default function createStore() {
	const sagaMiddleware = createSagaMiddleware();

	const middleware = composeWithDevTools({
		maxAge: 5000,
	})(
		applyMiddleware(
			sagaMiddleware,
			// Should always be last
			save(constants.localStorageSaveConfig)
		)
	);


	const reducer = createReducers();

	const preloadedState = load(constants.localStorageLoadConfig);

	const reduxStore = createReduxStore(reducer, preloadedState, middleware);

	sagaMiddleware.run(rootSaga);

	return reduxStore;
}
