import { ISelectOptions } from 'model/interfaces/ISelectOptions';
import { LicenceTypeEnum } from 'model/enums/LicenceTypeEnum';
import { UnknownEnumValue } from 'model/errors/UnknownEnumValue';
import { AvailabilityEnum } from 'model/enums/AvailabilityEnum';
import { DeletedEnum } from 'model/enums/DeletedEnum';
import i18n from '../../i18n';


export const licenceTypeToString = (type: LicenceTypeEnum): string => {
	switch (type) {
		case LicenceTypeEnum.AMATEUR:
			return (i18n.t('model.helpers.amateur'));

		case LicenceTypeEnum.PROFESSIONAL:
			return (i18n.t('model.helpers.professional'));

		default:
			throw new UnknownEnumValue('LicenceType', type);
	}
};

export const LICENCE_TYPES: LicenceTypeEnum[] = [LicenceTypeEnum.AMATEUR, LicenceTypeEnum.PROFESSIONAL];

export const licenceTypeSelectOptions: ISelectOptions[] = LICENCE_TYPES.map(type => ({
	label: licenceTypeToString(type),
	value: type,
}));

export const availabilityToString = (type: AvailabilityEnum): string => {
	switch (type) {
		case AvailabilityEnum.UNAVAILABLE:
			return (i18n.t('model.helpers.unavailable'));

		case AvailabilityEnum.AVAILABLE:
			return (i18n.t('model.helpers.available'));

		default:
			throw new UnknownEnumValue('Anailability', type);
	}
};

export const AVAILABILITIES: AvailabilityEnum[] = [AvailabilityEnum.AVAILABLE, AvailabilityEnum.UNAVAILABLE];

export const availabilitySelectOptions: ISelectOptions[] = AVAILABILITIES.map((type) => ({
	label: availabilityToString(type),
	value: type,
}));

export const deletedToString = (type: DeletedEnum): string => {
	switch (type) {
		case DeletedEnum.NORMAL:
			return (i18n.t('model.helpers.normal'));

		case DeletedEnum.DELETED:
			return (i18n.t('model.helpers.deleted'));

		default:
			throw new UnknownEnumValue('Deleted enum', type);
	}
}

export const DELETE_VALUES: DeletedEnum[] = [DeletedEnum.DELETED, DeletedEnum.NORMAL];

export const deletedSelectOptions: ISelectOptions[] = DELETE_VALUES.map((type) => ({
	label: deletedToString(type),
	value: type,
}))
