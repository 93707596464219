export enum RouteStatusEnum {
	PRESET, DRAFT, UNDER_PLANNING, SCHEDULED, IN_PROGRESS, MODIFIED, COMPLETED, CANCELLED, UNDER_MODIFICATION, SCHEDULED_MODIFIED
}

export const HomepageFilterRouteStatuses = [
	RouteStatusEnum.SCHEDULED,
	RouteStatusEnum.IN_PROGRESS,
	RouteStatusEnum.COMPLETED,
	RouteStatusEnum.CANCELLED,
];

export const RouteStatusPairs = {
	[RouteStatusEnum.PRESET]: 'model.enums.preset',
	[RouteStatusEnum.DRAFT]: 'model.enums.route.draft',
	[RouteStatusEnum.UNDER_PLANNING]: 'model.enums.draftModified',
	[RouteStatusEnum.SCHEDULED]: 'model.enums.route.scheduled',
	[RouteStatusEnum.IN_PROGRESS]: 'model.enums.route.inProgress',
	[RouteStatusEnum.MODIFIED]: 'model.enums.route.route.modified',
	[RouteStatusEnum.COMPLETED]: 'model.enums.route.route.completed',
	[RouteStatusEnum.CANCELLED]: 'model.enums.route.route.cancelled',
	[RouteStatusEnum.UNDER_MODIFICATION]: 'model.enums.underModification',
	[RouteStatusEnum.SCHEDULED_MODIFIED]: 'model.enums.scheduledModified',
};