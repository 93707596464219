import { createActionCreator } from 'deox';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import { IPlan } from 'model/interfaces/IPlan';
import { IApiError } from 'model/interfaces/IApiError';
import { IPlanningRoute, IRoute } from 'model/interfaces/IRoute';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IOrder } from 'model/interfaces/IOrder';
import { IRoutePartialAssignmentsState } from 'pages/Planning/AssignedOrders';

export enum PlanningActionsEnum {
	SET_STEP = '@PLANNING_SET_STEP',

	CREATE_PLAN = '@PLANNING_CREATE_PLAN',
	CREATE_PLAN_SUCCESS = '@PLANNING_CREATE_PLAN_SUCCESS',
	CREATE_PLAN_FAIL = '@PLANNING_CREATE_PLAN_FAIL',

	CANCEL_PLAN = '@PLANNING_CANCEL_PLAN',
	CANCEL_PLAN_FAIL = '@PLANNING_CANCEL_PLAN_FAIL',

	FETCH_ORDERS = '@PLANNING_FETCH_ORDERS',
	FETCH_ORDERS_SUCCESS = '@PLANNING_FETCH_ORDERS_SUCCESS',
	FETCH_ORDERS_FAIL = '@PLANNING_FETCH_ORDERS_FAIL',

	IMPORT_ORDERS = '@PLANNING_IMPORT_ORDERS',
	IMPORT_ORDERS_SUCCESS = '@PLANNING_IMPORT_ORDERS_SUCCESS',
	IMPORT_ORDERS_FAIL = '@PLANNING_IMPORT_ORDERS_FAIL',

	INIT_ROUTES = '@PLANNING_INIT_ROUTES',
	SET_ROUTE = '@PLANNING_SET_ROUTE',
	SET_ROUTES_ENABLED = '@PLANNING_SET_ROUTES_ENABLED',

	CREATE_ROUTES = '@PLANNING_CREATE_ROUTES',
	CREATE_ROUTE = '@PLANNING_CREATE_ROUTE',
	CREATE_ROUTE_SUCCESS = '@PLANNING_CREATE_ROUTE_SUCCESS',
	CREATE_ROUTE_FAIL = '@PLANNING_CREATE_ROUTE_FAIL',
	CREATE_ROUTES_AND_CONFIRM_PLAN = '@PLANNING_CREATE_ROUTES_AND_CONFIRM_PLAN',
	
	DELETE_ROUTE = '@PLANNING_DELETE_ROUTE',
	DELETE_ROUTE_SUCCESS = '@PLANNING_DELETE_ROUTE_SUCCESS',
	DELETE_ROUTE_FAIL = '@PLANNING_DELETE_ROUTE_FAIL',

	CREATE_ASSIGNMENTS = '@PLANNING_CREATE_ASSIGNMENTS',

	RECALCULATE = '@PLANNING_RECALCULATE',
	RECALCULATE_CANCEL = '@PLANNING_RECALCULATE_CANCEL',
	RECALCULATE_SAVE = '@PLANNING_RECALCULATE_SAVE',

	CONFIRM_PLAN = '@PLANNING_CONFIRM_PLAN',

	SET_INITIAL_STATE = '@PLANNING_SET_INITIAL_STATE',
	SET_REROUTE_ROUTE_ID = '@PLANNING_REROUTE_ROUTE_ID',
	SET_MODICIATION_STATUS = '@PLANNING_SET_MODIFICATION_STATUS',

	CALCULATE = '@PLANNING_CALCULATE',
	CALCULATE_SUCCESS = '@PLANNING_CALCULATE_SUCCESS',
	CALCULATE_FAIL = '@PLANNING_CALCULATE_FAIL,',

	LOADING_START = '@PLANNING_LOADING_START',
	LOADING_END = '@PLANNING_LOADING_END',
}

export const setStep = createActionCreator(PlanningActionsEnum.SET_STEP,
	resolve => (step: PlanningStepsEnum) => resolve(step)
);

export const createPlan = createActionCreator(PlanningActionsEnum.CREATE_PLAN,
	resolve => (date: string, shiftId: number) => resolve({ date, shiftId }));

export const createPlanSuccess = createActionCreator(
	PlanningActionsEnum.CREATE_PLAN_SUCCESS, resolve => (plan: IPlan) => resolve(plan)
);

export const createPlanFail = createActionCreator(
	PlanningActionsEnum.CREATE_PLAN_FAIL, resolve => (error: IApiError) => resolve(error),
);

export const cancelPlan = createActionCreator(PlanningActionsEnum.CANCEL_PLAN);

export const setInitialState = createActionCreator(PlanningActionsEnum.SET_INITIAL_STATE);

export const cancelPlanFail = createActionCreator(
	PlanningActionsEnum.CANCEL_PLAN_FAIL, resolve => (error: IApiError) => resolve(error),
);

export const fetchOrders = createActionCreator(PlanningActionsEnum.FETCH_ORDERS);

export const fetchOrdersSuccess = createActionCreator(
	PlanningActionsEnum.FETCH_ORDERS_SUCCESS, resolve => (orders: IPaginatedData<IOrder>) => resolve(orders)
);

export const fetchOrdersFail = createActionCreator(
	PlanningActionsEnum.FETCH_ORDERS_FAIL, resolve => (error: IApiError) => resolve(error),
);

export const importOrders = createActionCreator(PlanningActionsEnum.IMPORT_ORDERS, resolve => (fileList: FileList | null) => resolve(fileList));

export const importOrdersSuccess = createActionCreator(
	PlanningActionsEnum.IMPORT_ORDERS_SUCCESS, resolve => () => resolve()
);

export const importOrdersFail = createActionCreator(
	PlanningActionsEnum.IMPORT_ORDERS_FAIL, resolve => (error: IApiError) => resolve(error),
);

export const initRoutes = createActionCreator(PlanningActionsEnum.INIT_ROUTES,
	resolve => (routes: IPlanningRoute[]) => resolve(routes)
);

export const setRoute = createActionCreator(PlanningActionsEnum.SET_ROUTE,
	resolve => (route: IPlanningRoute, index: number) => resolve(route, index)
);

export const setRoutesEnabled = createActionCreator(PlanningActionsEnum.SET_ROUTES_ENABLED,
	resolve => (enabled: boolean = true) => resolve(enabled)
);

export const createRoutes = createActionCreator(PlanningActionsEnum.CREATE_ROUTES,
	resolve => (nextStep?: PlanningStepsEnum) => resolve(nextStep)
);

export const createRoutesAndConfirmPlan = createActionCreator(PlanningActionsEnum.CREATE_ROUTES_AND_CONFIRM_PLAN,
	resolve => (routeAssignments: IRoutePartialAssignmentsState[]) => resolve(routeAssignments)
);

export const createRoute = createActionCreator(PlanningActionsEnum.CREATE_ROUTE,
	resolve => (index: number) => resolve(undefined, index)
);

export const createRouteSuccess = createActionCreator(PlanningActionsEnum.CREATE_ROUTE_SUCCESS,
	resolve => (route: IRoute, index: number) => resolve(route, index)
);

export const createRouteFail = createActionCreator(
	PlanningActionsEnum.CREATE_ROUTE_FAIL, resolve => (error: IApiError, index: number) => resolve(error, index),
);

export const deleteRoute = createActionCreator(PlanningActionsEnum.DELETE_ROUTE,
	resolve => (index: number) => resolve(undefined, index)
);

export const deleteRouteSuccess = createActionCreator(PlanningActionsEnum.DELETE_ROUTE_SUCCESS,
	resolve => (index: number) => resolve(undefined, index)
);

export const deleteRouteFail = createActionCreator(PlanningActionsEnum.DELETE_ROUTE_FAIL,
	resolve => (error: IApiError, index: number) => resolve(error, index)
);

export const recalculate = createActionCreator(PlanningActionsEnum.RECALCULATE,
	resolve => (routeAssignments: IRoutePartialAssignmentsState[]) => resolve(routeAssignments)
);

export const cancelRecalculate = createActionCreator(PlanningActionsEnum.RECALCULATE_CANCEL,
	resolve => (routeAssignments: IRoutePartialAssignmentsState[], destination: PlanningStepsEnum = PlanningStepsEnum.PARAMETERS) => resolve({ routeAssignments, destination })
);

export const saveRecalculate = createActionCreator(PlanningActionsEnum.RECALCULATE_SAVE,
	resolve => (routeAssignments: IRoutePartialAssignmentsState[], destination: PlanningStepsEnum = PlanningStepsEnum.PARAMETERS) => resolve({ routeAssignments, destination })
);

export const confirmPlan = createActionCreator(PlanningActionsEnum.CONFIRM_PLAN,
	resolve => (routeAssignments: IRoutePartialAssignmentsState[]) => resolve(routeAssignments)
);

export const setRerouteRouteId = createActionCreator(PlanningActionsEnum.SET_REROUTE_ROUTE_ID,
	resolve => (id: number) => resolve(id)
);

export const setModificationStatus = createActionCreator(PlanningActionsEnum.SET_MODICIATION_STATUS,
	resolve => (id: number) => resolve(id)
);

export const calculate = createActionCreator(PlanningActionsEnum.CALCULATE);

export const calculateSuccess = createActionCreator(PlanningActionsEnum.CALCULATE_SUCCESS);

export const calculateFail = createActionCreator(
	PlanningActionsEnum.CALCULATE_FAIL, resolve => (error: IApiError) => resolve(error),
);

export const loadingStart = createActionCreator(PlanningActionsEnum.LOADING_START);

export const loadingEnd = createActionCreator(PlanningActionsEnum.LOADING_END);