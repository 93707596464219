import { createReducer } from 'deox';

import * as actions from 'pages/Vehicles/store/actions';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';
import { IVehicle } from 'model/interfaces/IVehicle';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';
import { IApiError } from 'model/interfaces/IApiError';

export interface IVehiclesState extends IReduxFetchedState<IPaginatedData<IVehicle>> {
	formSuccess?: IReduxActionState | boolean | undefined,
	formError?: (IReduxActionState & IApiError) | undefined,

	deleteSuccess?: IReduxActionState | boolean,
	deleteError?: IReduxActionState & IApiError,
}

const initialState: IVehiclesState = {};

export default createReducer(initialState, handle => [
	handle(actions.fetchVehicles, (state) => ({
		...state,
		loading: true,
	})),
	handle(actions.fetchVehiclesSuccess, (state, { payload }) => ({
		...state,
		error: undefined,
		data: { ...payload },
		loading: false,
	})),
	handle(actions.fetchVehiclesFail, (state, { payload }) => ({
		...state,
		data: undefined,
		error: { ...payload },
		loading: false,
	})),
	handle(actions.createVehicle, (state) => ({
		...state,
		loading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.updateVehicle, (state) => ({
		...state,
		loading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.formVehicleSuccess, (state, { payload }) => ({
		...state,
		loading: false,
		formSuccess: { ...payload },
		formError: undefined,
	})),
	handle(actions.formVehicleFail, (state, { payload }) => ({
		...state,
		loading: false,
		formSuccess: undefined,
		formError: { ...payload },
	})),
	handle(actions.formVehicleRestart, (state) => ({
		...state,
		loading: false,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.deleteVehicle, (state) => ({
		...state,
		loading: true,
		deleteSuccess: undefined,
		deleteError: undefined,
	})),
	handle(actions.deleteVehicleSuccess, (state, { payload }) => ({
		...state,
		loading: false,
		deleteSuccess: { ...payload },
		deleteError: undefined,
	})),
	handle(actions.deleteVehicleFail, (state, { payload }) => ({
		...state,
		loading: false,
		success: false,
		error: { ...payload },
	})),
]);
