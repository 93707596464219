import React from 'react';

import * as constants from './constants';
import stylesLayout from '../Layout.module.scss';
import Layout from 'pages/Layout';
import Grid from 'components/Grid';
import { IPackage } from 'model/interfaces/IPackage';
import * as actions from 'pages/Packages/store/actions';
import { initialState } from 'model/interfaces/IPackage';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import { useAdminGrid } from 'model/hooks/useAdminGrid';
import PackageForm from 'pages/Packages/PackageForm';
import BaseModal from 'components/modals/BaseModal';
import { useSearchedEntity } from 'model/hooks/useSearchedEntity';
import { IDriver } from 'model/interfaces/IDriver';
import { IGenericSearchRequest } from 'model/services/Api/interfaces/requests/IGenericSearchRequest';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { UserTypeEnum } from 'model/enums/UserTypeEnum';
import {useTranslation} from "react-i18next";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const Packages: React.FC<Props> = props => {
	const { t } = useTranslation();

	const {
		isFormOpened,
		openForm,
		closeForm,
		formInitialValues,
		editAction,
		deleteAction,
	} = useAdminGrid<IPackage>(
		initialState,
		t('pages.packages.wantDeletePackage'),
		actions.deletePackage,
		actions.fetchPackages,
	);

	const { packages, isLoading, error } = useSelector((state: ReduxState) => ({
		packages: state.packages.data?.results ?? [],
		isLoading: state.packages.loading,
		error: state.packages.error,
	}));

	const [ searchedPackages, onSearch ] = useSearchedEntity<IPackage, IGenericSearchRequest>(
		`${UrlEnum.PACKAGE}`,
		packages,
	);

	return (
		<>
			<BaseModal
				isOpen={ isFormOpened }
				onRequestClose={ closeForm }
				title={t('pages.packages.packageForm')}
			>
				<PackageForm
					onSuccess={ closeForm }
					initialValues={ formInitialValues }
				/>
			</BaseModal>

			<Layout className={stylesLayout.layoutSettings}>
				<section className={ stylesLayout.content }>
					<Grid<IPackage>
						data={ searchedPackages }
						columns={ constants.COLUMNS }
						rowSelectors={ constants.SELECTORS }
						rowActions={ [editAction, deleteAction] }
						loading={ isLoading }
						searchCallback={ onSearch }
						buttons={ () => (
							<>
								<button
									className="btn btn-primary"
									onClick={ openForm }
								>
									{t('pages.packages.addPackage')}
								</button>
							</>
						) }
					/>
				</section>
			</Layout>
		</>
	);
};

export default Packages as React.FC<BaseProps>;
