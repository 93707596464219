import React, { useEffect } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import TextInput from 'components/Form/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import * as vehicleRestrictionActions from 'pages/VehicleRestrictions/store/actions';
import TextAreaInput from 'components/Form/TextAreaInput';
import { IVehicleRestrictionEndpoint } from 'model/interfaces/IVehicleRestrictionEndpoint';
import {useTranslation} from "react-i18next";

type BaseProps = {
	onSuccess: () => void,
	initialValues: IVehicleRestrictionEndpoint,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const VehicleRestrictionForm: React.FC<Props> = props => {

	const dispatch = useDispatch();

	const createUpdateSuccess = useSelector<ReduxState>(state => state.vehicleRestrictions.formSuccess);

	const onSubmit = async (values: IVehicleRestrictionEndpoint) => {
		if (values.id) {
			dispatch(vehicleRestrictionActions.updateVehicleRestriction(values));
		} else {
			dispatch(vehicleRestrictionActions.createVehicleRestriction(values));
		}
	};

	useEffect(() => {
		if (createUpdateSuccess) {
			dispatch(vehicleRestrictionActions.formVehicleRestrictionRestart());
			props.onSuccess();
		}
	}, [ createUpdateSuccess ]);

	const { t } = useTranslation();

	return (
		<Formik<IVehicleRestrictionEndpoint>
			initialValues={ props.initialValues }
			onSubmit={ onSubmit }
		>
			{ formikBag => (
				<Form>
					<div className="row">
						<div className="col">
							<Field
								name="name"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.nameVR')} { ...props } /> }
							</Field>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field
								name="description"
							>
								{ (props: FieldProps) => <TextAreaInput label={t('pages.descriptionVR')} { ...props }/> }
							</Field>
						</div>
					</div>
					<div className="text-right">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={ ! formikBag.isValid || formikBag.isSubmitting }
						>
							{t('pages.saveVR')}
						</button>
					</div>
				</Form>
			) }
		</Formik>
	);
};

export default VehicleRestrictionForm;
