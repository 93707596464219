import { createActionCreator } from 'deox';
import { IApiError } from 'model/interfaces/IApiError';
import { IShift } from 'model/interfaces/IShift';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';

export enum ShiftActionsEnum {
	FETCH = '@SHIFTS_FETCH',
	FETCH_SUCCESS = '@SHIFTS_FETCH_SUCCESS',
	FETCH_FAIL = '@SHIFTS_FETCH_FAIL',

	CREATE = '@SHIFTS_CREATE',
	UPDATE = '@SHIFTS_UPDATE',
	FORM_SUCCESS = '@SHIFTS_FORM_SUCCESS',
	FORM_FAIL = '@SHIFTS_FORM_FAIL',
	FORM_RESTART = '@SHIFTS_FORM_RESTART',

	DELETE = '@SHIFTS_DELETE',
	DELETE_SUCCESS = '@SHIFTS_DELETE_SUCCESS',
	DELETE_FAIL = '@SHIFTS_DELETE_FAIL',
}

export const fetchShifts = createActionCreator(ShiftActionsEnum.FETCH);

export const fetchShiftsFail = createActionCreator(
	ShiftActionsEnum.FETCH_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const fetchShiftsSuccess = createActionCreator(
	ShiftActionsEnum.FETCH_SUCCESS,
	resolve => (data: IPaginatedData<IShift>) => resolve(data),
);

export const createShift = createActionCreator(
	ShiftActionsEnum.CREATE,
	resolve => (shift: IShift) => resolve(shift),
);

export const updateShift = createActionCreator(
	ShiftActionsEnum.UPDATE,
	resolve => (shift: IShift) => resolve(shift),
);

export const formShiftSuccess = createActionCreator(
	ShiftActionsEnum.FORM_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success));

export const formShiftFail = createActionCreator(
	ShiftActionsEnum.FORM_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const formShiftRestart = createActionCreator(ShiftActionsEnum.FORM_RESTART);

export const deleteShift = createActionCreator(
	ShiftActionsEnum.DELETE,
	resolve => (shift: IShift) => resolve(shift),
);

export const deleteShiftSuccess = createActionCreator(
	ShiftActionsEnum.DELETE_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success)
);

export const deleteShiftFail = createActionCreator(
	ShiftActionsEnum.DELETE_FAIL,
	resolve => (error: IReduxActionState & IApiError) => resolve(error),
);
