import { ICustomer } from 'model/interfaces/ICustomer';

const initialState: ICustomer = {
	id: 0,
	erp_id: '',
	name: '',
	addresses: [],
	email: '',
	telephone: '',
	fax: '',
	comments: '',
	demonstrator: 0,
	restrictions: [],
};

export default initialState;
