import React from 'react';

const memoryState: any = {};

export function useMemoryState(key: string, initialState: any) {
	const [state, setState] = React.useState(() => {
		const hasMemoryValue = Object.prototype.hasOwnProperty.call(memoryState, key);
		if (hasMemoryValue) {
			return memoryState[key]
		} else {
			return typeof initialState === 'function' ? initialState() : initialState;
		}
	});

	function onChange(nextState: any) {
		memoryState[key] = nextState;
		setState(nextState);
	}

	return [state, onChange];
}

export function resetMemoryState() {
	for (const key in memoryState){
		if (memoryState.hasOwnProperty(key)){
			delete memoryState[key];
		}
	}
}