import { Field, FieldProps, Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';

import Checkbox from 'components/Form/Checkbox';
import SemitrailerAsyncSelect from 'components/Form/SemitrailerAsyncSelect';
import DriverAsyncSelect from 'components/Form/DriverAsyncSelect';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as actions from 'pages/Planning/store/actions';
import { IPlanningRoute } from 'model/interfaces/IRoute';
import { IGridRowRender } from 'model/interfaces/grid/IGridRowRender';
import FormikOnChange from 'components/Form/FormikOnChange';
import AddressAsnycSelect from 'components/Form/AddressAsyncSelect';
import TextInput from 'components/Form/TextInput';
import {useTranslation} from "react-i18next";

const Form = (props: IGridRowRender<IPlanningRoute>) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const onChange = (values: IPlanningRoute) => {
		if (!values.enabled && props.row.enabled && props.row.id &&
			window.confirm(t('pages.disableVehicle'))) {
			// Unchecking the checkbox when the route is already created
			dispatch(actions.deleteRoute(props.rowIndex))
		}
		dispatch(actions.setRoute(values, props.row.vehicle!.id));
	};

	const validationSchema = Yup.object().shape({
		time_start: Yup.string().matches(/^\d\d:\d\d:\d\d$/, 'pages.timeFormatStart').required('pages.startRequired'),
		time_end: Yup.string().matches(/^\d\d:\d\d:\d\d$/, 'pages.timeFormatEnd').required('pages.endRequired'),
	});

	return (
		<Formik
			initialValues={props.row}
			validationSchema={validationSchema}
			onSubmit={() => {}}
		>
			<FormikForm>
				<FormikOnChange<IPlanningRoute> onChange={onChange} />

				<Field name="enabled">
					{(props: FieldProps) =>
						<Checkbox
							label={t('pages.vehicleEnabled')}
							className="form-check form-group d-inline-block"
							{...props}
						/>
					}
				</Field>
				<Field name="semitrailer">
					{(props: FieldProps) => <SemitrailerAsyncSelect label={t('pages.semitrailer')} {...props} />}
				</Field>
				<Field name="driver">
					{(props: FieldProps) => <DriverAsyncSelect label={t('pages.driver')} {...props} />}
				</Field>
				<Field name="starting_point">
					{(props: FieldProps) => <AddressAsnycSelect label={t('pages.startPoint')} {...props} />}
				</Field>
				<Field name="ending_point">
					{(props: FieldProps) => <AddressAsnycSelect label={t('pages.endPoint')} {...props} />}
				</Field>
				<div className="form-row">
					<div className="col">
						<Field name="time_start">
							{(props: FieldProps) => <TextInput label={t('pages.wrkinHrs')} placeholder="00:00:00" {...props} />}
						</Field>
					</div>
					<div className="col">
						<Field name="time_end">
							{(props: FieldProps) => <TextInput label="&nbsp;" placeholder="00:00:00" {...props} />}
						</Field>
					</div>
				</div>
			</FormikForm>
		</Formik>
	);
}

export default Form;