import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Footer from "pages/Planning/Footer";
import * as actions from 'pages/Planning/store/actions';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import stylesLayout from 'pages/Layout.module.scss';
import Layout from 'pages/Layout';
import { IPlanningRoute } from 'model/interfaces/IRoute';
import Row from './Row';
import Dropdown from 'components/Popover';
import ManageMenu from 'pages/Planning/Vehicles/ManageMenu';
import Grid from 'components/Grid';
import { ReduxState } from 'store/reducer';
import * as selectors from 'pages/Planning/store/selectors';
import { isDriversSelected } from './utils';
import * as executionDateActions from 'store/executionDate/actions';
import * as shiftActions from 'store/shift/actions';
import {useTranslation} from "react-i18next";
import i18n from "../../../i18n";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const COLUMNS: Array<string> = [
	"", "", "pages.planning.solution.start", "pages.planning.solution.end", "pages.planning.solution.distance", "pages.planning.solution.total", "pages.planning.solution.deliver", "pages.planning.solution.pickups", "pages.planning.solution.requis", "pages.planning.solution.driver", ""
];

const Solution: React.FC < Props > = props => {
	const dispatch = useDispatch();

	const { rows, isLoading, error, routeAssignments } = useSelector((state: ReduxState) => ({
		rows: state.planning.routes?.map(route => route.data as IPlanningRoute).filter(route => route.enabled) ?? [],
		isLoading: state.vehicles.loading,
		error: state.vehicles.error,
		routeAssignments: selectors.getRouteAssignments(state),
	}));

	const { t } = useTranslation();

	const saveAndUploadAll = () => {
		if ( !isDriversSelected(rows) ) {
			toast.error(i18n.t('pages.driversForAll'));
			return;
		}

		dispatch(actions.createRoutesAndConfirmPlan(routeAssignments));
	};

	return (
		<Layout className={ stylesLayout.layoutPlanning }>
			{props.children}
			<div className="d-flex align-items-center justify-content-center">
				<Grid<IPlanningRoute>
					data={ rows }
					columns={ COLUMNS }
					rowRender={ props => <Row {...props} /> }
					loading={ isLoading }
					buttons={ () => (
						<>
							<Dropdown menu={ ({ hide }) => <ManageMenu /> }>
								{ ({ onClick }) => (
									<button
										className="btn btn-sm btn-outline-primary"
										onClick={ onClick }
									>
										{t('pages.manage')}
										<i className="fa fa-angle-down ml-2" />
									</button>
								)}
							</Dropdown>
						</>
					) }
				/>
			</div>
			<Footer>
				<button
					className="btn"
					onClick={() => dispatch(actions.setStep(PlanningStepsEnum.PARAMETERS))}
				>
					{t('pages.back')}
				</button>
				<button
					className="btn mr-2"
					onClick={() => dispatch(actions.setStep(PlanningStepsEnum.REROUTE_SELECTED))}
				>
					{t('pages.reroute')}
				</button>
				<button
					className="btn btn-primary"
					onClick={ saveAndUploadAll }
				>
					{t('pages.saveUpload')}
				</button>
			</Footer>
		</Layout>
	);
};

export default Solution;