import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import stylesLayout from '../Layout.module.scss';
import Aside from 'pages/Planning/Aside';
import { ReduxState } from 'store/reducer';
import routes from 'pages/Planning/routes';
import Loader from 'components/Loader';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import * as actions from 'pages/Planning/store/actions';

const Planning = () => {
	const { step, data } = useSelector(({ planning }: ReduxState) => ({
		step: planning.step,
		data: planning.plan.data,
	}));

	const dispatch = useDispatch();

	if (step !== PlanningStepsEnum.NEW_PLAN && !data) {
		dispatch(actions.setInitialState());
	}

	const Step = routes[step];

	const isLoading = useSelector((state: ReduxState) =>
		state.planning.plan?.loading || state.planning.orders.loading || state.planning.routes?.some(route => route.loading) || false
	);

	return (
		<>
			<Loader visible={isLoading} />
			<Step>
				<aside className={ stylesLayout.asidePlanning + ' panel p-4' }>
					<Aside step={ step } />
				</aside>
			</Step>
		</>
	);
};

export default Planning;