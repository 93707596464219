import React, {useEffect, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, DropResult, } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

import styles from 'pages/Rerouting/index.module.scss';
import stylesAssignedOrders from 'pages/Planning/AssignedOrders/index.module.scss';
import { ReduxState } from 'store/reducer';
import { IRoute } from 'model/interfaces/IRoute';
import stylesLayout from 'pages/Layout.module.scss';
import Layout from 'pages/Layout';
import Droppable from 'pages/Planning/AssignedOrders/Droppable';
import { move, reorder } from 'pages/Planning/AssignedOrders/utils';
import { isUnderModification } from './utils';
import * as selectors from 'store/route/selectors';
import * as actions from 'store/route/actions';
import VehicleRouteCard from 'components/VehicleRouteCard';
import { IAssignment } from 'model/interfaces/IAssignment';
import { OrderTypeBadgeEnum } from 'components/OrderTypeBadge/OrderTypeBadgeEnum';
import OrderBadges from 'components/OrderTypeBadge/OrderBadges';
import { RoutesEnum } from 'model/enums/RoutesEnum';
import { IOrder } from 'model/interfaces/IOrder';
import Loader from 'components/Loader';
import { useRerouting } from 'model/hooks/useRerouting';
import ListOfOrders from 'pages/Planning/AssignedOrders/ListOfOrders';
import * as orderSelectors from 'pages/Orders/store/selectors';
import NoPlanPage from 'components/NoPlanPage';
import * as ordersActions from 'pages/Orders/store/actions';
import {useTranslation} from "react-i18next";
import OrdersMap from "../../Orders/OrdersMap";

export type IRouteAssignmentsState = {
	route?: IRoute,
	assignments: Partial<IAssignment>[],
};

const RerouteSelected = () => {

	const { allSelectedVehicles, isLoading, assignments, initialRouteAssignments, plan } = useSelector((state: ReduxState) => ({
		allSelectedVehicles: selectors.getAllSelectedRoutes(state),
		isLoading: state.routes.loading || false,
		assignments: orderSelectors.getUnassignedAssignments(state.orders),
		initialRouteAssignments: selectors.getInitialRouteAssignments(state),
		plan: state.plan.data,
	}));

	const dispatch = useDispatch();

	const { routeAssignments, onDragEnd, isPristine, setPristine } = useRerouting(initialRouteAssignments);

	const orders = useMemo(() => routeAssignments[0].assignments.map(assignment => assignment.order!), [routeAssignments[0].assignments]);

	const { t } = useTranslation();

	useEffect(() => {
		dispatch(ordersActions.fetchOrders());

	}, [])

	if (!plan?.id) {
		return <NoPlanPage />;
	}

	return (
		<DragDropContext onDragEnd={ onDragEnd }>
			<Loader visible={isLoading} />
			<OrdersMap orders={orders} plan={plan} />
			<Layout className={ stylesLayout.layoutReroutingSelected }>
				<ListOfOrders
					assignments={routeAssignments[0].assignments}
					className="pl-4"
					plan={plan}
				/>

				<div className={`${stylesLayout.content} d-flex align-items-start`}>
					{ routeAssignments.slice(1).map((route, routeIndex) => (
						<div className={`${stylesAssignedOrders.vehicle} panel mr-3 flex-shrink-0 pb-1 z-index-draggable`} key={routeIndex}>
							<div className="panel-header py-2 text-right">
								<Link
									to={ RoutesEnum.DETAIL_REROUTING.replace(':id', route!.route!.id!.toString()) }
									className="btn btn-sm btn-outline-primary"
								>
									<i className="fa fa-map-marked-alt mr-2" />{t('pages.rerouting.reroute.map')}
								</Link>
							</div>

							<div className="overflow-auto flex-grow-1">
								<div className="panel-body overflow-initial mb-4">
									<VehicleRouteCard route={ route.route as IRoute }/>

									{/*<div className="py-1">*/}
									{/*	<OrderBadges*/}
									{/*		orders={route.assignments.map(assignment => assignment.order)}*/}
									{/*		hide={[OrderTypeBadgeEnum.UNASSIGNED]}*/}
									{/*	/>*/}
									{/*</div>*/}
								</div>

								<Droppable droppableId={`${routeIndex+1}`} assignments={route.assignments} />
							</div>
						</div>
					))}
				</div>

				<footer className={`z-index-draggable ${styles.footer}`}>
					<div className={`panel bg-white h-100 px-3 ${styles.footerPanel}`}>
						<div className="h-100 justify-content-end d-flex align-items-center">
							{ isUnderModification(allSelectedVehicles) && isPristine ?
								<>
									<button
										className="btn text-danger mr-2"
										onClick={ () => dispatch(actions.cancelRecalculate(routeAssignments)) }
									>
										{t('pages.rerouting.reroute.cancel')}
									</button>
									<button
										className="btn btn-primary px-4"
										onClick={ () => dispatch(actions.saveRecalculate(routeAssignments)) }
									>
										{t('pages.rerouting.reroute.save')}
									</button>
								</>
								:
								<>
									<Link
										to={RoutesEnum.REROUTING}
										className="btn"
									>
										{t('pages.rerouting.reroute.back')}
									</Link>
									<button
										className="btn btn-primary"
										onClick={ () => {
											setPristine(true);
											dispatch(actions.recalculate(routeAssignments));
										}}
									>
										{t('pages.rerouting.reroute.recalculate')}
									</button>
								</>
							}
						</div>
					</div>
				</footer>
			</Layout>

		</DragDropContext>

	)


};

export default RerouteSelected;