import React from 'react';
import { IPlanningRoute, IRoute } from 'model/interfaces/IRoute';
import { getNoOfOrders } from 'model/helpers/assignment';
import { OrderStatusEnum } from 'model/enums/OrderStatusEnum';

type Props = {
	route: IRoute|IPlanningRoute
}

const NowPoint: React.FC<Props> = ({ route }) =>
	<>
		{getNoOfOrders(route.assignments, [OrderStatusEnum.COMPLETED, OrderStatusEnum.CANCELLED])} / {getNoOfOrders(route.assignments)}<br/>
		{ route.total_distance } km
	</>
;

export default NowPoint;