import React, { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Footer from 'pages/Planning/Footer';
import * as actions from 'pages/Planning/store/actions';
import NewOrderModal from 'components/modals/NewOrder';
import { initialValues } from 'components/modals/NewOrder/initialValues';
import { ReduxState } from 'store/reducer';
import OrderCard from 'components/cards/OrderCard';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import stylesLayout from 'pages/Layout.module.scss';
import Layout from 'pages/Layout';
import SearchFulltext from 'components/SearchFulltext';
import OrderFilters from 'components/OrderFilters';
import OrderBadges from 'components/OrderTypeBadge/OrderBadges';
import { OrderTypeBadgeEnum } from 'components/OrderTypeBadge/OrderTypeBadgeEnum';
import FiltersButton from 'components/Buttons/FiltersButton';
import { useSearchedEntity } from 'model/hooks/useSearchedEntity';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IOrder } from 'model/interfaces/IOrder';
import { IOrderRequest } from 'model/services/Api/interfaces/requests/IOrderRequest';
import {useTranslation} from "react-i18next";
import OrdersMap from "../Orders/OrdersMap";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const Orders: React.FC < Props > = props => {
	const dispatch = useDispatch();
	const [ isNewOrdeModalOpen, setNewOrderModalOpen ] = useState(false);
	const [ areFiltersVisible, setFiltersVisible ] = useState(false);
	const fileRef = useRef<HTMLInputElement>(null);

	const { orders, date, plan, shift } = useSelector((state: ReduxState) => ({
		orders: state.planning.orders.data?.results ?? [],
		date: state.planning.plan.data!.date,
		plan: state.planning.plan.data!.id,
		shift: state.planning.plan.data!.shift,
	}));

	const [ searchedOrders, onSearch, activeFiltersCount, isFiltersLoading ] = useSearchedEntity<IOrder, IOrderRequest>(
		UrlEnum.ORDER,
		orders,
		{ plan }
	);

	useEffect(() => {
		dispatch(actions.fetchOrders());
	}, []);

	const onUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			dispatch(actions.importOrders(e.target.files));
			fileRef.current!.value = ''
		}
	};

	const { t } = useTranslation();

	return (
		<Layout className={ stylesLayout.layoutPlanning }>
			{props.children}

			<div className="d-flex align-items-start justify-content-start overflow-hidden flex-column">
				<div className="panel px-3 pt-3 overflow-hidden d-flex flex-column maxh-100 z-index-draggable">
					{ (orders === undefined || (Array.isArray(orders) && orders.length === 0)) &&
						<div className="text-center">
							<i className="far fa-box-alt text-muted fa-9x mb-5" /><br />
							<h1 className="text-muted font-weight-normal mb-9">{t('pages.addOrders')}</h1>
						</div>
					}
					<div className="pb-3">
						<input
							type="file"
							id="upload-file"
							className="d-none"
							onChange={ onUploadFile }
							ref={fileRef}
						/>
						<label htmlFor="upload-file" className="btn btn-primary mr-3 mb-0">
							<i className="fa fa-upload mr-2" />
							{t('pages.uploadFile')}
						</label>
						<button
							className="btn btn-primary mr-3"
							onClick={() => window.alert('Not implemented')}
						>
							<i className="fa fa-upload mr-2" />
							{t('pages.uploadERP')}
						</button>
						<button
							className="btn btn-primary"
							onClick={() => setNewOrderModalOpen(true)}
						>
							{t('pages.addOrderMan')}
						</button>
					</div>

					{ orders && orders.length > 0 &&
						<div>
							<FiltersButton
								onClick={() => setFiltersVisible(!areFiltersVisible) }
								activeFiltersCount={ activeFiltersCount }
								isLoading={ isFiltersLoading }
								className="mb-2"
							/>

							<div className={`bg-gray-200 rounded pt-3 px-3 pb-1 mb-3 ${!areFiltersVisible ? 'd-none' : ''}`}>
								<OrderFilters
									onSearch={onSearch}
								/>
								<div className="mb-1">
									<OrderBadges
										orders={ orders }
										onSearch={ onSearch }
										hide={[
											OrderTypeBadgeEnum.TOTAL,
											OrderTypeBadgeEnum.UNASSIGNED,
											OrderTypeBadgeEnum.COMPLETED,
											OrderTypeBadgeEnum.CANCELLED
										]}
									/>
								</div>
							</div>

							<SearchFulltext
								placeholder={t('pages.delivPickAddr')}
								onSearch={ onSearch }
							/>
						</div>
					}

					<div className="flex-grow-1 overflow-auto">
						{ searchedOrders && searchedOrders.map((order, index) => (
							<OrderCard
								order={order}
								key={index}
								showEdit
								showDelete
								fetchAction={ actions.fetchOrders }
								planId={ plan }
								shift={ shift }
							/>
						))}
					</div>
				</div>
			</div>

			<Footer>
				<button
					className="btn btn-primary"
					onClick={ () => dispatch(actions.setStep(PlanningStepsEnum.VEHICLES)) }
					disabled={ !orders || orders.length === 0 }
				>
					{t('pages.next')}
				</button>
			</Footer>

			<NewOrderModal
				isOpen={ isNewOrdeModalOpen }
				onRequestClose={ () => setNewOrderModalOpen(false) }
				title={t('pages.newOrder')}
				initialValues={ initialValues }
				date={ date }
				fetchAction={ actions.fetchOrders }
				planId={ plan }
				shift={ shift }
				allowCostMatrixCalculation={false}
			/>

			<OrdersMap orders={orders} plan={plan}/>
		</Layout>
	);
};

export default Orders;