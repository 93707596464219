import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as planSelector from 'store/route/selectors';
import stylesLayout from 'pages/Layout.module.scss';
import VehicleRouteCard from 'components/VehicleRouteCard';
import SearchFulltext from 'components/SearchFulltext';
import TripAssignmentCard from 'components/cards/TripAssignmentCard';
import Layout from 'pages/Layout';
import { RoutesEnum } from 'model/enums/RoutesEnum';
import { ReduxState } from 'store/reducer';
import { IRoute } from 'model/interfaces/IRoute';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { sortAssignments } from 'model/helpers/assignment';
import EuFooter from 'components/EuFooter';
import DetailMap from 'pages/Homepage/DetailMap';
import OrderBadges from 'components/OrderTypeBadge/OrderBadges';
import { useRouteMatch } from 'react-router';
import { useApi } from 'model/hooks/useApi';
import FiltersButton from 'components/Buttons/FiltersButton';
import OrderFilters from 'components/OrderFilters';
import { useSearchedEntity } from 'model/hooks/useSearchedEntity';
import { IAssignmentRequest } from 'model/services/Api/interfaces/requests/IAssignmentRequest';
import { IAssignment } from 'model/interfaces/IAssignment';
import * as actions from 'store/route/actions';
import { OrderTypeBadgeEnum } from 'components/OrderTypeBadge/OrderTypeBadgeEnum';
import { useCancelToken } from 'model/hooks/useCancelToken';
import { RouteStatusEnum } from 'model/enums/RouteStatusEnum';
import {useTranslation} from "react-i18next";

type MatchParams = {
	id: string,
};

const Detail: React.FC = () => {
	const routeMatch = useRouteMatch<MatchParams>();

	const id = parseInt(routeMatch.params.id, 10);

	const { initialRoute } = useSelector((state: ReduxState) => ({
		initialRoute: planSelector.getRoute(state.routes, id)
	}));

	const [ route, setRoute ] = useState(initialRoute);
	const [ error, setError ] = useState();
	const [ areFiltersVisible, setFiltersVisible ] = useState(false);
	const { createNewToken, isCancel } = useCancelToken();

	const [ searchedAssignments, onSearch, activeFiltersCount, isFiltersLoading ] = useSearchedEntity<IAssignment, IAssignmentRequest>(
		UrlEnum.ASSIGNMENT,
		initialRoute?.assignments ?? [],
		{ route: id }
	);

	const api = useApi();

	const fetchRoute = async () => {
		try {
			const response = await api.getRequest<IRoute>(`${ UrlEnum.ROUTE }${ id }/`, {
				cancelToken: createNewToken(),
			});

			setRoute(response.data);
			setError(undefined);

		} catch (err) {
			if (isCancel(err)) return;
			console.error(err);

			setRoute(undefined);
			setError(err);
		}
	};

	useEffect(() => {
		fetchRoute();

	}, []);

	// useInterval(fetchRoute, POLLING_RATE);
	const { t } = useTranslation();

	if (typeof id === 'undefined') {
		throw new Error('No ID for detail provided');
	}

	return (
		<Layout className={ stylesLayout.layoutDashboard }>
			<aside className={ `${stylesLayout.aside} over-map` }>
				<div className="panel panel--flex">
					<div className="panel-header">
						<div className="row mb-2">
							<div className="col">
								<Link
									to={ RoutesEnum.HOMEPAGE }
									className="btn btn-sm"
								>
									<i className="fa fa-angle-left mr-2"/>
									<span>{t('pages.homepage.back')}</span>
								</Link>
							</div>
							{route && ![RouteStatusEnum.COMPLETED, RouteStatusEnum.CANCELLED].includes(route.status) &&
							<div className="col text-right">
								<Link
									to={RoutesEnum.DETAIL_REROUTING.replace(':id', id.toString())}
									className="btn btn-sm btn-primary"
								>
									{t('pages.homepage.reroute')}
								</Link>
							</div>
							}
						</div>
					</div>
					<div className="panel-body">
						{ route &&
						<>
							<VehicleRouteCard route={ route }/>

							<FiltersButton
								onClick={() => setFiltersVisible(!areFiltersVisible) }
								activeFiltersCount={ activeFiltersCount }
								isLoading={ isFiltersLoading }
							/>

							<div className={`bg-gray-200 rounded pt-3 px-3 mb-3 ${!areFiltersVisible ? 'd-none' : ''}`}>
								<OrderFilters
									onSearch={onSearch}
								/>
							</div>

							<div className="mt-2 mb-1">
								<OrderBadges
									orders={route.assignments.map(assignment => assignment.order)}
									onSearch={ onSearch }
									showDone
									hide={ [OrderTypeBadgeEnum.UNASSIGNED] }
									statusName="order_status"
								/>
							</div>

							<SearchFulltext
								placeholder={t('pages.homepage.deliveriesPickupsAddresses')}
								onSearch={ onSearch }
							/>

							{ searchedAssignments.sort(sortAssignments).map((item, itemIndex) => (
								<React.Fragment key={ itemIndex }>
									<TripAssignmentCard
										assignment={ item }
										showEdit
										showDelete
										fetchAction={ actions.fetchRoutes }
									/>
								</React.Fragment>
							)) }
						</>
						}
					</div>
				</div>
			</aside>

			<footer className={ `${stylesLayout.footer} over-map` }>
				<EuFooter/>
			</footer>

			<DetailMap route={route} />
		</Layout>
	);
}

export default Detail;