import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';

import * as actions from 'pages/Drivers/store/actions';
import { apiFactory } from 'model/services/Api/Api';
import { IDriversResponse } from 'model/services/Api/interfaces/responses/IDriversResponse';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { ActionType } from 'deox';
import { UserTypeEnum } from 'model/enums/UserTypeEnum';
import { IDriver } from 'model/interfaces/IDriver';
import { AddressTypeEnum } from 'model/enums/AddressTypeEnum';
import { AxiosResponse } from 'axios';
import i18n from '../../../i18n';

function * fetchDrivers() {
	const api = apiFactory(yield select());

	try {
		const response: IDriversResponse = yield call(api.getRequest, `${UrlEnum.USER}?user_type=${UserTypeEnum.DRIVER}`);
		yield put(actions.fetchDriversSuccess(response.data));
	} catch (err) {
		yield put(actions.fetchDriversFail(err));
	}
}

function * createDriver(action: ActionType<typeof actions.createDriver>) {
	const api = apiFactory(yield select());

	try {
		// Firstly create a new driver without address
		const postPayload = preparePayloadForCreate(action.payload);
		const response: AxiosResponse<IDriver> = yield call(api.postRequest, UrlEnum.USER, postPayload);

		if (!response.data.id) {
			throw new Error(i18n.t('pages.drivers.newFailed'));
		}

		// Then update with the address
		const patchPayload = preparePayloadForUpdate(action.payload, true);
		yield call(api.patchRequest, `${UrlEnum.USER}${response.data.id}/`, patchPayload);

		yield put(actions.fetchDrivers());
		yield put(actions.formDriverSuccess({
			message: 'pages.drivers.driverCreated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formDriverFail(err));
	}
}

function * updateDriver(action: ActionType<typeof actions.updateDriver>) {
	const api = apiFactory(yield select());

	const payload = preparePayloadForUpdate(action.payload);

	try {
		yield call(api.patchRequest, `${UrlEnum.USER}${action.payload.id}/`, payload);
		yield put(actions.fetchDrivers());
		yield put(actions.formDriverSuccess({
			message: 'pages.drivers.driverUpdated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formDriverFail(err));
	}
}

function preparePayloadForCreate(_payload: IDriver) {
	const payload = cloneDeep(_payload) as any;

	delete payload.id;
	delete payload.demonstrator;
	delete payload.address;

	return payload;
}

function preparePayloadForUpdate(_payload: IDriver, skipBasicData = false) {
	const payload = cloneDeep(_payload) as any;

	delete payload.id;
	delete payload.demonstrator;
	delete payload.password;

	if (skipBasicData) {
		delete payload.telephone;
		delete payload.email;
		delete payload.first_name;
		delete payload.last_name;
		delete payload.user_type;
		delete payload.username;
	}

	delete payload.address.id;
	delete payload.address.verification_type;
	delete payload.address.owner;
	delete payload.address.object_id;
	delete payload.address.content_type;
	delete payload.address.restrictions;

	if (!payload.address.address_type) {
		payload.address.address_type = AddressTypeEnum.HOME;
	}

	if (payload.address.location) {
		if (!payload.address.location.coordinates[0] || !payload.address.location.coordinates[1]) {
			delete payload.address.location;
		} else {
			payload.address.location.type = 'Point';
		}
	}

	return payload;
}

function * deleteDriver(action: ActionType<typeof actions.deleteDriver>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.deleteRequest, `${UrlEnum.USER}${action.payload.id}/`);
		yield put(actions.fetchDrivers());
		yield put(actions.deleteDriverSuccess({
			message: 'pages.drivers.driverRemoved',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.deleteDriverFail(err));
	}
}

function * watchFetchDrivers() {
	yield takeLatest(actions.fetchDrivers, fetchDrivers);
}

function * watchDeleteDriver() {
	yield takeEvery(actions.deleteDriver, deleteDriver);
}

function * watchCreateDriver() {
	yield takeEvery(actions.createDriver, createDriver);
}

function * watchUpdateDriver() {
	yield takeEvery(actions.updateDriver, updateDriver);
}

const driversSagas = [
	watchFetchDrivers(),
	watchDeleteDriver(),
	watchCreateDriver(),
	watchUpdateDriver(),
];

export default driversSagas;
