import { IAddress } from 'model/interfaces/IAddress';
import { ISelectOptions } from 'model/interfaces/ISelectOptions';
import { AddressTypeEnum } from 'model/enums/AddressTypeEnum';
import { VerificationTypeEnum } from 'model/enums/VerificationTypeEnum';
import i18n from '../../i18n';

export const addressToString = (address: IAddress): string =>
	`${address.address}, ${address.postal_code} - ${address.city} `;

export const addressTypeOptions: ISelectOptions[] = [
	{
		value: AddressTypeEnum.DEPOT,
		label: (i18n.t('helpers.depot'))
	},
	{
		value: AddressTypeEnum.SERVICE_ADDRESS,
		label: (i18n.t('helpers.serviceAddress'))
	},
	{
		value: AddressTypeEnum.OFFICE,
		label: (i18n.t('helpers.office'))
	},
	{
		value: AddressTypeEnum.AGENCY,
		label: (i18n.t('helpers.agency'))
	},
	{
		value: AddressTypeEnum.HOME,
		label: (i18n.t('helpers.home'))
	},
];

export const verificationTypeOptions: ISelectOptions[] = [
	{
		value: VerificationTypeEnum.UNVERIFIED,
		label: (i18n.t('helpers.unverified'))
	},
	{
		value: VerificationTypeEnum.GEOCODED,
		label: (i18n.t('helpers.geocoded'))
	},
	{
		value: VerificationTypeEnum.USER_VERIFIED,
		label: (i18n.t('helpers.userVerified'))
	},
	{
		value: VerificationTypeEnum.SYNCED_FROM_ERP,
		label: (i18n.t('helpers.syncedFromErp'))
	},
];