import React, { useEffect } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

import * as vehicleHelpers from 'model/helpers/vehicle';
import TextInput from 'components/Form/TextInput';
import SelectInput from 'components/Form/SelectInput';
import NumberInput from 'components/Form/NumberInput';
import TextAreaInput from 'components/Form/TextAreaInput';
import { IVehicle } from 'model/interfaces/IVehicle';
import { useDispatch } from 'react-redux';
import * as vehicleActions from 'pages/Vehicles/store/actions';
import { ReduxState } from 'store/reducer';
import AbilityAsyncMultiSelect from 'components/Form/AbilityAsyncMultiSelect';
import FuelAsnycSelect from 'components/Form/FuelAsyncSelect';
import EngineAsnycSelect from 'components/Form/EngineAsyncSelect';
import VehicleDescriptionAsnycSelect from 'components/Form/VehicleDescriptionAsyncSelect';
import {ContractTypePairs, ContractTypes} from 'model/enums/ContractTypeEnum';
import validationSchema from './validationSchema';
import VehicleRestrictionAsyncMultiSelect from 'components/Form/VehicleRestrictionAsyncMultiSelect';
import {useTranslation} from "react-i18next";
import {returnEnumPairsOptions} from "../../../model/helpers/enums";

type BaseProps = {
	onSuccess: () => void,
	initialValues: IVehicle,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const VehicleForm: React.FC<Props> = props => {

	const dispatch = useDispatch();

	const createUpdateSuccess = useSelector<ReduxState>(state => state.vehicles.formSuccess);

	const onSubmit = async (values: IVehicle) => {
		if (values.id) {
			dispatch(vehicleActions.updateVehicle(values));
		} else {
			dispatch(vehicleActions.createVehicle(values));
		}
	};

	useEffect(() => {
		if (createUpdateSuccess) {
			dispatch(vehicleActions.formVehicleRestart());
			props.onSuccess();
		}
	}, [ createUpdateSuccess ]);

	const { t } = useTranslation();

	return (
		<Formik<IVehicle>
			// validationSchema={ constants.VEHICLE_FORM_VALIDATION_SCHEMA }
			initialValues={ props.initialValues }
			validationSchema={ validationSchema }
			onSubmit={ onSubmit }
		>
			{ formikBag => (
				<Form>
					<Field name="id" type="hidden" />
					<div className="form-row">
						<div className="col">
							<Field
								name="brand"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.brandVehicle')} { ...props } /> }
							</Field>
						</div>
						<div className="col">
							<Field
								name="plate"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.plateVehicle')} { ...props }/> }
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="engine"
							>
								{
									(props: FieldProps) =>
										<EngineAsnycSelect label={t('pages.engineVehicle')} {...props} />
								}
							</Field>
						</div>
						<div className="col">
							<Field
								name="fuel"
							>
								{
									(props: FieldProps) =>
										<FuelAsnycSelect label={t('pages.fuelVehicle')} {...props} />
								}
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="description"
							>
								{
									(props: FieldProps) =>
										<VehicleDescriptionAsnycSelect label={t('pages.descriptionVehicle')} {...props} />
								}
							</Field>
						</div>
						<div className="col">
							<Field
								name="license_type"
							>
								{
									(props: FieldProps) =>
										<SelectInput
											label={t('pages.licenceTypeVehicle')}
											options={ vehicleHelpers.licenceTypeSelectOptions } { ...props }
										/>
								}
							</Field>
						</div>
						<div className="col">
							<Field
								name="contract_type"
							>
								{
									(props: FieldProps) =>
										<SelectInput
											label={t('pages.contractTypeVehicle')}
											options={ returnEnumPairsOptions(ContractTypes, ContractTypePairs) }
											{ ...props }
										/>
								}
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="capacity_weight"
							>
								{
									(props: FieldProps) => <NumberInput label={t('pages.capacityWeightVehicle')} { ...props } />
								}
							</Field>
						</div>
						<div className="col">
							<Field
								name="capacity_volume"
							>
								{
									(props: FieldProps) => <NumberInput label={<>{t('pages.capacityVolumeVehicle')} (m<sup>3</sup>)</>} { ...props } />
								}
							</Field>
						</div>
						<div className="col">
							<Field
								name="capacity_pieces"
							>
								{
									(props: FieldProps) => <NumberInput label={t('pages.capacityPiecesVehicle')} { ...props } />
								}
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="comments"
							>
								{
									(props: FieldProps) => <TextAreaInput label={t('pages.commentsVehicle')} { ...props } />
								}
							</Field>
						</div>
					</div>
					<Field name="abilities">
						{
							(props: FieldProps) => <AbilityAsyncMultiSelect label={t('pages.abilitiesVehicle')} { ...props } />
						}
					</Field>
					<Field name="restrictions">
						{
							(props: FieldProps) => <VehicleRestrictionAsyncMultiSelect label={t('pages.restrictionsVehicle')} { ...props } />
						}
					</Field>
					{/*<div className="form-row">*/}
						{/*<div className="col">*/}
						{/*	<Field*/}
						{/*		name="availability"*/}
						{/*	>*/}
						{/*		{*/}
						{/*			(props: FieldProps) =>*/}
						{/*				<SelectInput*/}
						{/*					label="Availability"*/}
						{/*					options={ vehicleHelpers.availabilitySelectOptions } { ...props }*/}
						{/*				/>*/}
						{/*		}*/}
						{/*	</Field>*/}
						{/*</div>*/}
						{/*<div className="col">*/}
						{/*	<Field*/}
						{/*		name="deleted"*/}
						{/*	>*/}
						{/*		{*/}
						{/*			(props: FieldProps) => <SelectInput*/}
						{/*				label="Deleted"*/}
						{/*				options={ vehicleHelpers.deletedSelectOptions } { ...props }*/}
						{/*			/>*/}
						{/*		}*/}
						{/*	</Field>*/}
						{/*</div>*/}
						{/*<div className="col">*/}
						{/*	<Field*/}
						{/*		name="demonstrator"*/}
						{/*	>*/}
						{/*		{*/}
						{/*			(props: FieldProps) => <DemonstratorAsyncSelect label="Demonstrator" { ...props } />*/}
						{/*		}*/}
						{/*	</Field>*/}
						{/*</div>*/}
					{/*</div>*/}
					{/*<div className="form-row">*/}
					{/*	<div className="col">*/}
					{/*		<FieldArray*/}
					{/*			name="abilities"*/}
					{/*		>*/}
					{/*			{(arrayHelpers) => (*/}
					{/*				<>*/}
					{/*					<div className="row">*/}
					{/*						<div className="col">*/}
					{/*							<label> Abilities </label>*/}
					{/*						</div>*/}
					{/*					</div>*/}
					{/*					<div className="row">*/}
					{/*						<div className="col">*/}
					{/*							{formikBag.values.abilities && formikBag.values.abilities.length > 0 && formikBag.values.abilities.map((ability, abilityIndex) => (*/}
					{/*								<div className="row" key={ abilityIndex }>*/}
					{/*									<div className="col">*/}
					{/*										<Field*/}
					{/*											name={ `abilities[${abilityIndex}]` }*/}
					{/*										>*/}
					{/*											{*/}
					{/*												(props: FieldProps) => <NumberInput { ...props } />*/}
					{/*											}*/}
					{/*										</Field>*/}
					{/*									</div>*/}
					{/*									<div className="col">*/}
					{/*										<button*/}
					{/*											onClick={ () => arrayHelpers.remove(abilityIndex)}*/}
					{/*										>*/}
					{/*											Remove*/}
					{/*										</button>*/}
					{/*									</div>*/}
					{/*								</div>*/}
					{/*							))}*/}
					{/*						</div>*/}
					{/*					</div>*/}
					{/*					<div className="row">*/}
					{/*						<div className="col">*/}
					{/*							<button*/}
					{/*								type="button"*/}
					{/*								onClick={ () => arrayHelpers.push(undefined) }*/}
					{/*							>*/}
					{/*								Add*/}
					{/*							</button>*/}
					{/*						</div>*/}
					{/*					</div>*/}
					{/*				</>)}*/}
					{/*		</FieldArray>*/}
					{/*	</div>*/}
					{/*</div>*/}
					<div className="text-right">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={ ! formikBag.isValid || formikBag.isSubmitting }
						>
							{t('pages.saveVehicle')}
						</button>
					</div>
				</Form>
			) }
		</Formik>
	);
};

export default VehicleForm;
