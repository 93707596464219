import React from 'react';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { ActionType } from 'deox';

import * as actions from './actions';
import * as planActions from 'store/plan/actions'
import { apiFactory } from 'model/services/Api/Api';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IPlan } from 'model/interfaces/IPlan';
import { ReduxState } from 'store/reducer';
import { isoDayFromUTCDatetime } from 'model/helpers/datetime';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import { PlanStatusEnum } from 'model/enums/PlanStatusEnum';
import { RouteStatusEnum } from 'model/enums/RouteStatusEnum';
import { IPlanningRoute, IRoute } from 'model/interfaces/IRoute';
import { IOrdersResponse } from 'model/services/Api/interfaces/responses/IOrdersResponse';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';
import { IRoutePartialAssignmentsState } from 'pages/Planning/AssignedOrders';
import { ModificationStatusEnum } from 'model/enums/ModificationStatusEnum';
import { IOrderImport } from 'model/services/Api/interfaces/responses/IOrderImport';
import * as executionDateActions from 'store/executionDate/actions';
import * as shiftActions from 'store/shift/actions';
import * as selectors from 'pages/Planning/store/selectors';
import { IAutoAssignmentCreateResponse } from 'model/services/Api/interfaces/responses/IAutoAssignmentCreateResponse';
import i18n from '../../../i18n';

function * createPlan(action: ActionType<typeof actions.createPlan>) {
	const state: ReduxState = yield select();
	const api = apiFactory(state);
	const { date, shiftId } = action.payload;

	try {
		// If plan is already defined in the store, simply continue to next step...
		if (state.planning.plan?.data?.id) {
			yield put(actions.createPlanSuccess(state.planning.plan.data));
			yield put(actions.setStep(PlanningStepsEnum.ORDERS));
			return;
		}

		// Get all plans and find out if desired plan already exists
		const getPlanResponse: AxiosResponse<IPaginatedData<IPlan>> = yield call(api.getRequest, UrlEnum.PLAN, {
			params: {
				shift: shiftId,
				date: isoDayFromUTCDatetime(date),
				status: PlanStatusEnum.UNDER_PLANNING,
			}
		});
		let plan = isPlanAlreadyCreated(getPlanResponse.data.results, state);

		if (plan) {
			yield put(actions.createPlanSuccess(plan));
			yield put(actions.setStep(PlanningStepsEnum.ORDERS));

		} else {
			const postPlanResponse: AxiosResponse<IPlan> = yield call(api.postRequest, UrlEnum.PLAN, {
				status: PlanStatusEnum.UNDER_PLANNING,
				demonstrator: state.loggedInUser.data!.demonstrator,
				shift: shiftId,
				date: isoDayFromUTCDatetime(date)
			});
			yield put(actions.createPlanSuccess(postPlanResponse.data));
			yield put(actions.setStep(PlanningStepsEnum.ORDERS));
		}

	} catch (err) {
		yield put(actions.createPlanFail(err));
	}
}


function * cancelPlan() {
	const state: ReduxState = yield select();
	const api = apiFactory(state);
	const planId = state.planning.plan?.data?.id;

	if (planId) {
		try {
			yield call(api.deleteRequest, `${UrlEnum.PLAN}${planId}/`);

		} catch (err) {
			yield put(actions.cancelPlanFail(err));
		}

	} else {
		console.error('Plan was not initialized');
	}

	yield put(actions.setInitialState());
}

function * createRoutes(action: ActionType<typeof actions.createRoutes>) {
	const state: ReduxState = yield select();
	const planId = state.planning.plan?.data?.id;
	const api = apiFactory(state);

	try {
		yield all(state.planning.routes!
			.filter(planningRoute => planningRoute.data!.enabled)
			.map(planningRoute => {
				return createOrUpdateRoute(state.planning.routes!.indexOf(planningRoute));
			})
		);

		if (planId && state.planning.step !== PlanningStepsEnum.SOLUTION) {
			try {
				yield put(actions.loadingStart());
				const response: AxiosResponse<{ success: boolean }> = yield call(api.postRequest, `/${UrlEnum.PLAN}${planId}/cost_matrix_create/`, {})

				if (response.data.success) {
					toast.success(i18n.t('pages.distanceCalc'))
				} else {
					toast.error(i18n.t('pages.distanceNotCalc'))
				}

			} catch (err) {
				toast.error(i18n.t('pages.distanceNotCalculated'))
			}
			yield put(actions.loadingEnd());
		}


		const nextStep = action.payload;
		if (nextStep) {
			yield put(actions.setStep(nextStep));
		}

	} catch (err) {
		// Do nothing ... error already rendered in createOrUpdateRoute
	}
}

function * createRoutesAndConfirmPlan(action: ActionType<typeof actions.createRoutesAndConfirmPlan>) {
	yield call(createRoutes, actions.createRoutes());
	yield call(confirmPlan, actions.confirmPlan(action.payload));

	// Switch to newly created plan
	const state: ReduxState = yield select();
	yield put(executionDateActions.set(state.planning.plan.data!.date, false));
	yield put(shiftActions.set(state.planning.plan.data!.shift));
}

function * createOrUpdateRoute(index: number) {
	const state: ReduxState = yield select();
	const api = apiFactory(state);
	const route = state.planning.routes![index].data!;
	const payload: any = {};
	const isNew = !route.id;

	if (isNew) {
		payload.plan = state.planning.plan!.data!.id;
		payload.demonstrator = state.loggedInUser.data!.demonstrator;
		payload.assignments = [];
		payload.status = RouteStatusEnum.DRAFT;
		payload.vehicle = route.vehicle!.id;
	}

	if (route.semitrailer) {
		payload.semitrailer = route.semitrailer.id;
	}
	if (route.driver) {
		payload.driver = route.driver.id;
	}
	if (route.starting_point) {
		payload.starting_point = route.starting_point.id;
	}
	if (route.ending_point) {
		payload.ending_point = route.ending_point.id;
	}
	if (route.shift) {
		payload.shift = route.shift.id;
	}
	if (route.time_start) {
		payload.time_start = `${state.planning.plan!.data?.date}T${route.time_start}Z`;
	}
	if (route.time_end) {
		payload.time_end = `${state.planning.plan!.data?.date}T${route.time_end}Z`;
	}

	try {
		yield put(actions.createRoute(index));
		const response: AxiosResponse<IRoute> = yield call(
			isNew ? api.postRequest : api.patchRequest,
			isNew ? UrlEnum.ROUTE : `${UrlEnum.ROUTE}${route.id}/`,
			payload
		);
		yield put(actions.createRouteSuccess(response.data, index));

	} catch (err) {
		yield put(actions.createRouteFail(err, index));
	}
}

function * recalculate(action: ActionType<typeof actions.recalculate>) {
	yield commonRecalculate(action.payload, RouteStatusEnum.UNDER_MODIFICATION);
}

function * cancelRecalculate(action: ActionType<typeof actions.cancelRecalculate>) {
	yield commonRecalculate(action.payload.routeAssignments, RouteStatusEnum.DRAFT);
	// George doesn't want to redirect back
	// yield put(actions.setStep(action.payload.destination));
}

function * saveRecalculate(action: ActionType<typeof actions.saveRecalculate>) {
	yield commonRecalculate(action.payload.routeAssignments, RouteStatusEnum.UNDER_PLANNING);
	// George doesn't want to redirect back
	// yield put(actions.setStep(action.payload.destination));
}

function * confirmPlan(action: ActionType<typeof actions.confirmPlan>) {
	yield commonRecalculate(action.payload, RouteStatusEnum.SCHEDULED);
	yield put(actions.setStep(PlanningStepsEnum.FINISHED));
}

function * commonRecalculate(routeAssignments: IRoutePartialAssignmentsState[], status: RouteStatusEnum) {
	const state: ReduxState = yield select();
	const api = apiFactory(state);

	for (const routeAssignment of routeAssignments) {
		// Skip first column, ie. unassigned orders
		if (!routeAssignment.route) continue;

		const index = state.planning.routes.findIndex((routeItem: IReduxFetchedState<IPlanningRoute>) =>
			routeItem.data?.id === routeAssignment.route?.id
		);

		// We do this basically only to switch flag loading to true at this particular route
		yield put(actions.createRoute(index));

		const payload: any = { status };

		if (status === RouteStatusEnum.UNDER_MODIFICATION) {
			payload.assignments = routeAssignment.assignments.map(assignment => ({ order: assignment.order!.id }));
		}

		try {
			const urlSuffix = status === RouteStatusEnum.UNDER_MODIFICATION ? 'assignments/' : '';

			const response: AxiosResponse<IRoute> = yield call(
				api.patchRequest,
				`${UrlEnum.ROUTE}${routeAssignment.route.id}/${urlSuffix}`,
				payload
			);
			yield put(actions.createRouteSuccess(response.data, index));

		} catch (err) {
			yield put(actions.createRouteFail(err, index));
		}
	}
}

function * setModificationStatus(action: ActionType<typeof actions.setModificationStatus>) {
	const state = yield select();
	const api = apiFactory(state);

	const index = state.planning.routes.findIndex((routeItem: IReduxFetchedState<IPlanningRoute>) =>
		routeItem.data?.id === action.payload
	);

	// We do this basically only to switch flag loading to true at this particular route
	yield put(actions.createRoute(index));

	const route: IPlanningRoute = state.planning.routes[index].data;

	const payload = {
		modification_status: route.modification_status
	}

	try {
		const response: AxiosResponse<IRoute> = yield call(
			api.patchRequest,
			`${UrlEnum.ROUTE}${route.id}/`,
			payload
		);
		yield put(actions.createRouteSuccess(response.data, index));

		if (route.modification_status === ModificationStatusEnum.MANUAL) {
			toast.warning(i18n.t('pages.vehicleWillNotCalcAlg', { brand: route.vehicle?.brand, plate: route.vehicle?.plate }));
		} else {
			toast.success(i18n.t('pages.vehicleWillCalcAlg', { brand: route.vehicle?.brand, plate: route.vehicle?.plate }));
		}

	} catch (err) {
		yield put(actions.createRouteFail(err, index));
	}
}

function * fetchPlanRoutes() {
	const state = yield select();
	const api = apiFactory(state);
	const routeAssignments = selectors.getRouteAssignments(state);

	for (const routeAssignment of routeAssignments) {
		// Skip first column, ie. unassigned orders
		if (!routeAssignment.route) continue;

		const index = state.planning.routes.findIndex((routeItem: IReduxFetchedState<IPlanningRoute>) =>
			routeItem.data?.id === routeAssignment.route?.id
		);

		// We do this basically only to switch flag loading to true at this particular route
		yield put(actions.createRoute(index));

		try {
			const response: AxiosResponse<IRoute> = yield call(
				api.getRequest, `${UrlEnum.ROUTE}${routeAssignment.route.id}/`
			);
			yield put(actions.createRouteSuccess(response.data, index));

		} catch (err) {
			yield put(actions.createRouteFail(err, index));
		}
	}
}

function * fetchOrders() {
	const store: ReduxState = yield select();
	const api = apiFactory(store);
	const plan = store.planning.plan?.data;

	if (!plan) {
		console.error("Couldn't fetch orders. No plan initialized.");
		return;
	}

	try {
		const response: IOrdersResponse = yield call(api.getRequest, UrlEnum.ORDER, {
			params: {
				plan_date: isoDayFromUTCDatetime(plan.date),
				plan_shift: plan.shift.id,
			},
		});
		yield put(actions.fetchOrdersSuccess(response.data));
	} catch (err) {
		yield put(actions.fetchOrdersFail(err));
	}
}

function * importOrders(action: ActionType<typeof actions.importOrders>)  {
	if (!action.payload || !action.payload[0]) {
		console.error("No file uploaded");
		return;
	}

	const store: ReduxState = yield select();
	const api = apiFactory(store);
	const plan = store.planning.plan!.data!.id;

	const formData = new FormData();
	formData.append('plan', plan.toString());
	formData.append('file', action.payload[0]);

	try {
		const response: AxiosResponse<string> = yield call(api.postRequest, `${UrlEnum.PLAN}${plan}/import_orders/`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

		yield put(actions.fetchOrders());

		const importStats: IOrderImport = JSON.parse(response.data);
		toast.success(
			<div>
				{i18n.t('pages.planning.importFinished')}<br />
				<br />
				{i18n.t('pages.planning.new')} {importStats.totals.new}<br />
				{i18n.t('pages.planning.update')} {importStats.totals.update}<br />
				{i18n.t('pages.planning.delete')} {importStats.totals.delete}<br />
				{i18n.t('pages.planning.skip')} {importStats.totals.skip}<br />
				{i18n.t('pages.planning.error')} {importStats.totals.error}<br />
				{i18n.t('pages.planning.invalid')} {importStats.totals.invalid}<br />
			</div>
		);

	} catch (err) {
		yield put(actions.importOrdersFail(err));
	}
}

function * calculate() {
	const state: ReduxState = yield select();
	const api = apiFactory(state);
	const planId = state.planning.plan?.data?.id;

	if (planId) {
		try {
			const response: AxiosResponse<IAutoAssignmentCreateResponse> = yield call(
				api.postRequest, `${UrlEnum.PLAN}${planId}/auto_assignment_create/`, {}
			);

			yield put(actions.calculateSuccess());

			if (response.data.success) {
				yield fetchPlanRoutes();
				yield put(actions.setStep(PlanningStepsEnum.SOLUTION));

			} else {
				toast.error(i18n.t('pages.planNotSolved'));
			}

		} catch (err) {
			yield put(actions.calculateFail(err));
		}

	} else {
		console.error('Plan was not initialized');
	}
}

function * deleteRoute(action: ActionType<typeof actions.deleteRoute>) {
	const state: ReduxState = yield select();
	const api = apiFactory(state);
	const { meta: index } = action;

	const routeId = state.planning.routes[index].data?.id

	try {
		yield call(api.deleteRequest, `${UrlEnum.ROUTE}${routeId}/`);
		yield put (actions.deleteRouteSuccess(index))
		toast.success(i18n.t('pages.vehicleDisabled'));

	} catch (err) {
		yield put(actions.deleteRouteFail(err, index));
	}
}

function * watchCreatePlan() {
	yield takeLatest(actions.createPlan, createPlan);
}

function * watchCancelPlan() {
	yield takeLatest(actions.cancelPlan, cancelPlan);
}

function * watchCreateRoutes() {
	yield takeLatest(actions.createRoutes, createRoutes);
}

function * watchFetchOrders() {
	yield takeLatest(actions.fetchOrders, fetchOrders);
}

function * watchRecalulcate() {
	yield takeLatest(actions.recalculate, recalculate);
}

function * watchCancelRecalulcate() {
	yield takeLatest(actions.cancelRecalculate, cancelRecalculate);
}

function * watchSaveRecalulcate() {
	yield takeLatest(actions.saveRecalculate, saveRecalculate);
}

function * watchConfirmPlan() {
	yield takeLatest(actions.confirmPlan, confirmPlan);
}

function * watchSetModificationStatus() {
	yield takeLatest(actions.setModificationStatus, setModificationStatus);
}

function * watchImportOrders() {
	yield takeLatest(actions.importOrders, importOrders);
}

function * watchCreateRoutesAndConfirmPlan() {
	yield takeLatest(actions.createRoutesAndConfirmPlan, createRoutesAndConfirmPlan);
}

function * watchCalculate() {
	yield takeLatest(actions.calculate, calculate);
}

function * watchDeleteRoute() {
	yield takeLatest(actions.deleteRoute, deleteRoute);
}

const planningSagas = [
	watchCreatePlan(),
	watchCancelPlan(),
	watchCreateRoutes(),
	watchFetchOrders(),
	watchRecalulcate(),
	watchCancelRecalulcate(),
	watchSaveRecalulcate(),
	watchConfirmPlan(),
	watchSetModificationStatus(),
	watchImportOrders(),
	watchCreateRoutesAndConfirmPlan(),
	watchCalculate(),
	watchDeleteRoute(),
];

const isPlanAlreadyCreated = (plans: IPlan[], state: ReduxState): IPlan|undefined => {
	for (const plan of plans) {
		if (
			plan.shift.id === state.shift.id &&
			plan.date === isoDayFromUTCDatetime(state.executionDate) &&
			plan.status === PlanStatusEnum.UNDER_PLANNING &&
			plan.demonstrator === state.loggedInUser.data!.demonstrator
		) {
			return plan;
		}
	}
	return undefined;
};

export default planningSagas;
