import React from 'react';
import styles from './index.module.scss';
import {OrderTypeBadgeEnum} from "./OrderTypeBadgeEnum";

type Props = {
	value: string,
	label: string,
	type: OrderTypeBadgeEnum,
	unselected: boolean,
	onClick(type: OrderTypeBadgeEnum): void,
}

const OrderTypeBadge: React.FC<Props> = (props) => (
	<button
		className={`${styles[`badge--${props.type}`]} ${props.unselected ? styles.unselected : ''} `}
		onClick={ () => props.onClick(props.type) }
	>
		<span className={styles.count}>
			{props.value}
		</span>
		<span>
			{props.label}
		</span>
	</button>

);

export default OrderTypeBadge;