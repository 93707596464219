import { IAddress } from 'model/interfaces/IAddress';
import { IRestriction } from 'model/interfaces/IRestriction';
import { OrderTypeEnum } from 'model/enums/OrderTypeEnum';
import React  from 'react';
import styles from 'components/cards/index.module.scss';
import { addressToString } from 'model/helpers/address';
import { IOrder } from 'model/interfaces/IOrder';
import { timeWithoutSeconds, localTimeFromUTCDatetime } from 'model/helpers/datetime';

type OrderInfoProps = {
	address: IAddress,
	restriction?: IRestriction,
	erpId: string,
	requisitionType?: OrderTypeEnum,
	order: IOrder,
	time?: string,
};

const OrderHeader: React.FC<OrderInfoProps> = (props) => {
	const isRequisition = typeof props.requisitionType !== 'undefined';

	const restrictions = [];
	if (props.restriction?.time_restriction) {
		for (const index in props.restriction?.time_restriction.time_windows) {
			if (!props.restriction?.time_restriction.time_windows.hasOwnProperty(index)) continue;
			const timeWindow = props.restriction?.time_restriction.time_windows[index];
			restrictions.push(
				<React.Fragment key={`restriction-${props.order.id}-${index}`}>{timeWithoutSeconds(timeWindow.start_time)} - {timeWithoutSeconds(timeWindow.end_time)}</React.Fragment>);
		}
	}

	return (
		<>
			<>
				{!isRequisition && props.time &&
				<strong className={`mr-2 text-large ${styles.orderHeaderTime}`}>
					{localTimeFromUTCDatetime(props.time)}
				</strong>
				}
				{props.requisitionType !== OrderTypeEnum.DELIVERY &&
				<>
					<span className={styles.badgeLeft}>
						{props.order.status.quantity} {props.order.status.quantity > 1 ? 'pcs' : 'pc'}
					</span>
					<span className={props.order.tags.length > 0 ? styles.badge : styles.badgeRight}>
						{props.order.package.name}
					</span>
					{props.order.tags.map((tag, index) => (
						<span
							className={props.order.tags.length === index + 1 ? styles.badgeRight : styles.badge}
							key={index}
						>
						{tag.name}
					</span>
					))}
				</>
				}
			</>
			<div className={`position-relative ${isRequisition ? 'mt-2' : ''}`}>
				{isRequisition &&
				<>
					<div className={styles[`requisition${props.requisitionType}`]} />
					{props.time &&
					<strong className={`d-block py-1 mr-2 text-large ${styles.orderHeaderTime}`}>
						{localTimeFromUTCDatetime(props.time)}
					</strong>
					}
				</>
				}
				{props.address.owner_name} [{props.erpId}]<br />
				{addressToString(props.address)}<br />
				{restrictions.map((restriction, index) =>
					<React.Fragment key={index}>
						{restriction}{index !== restrictions.length - 1 && <>, </>}
					</React.Fragment>
				)}
			</div>
		</>
	);
};

export default OrderHeader;