import React from 'react';
import styles from './index.module.scss';

type BaseProps = {
	visible: boolean
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const Loader: React.FC<Props> = (props) => {
	return (
		<div className={`${styles.container} ${!props.visible ? styles.hidden : ''}`}>
			<div className={styles.overlay} />
			<svg
				width="83px"
				height="42px"
				viewBox="0 0 83 42"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				className={styles.truck}
			>
				<g id="truck" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(0.000000, 0.000000)">
					<path d="M80.75,28.5 L79.5,28.5 L79.5,20.0546875 C79.5,19.0625 79.1015625,18.109375 78.3984375,17.40625 L70.59375,9.6015625 C69.890625,8.8984375 68.9375,8.5 67.9453125,8.5 L64.5,8.5 L64.5,4.75 C64.5,2.6796875 62.8203125,1 60.75,1 L35.75,1 C33.6796875,1 32,2.6796875 32,4.75 L32,29.75 C32,31.8203125 33.6796875,33.5 35.75,33.5 L37,33.5 L52,33.5 L62,33.5 L77,33.5 L80.75,33.5 C81.4375,33.5 82,32.9375 82,32.25 L82,29.75 C82,29.0625 81.4375,28.5 80.75,28.5 Z M75.75,21 L64.5,21 L64.5,12.25 L67.9453125,12.25 L75.75,20.0546875 L75.75,21 Z" id="body" fill="#000000" fillRule="nonzero" className={styles.truckBody} />
					<g id="wheels" transform="translate(37.078125, 26.000000)" fillRule="nonzero">
						<path d="M7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 Z" id="Oval" fill="#000000" />
						<path d="M7.5,3.7 C5.40131795,3.7 3.7,5.40131795 3.7,7.5 C3.7,9.59868205 5.40131795,11.3 7.5,11.3 C9.59868205,11.3 11.3,9.59868205 11.3,7.5 C11.3,5.40131795 9.59868205,3.7 7.5,3.7 Z" id="Path" fill="#FFFFFF" />
						<path d="M32.5,0 C36.6421356,0 40,3.35786438 40,7.5 C40,11.6421356 36.6421356,15 32.5,15 C28.3578644,15 25,11.6421356 25,7.5 C25,3.35786438 28.3578644,0 32.5,0 Z" id="Oval-Copy" fill="#000000" />
						<path d="M32.5,3.7 C30.401318,3.7 28.7,5.40131795 28.7,7.5 C28.7,9.59868205 30.401318,11.3 32.5,11.3 C34.598682,11.3 36.3,9.59868205 36.3,7.5 C36.3,5.40131795 34.598682,3.7 32.5,3.7 Z" id="Path" fill="#FFFFFF" />
					</g>
					<line x1="24.0245" y1="17.5" x2="3.9995" y2="17.5" id="middle" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className={styles.truckLineMiddle} />
					<line x1="24.0245" y1="24.5" x2="10.5" y2="24.5" id="bottom" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className={styles.truckLineBottom} />
					<line x1="24.0245" y1="10.5" x2="8.5" y2="10.5" id="top" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className={styles.truckLineTop} />
				</g>
			</svg>
		</div>
	);
};

export default Loader;
