export enum PlanStatusEnum {
	DRAFT = 0,
	UNDER_PLANNING,
	SCHEDULED,
	IN_PROGRESS,
	MODIFIED,
	COMPLETED,
	CANCELLED,
}

export const PlanStatusPairs = {
	[PlanStatusEnum.DRAFT]: 'model.enums.draft',
	[PlanStatusEnum.UNDER_PLANNING]: 'model.enums.underPlan',
	[PlanStatusEnum.SCHEDULED]: 'model.enums.scheduled',
	[PlanStatusEnum.IN_PROGRESS]: 'model.enums.inProgress',
	[PlanStatusEnum.MODIFIED]: 'model.enums.modified',
	[PlanStatusEnum.COMPLETED]: 'model.enums.completed',
	[PlanStatusEnum.CANCELLED]: 'model.enums.cancelled',
}