import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import * as routeActions from 'store/route/actions';
import * as routeSelectors from 'store/route/selectors';
import stylesLayout from '../Layout.module.scss';
import VehicleRouteCard from 'components/VehicleRouteCard';
import Layout from 'pages/Layout';
import SearchFulltext from '../../components/SearchFulltext';
import FiltersButton from 'components/Buttons/FiltersButton';
import EuFooter from 'components/EuFooter';
import { ReduxState } from 'store/reducer';
import VehicleRouteCardLoader from 'components/VehicleRouteCard/VehicleRouteCardLoader';
import HomepageMap from 'pages/Homepage/HomepageMap';
import { RoutesEnum } from 'model/enums/RoutesEnum';
import { useSearchedEntity } from 'model/hooks/useSearchedEntity';
import { IAssignment } from 'model/interfaces/IAssignment';
import { IAssignmentRequest } from 'model/services/Api/interfaces/requests/IAssignmentRequest';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IRoute } from 'model/interfaces/IRoute';
import { IGenericSearchRequest } from 'model/services/Api/interfaces/requests/IGenericSearchRequest';
import { getRouteParams } from 'store/route/sagas';
import { IRouteRequest } from 'model/services/Api/interfaces/requests/IRouteRequest';
import { isoDayFromUTCDatetime } from 'model/helpers/datetime';
import RouteFilters from 'pages/Homepage/RouteFilters';
import * as ordersActions from 'pages/Orders/store/actions';
import * as planActions from 'store/plan/actions';
import NoPlanPage from 'components/NoPlanPage';
import { useHistory } from 'react-router';
import {useTranslation} from "react-i18next";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const Loader: React.FC = () =>
	<>
		{[1,2,3,4,5].map((item, index) => <VehicleRouteCardLoader key={index} opacity={ 1.2 - 2*item*0.1 } />)}
	</>;

const Homepage: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();

	const [ areFiltersVisible, setFiltersVisible ] = useState(false);

	const { isLoading, routes, executionDate, shift, plan } = useSelector(({ routes, executionDate, shift, plan }: ReduxState) => ({
		isLoading: routeSelectors.isLoading(routes),
		routes: routeSelectors.getRoutes(routes),
		executionDate,
		shift: shift.id,
		plan: plan.data,
	}));

	React.useEffect(() => {
		dispatch(routeActions.fetchRoutes());

		// eslint-disable-next-line
	}, [executionDate, shift]);

	const [ searchedRoutes, onSearch, activeFiltersCount, isFiltersLoading ] = useSearchedEntity<IRoute, IRouteRequest>(
		UrlEnum.ROUTE,
		routes ?? [],
		getRouteParams(isoDayFromUTCDatetime(executionDate), shift),
	);

	if (!plan?.id) {
		return <NoPlanPage />;
	}

	const handleClickRerouteAll = () => {
		dispatch(routeActions.setAllSelected(true));
		history.push(RoutesEnum.REROUTING);
	};

	return (
		<Layout className={ stylesLayout.layoutDashboard }>
			<aside className={ `${ stylesLayout.asideDashboard } panel over-map` }>
				<div className="panel-header">
					<div className="row mb-2">
						<div className="col">
							<FiltersButton
								onClick={ () => setFiltersVisible(!areFiltersVisible) }
								activeFiltersCount={ activeFiltersCount }
								isLoading={ isFiltersLoading }
							/>
						</div>
						<div className="col text-right">
							<button onClick={handleClickRerouteAll} className="btn btn-sm btn-primary">
								{t('pages.homepage.rerouteAll')}
							</button>
						</div>
					</div>

					<div className={`bg-gray-200 rounded pt-3 px-3 mb-3 ${!areFiltersVisible ? 'd-none' : ''}`}>
						<RouteFilters onSearch={ onSearch } />
					</div>

					<SearchFulltext placeholder="" onSearch={ onSearch } />
				</div>

				<div className="panel-body">
					{ (typeof routes === 'undefined' || routes.length === 0) && isLoading && <Loader />}

					{ typeof routes !== 'undefined' && searchedRoutes.length > 0 && searchedRoutes.map((route, routeIndex) => (
						<VehicleRouteCard
							key={ routeIndex }
							route={ route }
							allowDetailLink
						/>
					)) }
				</div>
			</aside>

			<HomepageMap routes={ routes } />

			<footer className={ `${stylesLayout.footer} over-map` }>
				<EuFooter/>
			</footer>
		</Layout>
	);
};

export default Homepage;
