import React, { useState } from 'react';

import styles from './index.module.scss';
import { IOrder } from 'model/interfaces/IOrder';
import { OrderTypeEnum } from 'model/enums/OrderTypeEnum';
import OrderMoreInfo from 'components/cards/OrderMoreInfo';
import EditOrderButton from 'components/cards/EditOrderButton';
import OrderHeader from 'components/cards/OrderHeader';
import { OrderFetchActionType } from 'model/types/OrderFetchAction';
import { IShift } from 'model/interfaces/IShift';
import {useTranslation} from "react-i18next";
import MapTargetButton from "../Buttons/MapTargetButton";

type BaseProps = {
	order: IOrder,
	shift?: IShift,
	planId?: number,
	number?: number,
	showEdit?: boolean,
	showDelete?: boolean,
	fetchAction?: OrderFetchActionType,
	className?: string,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const OrderCard: React.FC<Props> = ({ order, number, showEdit= false, showDelete = false, ...props }) => {
	const [ showMore, setShowMore ] = useState(false);

	const { t } = useTranslation();

	return (
		<div className={ `${styles.container} ${styles[`orderStatus${order.status.status}`]} ${props.className ?? ''} ` }>
			<div className={ styles[`number${order.order_type}`] }>{typeof number !== 'undefined'  ? number : ''}</div>
			{ order.order_type === OrderTypeEnum.PICKUP &&
				<>
					<OrderHeader
						address={ order.address_from! }
						restriction={ order.restriction_from }
						erpId={ order.erp_id }
						order={ order }
					 />
					<OrderMoreInfo
						order={ order }
						showMore={ showMore }
					/>
				</>
			}
			{ order.order_type === OrderTypeEnum.DELIVERY &&
				<>
					<OrderHeader
						address={ order.address_to! }
						restriction={ order.restriction_to }
						erpId={ order.erp_id }
						order={ order }
					/>
					<OrderMoreInfo
						order={ order }
						showMore={ showMore }
					/>
				</>
			}
			{ order.order_type === OrderTypeEnum.REQUISITION &&
			<>
				<OrderHeader
					address={ order.address_from! }
					restriction={ order.restriction_from }
					erpId={ order.erp_id }
					requisitionType={OrderTypeEnum.PICKUP}
					order={ order }
				/>
				<OrderHeader
					address={ order.address_to! }
					restriction={ order.restriction_to }
					erpId={ order.erp_id }
					requisitionType={OrderTypeEnum.DELIVERY}
					order={ order }
				/>
				<OrderMoreInfo
					order={ order }
					showMore={ showMore }
				/>
			</>
			}

			<button
				className="btn font-weight-normal p-0 text-small text-primary mt-1"
				onClick={ () => setShowMore(!showMore) }
			>
				{showMore ? t('cards.lessInformation') : t('cards.moreInformation')}
				<i className={`far ${showMore ? 'fa-angle-up' : 'fa-angle-down'} ml-2`} />
			</button>

			{(showEdit || showDelete) && props.planId && props.shift &&
			<EditOrderButton
				order={order}
				showEdit={showEdit}
				showDelete={showDelete}
				fetchAction={ props.fetchAction }
				planId={ props.planId }
				shift={ props.shift }
			/>
			}

			<MapTargetButton order={order} />
		</div>
	);
};

export default OrderCard;
