import { createActionCreator } from 'deox';
import { IApiError } from 'model/interfaces/IApiError';
import { IVehicle } from 'model/interfaces/IVehicle';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';

export enum VehicleActionsEnum {
	FETCH = '@VEHICLES_FETCH',
	FETCH_SUCCESS = '@VEHICLES_FETCH_SUCCESS',
	FETCH_FAIL = '@VEHICLES_FETCH_FAIL',

	CREATE = '@VEHICLES_CREATE',
	UPDATE = '@VEHICLES_UPDATE',
	FORM_SUCCESS = '@VEHICLES_FORM_SUCCESS',
	FORM_FAIL = '@VEHICLES_FORM_FAIL',
	FORM_RESTART = '@VEHICLES_FORM_RESTART',

	DELETE = '@VEHICLES_DELETE',
	DELETE_SUCCESS = '@VEHICLES_DELETE_SUCCESS',
	DELETE_FAIL = '@VEHICLES_DELETE_FAIL',
}

export const fetchVehicles = createActionCreator(VehicleActionsEnum.FETCH);

export const fetchVehiclesFail = createActionCreator(
	VehicleActionsEnum.FETCH_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const fetchVehiclesSuccess = createActionCreator(
	VehicleActionsEnum.FETCH_SUCCESS,
	resolve => (data: IPaginatedData<IVehicle>) => resolve(data),
);

export const createVehicle = createActionCreator(
	VehicleActionsEnum.CREATE,
	resolve => (vehicle: IVehicle) => resolve(vehicle),
);

export const updateVehicle = createActionCreator(
	VehicleActionsEnum.UPDATE,
	resolve => (vehicle: IVehicle) => resolve(vehicle),
);

export const formVehicleSuccess = createActionCreator(
	VehicleActionsEnum.FORM_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success));

export const formVehicleFail = createActionCreator(
	VehicleActionsEnum.FORM_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const formVehicleRestart = createActionCreator(VehicleActionsEnum.FORM_RESTART);

export const deleteVehicle = createActionCreator(
	VehicleActionsEnum.DELETE,
	resolve => (vehicle: IVehicle) => resolve(vehicle),
);

export const deleteVehicleSuccess = createActionCreator(
	VehicleActionsEnum.DELETE_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success)
);

export const deleteVehicleFail = createActionCreator(
	VehicleActionsEnum.DELETE_FAIL,
	resolve => (error: IReduxActionState & IApiError) => resolve(error),
);
