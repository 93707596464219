import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';

import * as authSelectors from 'store/auth/selectors';
import { ReduxState } from 'store/reducer';
import { IQuery } from 'model/services/Api/interfaces/IQuery';
import { errorToApiError } from 'model/helpers/api';
import config from 'config/config.json'

export class Api {
	private readonly _instance: AxiosInstance;

	constructor (
		private readonly _token?: string,
	) {
		this._instance = axios.create({
			baseURL: config.apiUrl,
		});

		this.registerRequestInterceptors();
		this.registerResponseInterceptors();
	}

	getRequest = <T>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> => {
		return this._instance.get(url, config);
	};

	postRequest = <RequestBody, Response>(url: string, body: RequestBody, config: AxiosRequestConfig | undefined = undefined): AxiosPromise<Response> => {
		return this._instance.post(url, body, config);
	};

	putRequest = <RequestBody, Response>(url: string, body: RequestBody): AxiosPromise<Response> => {
		return this._instance.put(url, body);
	};

	patchRequest = <RequestBody, Response>(url: string, body: RequestBody): AxiosPromise<Response> => {
		return this._instance.patch(url, body);
	};

	deleteRequest = <Response>(url: string): AxiosPromise<Response> => {
		return this._instance.delete(url);
	};

	paramsToString = (params: IQuery): string => "/?" + Object.keys(params)
		.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
		.join('&');

	/**
	 * Runs for every request
	 */
	private registerRequestInterceptors = () => {
		this._instance.interceptors.request.use((config) => {
			if (config.headers === undefined) {
				config.headers = {};
			}

			if (typeof this._token !== 'undefined') {
				config.headers.Authorization = `Token ${this._token}`;
			}

			return config;
		})
	};

	/**
	 * Runs for every response
	 */
	private registerResponseInterceptors = () => {
		this._instance.interceptors.response.use(
			response => response,
				error => Promise.reject(errorToApiError(error))
		);
	}
}

export const apiFactory = (state: ReduxState): Api => new Api(authSelectors.getToken(state.auth));

