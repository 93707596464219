import { Coords } from 'model/types/Coords';

export function resolveDuplicateCoords(coords: Coords, usedCoords: Coords[]) {
	const OFFSET = 0.0001;
	
	const newCoords = coords.slice() as Coords;
	
	const doUsedCoordsIncludeCoords = (coords: Coords) => usedCoords.some(usedCoordsItem =>
		usedCoordsItem[0] === coords[0] && usedCoordsItem[1] === coords[1]
	);
	
	if (doUsedCoordsIncludeCoords(newCoords)) {
		if (doUsedCoordsIncludeCoords([newCoords[0] + OFFSET, newCoords[1] + OFFSET])) {
			if (doUsedCoordsIncludeCoords([newCoords[0] - OFFSET, newCoords[1] - OFFSET])) {
				if (doUsedCoordsIncludeCoords([newCoords[0] + OFFSET, newCoords[1] - OFFSET])) {
					if (doUsedCoordsIncludeCoords([newCoords[0] - OFFSET, newCoords[1] + OFFSET])) {
						if (doUsedCoordsIncludeCoords([newCoords[0], newCoords[1] + OFFSET])) {
							if (doUsedCoordsIncludeCoords([newCoords[0], newCoords[1] - OFFSET])) {
								if (doUsedCoordsIncludeCoords([newCoords[0] + OFFSET, newCoords[1]])) {
									newCoords[0] -= OFFSET;

								} else {
									newCoords[0] += OFFSET;
								}

							} else {
								newCoords[1] -= OFFSET;
							}

						} else {
							newCoords[1] += OFFSET;
						}

					} else {
						newCoords[1] += OFFSET;
						newCoords[0] -= OFFSET;
					}

				} else {
					newCoords[1] -= OFFSET;
					newCoords[0] += OFFSET;
				}

			} else {
				newCoords[1] -= OFFSET;
				newCoords[0] -= OFFSET;
			}

		} else {
			newCoords[1] += OFFSET;
			newCoords[0] += OFFSET;
		}
	}

	usedCoords.push(newCoords);

	return newCoords;
}