import { ReduxState } from 'store/reducer';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { apiFactory } from 'model/services/Api/Api';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import * as actions from 'store/plan/actions';
import { IPlansResponse } from 'model/services/Api/interfaces/responses/IPlansResponse';
import { isoDayFromUTCDatetime } from 'model/helpers/datetime';
import { ActionType } from 'deox';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import i18n from '../../i18n';

function * fetchPlan() {
	const state: ReduxState = yield select();
	const api = apiFactory(state);

	try {
		const response: IPlansResponse = yield call(api.getRequest, UrlEnum.PLAN, {
			params: {
				date: isoDayFromUTCDatetime(state.executionDate),
				shift: state.shift.id,
			}
		});

		if (response.data.results && response.data.results[0]) {
			yield put(actions.fetchPlanSuccess(response.data.results[0]));

		} else {
			throw new Error(i18n.t('store.noPlans'));
		}
	} catch (err) {
		yield put(actions.fetchPlanFail(err));
	}
}

function * exportUnexecutedOrders(action: ActionType<typeof actions.exportUnexecutedOrders>) {
	const state: ReduxState = yield select();
	const api = apiFactory(state);
	const { plan } = action.payload;

	try {
		const response: AxiosResponse<any> = yield call(api.postRequest, `${UrlEnum.PLAN}${plan.id}/export_unexecuted_orders/`, {}, {
			responseType: 'blob'
		});

		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `unexecuted_orders-${plan.date}-id${plan.id}.xlsx`);
		document.body.appendChild(link);
		link.click();

	}  catch (err) {
		toast.error(i18n.t('store.unexecutedOrders'));
	}
}

function * costMatrixCreate(action: ActionType<typeof actions.costMatrixCreate>) {
	const state: ReduxState = yield select();
	const api = apiFactory(state);
	const planId = action.payload

	yield call(api.postRequest, `/${UrlEnum.PLAN}${planId}/cost_matrix_create/`, {})
}

function * watchFetchPlan() {
	yield takeLatest(actions.fetchPlan, fetchPlan);
}

function * watchExportUnexecutedOrders() {
	yield takeLatest(actions.exportUnexecutedOrders, exportUnexecutedOrders);
}

function * watchCostMatrixCreate() {
	yield takeLatest(actions.costMatrixCreate, costMatrixCreate);
}

const planSagas = [
	watchFetchPlan(),
	watchExportUnexecutedOrders(),
	watchCostMatrixCreate()
];

export default planSagas;