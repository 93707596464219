import React from 'react';

import euLogos from 'images/eu-logos.jpg';
import styles from './index.module.scss';
import stylesLayout from 'pages/Layout.module.scss';
import {useTranslation} from "react-i18next";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const EuFooter: React.FC < Props > = props => {

	const { t } = useTranslation();

	return (
		<div className={`${styles.panel} panel bg-white`}>
			<div className="form-row align-items-center py-3 px-3">
				<div className="col flex-grow-0">
					<img
						src={euLogos}
						alt=""
						width={128}
						height={35}
						className="ml-1"
					/>
				</div>
				<div className="col flex-grow-1">
					<small className={`${styles.text} text-muted`}>
						{t('footer.contribution')}
					</small>
				</div>
			</div>
			<a
				href="https://www.greenyourroute.com/"
				target="_blank"
				rel="noreferrer noopener"
				className="stretched-link"
			/>
		</div>
	);
};

export default EuFooter;