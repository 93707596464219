import React from 'react';
import { Field, FieldArrayRenderProps, FieldProps } from 'formik';
import { uniqueId as lodashUniqueId } from 'lodash';

import ErrorMessage from 'components/Form/ErrorMessage';

import InputWrapper from 'components/Form/InputWrapper';
import { fieldClassName } from 'model/helpers/form';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import TextInput from 'components/Form/TextInput';
import {useTranslation} from "react-i18next";

type BaseProps = FieldArrayRenderProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const CoordinatesInput: React.FC<Props> = props => {
	const idLat = lodashUniqueId(`text-input-${props.name}[1]`);
	const idLon = lodashUniqueId(`text-input-${props.name}[0]`);

	const { t } = useTranslation();
	return (
		<>
			<div className="form-row">
				<div className="col-6">
					<label htmlFor={ idLat }>{t('form.latitude')}</label>
					<Field name={ `${props.name}[1]` }>
						{ (props: FieldProps) =>
							<input
								id={idLat}
								className={ fieldClassName(props.meta) }
								type="number"
								{ ...props.field }
							/>
						}
					</Field>
				</div>
				<div className="col-6">
					<label htmlFor={ idLon }>{t('form.longitude')}</label>
					<Field name={ `${props.name}[0]` }>
						{ (props: FieldProps) =>
							<input
								id={idLon}
								className={ fieldClassName(props.meta) }
								type="number"
								{ ...props.field }
							/>
						}
					</Field>
				</div>
			</div>
			<ErrorMessage name={ props.name } />
		</>
	);
};

export default CoordinatesInput;
