import React from 'react';
import stylesLayout from 'pages/Layout.module.scss';
import Layout from 'pages/Layout';
import { Link } from 'react-router-dom';
import { RoutesEnum } from 'model/enums/RoutesEnum';
import {useTranslation} from "react-i18next";

const NoPlanPage = () => {

	const { t } = useTranslation();

	return (
		<Layout className={ stylesLayout.layoutPlanningWithoutAside }>
			<div className="d-flex align-items-center justify-content-center">
				<div className="text-center p-8 mb-9">
					<i className="fa fa-calendar text-muted fa-9x mb-6" />
					<h1 className="mb-4">{t('components.noPlan')}</h1>
					<h2 className="font-weight-normal">{t('components.selectDayShift')} <Link to={RoutesEnum.PLANNING}>{t('components.newPlan')}</Link></h2>
				</div>
			</div>
		</Layout>
	);
};

export default NoPlanPage;