import { createReducer } from 'deox';

import * as actions from 'pages/Drivers/store/actions';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';
import { IDriver } from 'model/interfaces/IDriver';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';

export interface IDriversState extends IReduxFetchedState<IPaginatedData<IDriver>> {}

const initialState: IDriversState = {};

export default createReducer(initialState, handle => [
	handle(actions.fetchDrivers, (state) => ({
		...state,
		loading: true,
	})),
	handle(actions.fetchDriversSuccess, (state, { payload }) => ({
		...state,
		error: undefined,
		data: { ...payload },
		loading: false,
	})),
	handle(actions.fetchDriversFail, (state, { payload }) => ({
		...state,
		data: undefined,
		error: { ...payload },
		loading: false,
	})),
	handle(actions.createDriver, (state) => ({
		...state,
		formLoading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.updateDriver, (state) => ({
		...state,
		formLoading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.formDriverSuccess, (state, { payload }) => ({
		...state,
		formLoading: false,
		formSuccess: { ...payload },
		formError: undefined,
	})),
	handle(actions.formDriverFail, (state, { payload }) => ({
		...state,
		formLoading: false,
		formSuccess: undefined,
		formError: { ...payload },
	})),
	handle(actions.formDriverRestart, (state) => ({
		...state,
		formLoading: false,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.deleteDriver, (state) => ({
		...state,
		loading: true,
		deleteSuccess: undefined,
		deleteError: undefined,
	})),
	handle(actions.deleteDriverSuccess, (state, { payload }) => ({
		...state,
		loading: false,
		deleteSuccess: { ...payload },
		deleteError: undefined,
	})),
	handle(actions.deleteDriverFail, (state, { payload }) => ({
		...state,
		loading: false,
		success: false,
		error: { ...payload },
	})),
]);
