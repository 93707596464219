import React, { useEffect } from 'react';
import { Field, FieldArray, FieldProps, Form, Formik, useFormikContext } from 'formik';

import SelectInput from 'components/Form/SelectInput';
import {OrderTypeEnum, OrderTypePairs, OrderTypes} from 'model/enums/OrderTypeEnum';
import PackageAsyncSelect from 'components/Form/PackageAsyncSelect';
import TextInput from 'components/Form/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { IOrder } from 'model/interfaces/IOrder';
import * as orderActions from 'pages/Orders/store/actions';
import TextAreaInput from 'components/Form/TextAreaInput';
import { ReduxState } from 'store/reducer';
import BaseModal, { BaseModalProps } from 'components/modals/BaseModal';
import TagAsyncSelect from 'components/Form/TagAsyncSelect';
import { usePrevious } from 'model/hooks/usePrevious';
import styles from './index.module.scss';
import { IShift } from 'model/interfaces/IShift';
import AddressAsnycSelect from 'components/Form/AddressAsyncSelect';
import { AddressTypeEnum } from 'model/enums/AddressTypeEnum';
import { OrderFetchActionType } from 'model/types/OrderFetchAction';
import * as planActions from 'store/plan/actions'
import { roundToMaxTwoDecimalPlaces } from 'model/helpers/numbers';
import { useTranslation } from 'react-i18next';
import {returnEnumPairsOptions} from "../../../model/helpers/enums";

type BaseProps = {
	initialValues: IOrder,
	planId: number,
	shift: IShift,
	date?: string,
	fetchAction?: OrderFetchActionType,
	allowCostMatrixCalculation?: boolean
};

type InjectedProps = BaseModalProps;

type Props = BaseProps & InjectedProps;

const OnChangeComponent = (props: { shift: IShift }) => {
	const context = useFormikContext();
	const prevContext = usePrevious(context);
	// const shift = useSelector((state: ReduxState) => !isEmpty(state.shift) ? state.shift : undefined);

	const setRestrictions = (values: IOrder, shift: IShift) => {
		const timeWindowsOriginFieldMeta = context.getFieldMeta('restriction_from.time_restriction.time_windows');
		const timeWindowsDestinationFieldMeta = context.getFieldMeta('restriction_to.time_restriction.time_windows');

		if (!timeWindowsOriginFieldMeta.touched) {
			context.setFieldValue('restriction_from.time_restriction.time_windows', [{
				start_time: values.order_type === OrderTypeEnum.DELIVERY ? shift.time_start : '',
				end_time: values.order_type === OrderTypeEnum.DELIVERY ? shift.time_end : '',
			}]);
		}

		if (!timeWindowsDestinationFieldMeta.touched) {
			context.setFieldValue('restriction_to.time_restriction.time_windows', [{
				start_time: values.order_type === OrderTypeEnum.PICKUP ? shift.time_start : '',
				end_time: values.order_type === OrderTypeEnum.PICKUP ? shift.time_end : '',
			}]);
		}
	};

	useEffect(() => {
		const prevValues = prevContext?.values as IOrder|undefined;
		const values = context.values as IOrder;

		// At initialization, we set time windows, only for new orders
		if (!prevValues && props.shift) {
			setRestrictions(values, props.shift);
		}

		if (!prevValues) return;

		const weightFieldMeta = context.getFieldMeta('status.weight');
		const volumeFieldMeta = context.getFieldMeta('status.volume');
		const serviceTimeOriginFieldMeta = context.getFieldMeta('status.loading_service_time');
		const serviceTimeDestinationFieldMeta = context.getFieldMeta('status.unloading_service_time');

		// Set weight based on the package type and quantity
		if (!weightFieldMeta.touched && values.package?.weight && values.status.quantity && (prevValues!.status.quantity !== values.status.quantity || prevValues!.package !== values.package)) {
			context.setFieldValue('status.weight', roundToMaxTwoDecimalPlaces(values.package.weight * values.status.quantity));
		}

		// Set volume based on the package type and quantity
		if (!volumeFieldMeta.touched && values.package?.volume && values.status.quantity && (prevValues!.status.quantity !== values.status.quantity || prevValues!.package !== values.package)) {
			context.setFieldValue('status.volume', roundToMaxTwoDecimalPlaces(values.package.volume * values.status.quantity));
		}

		// Set origin and destination service times based on the package type
		if (prevValues!.package !== values.package) {
			if (values.package?.loading_service_time && !serviceTimeOriginFieldMeta.touched) {
				context.setFieldValue('status.loading_service_time', values.package.loading_service_time);
			}
			if (values.package?.unloading_service_time && !serviceTimeDestinationFieldMeta.touched) {
				context.setFieldValue('status.unloading_service_time', values.package.unloading_service_time);
			}
		}

		// Set origin time windows based on the currently selected Shift and Order Type
		if (props.shift && prevValues!.order_type !== values.order_type) {
			setRestrictions(values, props.shift);
		}

	}, [context.values]);

	return null;
};

const WeightVolumeMultiplication = ({field} : { field: 'weight'|'volume'} ) => {
	const context = useFormikContext();
	const values = context.values as IOrder;
	const weightFieldMeta = context.getFieldMeta(`status.${field}`);

	if (!weightFieldMeta.touched && values.package && values.package[field] && values.status.quantity) {
		return <div className={styles.weightVolumeMultiplication}>
			({values.status.quantity} * {values.package[field]})
		</div>
	}

	return null;
};

const NewOrderModal: React.FC<Props> = ({ allowCostMatrixCalculation = true, initialValues, ...props}) => {

	const dispatch = useDispatch();

	const formSuccess = useSelector((state: ReduxState) => state.orders.formSuccess);

	const onSubmit = async (values: IOrder) => {
		if (props.date) {
			values.execution_date = props.date;
		}

		if (values.id) {
			dispatch(orderActions.updateOrder(values, props.planId, props.fetchAction));
		} else {
			dispatch(orderActions.createOrder(values, props.planId, props.fetchAction));
		}

		if (allowCostMatrixCalculation) {
			setTimeout(() => dispatch(planActions.costMatrixCreate(props.planId)), 1000);
		}
	};

	useEffect(() => {
		if (formSuccess) {
			dispatch(orderActions.formOrderRestart());
			props.onRequestClose();
		}
	}, [ formSuccess ]);


	const { t } = useTranslation();
	return (
	<BaseModal {...props}>
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
		>
			{formikBag => {

				const timeWindowsPickup = formikBag.values.restriction_from?.time_restriction.time_windows;
				const timeWindowsDelivery = formikBag.values.restriction_to?.time_restriction.time_windows;


				return (
					<Form>
						{ /* only for new orders */}
						{!formikBag.values.id && <OnChangeComponent shift={props.shift}/>}
						<div className="form-row">
							<div className="col">
								<Field name="order_type">
									{(props: FieldProps) =>
										<SelectInput
											options={returnEnumPairsOptions(OrderTypes, OrderTypePairs)}
											label={t('modals.orderType')}
											noEmptyValue
											{...props}
										/>
									}
								</Field>
							</div>
							<div className="col">
								<Field name="status.quantity">
									{(props: FieldProps) =>
										<TextInput
											label={t('modals.quantity')}
											type="number"
											{...props}
										/>
									}
								</Field>
							</div>
						</div>
						<div className="form-row">
							<div className="col">
								<Field name="package">
									{(props: FieldProps) =>
										<PackageAsyncSelect
											label={t('modals.packageType')}
											{...props}
										/>
									}
								</Field>
							</div>
							<div className="col">
								<Field name="tags">
									{(props: FieldProps) =>
										<TagAsyncSelect
											label={t('modals.tags')}
											isMulti
											{...props}
										/>
									}
								</Field>
							</div>
						</div>
						<div className="form-row">
							<div className="col">
								<div className="position-relative">
									<Field name="status.weight">
										{(props: FieldProps) =>
											<TextInput
												label={t('modals.weight')}
												append={<span>kg</span>}
												{...props}
											/>
										}
									</Field>
									<WeightVolumeMultiplication field="weight"/>
								</div>
							</div>
							<div className="col">
								<div className="position-relative">
									<Field name="status.volume">
										{(props: FieldProps) =>
											<TextInput
												label={t('modals.volume')}
												append={<span>m<sup>3</sup></span>}
												{...props}
											/>
										}
									</Field>
									<WeightVolumeMultiplication field="volume"/>
								</div>
							</div>
						</div>
						<div className="form-row">
							<div className="col-6">
								<Field name="erp_id">
									{(props: FieldProps) =>
										<TextInput
											label={t('modals.ordersErpId')}
											{...props}
										/>
									}
								</Field>
							</div>
						</div>
						<Field name="comments">
							{(props: FieldProps) =>
								<TextAreaInput
									label={t('modals.Comments')}
									{...props}
								/>
							}
						</Field>

						<div className="form-row">
							<div className="col">
								<Field name="address_from">
									{(props: FieldProps) =>
										<>
											{formikBag.values.order_type === OrderTypeEnum.PICKUP &&
												<AddressAsnycSelect
													label={t('modals.originPickup')}
													addressType={AddressTypeEnum.SERVICE_ADDRESS}
													{...props}
												/>
											}
											{formikBag.values.order_type === OrderTypeEnum.DELIVERY &&
												<AddressAsnycSelect
													label={t('modals.originDelivery')}
													addressType={AddressTypeEnum.DEPOT}
													{...props}
												/>
											}
											{formikBag.values.order_type === OrderTypeEnum.REQUISITION &&
												<AddressAsnycSelect
													label={t('modals.originRequisition')}
													addressType={[AddressTypeEnum.DEPOT, AddressTypeEnum.SERVICE_ADDRESS]}
													{...props}
												/>
											}
										</>
									}
								</Field>
							</div>
							<div className="col">
								<Field name="address_to">
									{(props: FieldProps) =>
										<>
											{formikBag.values.order_type === OrderTypeEnum.PICKUP &&
												<AddressAsnycSelect
													label={t('modals.destinationPickup')}
													addressType={AddressTypeEnum.DEPOT}
													{...props}
												/>
											}
											{formikBag.values.order_type === OrderTypeEnum.DELIVERY &&
												<AddressAsnycSelect
													label={t('modals.destinationDelivery')}
													addressType={AddressTypeEnum.SERVICE_ADDRESS}
													{...props}
												/>
											}
											{formikBag.values.order_type === OrderTypeEnum.REQUISITION &&
												<AddressAsnycSelect
													label={t('modals.destinationRequisition')}
													addressType={[AddressTypeEnum.DEPOT, AddressTypeEnum.SERVICE_ADDRESS]}
													{...props}
												/>
											}
										</>
									}
								</Field>
							</div>
						</div>

						<div className="form-row">
							<div className="col-6">
								<Field name="status.loading_service_time">
									{(props: FieldProps) =>
										<TextInput
											label={t('modals.serviceTimeOrigin')}
											{...props}
										/>
									}
								</Field>
							</div>
							<div className="col-6">
								<Field name="status.unloading_service_time">
									{(props: FieldProps) =>
										<TextInput
											label={t('modals.serviceTimeDestination')}
											{...props}
										/>
									}
								</Field>
							</div>
						</div>

						{/*<div className="form-row">*/}
						{/*<div className="col">*/}
						{/*	<Field name="address_from">*/}
						{/*		{ (props: FieldProps) =>*/}
						{/*			<AddressAsnycSelect*/}
						{/*				label="Address From"*/}
						{/*				{ ...props }*/}
						{/*			/>*/}
						{/*		}*/}
						{/*	</Field>*/}
						{/*</div>*/}
						{/*<div className="col">*/}
						{/*	<Field name="address_to">*/}
						{/*		{ (props: FieldProps) =>*/}
						{/*			<AddressAsnycSelect*/}
						{/*				label="Address To"*/}
						{/*				{ ...props }*/}
						{/*			/>*/}
						{/*		}*/}
						{/*	</Field>*/}
						{/*</div>*/}
						{/*</div>*/}


						<div className="form-row">
							<div className="col-6">
								<FieldArray name="restriction_from.time_restriction.time_windows">
									{(arrayHelpers) => (
										<>
											<label> {t('components.modals.timeOrigin')} </label>
											{timeWindowsPickup && timeWindowsPickup.length > 0 && timeWindowsPickup.map((timeWindow, index) => (
												<div
													className="form-row"
													key={index}
												>
													<div className="col">
														<Field
															name={`restriction_from.time_restriction.time_windows[${index}].start_time`}>
															{(props: FieldProps) =>
																<TextInput
																	placeholder="00:00:00" {...props} />}
														</Field>
													</div>
													<div className="col">
														<Field
															name={`restriction_from.time_restriction.time_windows[${index}].end_time`}>
															{(props: FieldProps) =>
																<TextInput
																	placeholder="00:00:00" {...props} />}
														</Field>
													</div>
													<div className="col flex-grow-0">
														<button
															onClick={() => arrayHelpers.remove(index)}
															className="btn"
															type="button"
														>
															<i className="fa fa-times"/>
														</button>
													</div>
												</div>
											))}
											<div>
												<button
													type="button"
													className="btn btn-sm btn-secondary"
													onClick={() => arrayHelpers.push({
														start_time: '',
														end_time: '',
													})}
												>
													{t('modals.addO')}
												</button>
											</div>
										</>)
									}
								</FieldArray>
							</div>

							<div className="col-6">
								<FieldArray name="restriction_to.time_restriction.time_windows">
									{(arrayHelpers) => (
										<>
											<label> {t('components.modals.timeDestination')} </label>
											{timeWindowsDelivery && timeWindowsDelivery.length > 0 && timeWindowsDelivery.map((timeWindow, index) => (
												<div
													className="form-row"
													key={index}
												>
													<div className="col">
														<Field
															name={`restriction_to.time_restriction.time_windows[${index}].start_time`}>
															{(props: FieldProps) =>
																<TextInput
																	placeholder="00:00:00" {...props} />}
														</Field>
													</div>
													<div className="col">
														<Field
															name={`restriction_to.time_restriction.time_windows[${index}].end_time`}>
															{(props: FieldProps) =>
																<TextInput
																	placeholder="00:00:00" {...props} />}
														</Field>
													</div>
													<div className="col flex-grow-0">
														<button
															onClick={() => arrayHelpers.remove(index)}
															className="btn"
															type="button"
														>
															<i className="fa fa-times"/>
														</button>
													</div>
												</div>
											))}
											<div>
												<button
													type="button"
													className="btn btn-sm btn-secondary"
													onClick={() => arrayHelpers.push({
														start_time: '',
														end_time: '',
													})}
												>
													{t('modals.addD')}
												</button>
											</div>
										</>)
									}
								</FieldArray>
							</div>
						</div>


						<div className="text-right">
							<button
								type="submit"
								className="btn btn-primary"
								disabled={!formikBag.isValid || formikBag.isSubmitting}
							>
								{t('modals.save')}
							</button>
						</div>
					</Form>
				);
			}
			}
		</Formik>
	</BaseModal>
);
};

export default NewOrderModal;
