import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';

import styles from './index.module.scss';
import { IGenericSearchRequest } from 'model/services/Api/interfaces/requests/IGenericSearchRequest';

type Props = {
	placeholder: string,
	onSearch(params: IGenericSearchRequest): void,
}

const SearchFulltext: React.FC<Props> = ({ placeholder, onSearch }) => {
	const [ value, setValue ] = useState('');

	const debouncedOnSearch = useCallback(
		debounce(onSearch, 500)
	, [onSearch]);

	const onChange = (value: string) => {
		setValue(value);
		debouncedOnSearch({
			search: value || null
		});
	};

	return (
		<div className="position-relative mb-2">
			<i className={`far fa-search ${styles.icon}`} />
			<input
				type="text"
				className={`form-control ${styles.input}`}
				placeholder={placeholder}
				value={ value }
				onChange={ e => onChange(e.target.value) }
			/>
			{ value !== '' &&
				<button className={`${styles.clear} btn`} onClick={ () => onChange('') }>
					<i className={`far fa-times`} />
				</button>
			}
		</div>
	);

};

export default SearchFulltext;