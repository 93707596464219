import React from 'react';

type KeyValueRowsProps = {
	items: {
		[key:string]: React.ReactNode
	},
};

const KeyValueRows: React.FC<KeyValueRowsProps> = ({ items }) => {
	return (
		<>
			{Object.entries(items).map(( [ label, value ], index) => (
				<div className="row" key={index}>
					<div className="col text-muted">
						{ label }
					</div>
					<div className="col text-right">
						{ value }
					</div>
				</div>
			))}
		</>
	);
}

export default KeyValueRows;