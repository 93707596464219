import React, { useState, useEffect } from 'react';

import Map, { BaseProps as MapBaseProps } from 'components/Map';
import { Marker as MapboxMarker, GeoJSONLayer } from 'react-mapbox-gl';
import { FitBounds } from 'react-mapbox-gl/lib/map';
import { LinePaint, LngLatBounds } from 'mapbox-gl';

import imgMapMarkerStart from 'images/map-marker-start.svg';
import imgMapMarkerFinish from 'images/map-marker-finish.svg';
import { IRoute } from 'model/interfaces/IRoute';
import { getAllRouteLocations, getDefinedRouteLocations } from 'model/helpers/route';
import { usePrevious } from 'model/hooks/usePrevious';
import Markers from 'pages/Orders/Markers';
import { Coords } from 'model/types/Coords';
import { resolveDuplicateCoords } from 'pages/Orders/helpers';
import { IPlan } from 'model/interfaces/IPlan';

type Props = {
	route?: IRoute,
	plan?: IPlan
}

type LinesProps = {
	route?: IRoute,
};

const linePaint: LinePaint = {
	'line-color': '#979797',
	'line-width': 2,
	'line-dasharray': [3, 2],
};

const getGeoJSONLayerData = (coords1: [number, number], coords2: [number, number]) => ({
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				'type': 'LineString',
				'coordinates': [
					coords1,
					coords2,
				]
			}
		}
	]
});

const Lines: React.FC<LinesProps> = (props) => {
	const route = props.route;
	const assignments = route?.assignments;

	if (!route || !assignments) return null;

	const pairsOfCoords = [];
	const locations = getAllRouteLocations(route);

	// If zero or only one location point, we don't have to draw any lines...
	if (locations.length < 2) return null;

	for (let i = 1; i < locations.length; i++) {
		if (!locations[i - 1] || !locations[i]) continue;
		pairsOfCoords.push([locations[i - 1], locations[i]]);
	}

	// If we don't have a single pair of coords, we don't have to draw any lines...
	if (pairsOfCoords.length === 0) return null;

	return (
		<>
			{pairsOfCoords.map((pairOfCoords, index) => (
				<GeoJSONLayer
					data={getGeoJSONLayerData(pairOfCoords[0]!, pairOfCoords[1]!)}
					linePaint={linePaint}
					key={index}
				/>
			))}
		</>
	);
};

const DetailMap: React.FC<Props> = (props) => {

	const route = props.route;
	const prevRoute = usePrevious(route);
	const assignments = route?.assignments;

	const mapProps: MapBaseProps = {};
	const usedCoords: Coords[] = [];

	if (!prevRoute && route) {
		const locations = getDefinedRouteLocations(route);

		// https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
		// Pass the first coordinates in the LineString to `lngLatBounds` &
		// wrap each coordinate pair in `extend` to include them in the bounds
		// result. A variation of this technique could be applied to zooming
		// to the bounds of multiple Points or Polygon geomteries - it just
		// requires wrapping all the coordinates with the extend method.
		if (locations.length > 0) {
			mapProps.fitBounds = locations.reduce(function (bounds, coord) {
				return bounds.extend(coord);
			}, new LngLatBounds(locations[0], locations[0])).toArray() as FitBounds;
		}
	}

	return (
		<Map { ...mapProps }>
			<>
				{props.route?.starting_point?.location &&
					<MapboxMarker coordinates={resolveDuplicateCoords(props.route?.starting_point.location?.coordinates, usedCoords)} anchor="bottom">
						<img src={imgMapMarkerStart} alt="" />
					</MapboxMarker>
				}

				{props.route?.ending_point?.location &&
					<MapboxMarker coordinates={resolveDuplicateCoords(props.route?.ending_point.location?.coordinates, usedCoords)} anchor="bottom">
						<img src={imgMapMarkerFinish} alt="" />
					</MapboxMarker>
				}

				{/*<Lines route={props.route} />*/}

				{ assignments &&
				<Markers
					orders={ assignments.map(assignment => assignment.order) }
					assignments={ assignments }
					plan={props.plan}
				/>
				}
			</>
		</Map>
	);
};

export default DetailMap;