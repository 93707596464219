import { createReducer } from 'deox';

import * as actions from 'pages/VehicleRestrictions/store/actions';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';
import { IApiError } from 'model/interfaces/IApiError';
import { IVehicleRestrictionEndpoint } from 'model/interfaces/IVehicleRestrictionEndpoint';

export interface IVehicleRestrictionsState extends IReduxFetchedState<IPaginatedData<IVehicleRestrictionEndpoint>> {
	formSuccess?: IReduxActionState | boolean | undefined,
	formError?: (IReduxActionState & IApiError) | undefined,

	deleteSuccess?: IReduxActionState | boolean,
	deleteError?: IReduxActionState & IApiError,
}

const initialState: IVehicleRestrictionsState = {};

export default createReducer(initialState, handle => [
	handle(actions.fetchVehicleRestrictions, (state) => ({
		...state,
		loading: true,
	})),
	handle(actions.fetchVehicleRestrictionsSuccess, (state, { payload }) => ({
		...state,
		error: undefined,
		data: { ...payload },
		loading: false,
	})),
	handle(actions.fetchVehicleRestrictionsFail, (state, { payload }) => ({
		...state,
		data: undefined,
		error: { ...payload },
		loading: false,
	})),
	handle(actions.createVehicleRestriction, (state) => ({
		...state,
		loading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.updateVehicleRestriction, (state) => ({
		...state,
		loading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.formVehicleRestrictionsuccess, (state, { payload }) => ({
		...state,
		loading: false,
		formSuccess: { ...payload },
		formError: undefined,
	})),
	handle(actions.formVehicleRestrictionFail, (state, { payload }) => ({
		...state,
		loading: false,
		formSuccess: undefined,
		formError: { ...payload },
	})),
	handle(actions.formVehicleRestrictionRestart, (state) => ({
		...state,
		loading: false,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.deleteVehicleRestriction, (state) => ({
		...state,
		loading: true,
		deleteSuccess: undefined,
		deleteError: undefined,
	})),
	handle(actions.deleteVehicleRestrictionsuccess, (state, { payload }) => ({
		...state,
		loading: false,
		deleteSuccess: { ...payload },
		deleteError: undefined,
	})),
	handle(actions.deleteVehicleRestrictionFail, (state, { payload }) => ({
		...state,
		loading: false,
		success: false,
		error: { ...payload },
	})),
]);
