import { IGridRowRender } from 'model/interfaces/grid/IGridRowRender';
import { IPlanningRoute } from 'model/interfaces/IRoute';
import React, { useState } from 'react';
import styles from "./index.module.scss";
import BaseModal from 'components/modals/BaseModal';
import VehicleDescription from 'pages/Planning/VehicleDescription';
import Form from 'pages/Planning/Vehicles/Form';
import {useTranslation} from "react-i18next";

const Row = (props: IGridRowRender<IPlanningRoute>) => {
	const row = props.row;
	const [ isModalOpen, setModalOpen ] = useState(false);
	const { t } = useTranslation();

	const Button: React.FC = (props) =>
		<button onClick={() => setModalOpen(true)} className={styles.cellButton}>
			{props.children}
		</button>

	return (
		<>
			<BaseModal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)} title={`${row.vehicle!.brand} ${row.vehicle!.plate}`}>
				<Form { ...props } />
			</BaseModal>

			<tr>
				<td className="align-middle pr-0" style={{ width: '1px' }}>
					<Button>
						<i className={`${row.enabled ? 'fa fa-check-square text-primary' : 'far fa-square text-muted'} fa-2x`} />
					</Button>
				</td>
				<td className="align-middle">
					<VehicleDescription vehicle={row.vehicle!} />
				</td>
				<td className="align-middle">
					<Button>
						{ row.semitrailer
							? <span>{row.semitrailer?.brand} {row.semitrailer?.plate}</span>
							: <span className="text-primary">{t('pages.planning.vehicles.setSemitrailer')}</span>
						}
					</Button>
				</td>
				<td className="align-middle">
					<Button>
						{ row.driver
							? <span>{row.driver?.first_name} {row.driver?.last_name}</span>
							: <span className="text-primary">{t('pages.planning.vehicles.setDriver')}</span>
						}
					</Button>
				</td>
				<td className="align-middle">
					<Button>
						{row.starting_point
							? <span>{row.starting_point.address}, {row.starting_point.postal_code}, {row.starting_point.city}</span>
							: <span className="text-primary">{t('pages.planning.vehicles.setStartingPoint')}</span>
						}
						<br />
						{row.ending_point
							? <span>{row.ending_point.address}, {row.ending_point.postal_code}, {row.ending_point.city}</span>
							: <span className="text-primary">{t('pages.planning.vehicles.setEndingPoint')}</span>
						}
					</Button>
				</td>
				<td className="align-middle">
					<Button>
						{row.time_start && row.time_start !== '' ? <span>{row.time_start}</span> : <span  className="text-primary">{t('pages.planning.vehicles.setTimeStart')}</span>}
						<br />
						{row.time_end && row.time_end !== '' ? <span>{row.time_end}</span> : <span className="text-primary">{t('pages.planning.vehicles.setTimeEnd')}</span>}
					</Button>
				</td>
			</tr>
		</>
	);
};

export default Row;