import React, {useContext} from 'react';
import {OrderTypeEnum} from "../../model/enums/OrderTypeEnum";
import styles from "../cards/index.module.scss";
import {MapContext} from "../../contexts/MapContext";
import {Coords} from "../../model/types/Coords";
import {IOrder} from "../../model/interfaces/IOrder";
import {ICustomer} from "../../model/interfaces/ICustomer";

type BaseProps = {
	order?: IOrder
	customer?: ICustomer
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

type ButtonProps = {
	className?: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const MapTargetButton: React.FC<Props> = ({ order, customer, ...props }) => {

	const { setCenter, setZoom, isVisible } = useContext(MapContext);

	let locationFrom = [0, 0] as Coords;
	let locationTo = [0, 0] as Coords;
	let location = [0, 0] as Coords;

	if (order !== undefined) {
		locationFrom = order.address_from?.location?.coordinates ?? [0, 0];
		locationTo = order.address_to?.location?.coordinates ?? [0, 0];
	}
	else if (customer !== undefined) {
		location = customer.addresses[0]?.location?.coordinates ?? [0, 0]
	}

	const _setCenter = (coordinates: Coords|null) => {
		if (coordinates !== null) {
			setCenter(coordinates);
			setZoom([14]);
		}
	};

	const DeliveryButton: React.FC<ButtonProps> = ({className, ...props}) => {
		return (
			<button
				className={`btn font-weight-normal mt-1 ${styles.btnLocateInMap} ${className ?? null}`}
				onClick={
					() => _setCenter(locationTo)
				}
				{...props}
			>
				<i className={`far fa-flag-checkered`} />
			</button>
		);
	}

	const PickupButton: React.FC<ButtonProps> = ({className, ...props}) => {
		return (
			<button
				className={`btn font-weight-normal mt-1 ${styles.btnLocateInMap} ${className ?? null}`}
				onClick={
					() => _setCenter(locationFrom)
				}
				{...props}
			>
				<i className={`far fa-directions`} />
			</button>
		);
	}

	const renderRequisition = () => (
		<>
			<DeliveryButton style={{right: 35}} />
			<PickupButton />
		</>
	);

	const renderButtonsOrder = (order: IOrder) => {
		switch (order.order_type) {
			case OrderTypeEnum.DELIVERY:
				return (
					<DeliveryButton />
				);

			case OrderTypeEnum.PICKUP:
				return (
					<PickupButton />
				)

			case OrderTypeEnum.REQUISITION:
				return renderRequisition();
		}
	}

	const CustomerButton :React.FC<ButtonProps> = ({className, ...props}) => {
		return (
			<button
				className={`btn font-weight-normal mt-1 ${styles.btnLocateInMap} ${className ?? null}`}
				onClick={
					(e) => {
						e.stopPropagation();
						_setCenter(location)
					}
				}
				{...props}
			>
				<i className={`far fa-directions`} />
			</button>
		);
	}

	return (
		<>
			{(isVisible && order !== undefined) && renderButtonsOrder(order)}
			{(isVisible && customer !== undefined) && <CustomerButton />}
		</>
	);
};

export default MapTargetButton;
