import React from 'react';

import * as constants from './constants';
import stylesLayout from '../Layout.module.scss';
import Layout from 'pages/Layout';
import Grid from 'components/Grid';
import AddressFormModal from 'pages/Addresses/AddressFormModal';
import { IAddress, initialState } from 'model/interfaces/IAddress';
import * as actions from 'pages/Addresses/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import { useAdminGrid } from 'model/hooks/useAdminGrid';
import { useTranslation } from 'react-i18next';

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const Addresses: React.FC<Props> = props => {

	const { t } = useTranslation();

	const {
		isFormOpened,
		openForm,
		closeForm,
		formInitialValues,
		editAction,
		deleteAction,
	} = useAdminGrid<IAddress>(
		initialState,
			t('pages.deleteSemitrailer'),
		actions.deleteAddress,
		actions.fetchAddresses,
	);

	const dispatch = useDispatch();

	const { addresses, hasMore, count, isLoading, error } = useSelector((state: ReduxState) => ({
		addresses: state.addresses.data?.results ?? [],
		hasMore: !!state.addresses.data?.next ?? false,
		count: state.addresses.data?.count ?? 0,
		isLoading: state.addresses.loading,
		error: state.addresses.error,
	}));

	return (
		<>
			<AddressFormModal
				isOpen={ isFormOpened }
				close={ closeForm }
				initialValues={ formInitialValues }
			/>
			<Layout className={stylesLayout.layoutSettings}>
				<section className={ stylesLayout.contentSettings }>
					<Grid<IAddress>
						data={ addresses }
						totalNoOfRows={ count }
						fetchMore={ () => dispatch(actions.fetchMoreAddresses()) }
						hasMore={ hasMore }
						columns={ constants.COLUMNS }
						rowSelectors={ constants.SELECTORS }
						rowActions={ [editAction, deleteAction] }
						cellRender={ constants.RENDER }
						loading={ isLoading }
						buttons={ () => (
							<>
								<button
									className="btn btn-primary"
									onClick={ openForm }
								>
									{t('pages.addAddressModal')}
								</button>
							</>
						) }
					/>
				</section>
			</Layout>
		</>
	);
};

export default Addresses as React.FC<BaseProps>;
