import React from 'react';

import styles from './Aside.module.scss';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import { OrderTypeEnum } from 'model/enums/OrderTypeEnum';
import dayjs from 'dayjs';
import { DATE_TIME_DISPLAY_FORMAT } from 'model/constants';
import { ContractTypeEnum, getContractType, ContractTypes } from 'model/enums/ContractTypeEnum';
import { IPlanningRoute } from 'model/interfaces/IRoute';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';
import {useTranslation} from "react-i18next";
import * as selectors from 'pages/Planning/store/selectors';

type BaseProps = {
	step: PlanningStepsEnum,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const vehiclesNumberClassName = (step: PlanningStepsEnum): string => {
	if (step === PlanningStepsEnum.ORDERS) {
		return styles.number;
	}
	if (step === PlanningStepsEnum.VEHICLES) {
		return styles.numberActive;
	}
	return styles.numberCompleted;
};

const parametersNumberClassName = (step: PlanningStepsEnum): string => {
	if ([PlanningStepsEnum.ORDERS, PlanningStepsEnum.VEHICLES].includes(step)) {
		return styles.number;
	}
	if ([PlanningStepsEnum.PARAMETERS, PlanningStepsEnum.ASSIGNED_ORDERS, PlanningStepsEnum.CLUSTERING, PlanningStepsEnum.SCHEDULING_CRITERIA].includes(step)) {
		return styles.numberActive;
	}
	return styles.numberCompleted;
};

const solutionNumberClassName =  (step: PlanningStepsEnum): string => {
	if (step === PlanningStepsEnum.SOLUTION) {
		return styles.numberActive;
	}
	if (step === PlanningStepsEnum.FINISHED) {
		return styles.numberCompleted;
	}
	return styles.number;
};


const Aside: React.FC < Props > = props => {
	const step = props.step;

	const { orders, plan, routes, assignedOrders } = useSelector((state: ReduxState) => ({
		orders: state.planning.orders.data?.results ?? [],
		routes: state.planning.routes,
		plan: state.planning.plan?.data,
		assignedOrders: selectors.getAssignedOrdersIds(state),
	}));

	const { t } = useTranslation();

	return (
		<>
			{ plan &&
				<>
					<dl className="row mb-1">
						<dt className="col-3">{t('pages.date')} </dt>
						<dd className="col-9 mb-0">{dayjs(plan.date).format(DATE_TIME_DISPLAY_FORMAT)}</dd>
					</dl>
					<dl className="row mb-1">
						<dt className="col-3">{t('pages.shift')} </dt>
						<dd className="col-9 mb-0">{plan.shift.name} ({plan.shift.time_start} - {plan.shift.time_end})</dd>
					</dl>
				</>
			}
			<ul className="list-unstyled mt-3">
				<li className={styles.liNotLast}>
					<div className={step === PlanningStepsEnum.ORDERS ? styles.numberActive : styles.numberCompleted}>
						{step === PlanningStepsEnum.ORDERS ? 1 : <i className="fa fa-check" />}
					</div>
					<h3>{t('pages.planning.orders')}</h3>
					<dl className="text-delivery row mb-0">
						<dt className="col">{t('pages.deliveries')}</dt>
						<dd className="col flex-grow-0 mb-0 font-weight-bold">
							{orders.filter(order => order.order_type === OrderTypeEnum.DELIVERY).length}
						</dd>
					</dl>
					<dl className="text-pickup row mb-0">
						<dt className="col">{t('pages.pickups')}</dt>
						<dd className="col flex-grow-0 mb-0 font-weight-bold">
							{orders.filter(order => order.order_type === OrderTypeEnum.PICKUP).length}
						</dd>
					</dl>
					<dl className="text-requisition row mb-0">
						<dt className="col">{t('pages.requisitions')}</dt>
						<dd className="col flex-grow-0 mb-0 font-weight-bold">
							{orders.filter(order => order.order_type === OrderTypeEnum.REQUISITION).length}
						</dd>
					</dl>
					<dl className="text-muted row mb-0">
						<dt className="col">{t('pages.total')}</dt>
						<dd className="col flex-grow-0 mb-0 font-weight-bold">{orders.length}</dd>
					</dl>
					<dl className="text-muted row mb-0">
						<dt className="col">{t('pages.assigned')}</dt>
						<dd className="col flex-grow-0 mb-0 font-weight-bold">
							{assignedOrders.length}
						</dd>
					</dl>
					<dl className="text-muted row mb-0">
						<dt className="col">{t('pages.unassigned')}</dt>
						<dd className="col flex-grow-0 mb-0 font-weight-bold">
							{orders.length - assignedOrders.length}
						</dd>
					</dl>
				</li>
				<li className={styles.liNotLast}>
					<div className={vehiclesNumberClassName(step)}>
						{vehiclesNumberClassName(step) !== styles.numberCompleted ? 2 : <i className="fa fa-check" />}
					</div>
					<h3>{t('pages.vehicles')}</h3>
					{ ContractTypes.map(type =>
						<dl className="text-muted row mb-0" key={type}>
							<dt className="col">{ getContractType(type) }</dt>
							<dd className="col flex-grow-0 mb-0 font-weight-bold">
								{ routes
									.filter(route => route.data?.enabled)
									.reduce((acc: number, route: IReduxFetchedState<IPlanningRoute>) =>
										route.data?.vehicle?.contract_type === type ? acc + 1 : acc
									, 0)
								}
							</dd>
						</dl>
					)}
				</li>
				<li className={styles.liNotLast}>
					<div className={parametersNumberClassName(step)}>
						{parametersNumberClassName(step) !== styles.numberCompleted ? 3 : <i className="fa fa-check" />}
					</div>
					<h3>{t('pages.parameters')}</h3>
					<dl className="text-muted row mb-0">
						<dt className="col">{t('pages.clustering')}</dt>
						<dd className="col flex-grow-0 mb-0 font-weight-bold">{t('pages.recommended')}</dd>
					</dl>
					<dl className="text-muted row mb-0">
						<dt className="col">{t('pages.schedulingCriteria')}</dt>
						<dd className="col flex-grow-0 mb-0 font-weight-bold">{t('pages.planning.default')}</dd>
					</dl>
				</li>
				<li className={styles.li}>
					<div className={solutionNumberClassName(step)}>
						{solutionNumberClassName(step) !== styles.numberCompleted ? 4 : <i className="fa fa-check" />}
					</div>
					<h3>{t('pages.solution')}</h3>
					<dl className="text-muted row mb-0">
						<dt className="col">{t('pages.totalTime')}</dt>
						<dd className="col flex-grow-0 mb-0 font-weight-bold">0:00</dd>
					</dl>
					<dl className="text-muted row mb-0">
						<dt className="col">{t('pages.totalDistance')}</dt>
						<dd className="col flex-grow-0 mb-0 font-weight-bold text-nowrap">0 km</dd>
					</dl>
				</li>
			</ul>
		</>
	);
};

export default Aside;