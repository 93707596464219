import { Field, FieldProps, Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';

import SemitrailerAsyncSelect from 'components/Form/SemitrailerAsyncSelect';
import DriverAsyncSelect from 'components/Form/DriverAsyncSelect';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/route/actions';
import { IRoute } from 'model/interfaces/IRoute';
import AddressAsnycSelect from 'components/Form/AddressAsyncSelect';
import TextInput from 'components/Form/TextInput';
import VehicleAsyncSelect from 'components/Form/VehicleAsyncSelect';
import { ReduxState } from 'store/reducer';
import i18n from '../../i18n';


const validationSchema = Yup.object({
	vehicle: Yup.object().nullable().required('pages.rerouting.vehicleRequired'),
	driver: Yup.object().nullable().required('pages.rerouting.driverRequired'),
	starting_point: Yup.object().nullable().required('pages.rerouting.startRequired'),
	ending_point: Yup.object().nullable().required('pages.rerouting.endRequired'),
	time_start: Yup.string().matches(/^\d\d:\d\d:\d\d$/, 'pages.rerouting.format00').required('pages.rerouting.timeStartRequired'),
	time_end: Yup.string().matches(/^\d\d:\d\d:\d\d$/, 'pages.rerouting.format00:00').required('pages.rerouting.timeEndRequired'),
});

type Props = {
	close: () => void
}

const EnableVehiclesForm: React.FC<Props> = (props) => {
	const dispatch = useDispatch();
	const { plan, enabledVehicles } = useSelector((state: ReduxState) => ({
		plan: state.plan.data?.shift,
		enabledVehicles: state.routes.data?.results.map(route => route.vehicle.id)
	}));

	const onSubmit = (values: Partial<IRoute>) => {
		dispatch(actions.createRoute(values));
		props.close();
	};

	return (
		<Formik
			initialValues={{
				vehicle: undefined,
				driver: undefined,
				starting_point: undefined,
				ending_point: undefined,
				time_start: plan?.time_start,
				time_end: plan?.time_end
			}}
			validationSchema={validationSchema}
			validate={(values) => {
				const errors: any = {};

				if (values.vehicle && enabledVehicles?.includes(values.vehicle.id)) {
					errors.vehicle = i18n.t('pages.rerouting.vehicleEnabledSelectDifferent');
				}
				
				return errors;
			}}
			onSubmit={onSubmit}
		>
			{formikBag => 
				<FormikForm>
					<Field name="vehicle">
						{(props: FieldProps) => <VehicleAsyncSelect label={i18n.t('pages.rerouting.vehicle')} {...props} />}
					</Field>
					<Field name="semitrailer">
						{(props: FieldProps) => <SemitrailerAsyncSelect label={i18n.t('pages.rerouting.semitrailer')} {...props} />}
					</Field>
					<Field name="driver">
						{(props: FieldProps) => <DriverAsyncSelect label={i18n.t('pages.rerouting.driver')} {...props} />}
					</Field>
					<Field name="starting_point">
						{(props: FieldProps) => <AddressAsnycSelect label={i18n.t('pages.rerouting.startPoint')} {...props} />}
					</Field>
					<Field name="ending_point">
						{(props: FieldProps) => <AddressAsnycSelect label={i18n.t('pages.rerouting.endPoint')} {...props} />}
					</Field>
					<div className="form-row">
						<div className="col">
							<Field name="time_start">
								{(props: FieldProps) => <TextInput label={i18n.t('pages.rerouting.wrkinHrs')} placeholder="00:00:00" {...props} />}
							</Field>
						</div>
						<div className="col">
							<Field name="time_end">
								{(props: FieldProps) => <TextInput label="&nbsp;" placeholder="00:00:00" {...props} />}
							</Field>
						</div>
					</div>
					<div className="text-right">
						<button
							className="btn btn-primary"
							type="submit"
							disabled={ formikBag.isSubmitting }
						>
							{i18n.t('pages.rerouting.enableVeh')}
						</button>
					</div>
				</FormikForm>
			}
		</Formik>
	);
}

export default EnableVehiclesForm;