import Map, { BaseProps as MapBaseProps } from 'components/Map';
import React from 'react';
import { ICustomer } from 'model/interfaces/ICustomer';
import { LngLatBounds } from 'mapbox-gl';
import { FitBounds } from 'react-mapbox-gl/lib/map';
import CustomerMapMarker from 'pages/Customers/CustomerMapMarker';
import { usePrevious } from 'model/hooks/usePrevious';
import { Coords } from 'model/types/Coords';
import { resolveDuplicateCoords } from 'pages/Orders/helpers';

type Props = {
	customers: ICustomer[],
};

const CustomersMap: React.FC<Props> = ({ customers }) => {
	const prevCustomers = usePrevious(customers);
	const mapProps: MapBaseProps = {};
	const usedCoords: Coords[] = [];

	if (prevCustomers && customers && prevCustomers.length === 0 && customers.length > 0) {
		const locations = customers.filter(customer => customer.addresses[0]?.location?.coordinates)
			.map(customer => customer.addresses[0].location!.coordinates);

		// https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
		// Pass the first coordinates in the LineString to `lngLatBounds` &
		// wrap each coordinate pair in `extend` to include them in the bounds
		// result. A variation of this technique could be applied to zooming
		// to the bounds of multiple Points or Polygon geomteries - it just
		// requires wrapping all the coordinates with the extend method.
		if (locations.length > 0) {
			mapProps.fitBounds = locations.reduce(function (bounds, coord) {
				return bounds.extend(coord);
			}, new LngLatBounds(locations[0], locations[0])).toArray() as FitBounds;
		}
	}

	return (
		<Map { ...mapProps }>
			{ customers.map((customer, index) => {
				const coordinates = customer.addresses[0]?.location?.coordinates;
				if (!coordinates) return undefined;

				return (
					<CustomerMapMarker
						customer={customer}
						coordinates={resolveDuplicateCoords(coordinates, usedCoords)}
						number={index + 1}
						key={index}
					/>
				);
			})}
		</Map>
	);

};

export default CustomersMap;