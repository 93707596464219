import { createReducer } from 'deox';

import * as actions from './actions';
import { IPlan } from 'model/interfaces/IPlan';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';

export interface IPlanState extends IReduxFetchedState<IPlan> { }

export default createReducer({}, handle => [
	handle(actions.fetchPlan, (state) => ({
		...state,
		loading: true,
	})),
	handle(actions.fetchPlanSuccess, (state, { payload }) => ({
		...state,
		error: undefined,
		data: { ...payload },
		loading: false,
	})),
	handle(actions.fetchPlanFail, (state, { payload }) => ({
		...state,
		data: undefined,
		error: { ...payload },
		loading: false,
	})),
]);
