import React from 'react';
import { FieldProps } from 'formik';

import BaseAsyncSelect from 'components/Form/BaseAsyncSelect';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { ISemitrailer } from 'model/interfaces/ISemitrailer';

type BaseProps = {} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const SemitrailerAsyncSelect: React.FC<Props> = props => (
	<BaseAsyncSelect<ISemitrailer>
		url={ UrlEnum.SEMITRAILER }
		getOptionLabel={ option => `${option.brand} ${option.plate}` }
		{ ...props }
	/>
);

export default SemitrailerAsyncSelect;
