import React from 'react';
import { Marker } from 'react-mapbox-gl';

import Map, { BaseProps as MapBaseProps } from 'components/Map';
import { usePrevious } from 'model/hooks/usePrevious';
import { LngLatBounds } from 'mapbox-gl';
import { FitBounds } from 'react-mapbox-gl/lib/map';
import { IOrder } from 'model/interfaces/IOrder';
import { getDefinedOrdersLocations } from 'model/helpers/orders';
import Markers from 'pages/Orders/Markers';

type Props = {
	orders?: IOrder[],
	plan?: any
};

export const OrdersMap: React.FC<Props> = ({ orders,plan }) => {
	const prevOrders = usePrevious(orders);

	const mapProps: MapBaseProps = {};

	if (!prevOrders && orders) {
		const locations = getDefinedOrdersLocations(orders);

		// https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
		// Pass the first coordinates in the LineString to `lngLatBounds` &
		// wrap each coordinate pair in `extend` to include them in the bounds
		// result. A variation of this technique could be applied to zooming
		// to the bounds of multiple Points or Polygon geomteries - it just
		// requires wrapping all the coordinates with the extend method.
		if (locations.length > 0) {
			mapProps.fitBounds = locations.reduce(function (bounds, coord) {
				return bounds.extend(coord);
			}, new LngLatBounds(locations[0], locations[0])).toArray() as FitBounds;
		}
	}

	return (
		<Map { ...mapProps }>
			<>
				{orders && <Markers orders={orders} plan={plan} /> }
			</>
		</Map>
	);
};

export default OrdersMap;