import { IRoutesState } from 'store/route/reducer';
import { IRoute } from 'model/interfaces/IRoute';
import { ReduxState } from 'store/reducer';
import { IRouteAssignmentsState } from 'pages/Rerouting/RerouteSelected';
import { RouteStatusEnum } from 'model/enums/RouteStatusEnum';

export const isLoading = (state: IRoutesState) => !! state.loading;

export const getRoutes = (state: IRoutesState) => state.data?.results;

export const getRoute = (state: IRoutesState, routePk: number): IRoute|undefined => {
	const routes = getRoutes(state);

	if (!routes) return undefined;

	return routes.find(route => route.id === routePk);
};

export const getAllSelectedRoutes = (state: ReduxState) =>
	state.routes.data?.results.filter((route, index) => state.routes.meta[index].selected) ?? [];

export const getInitialRouteAssignments = (state: ReduxState): IRouteAssignmentsState[] =>
	getAllSelectedRoutes(state).map(route => ({
		route,
		assignments: route?.assignments ?? [],
	}));

export const getRoutesInProgress = (state: ReduxState) =>
	state.routes.data?.results.filter(route => ![RouteStatusEnum.COMPLETED, RouteStatusEnum.CANCELLED].includes(route.status)) ?? [];