import React, { useEffect } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import TextInput from 'components/Form/TextInput';
import { IPackage } from 'model/interfaces/IPackage';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import * as packageActions from 'pages/Packages/store/actions';
import TextAreaInput from 'components/Form/TextAreaInput';
import validationSchema from './validationSchema';
import {useTranslation} from "react-i18next";

type BaseProps = {
	onSuccess: () => void,
	initialValues: IPackage,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const PackageForm: React.FC<Props> = props => {

	const dispatch = useDispatch();

	const createUpdateSuccess = useSelector<ReduxState>(state => state.packages.formSuccess);

	const onSubmit = async (values: IPackage) => {
		if (values.id) {
			dispatch(packageActions.updatePackage(values));
		} else {
			dispatch(packageActions.createPackage(values));
		}
	};

	useEffect(() => {
		if (createUpdateSuccess) {
			dispatch(packageActions.formPackageRestart());
			props.onSuccess();
		}
	}, [ createUpdateSuccess ]);

	const { t } = useTranslation();

	return (
		<Formik<IPackage>
			initialValues={ props.initialValues }
			onSubmit={ onSubmit }
			validationSchema={ validationSchema }
		>
			{ formikBag => (
				<Form>
					<div className="row">
						<div className="col">
							<Field
								name="name"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.namePackage')} { ...props } /> }
							</Field>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field
								name="description"
							>
								{ (props: FieldProps) => <TextAreaInput label={t('pages.descriptionPackage')} { ...props }/> }
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="weight"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.weightKg')} { ...props }/> }
							</Field>
						</div>
						<div className="col">
							<Field
								name="volume"
							>
								{ (props: FieldProps) => <TextInput label={<>{t('pages.volume')} (m<sup>3</sup>)</>} { ...props }/> }
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="loading_service_time"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.loadingServiceTime')} placeholder="00:00:00" { ...props }/> }
							</Field>
						</div>
						<div className="col">
							<Field
								name="unloading_service_time"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.unloadingServiceTime')} placeholder="00:00:00" { ...props }/> }
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="width"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.width')} { ...props }/> }
							</Field>
						</div>
						<div className="col">
							<Field
								name="height"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.height')} { ...props }/> }
							</Field>
						</div>
						<div className="col">
							<Field
								name="length"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.length')} { ...props }/> }
							</Field>
						</div>
					</div>
					<div className="text-right">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={ ! formikBag.isValid || formikBag.isSubmitting }
						>
							{t('pages.savePackage')}
						</button>
					</div>
				</Form>
			) }
		</Formik>
	);
};

export default PackageForm;
