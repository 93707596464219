import NewOrderModal from 'components/modals/NewOrder';
import Dropdown, { DropdownMenuProps } from 'components/Popover';
import styles from 'components/cards/index.module.scss';
import React from 'react';
import { IOrder } from 'model/interfaces/IOrder';
import { useDispatch } from 'react-redux';
import * as actions from 'pages/Orders/store/actions';
import { OrderFetchActionType } from 'model/types/OrderFetchAction';
import { IShift } from 'model/interfaces/IShift';
import {useTranslation} from "react-i18next";

type DropdownMenuCustomProps = {
	openModal: () => void,
	order: IOrder,
	showEdit: boolean,
	showDelete: boolean,
	fetchAction?: OrderFetchActionType,
};

const DropdownMenu: React.FC<DropdownMenuProps & DropdownMenuCustomProps> = ({ hide, openModal, showEdit, showDelete, order, ...props }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	return (
		<>
			{showEdit &&
			<button
				onClick={(e) => {
					hide(e);
					openModal();
				}}
				className="dropdown-item"
			>
				{t('components.cards.edit')}
			</button>
			}
			{showDelete &&
			<>
				<div className="dropdown-divider" />
				<button
					onClick={(e) => {
						hide(e);
						if (window.confirm(t('cards.deleteOrder'))) {
							dispatch(actions.deleteOrder(order, props.fetchAction));
						}
					}}
					className="dropdown-item text-danger"
				>
					{t('components.cards.delete')}
				</button>
			</>
			}
		</>
	);
};

type Props = {
	order: IOrder,
	shift: IShift,
	showEdit: boolean,
	showDelete: boolean,
	planId: number,
	fetchAction?: OrderFetchActionType,
};

const EditOrderButton: React.FC<Props> = ({ order, showEdit, showDelete, ...props }) => {

	const [ isFormOpened, setIsFormOpened ] = React.useState(false);

	const closeForm = () => setIsFormOpened(false);

	const { t } = useTranslation();

	return (
		<>
			<NewOrderModal
				isOpen={ isFormOpened }
				onRequestClose={ closeForm }
				title={t('cards.editOrder')}
				initialValues={ order }
				fetchAction={ props.fetchAction }
				planId={ props.planId }
				shift={ props.shift }
			/>

			<Dropdown
				menu={ ({hide}) => <DropdownMenu
					hide={ hide }
					order={ order }
					openModal={ () => setIsFormOpened(true) }
					showEdit={showEdit}
					showDelete={showDelete}
					fetchAction={ props.fetchAction }
				/> }
			>
				{ ({onClick}) => (
					<button
						className={ `btn mr-1 ${ styles.ellipsis }` }
						onClick={ onClick }
					>
						<i className="far fa-ellipsis-h" />
					</button>
				) }
			</Dropdown>
		</>
	);
}

export default EditOrderButton;