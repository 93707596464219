import { createReducer } from 'deox';

import * as actions from './actions';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';

export interface IAuthStateData {
	token?: string,
	id?: number,
}

export interface IAuthState extends IReduxFetchedState<IAuthStateData> {}

const initialState: IAuthState = {};

export default createReducer(initialState, handle => [
	handle(actions.login, (state, { payload }) => ({
		...state, loading: true
	})),
	handle(actions.loginSuccess, (state, { payload }) => ({
		...state, data: { ...payload }, loading: false,
	})),
	handle(actions.loginFail, (state, { payload }) => ({
		...state, data: undefined, error: { ...payload }, loading: false,
	})),
	handle(actions.destroy, () => ({ ...initialState, })),
]);
