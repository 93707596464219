import { createActionCreator } from 'deox';
import { IPlan } from 'model/interfaces/IPlan';
import { IApiError } from 'model/interfaces/IApiError';

export enum PlanActions {
	FETCH = '@PLAN_FETCH',
	FETCH_SUCCESS = '@PLAN_FETCH_SUCCESS',
	FETCH_FAIL = '@PLAN_FETCH_FAIL',

	EXPORT_UNEXECUTED_ORDERS = '@ORDER_EXPORT_UNEXECUTED_ORDERS',

	COST_MATRIX_CREATE = '@PLAN_COST_MATRIX_CREATE',
}

export const fetchPlan = createActionCreator(PlanActions.FETCH);

export const fetchPlanFail = createActionCreator(
	PlanActions.FETCH_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const fetchPlanSuccess = createActionCreator(
	PlanActions.FETCH_SUCCESS,
	resolve => (data: IPlan) => resolve(data),
);

export const exportUnexecutedOrders = createActionCreator(
	PlanActions.EXPORT_UNEXECUTED_ORDERS,
	resolve => (plan: IPlan) => resolve({ plan })
);

export const costMatrixCreate = createActionCreator(
	PlanActions.COST_MATRIX_CREATE,
	resolve => (planId: number) => resolve(planId)
);