import React from 'react';

import BaseModal, { BaseModalProps } from 'components/modals/BaseModal';
import Loader from 'components/Loader';
import { Api } from 'model/services/Api/Api';
import { IVehicle } from 'model/interfaces/IVehicle';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { ISemitrailer } from 'model/interfaces/ISemitrailer';
import SemitrailerForm from 'pages/Semitrailers/SemitrailerForm';
import {useTranslation} from "react-i18next";

type BaseProps = {
	isOpen: boolean,
	close: () => void,
	initialValues: ISemitrailer,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const SemitrailerFormModal: React.FC<Props> = props => {

	const onSuccess = () => {
		props.close();
	};

	const { t } = useTranslation();

	return (
		<BaseModal
			isOpen={ props.isOpen }
			onRequestClose={ props.close }
			title={t('pages.semitrailerForm')}
		>
			<SemitrailerForm
				onSuccess={ onSuccess }
				initialValues={ props.initialValues }
			/>
		</BaseModal>
	);
};

export default SemitrailerFormModal;
