import React, { useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik, FormikHelpers, useFormik } from 'formik';
import TextInput from 'components/Form/TextInput';
import { IDriver } from 'model/interfaces/IDriver';
// import DemonstratorAsyncSelect from 'components/Form/DemonstratorAsyncSelect';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import * as driverActions from 'pages/Drivers/store/actions';
import AddressFormComponent from 'components/Form/AddressFormComponent';
import basicValidationSchema from 'pages/Drivers/DriverForm/validationSchema';
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";

type BaseProps = {
	onSuccess: () => void,
	initialValues: IDriver,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const DriverForm: React.FC<Props> = props => {

	const dispatch = useDispatch();

	const [ isSubmitDisabled, setSubmitDisabled ] = useState(false);

	const [ formSuccess, formFail, formLoading ] = useSelector((state: ReduxState) => ([
		state.drivers.formSuccess,
		state.drivers.formError,
		state.drivers.formLoading
	]));

	const onSubmit = async (values: IDriver) => {
		if (values.id) {
			dispatch(driverActions.updateDriver(values));
		} else {
			dispatch(driverActions.createDriver(values));
		}
	};

	useEffect(() => {
		if (formSuccess) {
			dispatch(driverActions.formDriverRestart());
			props.onSuccess();
		}
	}, [ formSuccess ]);

	useEffect(() => {
		if (formLoading) {
			setSubmitDisabled(true);
		}
	}, [ formLoading ]);

	useEffect(() => {
		if (formFail) {
			setSubmitDisabled(false);
		}
	}, [ formFail ]);

	const { t } = useTranslation();

	const validationSchema = props.initialValues.id === 0
		? basicValidationSchema.shape({
			password: Yup.string().required(t('pages.passwordRequired')),
		})
		: basicValidationSchema;

	return (
		<Formik<IDriver>
			initialValues={ props.initialValues }
			onSubmit={ onSubmit }
			validationSchema={ validationSchema }
		>
			{ formikBag => (
				<Form>
					<div className="form-row">
						<div className="col">
							<Field
								name="username"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.usernameDriver')} { ...props } /> }
							</Field>
						</div>
						{formikBag.values.id === 0 &&
						<div className="col">
							<Field
								name="password"
							>
								{(props: FieldProps) => <TextInput label={t('pages.passwordDriver')} type="password" {...props} />}
							</Field>
						</div>
						}
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="first_name"
							>
								{
									(props: FieldProps) => <TextInput label={t('pages.firstName')} { ...props }/>
								}
							</Field>
						</div>
						<div className="col">
							<Field
								name="last_name"
							>
								{
									(props: FieldProps) => <TextInput label={t('pages.lastName')} { ...props }/>
								}
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="email"
							>
								{
									(props: FieldProps) => <TextInput label={t('pages.email')} { ...props }/>
								}
							</Field>
						</div>
						<div className="col">
							<Field
								name="telephone"
							>
								{
									(props: FieldProps) => <TextInput label={t('pages.phone')} { ...props }/>
								}
							</Field>
						</div>
					</div>

					<AddressFormComponent name="address" />

					<div className="text-right">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={ ! formikBag.isValid || formikBag.isSubmitting || isSubmitDisabled }
						>
							{t('pages.save')}
						</button>
					</div>
				</Form>
			) }
		</Formik>
	);
};

export default DriverForm;
