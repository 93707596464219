import React, { useEffect } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import TextInput from 'components/Form/TextInput';
import { IMaterialTag } from 'model/interfaces/IMaterialTag';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import * as materialTagActions from 'pages/MaterialTags/store/actions';
import TextAreaInput from 'components/Form/TextAreaInput';
import {useTranslation} from "react-i18next";

type BaseProps = {
	onSuccess: () => void,
	initialValues: IMaterialTag,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const MaterialTagForm: React.FC<Props> = props => {

	const dispatch = useDispatch();

	const createUpdateSuccess = useSelector<ReduxState>(state => state.materialTags.formSuccess);

	const onSubmit = async (values: IMaterialTag) => {
		if (values.id) {
			dispatch(materialTagActions.updateMaterialTag(values));
		} else {
			dispatch(materialTagActions.createMaterialTag(values));
		}
	};

	useEffect(() => {
		if (createUpdateSuccess) {
			dispatch(materialTagActions.formMaterialTagRestart());
			props.onSuccess();
		}
	}, [ createUpdateSuccess ]);

	const { t } = useTranslation();

	return (
		<Formik<IMaterialTag>
			initialValues={ props.initialValues }
			onSubmit={ onSubmit }
		>
			{ formikBag => (
				<Form>
					<div className="row">
						<div className="col">
							<Field
								name="name"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.nameMaterial')} { ...props } /> }
							</Field>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field
								name="description"
							>
								{ (props: FieldProps) => <TextAreaInput label={t('pages.descriptionMaterial')} { ...props }/> }
							</Field>
						</div>
					</div>
					<div className="text-right">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={ ! formikBag.isValid || formikBag.isSubmitting }
						>
							{t('pages.saveMaterial')}
						</button>
					</div>
				</Form>
			) }
		</Formik>
	);
};

export default MaterialTagForm;
