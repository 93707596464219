import { all, call, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import queryString from 'query-string';

import * as actions from './actions';
import { apiFactory } from 'model/services/Api/Api';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { delay } from 'model/helpers/api';
import { POLLING_RATE } from 'model/constants';
import { ActionType } from 'deox';
import { IRoutesResponse } from 'model/services/Api/interfaces/responses/IRoutesResponse';
import { ReduxState } from 'store/reducer';
import { RouteStatusEnum } from 'model/enums/RouteStatusEnum';
import { IRoute } from 'model/interfaces/IRoute';
import { AxiosResponse } from 'axios';
import { IRouteAssignmentsState } from 'pages/Rerouting/RerouteSelected';
import { IRouteRequest } from 'model/services/Api/interfaces/requests/IRouteRequest';
import { isoDayFromUTCDatetime } from 'model/helpers/datetime';
import i18n from '../../i18n';

function * fetchRoutes() {
	const state: ReduxState = yield select();
	const api = apiFactory(state);
	const shift = state.shift.id;
	const executionDate = isoDayFromUTCDatetime(state.executionDate);

	try {
		const response: IRoutesResponse = yield call(api.getRequest, UrlEnum.ROUTE, {
			params: getRouteParams(executionDate, shift),
			paramsSerializer: params => queryString.stringify(params),
		});
		yield put(actions.fetchRoutesSuccess(response.data));
	} catch (err) {
		yield put(actions.fetchRoutesFail(err));
		yield put(actions.stopPolling());
	}
}

function * fetchRoute(action: ActionType<typeof actions.fetchRoute>) {
	const state: ReduxState = yield select();
	const api = apiFactory(state);
	const routeId = action.payload

	try {
		const response: AxiosResponse<IRoute> = yield call(api.getRequest, `${UrlEnum.ROUTE}${routeId}`);
		yield put(actions.fetchRouteSuccess(response.data));
	} catch (err) {
		yield put(actions.fetchRouteFail(err));
	}
}

function * pollRoutes() {
	while (true) {
		const state: ReduxState = yield select();
		const api = apiFactory(state);
		const shift = state.shift.id;
		const executionDate = isoDayFromUTCDatetime(state.executionDate);

		try {
			const response: IRoutesResponse = yield call(api.getRequest, UrlEnum.ROUTE, {
				params: getRouteParams(executionDate, shift),
			});
			yield put(actions.fetchRoutesSuccess(response.data));
			yield call(delay, POLLING_RATE);
		} catch (err) {
			yield put(actions.fetchRoutesFail(err));
			yield put(actions.stopPolling());
		}
	}
}

export function getRouteParams(executionDate: string, shift?: number): IRouteRequest {
	return {
		plan_date: executionDate,
		plan_shift: shift?.toString() ?? undefined,
		status: [RouteStatusEnum.SCHEDULED, RouteStatusEnum.IN_PROGRESS, RouteStatusEnum.MODIFIED, RouteStatusEnum.CANCELLED, RouteStatusEnum.COMPLETED, RouteStatusEnum.UNDER_MODIFICATION, RouteStatusEnum.SCHEDULED_MODIFIED],
	};
}

function * recalculate(action: ActionType<typeof actions.recalculate>) {
	yield commonRecalculate(action.payload, RouteStatusEnum.UNDER_MODIFICATION);
}

function * cancelRecalculate(action: ActionType<typeof actions.cancelRecalculate>) {
	yield commonRecalculate(action.payload);
	// George doesn't want to redirect back
	// history.push(RoutesEnum.REROUTING);
	yield put(actions.setReroutingCancel(true));
}

function * saveRecalculate(action: ActionType<typeof actions.saveRecalculate>) {
	yield commonRecalculate(action.payload, undefined, getNewStatusForSave);
	// George doesn't want to redirect back
	// history.push(RoutesEnum.REROUTING);
}

function * commonRecalculate(
	routeAssignments: IRouteAssignmentsState[],
	status?: RouteStatusEnum,
	getNewStatus?: (prevStatus: RouteStatusEnum) => RouteStatusEnum
) {
	const state: ReduxState = yield select();
	const api = apiFactory(state);

	// We do this basically only to switch flag loading to true
	yield put(actions.setLoading());

	for (const routeAssignment of routeAssignments) {
		// Skip first column, ie. unassigned orders
		if (!routeAssignment.route) continue;

		const index = state.routes.data?.results.findIndex((routeItem) =>
			routeItem.id === routeAssignment.route?.id
		);

		if (typeof index === 'undefined') continue;

		// - If status is defined, we use it
		// - If status is not defined and getNewStatus is defined, we use the function getNewStatus to get a new status
		// 		from the previou status (for saving)
		// - If status is not defined and getNewStatus is also not defined, we use directly the previous status
		// 		(for cancelling)

		// Only for new state (clean local storage) when we don't know the previous state
		const prevStatus = state.routes.meta[index].prevStatus || RouteStatusEnum.SCHEDULED;
		const payload: any = {
			status: typeof status !== 'undefined'
				? status
				:
					( getNewStatus
						? getNewStatus(prevStatus)
						: prevStatus
					),
		};

		if (status === RouteStatusEnum.UNDER_MODIFICATION) {
			payload.assignments = routeAssignment.assignments.map(assignment => ({ order: assignment.order!.id }));
			if (routeAssignment.route.status !== RouteStatusEnum.UNDER_MODIFICATION) {
				// We store only in case Recalculate button is clicked for the first time. Without it,
				// we would store in previous status UNDER_MODIFICATION status.
				yield put(actions.setPrevStatus(routeAssignment.route.status, index));
			}
		}

		try {
			const urlSuffix = status === RouteStatusEnum.UNDER_MODIFICATION ? 'assignments/' : '';

			yield call(
				api.patchRequest,
				`${UrlEnum.ROUTE}${routeAssignment.route.id}/${urlSuffix}`,
				payload
			);

		} catch (err) {
			toast.error(err.message);
		}
	}

	// We take all relevant route IDs...
	const routeIds = routeAssignments
		.map(routeAssignment => routeAssignment.route?.id)
		.filter((routeId): routeId is number => typeof routeId === 'number');

	// ...if we end up with zero routes (for some unknown reason) or with more
	// than 3 routes, we fetch all the routes...
	if (routeIds.length === 0 || routeIds.length > 3) {
		yield put(actions.fetchRoutes());

	// ...otherwise, we fetch only those relevant routes
	} else {
		yield all(
			routeIds.map(routeId =>
				put(actions.fetchRoute(routeId))
			)
		)
	}
}

function getNewStatusForSave(prevStatus: RouteStatusEnum): RouteStatusEnum  {
	switch (prevStatus) {
		case RouteStatusEnum.SCHEDULED:
			return RouteStatusEnum.SCHEDULED_MODIFIED;
		case RouteStatusEnum.IN_PROGRESS:
			return RouteStatusEnum.MODIFIED;
		default:
			return prevStatus;
	}
}

function * createRoute(action: ActionType<typeof actions.createRoute>) {
	const state: ReduxState = yield select();
	const api = apiFactory(state);
	const route = action.payload

	const payload: any = {
		plan: state.plan.data?.id,
		demonstrator: state.loggedInUser.data!.demonstrator,
		assignments: [],
		status: RouteStatusEnum.SCHEDULED,
		vehicle: route.vehicle?.id,
		driver: route.driver?.id,
		starting_point: route.starting_point?.id,
		ending_point: route.ending_point?.id,
		shift: route.shift?.id,
		time_start: `${state.plan.data?.date}T${route.time_start}Z`,
		time_end: `${state.plan.data?.date}T${route.time_end}Z`
	}

	if (route.semitrailer) {
		payload.semitrailer = route.semitrailer.id;
	}

	try {
		yield call(api.postRequest, UrlEnum.ROUTE, payload)
		yield put(actions.createRouteSuccess())
		yield put(actions.fetchRoutes())
		toast.success(i18n.t('store.vehicleEnabled'))

	} catch (err) {
		yield put(actions.createRouteFail(err));
	}
}

function * watchFetch() {
	yield takeLatest(actions.fetchRoutes, fetchRoutes);
}

function * watchFetchRoute() {
	yield takeEvery(actions.fetchRoute, fetchRoute);
}

function * watchPolling() {
	while (true) {
		yield take(actions.startPolling);
		yield race([call(pollRoutes), take(actions.stopPolling)]);
	}
}

function * watchRecalulcate() {
	yield takeLatest(actions.recalculate, recalculate);
}

function * watchCancelRecalulcate() {
	yield takeLatest(actions.cancelRecalculate, cancelRecalculate);
}

function * watchSaveRecalulcate() {
	yield takeLatest(actions.saveRecalculate, saveRecalculate);
}

function * watchCreateRoute() {
	yield takeLatest(actions.createRoute, createRoute);
}

export const planSagas: Generator[] = [
	watchFetch(),
	watchFetchRoute(),
	watchPolling(),
	watchRecalulcate(),
	watchCancelRecalulcate(),
	watchSaveRecalulcate(),
	watchCreateRoute(),
];

export default planSagas;
