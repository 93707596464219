import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as actions from 'pages/Planning/store/actions';
import stylesLayout from 'pages/Layout.module.scss';
import Layout from 'pages/Layout';
import { RoutesEnum } from 'model/enums/RoutesEnum';
import {useTranslation} from "react-i18next";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const Finished: React.FC < Props > = props => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { t } = useTranslation();

	return (
		<Layout className={ stylesLayout.layoutPlanning }>
			{props.children}
			<div className="d-flex align-items-center justify-content-center text-center">
				<div>
					<i className="fa fa-check-circle text-primary fa-9x" />
					<br />
					<h1 className="text-muted font-weight-normal py-5 mb-5">{t('pages.planCreated')}</h1>
					<button
						className="btn btn-primary mt-5"
						onClick={() => {
							dispatch(actions.setInitialState());
							history.push(RoutesEnum.HOMEPAGE);
						}}
					>
						{t('pages.goToVnR')}
					</button>
					<button
						className="btn btn-outline-primary mt-5 ml-3"
						onClick={() => dispatch(actions.setInitialState())}
					>
						{t('pages.startNew')}
					</button>
				</div>
			</div>
		</Layout>
	);
};

export default Finished;