import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import * as orderSelectors from 'pages/Orders/store/selectors';
import { usePrevious } from 'model/hooks/usePrevious';
import { useEffect, useState } from 'react';
import { RouteStatusEnum } from 'model/enums/RouteStatusEnum';
import { DropResult } from 'react-beautiful-dnd';
import { move, reorder } from 'pages/Planning/AssignedOrders/utils';
import { IRouteAssignmentsState } from 'pages/Rerouting/RerouteSelected';
import { IAssignment } from 'model/interfaces/IAssignment';
import { OrderStatusEnum } from 'model/enums/OrderStatusEnum';
import * as actions from 'store/route/actions';

export const useRerouting = (initialRouteAssignments: IRouteAssignmentsState[]) => {
	const { orders, routes, meta, isReroutingCancel } = useSelector((state: ReduxState) => ({
		orders: state.orders,
		routes: state.routes.data?.results ?? [],
		meta: state.routes.meta,
		isReroutingCancel: state.routes.isReroutingCancel,
	}));

	const dispatch = useDispatch();

	const assignments = orderSelectors.getUnassignedAssignments(orders);

	const prevRoutes = usePrevious(routes);

	const [ isPristine, setPristine ] = useState(true);
	const [ routeAssignments, setRouteAssignments ] = useState<IRouteAssignmentsState[]>([{ assignments }].concat(initialRouteAssignments));

	useEffect(() => {
		setRouteAssignments([{ assignments }].concat(initialRouteAssignments));

	}, [orders])

	// Update partial assignment with additional fields
	useEffect(() => {
		const newRouteAssignments = routeAssignments.slice();

		// Cycling through columns
		for (const routeIndex in newRouteAssignments) {
			const route = newRouteAssignments[routeIndex];

			// If "Cancel" clicked (changing status from UNDER_MODIFICATION to DRAFT), restore all routes from store
			if (route.route) {
				const storeRoute = routes.find(routeItem => routeItem.id === route.route?.id);
				const prevStoreRoute = prevRoutes?.find(routeItem => routeItem.id === route.route?.id);
				const index = routes.findIndex(routeItem => routeItem.id === route.route?.id);

				if (prevStoreRoute?.status === RouteStatusEnum.UNDER_MODIFICATION && storeRoute?.status === meta[index].prevStatus) {
					newRouteAssignments[routeIndex].assignments = storeRoute.assignments ?? [];

					if (parseInt(routeIndex, 10) === 1 && isReroutingCancel) {
						// With first route we need to restore also all orders
						newRouteAssignments[0].assignments = assignments;
					}

					// I'm not sure why I put it here, I think it shouldn't be here
					// continue;
				}

				// Update route info
				newRouteAssignments[routeIndex].route = storeRoute;
			}

			// Cycling through assignments in a column
			for (const assignmentIndex in route.assignments) {
				if (!route.assignments.hasOwnProperty(assignmentIndex)) continue;
				const assignment = route.assignments[assignmentIndex];

				if (!route.route) {
					// First column - list of orders: remove all information but order
					if (newRouteAssignments[routeIndex].assignments[assignmentIndex].sequence_number) {
						newRouteAssignments[routeIndex].assignments[assignmentIndex] = {
							order: newRouteAssignments[routeIndex].assignments[assignmentIndex].order
						};
					}

				} else {
					// Vehicles/routes: Update assignment information
					const id = assignment.order!.id;

					const updatedAssignment = initialRouteAssignments
						.flatMap(routeAssignment => routeAssignment.assignments)
						.find(assignment => assignment.order!.id === id);

					if (updatedAssignment && updatedAssignment.sequence_number !== assignment.sequence_number) {
						newRouteAssignments[routeIndex].assignments[assignmentIndex] = updatedAssignment;
					}
				}
			}
		}

		setRouteAssignments(newRouteAssignments);

		dispatch(actions.setReroutingCancel(false));

	}, [routes]);

	const onDragEnd = ({ source, destination }: DropResult) => {
		// dropped outside the list
		if (!destination) {
			return;
		}

		const sInd = +source.droppableId;
		const dInd = +destination.droppableId;

		if (sInd === dInd) {
			const newOrders = reorder(routeAssignments[sInd].assignments, source.index, destination.index);

			if (!isOrderValid(newOrders)) return;

			const newRouteOrders = [ ...routeAssignments ];
			newRouteOrders[sInd].assignments = newOrders;
			setRouteAssignments(newRouteOrders);

		} else {
			const result = move(routeAssignments[sInd].assignments, routeAssignments[dInd].assignments, source, destination);

			for (const column of Object.values(result)) {
				if (!isOrderValid(column)) return;
			}

			const newRouteOrders = [ ...routeAssignments ];
			newRouteOrders[sInd].assignments = result[sInd];
			newRouteOrders[dInd].assignments = result[dInd];
			setRouteAssignments(newRouteOrders);
		}

		setPristine(false);
	};

	return { routeAssignments, onDragEnd, isPristine, setPristine };

};

/**
 * We must check if the order of assignments is correct, ie. we cannot have non-finished tasks before finished ones.
 * @param assignments
 */
export const isOrderValid = (assignments: Partial<IAssignment>[]) => {
	let pendingAssignments = false;

	for (const assignment of assignments) {
		if ([OrderStatusEnum.COMPLETED, OrderStatusEnum.CANCELLED, OrderStatusEnum.IN_PROGRESS].includes(assignment.order!.status.status)) {
			if (pendingAssignments) return false;

		} else {
			pendingAssignments = true;
		}
	}

	return true;
};