import { ITimeWindow } from 'model/interfaces/ITimeWindow';

const initialState: ITimeWindow = {
	id: 0,
	start_time: '',
	end_time: '',
	demonstrator: 0,
	days: [],
};

export default initialState;
