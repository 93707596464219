import React, {Dispatch, SetStateAction, useState, useEffect, useContext} from 'react';
import ReactMapboxGl from "react-mapbox-gl";
import { FitBounds } from 'react-mapbox-gl/lib/map';
import * as actions from 'pages/Drivers/store/actions';

import styles from './index.module.scss';
import {MapContext} from "../../contexts/MapContext";

export type BaseProps = {
	fitBounds?: FitBounds,
	center?: [number, number],
	setCenter?: (center: [number, number]) => void,
};

type InjectedProps = {
	children?: JSX.Element | JSX.Element[] | Array<JSX.Element | undefined>,
};

type Props = BaseProps & InjectedProps;

const MapComponent = ReactMapboxGl({
	accessToken: 'pk.eyJ1IjoiaWtvdXRzYWYiLCJhIjoiY2tldnNnc2U0M2FscDJ5bXFoMDkzZmNsMiJ9.nfIm3NyplBsaOHDazm7cNA'
});

const cedaStyleToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlLZXkiOiJieFV2a25VdWVwOWNNVEZxeVJVTnAxTGVma282R0xQWDVFMnlIZDdBIiwiaWF0IjoxNjc3NTgzMjc5fQ.ij_Sspl97pgQ89xOhuDDhPmjy36k2KwgwAwfO-fISgk';

const Map: React.FC<Props> = (props) => {

	const { center, setCenter, zoom, setZoom, setIsVisible } = useContext(MapContext);

	useEffect(() => {
		setIsVisible(true);
		return () => {
			setIsVisible(false);
		}
	}, [])

	return (
		<MapComponent
			center={center}
			zoom={zoom}
			className={styles.map}
			style={"https://api.ceda.cz/vmt/styles/eur-basic-2211/style-xyz?token=" + cedaStyleToken}
			containerStyle={{
				'fontFamily': 'Open Sans, sans-serif',
			}}
			fitBounds={props.fitBounds}
			fitBoundsOptions={{
				padding: {
					top: 130,
					bottom: 70,
					left: 450,
					right: 70,
				},
				maxZoom: 14,
			}}
			onZoom={ (map, event) => {
				setZoom([map.getZoom()]);
			}}
			onMove={ (map, event) => {
				let cords = map.getCenter().toArray() as [number, number];
				setCenter(cords);
				if (props.setCenter !== undefined)
				{
					props.setCenter(cords);
				}
			}}
		>
			{props.children}
		</MapComponent>
	);
};

export default Map;