import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './Footer.module.scss';
import * as actions from 'pages/Planning/store/actions';
import {useTranslation} from "react-i18next";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const Footer: React.FC <Props> = props => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const cancelPlanning = () => {
		if (!window.confirm(t('pages.cancelPlanningQ'))) return;

		dispatch(actions.cancelPlan());
	};

	return (
		<div className={styles.footer}>
			<div className={`panel bg-white h-100 px-3 ${styles.footerPanel}`}>
				<div className="row h-100 align-items-center">
					<div className="col flex-grow-0 text-nowrap">
						<button
							className="btn btn-link"
							onClick={ cancelPlanning }
						>
							{t('pages.cancelPlanning')}
						</button>
					</div>
					<div className="col flex-grow-1 text-right">
						{props.children}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;