import { createActionCreator } from 'deox';
import { IApiError } from 'model/interfaces/IApiError';
import { ITimeWindow } from 'model/interfaces/ITimeWindow';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';

export enum TimeWindowActionsEnum {
	FETCH = '@TimeWindowS_FETCH',
	FETCH_SUCCESS = '@TimeWindowS_FETCH_SUCCESS',
	FETCH_FAIL = '@TimeWindowS_FETCH_FAIL',

	CREATE = '@TimeWindowS_CREATE',
	UPDATE = '@TimeWindowS_UPDATE',
	FORM_SUCCESS = '@TimeWindowS_FORM_SUCCESS',
	FORM_FAIL = '@TimeWindowS_FORM_FAIL',
	FORM_RESTART = '@TimeWindowS_FORM_RESTART',

	DELETE = '@TimeWindowS_DELETE',
	DELETE_SUCCESS = '@TimeWindowS_DELETE_SUCCESS',
	DELETE_FAIL = '@TimeWindowS_DELETE_FAIL',
}

export const fetchTimeWindows = createActionCreator(TimeWindowActionsEnum.FETCH);

export const fetchTimeWindowsFail = createActionCreator(
	TimeWindowActionsEnum.FETCH_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const fetchTimeWindowsSuccess = createActionCreator(
	TimeWindowActionsEnum.FETCH_SUCCESS,
	resolve => (data: IPaginatedData<ITimeWindow>) => resolve(data),
);

export const createTimeWindow = createActionCreator(
	TimeWindowActionsEnum.CREATE,
	resolve => (timeWindow: ITimeWindow) => resolve(timeWindow),
);

export const updateTimeWindow = createActionCreator(
	TimeWindowActionsEnum.UPDATE,
	resolve => (timeWindow: ITimeWindow) => resolve(timeWindow),
);

export const formTimeWindowSuccess = createActionCreator(
	TimeWindowActionsEnum.FORM_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success));

export const formTimeWindowFail = createActionCreator(
	TimeWindowActionsEnum.FORM_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const formTimeWindowRestart = createActionCreator(TimeWindowActionsEnum.FORM_RESTART);

export const deleteTimeWindow = createActionCreator(
	TimeWindowActionsEnum.DELETE,
	resolve => (timeWindow: ITimeWindow) => resolve(timeWindow),
);

export const deleteTimeWindowSuccess = createActionCreator(
	TimeWindowActionsEnum.DELETE_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success)
);

export const deleteTimeWindowFail = createActionCreator(
	TimeWindowActionsEnum.DELETE_FAIL,
	resolve => (error: IReduxActionState & IApiError) => resolve(error),
);
