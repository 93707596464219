import { IGridCellRender } from 'model/interfaces/grid/IGridCellRender';
import React from 'react';
import { IAddress } from 'model/interfaces/IAddress';
import {AddressTypePairs} from 'model/enums/AddressTypeEnum';
import {VerificationTypePairs} from 'model/enums/VerificationTypeEnum';
import {OwnerTypePairs} from 'model/enums/OwnerTypeEnum';
import {returnEnumPairsValue} from "../../model/helpers/enums";

export const COLUMNS: string[] = [
	'pages.addresses.streetNo', 'pages.addresses.city', 'pages.addresses.postal', 'pages.addresses.address', 'pages.addresses.verification', 'pages.addresses.owner', '',
];

export const SELECTORS: string[] = [
	'address', 'city', 'postal_code', 'address_type', 'verification_type', 'content_type'
];

export const RENDER: IGridCellRender<IAddress> = {
	'address_type': (row: IAddress) => {
		return <td>{returnEnumPairsValue(AddressTypePairs, row.address_type)}</td>;
	},
	'verification_type': (row: IAddress) => {
		return <td>{ returnEnumPairsValue(VerificationTypePairs, row.verification_type) }</td>;
	},
	'content_type': (row: IAddress) => {
		return <td>{ returnEnumPairsValue(OwnerTypePairs, row.content_type) }</td>;
	}
};