import { ReduxState } from 'store/reducer';
import { IRoutePartialAssignmentsState } from 'pages/Planning/AssignedOrders';
import { IPlanningRoute, IRoute } from 'model/interfaces/IRoute';
import { IAssignment } from 'model/interfaces/IAssignment';
import { IOrder } from 'model/interfaces/IOrder';

export const getAssignedOrdersIds = (state: ReduxState) =>
	state.planning.routes
		.filter(route => route.data!.enabled)
		.map(route => route.data!.assignments!)
		.flatMap(assignments => assignments === undefined ? [] : assignments.map(assignment => assignment.order.id));

export const getUnassignedAssignments = (state: ReduxState): Array<Partial<IAssignment>> =>
	state.planning.orders.data?.results
		.filter(order => !getAssignedOrdersIds(state).includes(order.id))
		.map(order => ({ order })) ?? [];

export const getOrderIdToAssignmentIdMapping = (state: ReduxState) => {
	const mapping: { [key: string]: number } = {};

	for (const route of state.planning.routes) {
		if (!route.data?.assignments) continue;

		for (const assignment of route.data.assignments) {
			mapping[assignment.order.id] = assignment.id;
		}
	}

	return mapping;
};

export const getAllEnabledVehicles = (state: ReduxState) =>
	state.planning.routes.map(route => route.data).filter(route => route?.enabled) ?? [];

export const getAllEnabledAndSelectedVehicles = (state: ReduxState) =>
	getAllEnabledVehicles(state).filter(route => route?.selected);

// export const getInitialRouteOrders = (state: ReduxState): IRoutePartialAssignmentsState[] =>
// 	getAllEnabledAndSelectedVehicles(state).map(route => ({
// 		route,
// 		assignments: route?.assignments?.map(assignment => assignment.order) ?? [],
// 	}));

export const getInitialRouteAssignments = (state: ReduxState): IRoutePartialAssignmentsState[] =>
	getAllEnabledAndSelectedVehicles(state).map(route => ({
		route,
		assignments: route?.assignments ?? [],
	}));

export const getRouteAssignments = (state: ReduxState): IRoutePartialAssignmentsState[] =>
	getAllEnabledVehicles(state).map(route => ({
		route,
		assignments: route?.assignments ?? [],
	}));

export const getRoute = (state: ReduxState) =>
	state.planning.routes.map(route => route.data).find(route => route!.id === state.planning.rerouteRouteId);