import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';

import Footer from 'pages/Planning/AssignedOrders/Footer';
import styles from 'pages/Planning/AssignedOrders/index.module.scss';
import { IRoute } from 'model/interfaces/IRoute';
import stylesLayout from 'pages/Layout.module.scss';
import Layout from 'pages/Layout';
import Droppable from 'pages/Planning/AssignedOrders/Droppable';
import * as actions from 'pages/Planning/store/actions';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import VehicleRouteCard from 'components/VehicleRouteCard';
import { usePlanningRerouting } from 'model/hooks/usePlanningRerouting';
import ListOfOrders from 'pages/Planning/AssignedOrders/ListOfOrders';
import { ReduxState } from 'store/reducer';
import * as selectors from 'pages/Planning/store/selectors';

type Props = {};

const RerouteSelected: React.FC<Props> = (props) => {
	const { initialRouteAssignments, plan } = useSelector((state: ReduxState) => ({
		initialRouteAssignments: selectors.getInitialRouteAssignments(state),
		plan: state.planning.plan.data!,
	}));

	const dispatch = useDispatch();

	const { routeAssignments, onDragEnd, isPristine, setPristine } = usePlanningRerouting(initialRouteAssignments);

	return (
		<DragDropContext onDragEnd={ onDragEnd }>
			<Layout className={ stylesLayout.layoutPlanningAssignedOrders }>
				{props.children}

				<ListOfOrders
					assignments={routeAssignments[0].assignments}
					plan={plan}
				/>

				<div className={`${styles.vehicles} d-flex align-items-start`}>
					{ routeAssignments.slice(1).map((route, routeIndex) => (
						<div className={`${styles.vehicle} panel mr-3 flex-shrink-0 pb-1`} key={routeIndex}>
							<div className="panel-header py-2 text-right">
								<button
									className="btn btn-sm btn-outline-primary"
									onClick={() => {
										dispatch(actions.setRerouteRouteId(route.route!.id!));
										dispatch(actions.setStep(PlanningStepsEnum.REROUTE_SINGLE));
									}}
								>
									<i className="fa fa-map-marked-alt mr-2" />Map
								</button>
							</div>

							<div className="overflow-auto flex-grow-1">
								<div className="panel-body">
									<VehicleRouteCard route={ route.route as IRoute }/>

									{/*<div className="py-1">*/}
									{/*	<OrderBadges*/}
									{/*		orders={route.assignments.map(assignment => assignment.order!)}*/}
									{/*		hide={[OrderTypeBadgeEnum.UNASSIGNED, OrderTypeBadgeEnum.COMPLETED, OrderTypeBadgeEnum.CANCELLED]}*/}
									{/*	/>*/}
									{/*</div>*/}
								</div>

								<Droppable droppableId={`${routeIndex+1}`} assignments={route.assignments} />
							</div>
						</div>
					))}
				</div>

				<Footer
					back={PlanningStepsEnum.SOLUTION}
					isPristine={isPristine}
					setPristine={setPristine}
					routeAssignments={routeAssignments}
				/>
			</Layout>

		</DragDropContext>

	)

};

export default RerouteSelected;