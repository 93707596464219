import React, { useEffect } from 'react';
import { Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import TextInput from 'components/Form/TextInput';
import NumberInput from 'components/Form/NumberInput';
import TextAreaInput from 'components/Form/TextAreaInput';
import { ITimeWindow } from 'model/interfaces/ITimeWindow';
import DemonstratorAsyncSelect from 'components/Form/DemonstratorAsyncSelect';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import * as timeWindowActions from 'pages/TimeWindows/store/actions';
import {useTranslation} from "react-i18next";

type BaseProps = {
	onSuccess: () => void,
	initialValues: ITimeWindow,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const TimeWindowForm: React.FC<Props> = props => {

	const dispatch = useDispatch();

	const createUpdateSuccess = useSelector<ReduxState>(state => state.timeWindows.formSuccess);

	const onSubmit = async (values: ITimeWindow) => {
		if (values.id) {
			dispatch(timeWindowActions.updateTimeWindow(values));
		} else {
			dispatch(timeWindowActions.createTimeWindow(values));
		}
	};

	useEffect(() => {
		if (createUpdateSuccess) {
			dispatch(timeWindowActions.formTimeWindowRestart());
			props.onSuccess();
		}
	}, [ createUpdateSuccess ]);

	const { t } = useTranslation();

	return (
		<Formik<ITimeWindow>
			initialValues={ props.initialValues }
			onSubmit={ onSubmit }
		>
			{ formikBag => (
				<Form>
					<div className="row">
						<div className="col">
							<Field
								name="days"
								type="checkbox"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Field
								name="start_time"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.startTimeWindow')} { ...props } /> }
							</Field>
						</div>
						<div className="col">
							<Field
								name="end_time"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.endTimeWindow')} { ...props }/> }
							</Field>
						</div>
					</div>
					<div className="text-right">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={ ! formikBag.isValid || formikBag.isSubmitting }
						>
							{t('pages.saveWindow')}
						</button>
					</div>
				</Form>
			) }
		</Formik>
	);
};

export default TimeWindowForm;
