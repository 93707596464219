import React from 'react';

import VehicleForm from 'pages/Vehicles/VehicleForm';
import BaseModal from 'components/modals/BaseModal';
import { IVehicle } from 'model/interfaces/IVehicle';
import {useTranslation} from "react-i18next";

type BaseProps = {
	isOpen: boolean,
	close: () => void,
	initialValues: IVehicle,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const VehicleFormModal: React.FC<Props> = props => {

	const onSuccess = () => {
		props.close();
	};

	const { t } = useTranslation();

	return (
		<BaseModal
			isOpen={ props.isOpen }
			onRequestClose={ props.close }
			title={t('pages.vehicleForm')}
		>
			<VehicleForm
				onSuccess={ onSuccess }
				initialValues={ props.initialValues }
			/>
		</BaseModal>
	);
};

export default VehicleFormModal;
