import React from 'react';

import BaseModal from 'components/modals/BaseModal';
import { IAddress } from 'model/interfaces/IAddress';
import AddressForm from 'pages/Addresses/AddressForm';
import {useTranslation} from "react-i18next";

type BaseProps = {
	isOpen: boolean,
	close: () => void,
	initialValues: IAddress,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const AddressFormModal: React.FC<Props> = props => {

	const { t } = useTranslation();

	const onSuccess = () => {
		props.close();
	};

	return (
		<BaseModal
			isOpen={ props.isOpen }
			onRequestClose={ props.close }
			title={t('pages.addressForm')}
		>
			<AddressForm
				onSuccess={ onSuccess }
				initialValues={ props.initialValues }
			/>
		</BaseModal>
	);
};

export default AddressFormModal;
