import React, { forwardRef, MutableRefObject, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Tippy from '@tippyjs/react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import cs from "date-fns/locale/cs";
import el from "date-fns/locale/el";
import it from "date-fns/locale/it";
import dayjs from 'dayjs';
import { NavLink as RouterNavLink, useRouteMatch, matchPath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as executionDateActions from 'store/executionDate/actions';
import styles from './index.module.scss';
import NavLink from './NavLink';
import imgLogo from 'images/logo.png';
import { RoutesEnum, SettingsRoutes } from 'model/enums/RoutesEnum';
import { matchRoute } from 'routes';
import * as authActions from 'store/auth/actions';
import { ReduxState } from 'store/reducer';
import { DATE_TIME_DISPLAY_FORMAT } from 'model/constants';
import Subnav from 'components/Header/Subnav';
import * as loggedInUserSelectors from 'store/loggedInUser/selectors';
import ShiftPicker from 'components/Header/ShiftPicker';
import { IShift } from 'model/interfaces/IShift';
import { timeWithoutSeconds } from 'model/helpers/datetime';
import {PlanStatusPairs} from 'model/enums/PlanStatusEnum';
import { useTranslation } from 'react-i18next';
import { LanguageEnum, languageOptions, getLanguageName } from 'model/enums/LanguageEnum';
import {returnEnumPairsValue} from "../../model/helpers/enums";
import i18n from '../../i18n';

const CustomDatePickerInput = forwardRef((props: any, ref) => {

	const momentDate = dayjs(props.value);
	const isToday = dayjs().isSame(momentDate, 'day');
	const displayDate: string = isToday
		? `${momentDate.format(DATE_TIME_DISPLAY_FORMAT)} ` + i18n.t('components.header.today')
		: momentDate.format(DATE_TIME_DISPLAY_FORMAT);
	const shift: IShift = props.shift;

	return (
		<button
			className={ `${ styles.linkCalendar } nav-link dropdown-toggle bg-white d-flex align-items-center` }
			onClick={ props.onClick }
		>
			<i className="fa fa-calendar mr-2"/>
			<span className="text-left">
				{ displayDate }<br />
				<small className="font-weight-normal">{ shift.name } ({ timeWithoutSeconds(shift.time_start) } – { timeWithoutSeconds(shift.time_end) })</small>
			</span>
		</button>
	);
});

const PopperContainer: React.FC = ({ children }) => (
	ReactDOM.createPortal(
		children,
		document.body,
	)
);

const Header = () => {

	const dispatch = useDispatch();
	const setExecutionDate = (date: string) => dispatch(executionDateActions.set(date));
	const routeMatch = useRouteMatch();
	const { t, i18n } = useTranslation();
	const [langMenuOpen, setLangMenuOpen] = useState(false);

	const { executionDate, loggedInUserName, shift, plan } = useSelector((state: ReduxState) => ({
		executionDate: state.executionDate,
		loggedInUserName: loggedInUserSelectors.getName(state.loggedInUser),
		shift: state.shift,
		plan: state.plan.data
	}));

	registerLocale("cs", cs);
	registerLocale("it", it);
	registerLocale("el", el);

	return (
		<header className={ `${ styles.header } navbar-expand` }>
			<div className={styles.headerUpper}>
				<RouterNavLink to={ RoutesEnum.HOMEPAGE }>
					<img
						src={ imgLogo }
						alt=""
						width={ 60 }
						height={ 50 }
					/>
				</RouterNavLink>
				<ul className="navbar-nav mr-auto">
					<NavLink
						route={ matchRoute(RoutesEnum.HOMEPAGE) }
						label={t('navbar.vehiclesAndRoutes')}
						isActive={ (match, location) => {
							return !!matchPath(location.pathname, {
								path: RoutesEnum.HOMEPAGE,
								exact: true,
							}) || !!matchPath(location.pathname, RoutesEnum.DETAIL);
						} }
					/>
					<NavLink
						route={ matchRoute(RoutesEnum.ORDERS) }
						label={t('navbar.orders')}
					/>
					<NavLink
						route={ matchRoute(RoutesEnum.REROUTING) }
						label={t('navbar.rerouting')}
					/>
					<NavLink
						route={ matchRoute(RoutesEnum.PLANNING) }
						label={t('navbar.planing')}
					/>
				</ul>
				<ul className="navbar-nav">
					{routeMatch.path !== RoutesEnum.PLANNING &&
						<>
							{plan &&
							<li className="nav-item d-flex align-items-center mr-4">
								<div className={`badge badge-pill text-white text-medium ${styles[`badgeStatus${plan.status}`]}`}>{returnEnumPairsValue(PlanStatusPairs, plan.status)}</div>
							</li>
							}
							<li className="nav-item dropdown">
								<ReactDatePicker
									locale={i18n.language}
									onChange={(date: Date) => setExecutionDate((date as Date).toISOString())}
									selected={new Date(executionDate)}
									customInput={<CustomDatePickerInput shift={shift} />}
									popperContainer={PopperContainer}
									todayButton={t('navbar.today')}
								>
									<ShiftPicker />
								</ReactDatePicker>
							</li>
						</>
					}
					<li className="nav-item dropdown">
						<Tippy
							content={ <LanguageMenu setLangMenuOpen={setLangMenuOpen} /> }
							placement="bottom-end"
							offset={ [0, -10] }
							arrow={ false }
							interactive={ true }
							appendTo={document.body}
							visible={langMenuOpen}
							onClickOutside={() => setLangMenuOpen(false)}
						>
							<button
								className={ `${ styles.link } nav-link dropdown-toggle bg-white` }
								onClick={() => setLangMenuOpen(true)}
							>
								<i className="fa fa-globe mr-2"/>
								<span>{getLanguageName(i18n.language as LanguageEnum)}</span>
							</button>
						</Tippy>
					</li>
					<li className="nav-item dropdown">
						<Tippy
							content={ <ProfileMenu/> }
							trigger="click"
							placement="bottom-end"
							offset={ [0, -10] }
							arrow={ false }
							interactive={ true }
							appendTo={document.body}
						>
							<button
								className={ `${ styles.link } nav-link dropdown-toggle bg-white` }
							>
								<i className="fa fa-user mr-2"/>
								<span>{loggedInUserName}</span>
							</button>
						</Tippy>
					</li>
				</ul>
			</div>

			{ SettingsRoutes.includes(routeMatch.path as RoutesEnum) && <Subnav/> }
		</header>
	);
};

const ProfileMenu = () => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	return (
		<div className="dropdown-menu dropdown-menu-right show position-static">
			<RouterNavLink to={ RoutesEnum.PROFILE } className="dropdown-item">
				{t('navbar.settings')}
			</RouterNavLink>
			<div className="dropdown-divider"/>
			<button
				className="dropdown-item text-danger"
				onClick={ () => dispatch(authActions.destroy()) }
			>{t('navbar.logout')}
			</button>
		</div>
	);
};

const LanguageMenu: React.FC<{
	setLangMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setLangMenuOpen }) => {
	const { i18n } = useTranslation();

	return (
		<div className="dropdown-menu dropdown-menu-right show position-static">
			{languageOptions.map((lang) => (
				<button
					className="dropdown-item"
					key={lang.value}
					onClick={ () => {
						localStorage.setItem('language', lang.value)
						i18n.changeLanguage(lang.value)
						setLangMenuOpen(false)
					}}
				>
					{lang.label}
				</button>
			))}
			
		</div>
	);
};

export default Header;
