import React from 'react';

import stylesLayout from '../Layout.module.scss';
import Layout from 'pages/Layout';
import ProfileForm from 'pages/Profile/ProfileForm';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import { IUser } from 'model/interfaces/IUser';

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const Profile: React.FC<Props> = props => {

	const formInitialValues = useSelector<ReduxState>(state => state.loggedInUser.data) as IUser;

	return (
		<Layout className={stylesLayout.layoutSettings}>
			<section className={ stylesLayout.content }>
				<div className="container my-5 d-flex justify-content-center flex-column align-items-center">
					<div className="panel p-3 overflow-auto">
						<ProfileForm initialValues={ formInitialValues } />
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default Profile as React.FC<BaseProps>;
