import React from 'react';
import { uniqueId as lodashUniqueId } from 'lodash';
import i18n from '../../i18n';

import { ErrorMessage, FieldProps } from 'formik';
import { CommonInputProps } from 'components/Form/CommonInputProps';

type BaseProps = {
	className?: string,
} & FieldProps & CommonInputProps ;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

/**
 * Not very useful yet, but will be extended to prevent anything but numbers to be written
 */
const Checkbox: React.FC<Props> = props => {
	const id = lodashUniqueId(`checkbox-${props.field.name}`);

	return (
		<div className={props.className ?? 'form-check form-group'}>
			<input
				id={ id }
				className={` form-check-input ${props.meta.touched && props.meta.error ? 'is-invalid' : ''} `}
				type="checkbox"
				checked={props.meta.value}
				{ ...props.field }
			/>
			<label htmlFor={ id } className="form-check-label">
				{props.label}
			</label>

			<ErrorMessage name={ props.field.name }>
				{ (msg) => <div className="alert alert-danger">{ i18n.t(msg) }</div> }
			</ErrorMessage>
		</div>
	);
};

export default Checkbox;
