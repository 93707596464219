import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from 'pages/Packages/store/actions';
import { apiFactory } from 'model/services/Api/Api';
import { IPackagesResponse } from 'model/services/Api/interfaces/responses/IPackagesResponse';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { ActionType } from 'deox';
import { IPackage } from 'model/interfaces/IPackage';

function * fetchPackages() {
	const api = apiFactory(yield select());

	try {
		const response: IPackagesResponse = yield call(api.getRequest, UrlEnum.PACKAGE);
		yield put(actions.fetchPackagesSuccess(response.data));
	} catch (err) {
		yield put(actions.fetchPackagesFail(err));
	}
}

function * createPackage(action: ActionType<typeof actions.createPackage>) {
	const api = apiFactory(yield select());

	const { id, demonstrator, ...payload } = action.payload;

	try {
		yield call(api.postRequest, UrlEnum.PACKAGE, payload);
		yield put(actions.fetchPackages());
		yield put(actions.formPackageSuccess({
			message: 'pages.packages.packageCreated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formPackageFail(err));
	}
}

function * updatePackage(action: ActionType<typeof actions.updatePackage>) {
	const api = apiFactory(yield select());

	const { id, demonstrator, ...payload } = action.payload;

	try {
		yield call(api.patchRequest, `${UrlEnum.PACKAGE}${id}/`, payload);
		yield put(actions.fetchPackages());
		yield put(actions.formPackageSuccess({
			message: 'pages.packages.packageUpdated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formPackageFail(err));
	}
}

function * deletePackage(action: ActionType<typeof actions.deletePackage>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.deleteRequest, `${UrlEnum.PACKAGE}${action.payload.id}/`);
		yield put(actions.fetchPackages());
		yield put(actions.deletePackageSuccess({
			message: 'pages.packages.packageRemoved',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.deletePackageFail(err));
	}
}

function * watchFetchPackages() {
	yield takeLatest(actions.fetchPackages, fetchPackages);
}

function * watchDeletePackage() {
	yield takeEvery(actions.deletePackage, deletePackage);
}

function * watchCreatePackage() {
	yield takeEvery(actions.createPackage, createPackage);
}

function * watchUpdatePackage() {
	yield takeEvery(actions.updatePackage, updatePackage);
}

const packagesSagas = [
	watchFetchPackages(),
	watchDeletePackage(),
	watchCreatePackage(),
	watchUpdatePackage(),
];

export default packagesSagas;
