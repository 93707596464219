import { createReducer } from 'deox';

import * as actions from 'pages/MaterialTags/store/actions';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';
import { IMaterialTag } from 'model/interfaces/IMaterialTag';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';
import { IApiError } from 'model/interfaces/IApiError';

export interface IMaterialTagsState extends IReduxFetchedState<IPaginatedData<IMaterialTag>> {
	formSuccess?: IReduxActionState | boolean | undefined,
	formError?: (IReduxActionState & IApiError) | undefined,

	deleteSuccess?: IReduxActionState | boolean,
	deleteError?: IReduxActionState & IApiError,
}

const initialState: IMaterialTagsState = {};

export default createReducer(initialState, handle => [
	handle(actions.fetchMaterialTags, (state) => ({
		...state,
		loading: true,
	})),
	handle(actions.fetchMaterialTagsSuccess, (state, { payload }) => ({
		...state,
		error: undefined,
		data: { ...payload },
		loading: false,
	})),
	handle(actions.fetchMaterialTagsFail, (state, { payload }) => ({
		...state,
		data: undefined,
		error: { ...payload },
		loading: false,
	})),
	handle(actions.createMaterialTag, (state) => ({
		...state,
		loading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.updateMaterialTag, (state) => ({
		...state,
		loading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.formMaterialTagsuccess, (state, { payload }) => ({
		...state,
		loading: false,
		formSuccess: { ...payload },
		formError: undefined,
	})),
	handle(actions.formMaterialTagFail, (state, { payload }) => ({
		...state,
		loading: false,
		formSuccess: undefined,
		formError: { ...payload },
	})),
	handle(actions.formMaterialTagRestart, (state) => ({
		...state,
		loading: false,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.deleteMaterialTag, (state) => ({
		...state,
		loading: true,
		deleteSuccess: undefined,
		deleteError: undefined,
	})),
	handle(actions.deleteMaterialTagsuccess, (state, { payload }) => ({
		...state,
		loading: false,
		deleteSuccess: { ...payload },
		deleteError: undefined,
	})),
	handle(actions.deleteMaterialTagFail, (state, { payload }) => ({
		...state,
		loading: false,
		success: false,
		error: { ...payload },
	})),
]);
