import React from 'react';
import { uniqueId as lodashUniqueId } from 'lodash';
import i18n from '../../i18n';

import { ErrorMessage, FieldProps } from 'formik';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import { IDay } from 'model/interfaces/IDay';

type BaseProps = {
	className?: string,
	value: IDay
} & FieldProps & CommonInputProps ;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

/**
 * Not very useful yet, but will be extended to prevent anything but numbers to be written
 */
const DaysInWeekCheckbox: React.FC<Props> = props => {
	const meta = props.form.getFieldMeta(props.field.name);

	const id = lodashUniqueId(`checkbox-${props.field.name}`);

	const handleChange = () => {
		const values = props.field.value || [];
		const index = values.findIndex((value: IDay) => value.id === props.value.id);
		if (index === -1) {
			values.push(props.value);
		} else {
			values.splice(index, 1);
		}
		props.form.setFieldValue(props.field.name, values);
	};

	return (
		<div className="col-3">
			<div className={props.className ?? 'form-check form-group'}>
				<input
					id={ id }
					className={` form-check-input ${meta.touched && meta.error ? 'is-invalid' : ''} `}
					type="checkbox"
					onChange={handleChange}
					checked={props.field.value.findIndex((value: IDay) => value.id === props.value.id) !== -1}
				/>
				<label htmlFor={ id } className="form-check-label">
					{props.label}
				</label>

				<ErrorMessage name={ props.field.name }>
					{ (msg) => <div className="alert alert-danger">{ i18n.t(msg) }</div> }
				</ErrorMessage>
			</div>
		</div>
	);
};

export default DaysInWeekCheckbox;
