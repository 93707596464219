import { DeletedEnum } from 'model/enums/DeletedEnum';
import { ISemitrailer } from 'model/interfaces/ISemitrailer';

const initialState: ISemitrailer = {
	id: 0,
	abilities: [],
	demonstrator: 0,
	plate: '',
	brand: '',
	description: '',
	capacity_weight: undefined,
	capacity_volume: undefined,
	capacity_pieces: undefined,
	deleted: DeletedEnum.NORMAL,
};

export default initialState;
