import { DraggableLocation, DropResult } from 'react-beautiful-dnd';
import { IVehicle } from 'model/interfaces/IVehicle';
import { RouteStatusEnum } from 'model/enums/RouteStatusEnum';
import { IPlanningRoute } from 'model/interfaces/IRoute';
import { IAssignment } from 'model/interfaces/IAssignment';

interface IMoveResult {
	[key: string]: Array<any>,
}

export const reorder = (list: Array<any>, startIndex: number, endIndex: number): Array<any> => {
	const result = [ ...list ];
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

export const move = (source: Array<any>, destination: Array<any>, droppableSource: DraggableLocation, droppableDestination: DraggableLocation): IMoveResult => {
	const sourceClone = [ ...source ];
	const destClone = [ ...destination ];
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	const result: IMoveResult = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;

	return result;
};

export const getVehicleRemainingVolume = (vehicle: IVehicle, assignments: Array<Partial<IAssignment>>): number =>
	assignments.reduce((volume, assignment) =>
		volume - assignment.order!.status.volume * assignment.order!.status.quantity, vehicle.capacity_volume || 0);

export const isUnderModification = (routes: Array<IPlanningRoute|undefined>) =>
	routes.filter(route => route?.selected)
		.some(route => route?.status === RouteStatusEnum.UNDER_MODIFICATION);