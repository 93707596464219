import React from 'react';

import styles from './index.module.scss';
import Progress from './Progress';
import LoaderText from 'components/Loader/LoaderText';

type BaseProps = {
	opacity: number,
}

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const VehicleRouteCardLoader: React.FC<Props> = ( props ) => {
	return (
		<div className={ styles.container } style={{ opacity: props.opacity }}>
			<div className="form-row align-items-baseline">
				<div className="col overflow-hidden">
					<h3 className="text-truncate">
						<LoaderText width="90%" />
					</h3>
				</div>
				<div className="col text-muted text-right text-truncate">
					<LoaderText width="60%" />
				</div>
			</div>

			<Progress loader />

			<div className="form-row align-items-center justify-content-between">
				<div className="col">
					<LoaderText width="50%" /><br />
					<LoaderText width="80%" />
				</div>
				<div className="col text-center">
					<LoaderText width="30%" /><br />
					<LoaderText width="30%" />
				</div>
				<div className="col text-right">
					<LoaderText width="50%" /><br />
					<LoaderText width="80%" />
				</div>
			</div>
		</div>
	);
};

export default VehicleRouteCardLoader;
