import { OrderStatusEnum } from 'model/enums/OrderStatusEnum';
import { IOrderStatusHistory } from 'model/interfaces/IOrderStatusHistory';

export interface IOrderStatus {
	readonly id: number,
	readonly created: string,
	readonly last_updated: string,
	status: OrderStatusEnum,
	description: string,
	quantity: number,
	volume: number,
	weight: number,
	loading_service_time: string,
	unloading_service_time: string,
	readonly history: IOrderStatusHistory[],
}

export const initialValues: IOrderStatus = {
	id: 0,
	created: '',
	last_updated: '',
	status: OrderStatusEnum.NEW,
	description: '',
	quantity: 1,
	volume: 0,
	weight: 0,
	loading_service_time: '',
	unloading_service_time: '',
	history: [],
};