import React from 'react';
import styles from './index.module.scss';
import { IRoute } from 'model/interfaces/IRoute';
import { getCompletedOrderPercentage } from 'model/helpers/assignment';
import { RouteStatusEnum } from 'model/enums/RouteStatusEnum';

type Props = {
	route?: IRoute,
	loader?: boolean,
}

const Progress: React.FC<Props> = ({ route, loader }) => {
	const value = getCompletedOrderPercentage(route?.assignments ?? []);

	return (
		<div className={styles.progress}>
			<div
				className={loader ? styles.progressBarLoader : getProgressBarClassName(value, route)}
				style={{width: `${loader ? 30 : value}%`}}
			>
				{!loader && <i className="fa fa-truck" />}
			</div>
		</div>
	);
};

const getProgressBarClassName = (value: number, route?: IRoute) => {
	if (!route || [RouteStatusEnum.DRAFT, RouteStatusEnum.UNDER_PLANNING, RouteStatusEnum.SCHEDULED].includes(route.status)) {
		return styles.progressBarStart;
	} else if (route?.status === RouteStatusEnum.COMPLETED) {
		return styles.progressBarEnd;
	} else if (route?.status === RouteStatusEnum.CANCELLED) {
		return styles.progressBarCancelled;
	} else {
		return styles.progressBar;
	}
};

export default Progress;