import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IGridRowAction } from 'model/interfaces/grid/IGridRowAction';
import i18n from '../../i18n';


type IUseAdminGridReturnType<T> = {
	isFormOpened: boolean,
	openForm: () => void,
	closeForm: () => void,
	formInitialValues: T,
	editAction: IGridRowAction<T>,
	deleteAction: IGridRowAction<T>,
};

export const useAdminGrid = <T>(
	initialState: T,
	removeConfirmMessage: string,
	deleteRow: (row: T) => {},
	fetchRows: () => {},
): IUseAdminGridReturnType<T> => {

	const [ isFormOpened, setIsFormOpened ] = React.useState<boolean>(false);

	const [ formInitialValues, setFormInitialValues ] = React.useState<T>(initialState);

	const [openForm, closeForm] = [() => {
		setFormInitialValues(initialState);
		setIsFormOpened(true);
	}, () => {
		setFormInitialValues(initialState);
		setIsFormOpened(false)
	}];

	const dispatch = useDispatch();

	const editAction: IGridRowAction<T> = {
		label: (i18n.t('hooks.edit')),
		action: (row) => {
			setFormInitialValues(row);
			setIsFormOpened(true);
		},
	};

	const deleteAction: IGridRowAction<T> = {
		label: (i18n.t('hooks.delete')),
		action: async (row) => {
			if (window.confirm(removeConfirmMessage)) {
				dispatch(deleteRow(row));
			}
		}
	};

	useEffect(() => {
		dispatch(fetchRows());

		// Only run once while mounted
		// eslint-disable-next-line
	}, []);

	return {
		isFormOpened,
		openForm,
		closeForm,
		formInitialValues,
		editAction,
		deleteAction,
	};


};