import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Footer from 'pages/Planning/Footer';
import * as actions from 'pages/Planning/store/actions';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import stylesLayout from 'pages/Layout.module.scss';
import Layout from 'pages/Layout';
import CalculateModal from 'pages/Planning/CalculateModal';
import {useTranslation} from "react-i18next";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const Parameters: React.FC < Props > = props => {
	const dispatch = useDispatch();
	const [ isCalculateModalVisible, setCalculateModalVisible ] = useState(false)
	const { t } = useTranslation();

	return (
		<Layout className={ stylesLayout.layoutPlanning }>
			<CalculateModal
				isOpen={isCalculateModalVisible}
				onRequestClose={() => setCalculateModalVisible(false)}
				title=""
			/>

			{props.children}
			<div className="d-flex align-items-center justify-content-center">
				<div className="panel pt-5 px-5 pb-2">
					<div className="row align-items-baseline">
						<div className="col-12 col-md text-center mb-4">
							<button className="btn text-muted">
								<i className="fa fa-object-group fa-3x mb-4" /><br />
								<span className="text-nowrap">
									{t('pages.planning.clustering')}
									<i className="fa fa-angle-right ml-2" />
								</span>
							</button>
						</div>
						<div className="col-12 col-md text-center mb-4">
							<button
								className="btn text-muted"
								onClick={ () => dispatch(actions.setStep(PlanningStepsEnum.ASSIGNED_ORDERS_SELECTION)) }
							>
								<i className="fa fa-truck fa-3x mb-4" /><br />
								<span className="text-nowrap">
									{t('pages.planning.assignedOrders')}
									<i className="fa fa-angle-right ml-2" />
								</span>
							</button>
						</div>
						<div className="col-12 col-md text-center mb-4">
							<button className="btn text-muted">
								<i className="fa fa-calendar fa-3x mb-3 mb-4" /><br />
								<span className="text-nowrap">
									{t('pages.planning.schedulingCriteria')}
									<i className="fa fa-angle-right ml-2" />
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<Footer>
				<button
					className="btn"
					onClick={() => dispatch(actions.setStep(PlanningStepsEnum.VEHICLES))}
				>
					{t('pages.planning.back')}
				</button>
				<button
					className="btn btn-secondary mr-2"
					onClick={() => dispatch(actions.setStep(PlanningStepsEnum.SOLUTION))}
				>
					{t('pages.planning.solution')}
				</button>
				<button
					className="btn btn-primary"
					onClick={() => setCalculateModalVisible(true)}
				>
					{t('pages.planning.calculate')}
				</button>
			</Footer>
		</Layout>
	);
};

export default Parameters;