import React from 'react';
import { FieldProps } from 'formik';

import BaseAsyncSelect from 'components/Form/BaseAsyncSelect';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import { IVehicle } from 'model/interfaces/IVehicle';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';

type BaseProps = {} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const VehicleAsyncSelect: React.FC<Props> = props => (
	<BaseAsyncSelect<IVehicle>
		url={ UrlEnum.VEHICLE }
		getOptionLabel={ option => `${ option.brand } - ${ option.plate }` }
		{ ...props }
	/>
)

export default VehicleAsyncSelect;
