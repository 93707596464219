import React, {createContext, useState} from "react";

type MapContextProps = {
	children: any
};

const MapContext = createContext(null as any);

const MapContextProvider: React.FC<MapContextProps> = ({children}) => {
	const [center, setCenter] = useState([22.894823, 39.371452]);
	const [zoom, setZoom] = useState<[number]>([8]);
	const [isVisible, setIsVisible] = useState(false);

	return (
		<MapContext.Provider value={{
			center: center,
			setCenter: setCenter,

			zoom: zoom,
			setZoom: setZoom,

			isVisible: isVisible,
			setIsVisible: setIsVisible
		}}>
			{children}
		</MapContext.Provider>
	);
};

export { MapContext, MapContextProvider };