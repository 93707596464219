import { ISelectOptions } from "model/interfaces/ISelectOptions";

export enum LanguageEnum {
    CS = 'cs',
    EN = 'en',
    IT = 'it',
    EL = 'el'
}

const Languages = [
    LanguageEnum.EN,
    LanguageEnum.CS,
    LanguageEnum.IT,
    LanguageEnum.EL,
]

const LanguagePairs = {
	[LanguageEnum.EN]: 'English',
	[LanguageEnum.CS]: 'Čeština',
	[LanguageEnum.IT]: 'Italiano',
    [LanguageEnum.EL]: 'Ελληνικά',
};

export const getLanguageName = (lang: LanguageEnum) => LanguagePairs[lang]

export const languageOptions: ISelectOptions[] = Languages.map((lang) => ({
    label: LanguagePairs[lang],
    value: lang
}));