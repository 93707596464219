import React from 'react';
import { isEmpty } from 'lodash';

import { IShift } from 'model/interfaces/IShift';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import * as shiftActions from 'store/shift/actions';
import { ReduxState } from 'store/reducer';
import { timeWithoutSeconds } from 'model/helpers/datetime';

type BaseProps = {
	shift?: IShift,
	setShift?: React.Dispatch<React.SetStateAction<IShift>>,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const ShiftPicker: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const [ options, value ] = useSelector((state: ReduxState) => [
		state.shifts.data?.results ?? [],
		!isEmpty(state.shift) ? state.shift : undefined,
	]);

	const onChange = (option: IShift | any) => {
		if (props.setShift) {
			// Local set - used in Planning section
			props.setShift(option);

		} else {
			// Global set - used in Header
			dispatch(shiftActions.set(option));
		}
	};

	return (
		<Select<IShift>
			getOptionValue={ option => option.id.toString() }
			onChange={ onChange }
			options={ options }
			defaultOptions
			value={ props.shift ?? value }
			className="clear-both text-left"
			formatOptionLabel={ option => (
				<div style={ {lineHeight: 1.1} }>
					{ option.name }<br />
					<small className="text-muted">{ timeWithoutSeconds(option.time_start) } - { timeWithoutSeconds(option.time_end) }</small>
				</div>
			) }
		/>
	);

};

export default ShiftPicker;
