import { takeLatest, put, select, call } from 'redux-saga/effects';

import * as actions from './actions';
import * as loggedInUserActions from 'store/loggedInUser/actions';
import * as shiftsActions from 'pages/Shifts/store/actions';
import { apiFactory } from 'model/services/Api/Api';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IApiTokenAuthResponse } from 'model/services/Api/interfaces/responses/IApiTokenAuthResponse';
import { AxiosResponse } from 'axios';

function* requestLogin({ payload }: ReturnType<typeof actions.login>): any {
	const api = apiFactory(yield select());

	try {
		const response: AxiosResponse<IApiTokenAuthResponse> =
			yield call(api.postRequest, UrlEnum.API_TOKEN_AUTH, payload);

		yield put(actions.loginSuccess(response.data));
		yield put(loggedInUserActions.fetchLoggedInUser());
		yield put(shiftsActions.fetchShifts());
	} catch (err) {
		yield put(actions.loginFail(err));
	}
}

function* watchLogin() {
	yield takeLatest(actions.login, requestLogin);
}

const authSagas = [
	watchLogin(),
];

export default authSagas;
