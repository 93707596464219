import { AddressTypeEnum } from 'model/enums/AddressTypeEnum';
import { VerificationTypeEnum } from 'model/enums/VerificationTypeEnum';
import { IRestriction } from 'model/interfaces/IRestriction';
import { ILocation, initialState as locationInitialState } from 'model/interfaces/ILocation';
import { OwnerTypeEnum } from 'model/enums/OwnerTypeEnum';

export interface IAddress {
	readonly id: number,
	address: string,
	postal_code?: string,
	city: string,
	location?: ILocation,
	comments?: string,
	address_type: AddressTypeEnum,
	verification_type: VerificationTypeEnum,
	owner_id?: number,
	owner_erp_id?: string,
	owner_name?: string,
	owner_telephone?: string,
	owner: {
		owner_id: number,
		owner_name: string,
	}
	object_id: number,
	content_type: OwnerTypeEnum,
	restrictions?: IRestriction[],
	altitude?: number,
	erp_id?: string
}

export const initialState: IAddress = {
	id: 0,
	address: '',
	postal_code: '',
	city: '',
	location: undefined,
	comments: '',
	address_type: AddressTypeEnum.DEPOT,
	verification_type: VerificationTypeEnum.UNVERIFIED,
	owner: {
		owner_id: 0,
		owner_name: '',
	},
	object_id: 0,
	content_type: OwnerTypeEnum.CUSTOM_USER,
	restrictions: [],
};