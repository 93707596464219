import React from 'react';
import { FieldProps } from 'formik';

import BaseAsyncSelect from 'components/Form/BaseAsyncSelect';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IVehicleDescription } from 'model/interfaces/IVehicleDescription';

type BaseProps = {} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const VehicleDescriptionAsnycSelect: React.FC<Props> = props => (
	<BaseAsyncSelect<IVehicleDescription>
		url={ `${UrlEnum.VEHICLE_DESCRIPTION}` }
		getOptionLabel={ option => `${option.name}` }
		{ ...props }
	/>
);

export default VehicleDescriptionAsnycSelect;
