import { createActionCreator } from 'deox';
import { IApiError } from 'model/interfaces/IApiError';
import { IVehicleAbility } from 'model/interfaces/IVehicleAbility';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';

export enum VehicleAbilityActionsEnum {
	FETCH = '@VEHICLEABILITIES_FETCH',
	FETCH_SUCCESS = '@VEHICLEABILITIES_FETCH_SUCCESS',
	FETCH_FAIL = '@VEHICLEABILITIES_FETCH_FAIL',

	CREATE = '@VEHICLEABILITIES_CREATE',
	UPDATE = '@VEHICLEABILITIES_UPDATE',
	FORM_SUCCESS = '@VEHICLEABILITIES_FORM_SUCCESS',
	FORM_FAIL = '@VEHICLEABILITIES_FORM_FAIL',
	FORM_RESTART = '@VEHICLEABILITIES_FORM_RESTART',

	DELETE = '@VEHICLEABILITIES_DELETE',
	DELETE_SUCCESS = '@VEHICLEABILITIES_DELETE_SUCCESS',
	DELETE_FAIL = '@VEHICLEABILITIES_DELETE_FAIL',
}

export const fetchVehicleAbilities = createActionCreator(VehicleAbilityActionsEnum.FETCH);

export const fetchVehicleAbilitiesFail = createActionCreator(
	VehicleAbilityActionsEnum.FETCH_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const fetchVehicleAbilitiesSuccess = createActionCreator(
	VehicleAbilityActionsEnum.FETCH_SUCCESS,
	resolve => (data: IPaginatedData<IVehicleAbility>) => resolve(data),
);

export const createVehicleAbility = createActionCreator(
	VehicleAbilityActionsEnum.CREATE,
	resolve => (vehicleAbilityItem: IVehicleAbility) => resolve(vehicleAbilityItem),
);

export const updateVehicleAbility = createActionCreator(
	VehicleAbilityActionsEnum.UPDATE,
	resolve => (vehicleAbilityItem: IVehicleAbility) => resolve(vehicleAbilityItem),
);

export const formVehicleAbilitiesuccess = createActionCreator(
	VehicleAbilityActionsEnum.FORM_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success));

export const formVehicleAbilityFail = createActionCreator(
	VehicleAbilityActionsEnum.FORM_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const formVehicleAbilityRestart = createActionCreator(VehicleAbilityActionsEnum.FORM_RESTART);

export const deleteVehicleAbility = createActionCreator(
	VehicleAbilityActionsEnum.DELETE,
	resolve => (vehicleAbilityItem: IVehicleAbility) => resolve(vehicleAbilityItem),
);

export const deleteVehicleAbilitiesuccess = createActionCreator(
	VehicleAbilityActionsEnum.DELETE_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success)
);

export const deleteVehicleAbilityFail = createActionCreator(
	VehicleAbilityActionsEnum.DELETE_FAIL,
	resolve => (error: IReduxActionState & IApiError) => resolve(error),
);
