import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from 'pages/VehicleAbilities/store/actions';
import { apiFactory } from 'model/services/Api/Api';
import { IVehicleAbilitiesResponse } from 'model/services/Api/interfaces/responses/IVehicleAbilitiesResponse';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { ActionType } from 'deox';

function * fetchVehicleAbilities() {
	const api = apiFactory(yield select());

	try {
		const response: IVehicleAbilitiesResponse = yield call(api.getRequest, UrlEnum.VEHICLE_ABILITY);
		yield put(actions.fetchVehicleAbilitiesSuccess(response.data));
	} catch (err) {
		console.error(err.message);
		yield put(actions.fetchVehicleAbilitiesFail(err));
	}
}

function * createVehicleAbility(action: ActionType<typeof actions.createVehicleAbility>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.postRequest, UrlEnum.VEHICLE_ABILITY, action.payload);
		yield put(actions.fetchVehicleAbilities());
		yield put(actions.formVehicleAbilitiesuccess({
			message: 'pages.VAcreated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formVehicleAbilityFail(err));
	}
}

function * updateVehicleAbility(action: ActionType<typeof actions.updateVehicleAbility>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.patchRequest, `${UrlEnum.VEHICLE_ABILITY}${action.payload.id}/`, action.payload);
		yield put(actions.fetchVehicleAbilities());
		yield put(actions.formVehicleAbilitiesuccess({
			message: 'pages.VAupdated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formVehicleAbilityFail(err));
	}
}

function * deleteVehicleAbility(action: ActionType<typeof actions.deleteVehicleAbility>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.deleteRequest, `${UrlEnum.VEHICLE_ABILITY}${action.payload.id}/`);
		yield put(actions.fetchVehicleAbilities());
		yield put(actions.deleteVehicleAbilitiesuccess({
			message: 'pages.VAremoved',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.deleteVehicleAbilityFail(err));
	}
}

function * watchFetchVehicleAbilities() {
	yield takeLatest(actions.fetchVehicleAbilities, fetchVehicleAbilities);
}

function * watchDeleteVehicleAbility() {
	yield takeEvery(actions.deleteVehicleAbility, deleteVehicleAbility);
}

function * watchCreateVehicleAbility() {
	yield takeEvery(actions.createVehicleAbility, createVehicleAbility);
}

function * watchUpdateVehicleAbility() {
	yield takeEvery(actions.updateVehicleAbility, updateVehicleAbility);
}

const vehicleAbilitiesSagas = [
	watchFetchVehicleAbilities(),
	watchDeleteVehicleAbility(),
	watchCreateVehicleAbility(),
	watchUpdateVehicleAbility(),
];

export default vehicleAbilitiesSagas;
