import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { FieldProps } from 'formik';
import { debounce, uniqueId as lodashUniqueId } from 'lodash';
import queryString from 'query-string';
import hash from 'object-hash';

import InputWrapper from 'components/Form/InputWrapper';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import { useSelector } from 'react-redux';
import { apiFactory } from 'model/services/Api/Api';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { useMemoryState } from 'model/hooks/useMemoryState';
import axios, { Cancel, CancelTokenSource } from 'axios';
import { useCancelToken } from 'model/hooks/useCancelToken';

type BaseProps<T> = {
	url: string,
	params?: { [key: string]: string|number|string[]|number[] },
	getOptionLabel?: (option: T) => string,
	getOptionValue?: (option: T) => string|T,
	formatOptionLabel?: (option: T) => React.ReactNode,
	isMulti?: boolean,
	mapResults?: (results: T[]) => T[],
} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props<T> = BaseProps<T> & InjectedProps;

// TODO: IS-INVALID CLASS IF NOT VALID
// TODO: STYLE - GREEN BORDER WHEN ACTIVE
const BaseAsyncSelect = <OptionType extends { id: number }>(props: Props<OptionType>) => {
	const [valueSource, setValueSource] = React.useState<OptionType | undefined>(undefined);
	// const value: number | string | OptionType = props.field.value;
	const api = useSelector(apiFactory);
	const id: string = lodashUniqueId(`select-async-${ props.field.name }`);
	const [ options, setOptions ] = useMemoryState(`${props.field.name}${props.params ? hash.MD5(props.params) : ''}`, undefined);
	const { createNewToken, isCancel } = useCancelToken();

	if (!valueSource && props.field.value) {
		setValueSource(props.field.value);
	}

	// React.useEffect(() => {
	// 	if ((typeof value === 'number' && value > 0) || typeof value === 'string') {
	// 		api.getRequest<OptionType>(`${ props.url }${ value }/`)
	// 			.then(result => setValueSource(result.data))
	// 			.catch(error => console.error(error));
	// 	}
	//
	// 	// eslint-disable-next-line
	// }, [value]);

	const loadOptions = (inputValue: string, callback: any) => {
		const params = Object.assign({}, props.params);
		if (inputValue !== '') params.search = inputValue;
		const stringParams = Object.keys(params).length > 0 ? `?${queryString.stringify(params)}` : '';

		if (inputValue === '' && options !== undefined) {
			callback(options);

		} else {
			api.getRequest<IPaginatedData<OptionType>>(`${ props.url }${ stringParams }`, {
				cancelToken: createNewToken(),
			})
				.then(result => {
					if (inputValue === '') {
						setOptions(result.data.results);
					}
					callback(result.data.results);
				})
				.catch(err => {
					if (isCancel(err)) return;
					console.error(err);
				});
		}
	};

	const debouncedLoadOptions = debounce(loadOptions, 500);

	// const getOptionValue = props.getOptionValue || (option => option?.id.toString());

	const onChange = (option: OptionType | any) => {
		props.form.setFieldValue(props.field.name, option);
		setValueSource(option);
	};

	const asyncSelectProps = {
		getOptionLabel: props.getOptionLabel,
		formatOptionLabel: props.formatOptionLabel,
	};

	return (
		<InputWrapper { ...props } id={ id }>
			<AsyncSelect<OptionType>
				getOptionValue={ option => option.id.toString() }
				onChange={ onChange }
				loadOptions={ debouncedLoadOptions }
				defaultOptions
				id={ id }
				value={ valueSource }
				isMulti={ props.isMulti }
				isClearable
				{ ...asyncSelectProps }
			/>
		</InputWrapper>
	);
};

export default BaseAsyncSelect;
