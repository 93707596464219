import { createActionCreator } from 'deox';
import { IApiError } from 'model/interfaces/IApiError';
import { IMaterialTag } from 'model/interfaces/IMaterialTag';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';

export enum MaterialTagActionsEnum {
	FETCH = '@MATERIALTAGS_FETCH',
	FETCH_SUCCESS = '@MATERIALTAGS_FETCH_SUCCESS',
	FETCH_FAIL = '@MATERIALTAGS_FETCH_FAIL',

	CREATE = '@MATERIALTAGS_CREATE',
	UPDATE = '@MATERIALTAGS_UPDATE',
	FORM_SUCCESS = '@MATERIALTAGS_FORM_SUCCESS',
	FORM_FAIL = '@MATERIALTAGS_FORM_FAIL',
	FORM_RESTART = '@MATERIALTAGS_FORM_RESTART',

	DELETE = '@MATERIALTAGS_DELETE',
	DELETE_SUCCESS = '@MATERIALTAGS_DELETE_SUCCESS',
	DELETE_FAIL = '@MATERIALTAGS_DELETE_FAIL',
}

export const fetchMaterialTags = createActionCreator(MaterialTagActionsEnum.FETCH);

export const fetchMaterialTagsFail = createActionCreator(
	MaterialTagActionsEnum.FETCH_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const fetchMaterialTagsSuccess = createActionCreator(
	MaterialTagActionsEnum.FETCH_SUCCESS,
	resolve => (data: IPaginatedData<IMaterialTag>) => resolve(data),
);

export const createMaterialTag = createActionCreator(
	MaterialTagActionsEnum.CREATE,
	resolve => (materialTagItem: IMaterialTag) => resolve(materialTagItem),
);

export const updateMaterialTag = createActionCreator(
	MaterialTagActionsEnum.UPDATE,
	resolve => (materialTagItem: IMaterialTag) => resolve(materialTagItem),
);

export const formMaterialTagsuccess = createActionCreator(
	MaterialTagActionsEnum.FORM_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success));

export const formMaterialTagFail = createActionCreator(
	MaterialTagActionsEnum.FORM_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const formMaterialTagRestart = createActionCreator(MaterialTagActionsEnum.FORM_RESTART);

export const deleteMaterialTag = createActionCreator(
	MaterialTagActionsEnum.DELETE,
	resolve => (materialTagItem: IMaterialTag) => resolve(materialTagItem),
);

export const deleteMaterialTagsuccess = createActionCreator(
	MaterialTagActionsEnum.DELETE_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success)
);

export const deleteMaterialTagFail = createActionCreator(
	MaterialTagActionsEnum.DELETE_FAIL,
	resolve => (error: IReduxActionState & IApiError) => resolve(error),
);
