import React from 'react';

import * as constants from './constants';
import stylesLayout from '../Layout.module.scss';
import Layout from 'pages/Layout';
import Grid from 'components/Grid';
import TimeWindowFormModal from 'pages/TimeWindows/TimeWindowFormModal';
import { ITimeWindow } from 'model/interfaces/ITimeWindow';
import * as actions from 'pages/TimeWindows/store/actions';
import initialState from 'pages/TimeWindows/TimeWindowForm/initialState';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import { useAdminGrid } from 'model/hooks/useAdminGrid';
import {useTranslation} from "react-i18next";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const TimeWindows: React.FC<Props> = props => {

	const { t } = useTranslation();

	const {
		isFormOpened,
		openForm,
		closeForm,
		formInitialValues,
		editAction,
		deleteAction,
	} = useAdminGrid<ITimeWindow>(
		initialState,
		t('pages.time.wantDeleteTime'),
		actions.deleteTimeWindow,
		actions.fetchTimeWindows,
	);

	const { timeWindows, isLoading, error } = useSelector((state: ReduxState) => ({
		timeWindows: state.timeWindows.data?.results ?? [],
		isLoading: state.timeWindows.loading,
		error: state.timeWindows.error,
	}));

	return (
		<>
			<TimeWindowFormModal
				isOpen={ isFormOpened }
				close={ closeForm }
				initialValues={ formInitialValues }
			/>
			<Layout className={stylesLayout.layoutSettings}>
				<section className={ stylesLayout.content }>
					<Grid<ITimeWindow>
						data={ timeWindows }
						columns={ constants.COLUMNS }
						rowSelectors={ constants.SELECTORS }
						rowActions={ [editAction, deleteAction] }
						loading={ isLoading }
						buttons={ () => (
							<>
								<button
									className="btn btn-primary"
									onClick={ openForm }
								>
									{t('pages.time.addTime')}
								</button>
							</>
						) }
					/>
				</section>
			</Layout>
		</>
	);
};

export default TimeWindows as React.FC<BaseProps>;
