import React from 'react';
import { FieldProps } from 'formik';

import BaseAsyncSelect from 'components/Form/BaseAsyncSelect';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IVehicleAbility } from 'model/interfaces/IVehicleAbility';

type BaseProps = {} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const AbilityAsyncMultiSelect: React.FC<Props> = props => (
	<BaseAsyncSelect<IVehicleAbility>
		url={ UrlEnum.VEHICLE_ABILITY }
		getOptionLabel={ option => `${option.name}` }
		isMulti
		{ ...props }
	/>
)

export default AbilityAsyncMultiSelect;
