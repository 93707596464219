import React from 'react';

import styles from './Layout.module.scss';
import Header from "components/Header";

type BaseProps = {
	className?: string
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const Layout: React.FC<Props> = props => {
	return (
		<div className={props.className || styles.layout}>
			<Header />

			{props.children}
		</div>
	);
};

export default Layout;
