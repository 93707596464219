import React from 'react';
import { FieldProps } from 'formik';

import BaseAsyncSelect from 'components/Form/BaseAsyncSelect';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IAddress } from 'model/interfaces/IAddress';
import { AddressTypeEnum } from 'model/enums/AddressTypeEnum';

type BaseProps = {
	addressType?: AddressTypeEnum | AddressTypeEnum[],
} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const AddressAsnycSelect: React.FC<Props> = props => (
	<BaseAsyncSelect<IAddress>
		url={ `${UrlEnum.ADDRESS}` }
		params={ typeof props.addressType !== 'undefined' ? { address_type: props.addressType } : undefined }
		formatOptionLabel={ option => (
			<div style={{ lineHeight: 1.1 }}>
				{option.owner?.owner_name ?? option.owner_name ?? ''}<br />
				<small className="text-muted">{option.address}, {option.postal_code}, {option.city}</small>
			</div>
		)}
		{ ...props }
	/>
);

export default AddressAsnycSelect;
