import { createActionCreator } from 'deox';
import { IShift } from 'model/interfaces/IShift';

export enum ShiftActions {
	SET = '@SHIFT_SET',
}

export const set = createActionCreator(
	ShiftActions.SET, resolve => (shift: IShift) => resolve(shift),
);
