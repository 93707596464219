import React from 'react';

import * as constants from './constants';
import stylesLayout from '../Layout.module.scss';
import Layout from 'pages/Layout';
import Grid from 'components/Grid';
import SemitrailerFormModal from 'pages/Semitrailers/SemitrailerFormModal';
import { ISemitrailer } from 'model/interfaces/ISemitrailer';
import * as actions from 'pages/Semitrailers/store/actions';
import initialState from 'pages/Semitrailers/SemitrailerForm/initialState';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import { useAdminGrid } from 'model/hooks/useAdminGrid';
import { useSearchedEntity } from 'model/hooks/useSearchedEntity';
import { IVehicle } from 'model/interfaces/IVehicle';
import { IGenericSearchRequest } from 'model/services/Api/interfaces/requests/IGenericSearchRequest';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import {useTranslation} from "react-i18next";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const Semitrailers: React.FC<Props> = props => {

	const { t } = useTranslation();

	const {
		isFormOpened,
		openForm,
		closeForm,
		formInitialValues,
		editAction,
		deleteAction,
	} = useAdminGrid<ISemitrailer>(
		initialState,
		t('pages.semitrailers.wantDeleteSemitrailer'),
		actions.deleteSemitrailer,
		actions.fetchSemitrailers,
	);

	const { semitrailers, isLoading, error } = useSelector((state: ReduxState) => ({
		semitrailers: state.semitrailers.data?.results ?? [],
		isLoading: state.semitrailers.loading,
		error: state.semitrailers.error,
	}));

	const [ searchedSemitrailers, onSearch ] = useSearchedEntity<ISemitrailer, IGenericSearchRequest>(
		UrlEnum.SEMITRAILER,
		semitrailers,
	);

	return (
		<>
			<SemitrailerFormModal
				isOpen={ isFormOpened }
				close={ closeForm }
				initialValues={ formInitialValues }
			/>
			<Layout className={stylesLayout.layoutSettings}>
				<section className={ stylesLayout.content }>
					<Grid<ISemitrailer>
						data={ searchedSemitrailers }
						columns={ constants.COLUMNS }
						rowSelectors={ constants.SELECTORS }
						rowActions={ [editAction, deleteAction] }
						loading={ isLoading }
						searchCallback={ onSearch }
						buttons={ () => (
							<>
								<button
									className="btn btn-primary"
									onClick={ openForm }
								>
									{t('pages.semitrailers.addSemitrailer')}
								</button>
							</>
						) }
					/>
				</section>
			</Layout>
		</>
	);
};

export default Semitrailers as React.FC<BaseProps>;
