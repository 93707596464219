import { Link } from 'react-router-dom';
import { IGridRowRender } from 'model/interfaces/grid/IGridRowRender';
import { IRoute } from 'model/interfaces/IRoute';
import React, { useEffect, useState } from 'react';
import styles from 'pages/Planning/Vehicles/index.module.scss';
import * as actions from 'store/route/actions';
import VehicleDescription from 'pages/Planning/VehicleDescription';
import StartingPoint from 'components/VehicleRouteCard/StartingPoint';
import EndingPoint from 'components/VehicleRouteCard/EndingPoint';
import { getNoOfOrders } from 'model/helpers/assignment';
import { OrderTypeEnum } from 'model/enums/OrderTypeEnum';
import { useDispatch, useSelector } from 'react-redux';
import NowPoint from 'components/VehicleRouteCard/NowPoint';
import { OrderStatusEnum } from 'model/enums/OrderStatusEnum';
import { RoutesEnum } from 'model/enums/RoutesEnum';
import { Formik, Field, useFormikContext } from 'formik';
import { ReduxState } from 'store/reducer';

interface ISelectedForm {
	selected: boolean
}

const FormikUpdateSelectedComponent = ({ rowIndex }: { rowIndex: number }) => {
	const context = useFormikContext();
	const values = context.values as ISelectedForm;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(actions.setSelected(values.selected, rowIndex));
	}, [context.values]);

	return null;
};

const Row = (props: IGridRowRender<IRoute>) => {
	const row = props.row;
	const selected = useSelector((state: ReduxState) => state.routes.meta[props.rowIndex]?.selected);

	return (
		<>
			<tr>
				<td className="align-middle pr-0" style={{ width: '1px' }}>
					<Formik<ISelectedForm> initialValues={{ selected }} onSubmit={() => {}}>
						<div className="form-check">
							<FormikUpdateSelectedComponent rowIndex={props.rowIndex} />
							<Field
								type="checkbox"
								name="selected"
								className="form-check-input"
								id={ `route-selected-${row.id}` }
								checked={ selected }
							/>
							<label htmlFor={ `route-selected-${row.id}` } className="form-check-label" />
						</div>
					</Formik>

				</td>
				<td className="align-middle">
					<VehicleDescription vehicle={row.vehicle!} />
				</td>
				<td className="align-middle">
					<StartingPoint route={row} />
				</td>
				<td className="align-middle text-center">
					<NowPoint route={row} />
				</td>
				<td className="align-middle text-right">
					<EndingPoint route={row} />
				</td>
				<td className="align-middle text-center">
					<span className="badge-order badge-dark">
						{ getNoOfOrders(row.assignments) }
					</span>
				</td>
				<td className="align-middle text-center">
					<span className="badge-order badge-delivery">
						{ getNoOfOrders(row.assignments, undefined, OrderTypeEnum.DELIVERY) }
					</span>
				</td>
				<td className="align-middle text-center">
					<span className="badge-order badge-pickup">
						{ getNoOfOrders(row.assignments, undefined, OrderTypeEnum.PICKUP) }
					</span>
				</td>
				<td className="align-middle text-center">
					<span className="badge-order badge-requisition">
						{ getNoOfOrders(row.assignments, undefined, OrderTypeEnum.REQUISITION) }
					</span>
				</td>
				<td className="align-middle text-center">
					<span className="badge-order badge-completed">
						{ getNoOfOrders(row.assignments, OrderStatusEnum.COMPLETED) }
					</span>
				</td>
				<td className="align-middle text-center">
					<span className="badge-order badge-cancelled">
						{ getNoOfOrders(row.assignments, OrderStatusEnum.CANCELLED) }
					</span>
				</td>
				<td className="align-middle border-left px-0 position-relative">
					<Link
						to={ RoutesEnum.DETAIL_REROUTING.replace(':id', row.id.toString()) }
						className="btn stretched-link"
					>
						<i className="fa fa-angle-right" />
					</Link>
				</td>
			</tr>
		</>
	);
};

export default Row;