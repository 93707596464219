import React from 'react';
import { Link } from 'react-router-dom';
import { RoutesEnum } from 'model/enums/RoutesEnum';
import {useTranslation} from "react-i18next";


const ManageMenu = () => {
	const { t } = useTranslation();
	return (
		<>
			<Link to={ RoutesEnum.VEHICLES } className="dropdown-item">
				{t('pages.planning.vehicles.vehicles')}
			</Link>
			<Link to={ RoutesEnum.SEMITRAILERS } className="dropdown-item">
				{t('pages.planning.vehicles.semitrailers')}
			</Link>
			<Link to={ RoutesEnum.DRIVERS } className="dropdown-item">
				{t('pages.planning.vehicles.drivers')}
			</Link>
			<Link to={ RoutesEnum.SHIFTS } className="dropdown-item">
				{t('pages.planning.vehicles.shifts')}
			</Link>
		</>
	)
};

export default ManageMenu;