import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from 'pages/TimeWindows/store/actions';
import { apiFactory } from 'model/services/Api/Api';
import { ITimeWindowsResponse} from 'model/services/Api/interfaces/responses/ITimeWindowsResponse';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { ActionType } from 'deox';

function * fetchTimeWindows() {
	const api = apiFactory(yield select());

	try {
		const response: ITimeWindowsResponse = yield call(api.getRequest, UrlEnum.TIME_WINDOW);
		yield put(actions.fetchTimeWindowsSuccess(response.data));
	} catch (err) {
		console.error(err.message);
		yield put(actions.fetchTimeWindowsFail(err));
	}
}

function * createTimeWindow(action: ActionType<typeof actions.createTimeWindow>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.postRequest, UrlEnum.TIME_WINDOW, action.payload);
		yield put(actions.fetchTimeWindows());
		yield put(actions.formTimeWindowSuccess({
			message: 'pages.time.windowCreated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formTimeWindowFail(err));
	}
}

function * updateTimeWindow(action: ActionType<typeof actions.updateTimeWindow>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.patchRequest, `${UrlEnum.TIME_WINDOW}${action.payload.id}/`, action.payload);
		yield put(actions.fetchTimeWindows());
		yield put(actions.formTimeWindowSuccess({
			message: 'pages.time.windowUpdated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formTimeWindowFail(err));
	}
}

function * deleteTimeWindow(action: ActionType<typeof actions.deleteTimeWindow>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.deleteRequest, `${UrlEnum.TIME_WINDOW}${action.payload.id}/`);
		yield put(actions.fetchTimeWindows());
		yield put(actions.deleteTimeWindowSuccess({
			message: 'pages.time.windowRemoved',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.deleteTimeWindowFail(err));
	}
}

function * watchFetchTimeWindows() {
	yield takeLatest(actions.fetchTimeWindows, fetchTimeWindows);
}

function * watchDeleteTimeWindow() {
	yield takeEvery(actions.deleteTimeWindow, deleteTimeWindow);
}

function * watchCreateTimeWindow() {
	yield takeEvery(actions.createTimeWindow, createTimeWindow);
}

function * watchUpdateTimeWindow() {
	yield takeEvery(actions.updateTimeWindow, updateTimeWindow);
}

const timeWindowsSagas = [
	watchFetchTimeWindows(),
	watchDeleteTimeWindow(),
	watchCreateTimeWindow(),
	watchUpdateTimeWindow(),
];

export default timeWindowsSagas;
