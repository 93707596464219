import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import * as authActions from 'store/auth/actions';
import * as authSelectors from 'store/auth/selectors';
import LoginForm from 'pages/Login/LoginForm';
import { ILoginFormValues } from 'model/interfaces/forms/ILoginFormValues';
import { ReduxState } from 'store/reducer';
import { RoutesEnum } from 'model/enums/RoutesEnum';
import imgLogo from 'images/logo.png';
import styles from './index.module.scss';
import EuFooter from "components/EuFooter";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const Login: React.FC<Props> = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { isLoggedIn, loading } = useSelector((state: ReduxState) => ({
		isLoggedIn: authSelectors.isLoggedIn(state.auth),
		loading: authSelectors.isLoading(state.auth),
	}));

	React.useEffect(() => {
		if (isLoggedIn) {
			history.push(RoutesEnum.HOMEPAGE);
		}
	}, [isLoggedIn, history]);

	const onSubmit = async (values: ILoginFormValues) => {
		if (loading) {
			return;
		}

		dispatch(authActions.login(values));
	};

	return (
		<div className="vw-100 vh-100 d-flex align-items-center justify-content-center">
			<div>
				<div className={`${styles.panel} panel p-4 mb-6`}>
					<div className="text-center pt-5 pb-7">
						<img
							src={imgLogo}
							alt="Green Your Route"
							width={100}
						/>
					</div>

					<LoginForm onSubmit={ onSubmit }/>
				</div>
				<EuFooter/>
			</div>
		</div>
	);
};

export default Login;
