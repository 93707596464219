import React, { MouseEventHandler } from 'react';

import styles from './index.module.scss';
import { ICustomer } from 'model/interfaces/ICustomer';
import Dropdown, { DropdownMenuProps } from 'components/Popover';
import CustomerForm from 'pages/Customers/CustomerForm';
import BaseModal from 'components/modals/BaseModal';
import { useDispatch } from 'react-redux';
import * as actions from 'pages/Customers/store/actions';
import {useTranslation} from "react-i18next";
import MapTargetButton from "../Buttons/MapTargetButton";

type BaseProps = {
	number: number,
	customer: ICustomer,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const CustomerCard: React.FC<Props> = ({ customer, number }) => {
	const [ isFormOpened, setIsFormOpened ] = React.useState(false);
	const closeForm = () => setIsFormOpened(false);

	const { t } = useTranslation();

	return (
		<>
			<BaseModal
				isOpen={ isFormOpened }
				onRequestClose={ closeForm }
				title={t('cards.customerForm')}
			>
				<CustomerForm
					onSuccess={ closeForm }
					initialValues={ customer }
				/>
			</BaseModal>

			<div
				className={ `${styles.containerClickable}` }
				onClick={ () => setIsFormOpened(true) }
			>
				<div className={ styles.numberGreen }>
					{ number }
				</div>
				<h3>{customer.name}</h3>
				{customer.addresses.map((address, index) => (
					<address className={`${index > 0 ? 'mt-2' : ''} mb-0`} key={index}>
						{address.address}<br />
						{address.city} {address.postal_code}
					</address>
				))}
				<Dropdown menu={ ({hide}) => <DropdownMenu
					hide={ hide }
					customer={customer}
				/> }>
					{ ({onClick}) => (
						<button
							className={ `btn mr-1 ${ styles.ellipsis }` }
							onClick={ onClick }
						>
							<i className="far fa-ellipsis-h" />
						</button>
					) }
				</Dropdown>

				<MapTargetButton customer={customer} />
			</div>
		</>
	);
};

const DropdownMenu: React.FC<DropdownMenuProps & { customer: ICustomer }> = ({ hide, customer }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const onClick: MouseEventHandler = (e) => {
		hide(e);

		if (window.confirm(t('cards.deleteCustomer'))) {
			dispatch(actions.deleteCustomer(customer));
		}
	}

	return (
		<>
			<button
				onClick={ onClick }
				className="dropdown-item text-danger"
			>
				Delete
			</button>
		</>
	);
};

export default CustomerCard;
