import React from 'react';
import { FieldProps } from 'formik';
import { uniqueId as lodashUniqueId } from 'lodash';

import InputWrapper from 'components/Form/InputWrapper';
import { fieldClassName } from 'model/helpers/form';
import { CommonInputProps } from 'components/Form/CommonInputProps';

type BaseProps = {
	type?: 'text'|'number'|'password',
	append?: string|React.ReactChild,
} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const TextInput: React.FC<Props> = props => {
	const id = lodashUniqueId(`text-input-${props.field.name}`);

	return (
		<InputWrapper { ...props } id={ id }>
			<div className="input-group">
				<input
					id={ id }
					className={ fieldClassName(props.meta) }
					type={props.type || 'text'}
					placeholder={ props.placeholder }
					{ ...props.field }
				/>
				{ props.append &&
				<div className="input-group-append">
					<span className="input-group-text">{ props.append }</span>
				</div>
				}
			</div>
		</InputWrapper>
	);
};

export default TextInput;
