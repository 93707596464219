import { takeLatest, put, select, call } from 'redux-saga/effects';

import * as actions from './actions';
import { apiFactory } from 'model/services/Api/Api';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { AxiosResponse } from 'axios';
import { IUser } from 'model/interfaces/IUser';
import { ReduxState } from 'store/reducer';
import { ActionType } from 'deox';
import {toast} from "react-toastify";

function* fetchLoggedInUser(): any {
	const api = apiFactory(yield select());

	try {
		const userId = yield select((state: ReduxState) => state.auth.data!.id);
		const userResponse: AxiosResponse<IUser> =
			yield call(api.getRequest, `${UrlEnum.USER}${userId}/`);
		yield put(actions.fetchLoggedInUserSuccess(userResponse.data));

	} catch (err) {
		toast.error(err.message);
	}
}

function* updateLoggedInUser(action: ActionType<typeof actions.updateLoggedInUser>): any {
	const api = apiFactory(yield select());

	const { id, demonstrator, ...payload } = action.payload;

	try {
		yield call(api.patchRequest, `${UrlEnum.USER}${id}/`, payload);
		yield put(actions.fetchLoggedInUser());
		yield put(actions.updateLoggedInUserSuccess({
			message: 'store.userUpdated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.updateLoggedInUserFail(err));
	}
}

function* watchFetchLoggedInUser() {
	yield takeLatest(actions.fetchLoggedInUser, fetchLoggedInUser);
}

function* watchUpdateLoggedInUser() {
	yield takeLatest(actions.updateLoggedInUser, updateLoggedInUser);
}

const loggedInUserSagas = [
	watchFetchLoggedInUser(),
	watchUpdateLoggedInUser(),
];

export default loggedInUserSagas;
