export enum UrlEnum {
	USER = 'user/',
	API_TOKEN_AUTH = 'api-token-auth/',
	VEHICLE = 'vehicle/',
	PLAN = 'plan/',
	ROUTE = 'route/',
	ORDER = 'order/',
	SEMITRAILER = 'semitrailer/',
	DEMONSTRATOR = 'demonstrator/',
	TIME_WINDOW = 'timewindow/',
	SHIFT = 'shift/',
	PACKAGE = 'package/',
	VEHICLE_ABILITY = 'vehicleability/',
	MATERIAL_TAG = 'materialtag/',
	CUSTOMER = 'customer/',
	CUSTOMER_IMPORT = 'customer/import_customers/',
	ADDRESS = 'address/',
	ASSIGNMENT = 'assignment/',
	FUEL_TYPE = 'fueltype/',
	ENGINE_TYPE = 'enginetype/',
	VEHICLE_DESCRIPTION = 'vehicledescription/',
	VEHICLE_RESTRICTION = 'vehiclerestriction/',
}
