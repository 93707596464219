import React  from 'react';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import { toast } from 'react-toastify';

import Footer from 'pages/Planning/Footer';
import * as actions from 'pages/Planning/store/actions';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import { useDispatch, useSelector } from 'react-redux';
import VehicleDescription from 'pages/Planning/VehicleDescription';
import Checkbox from 'components/Form/Checkbox';
import stylesLayout from 'pages/Layout.module.scss';
import Layout from 'pages/Layout';
import { IPlanningRoute } from 'model/interfaces/IRoute';
import { ReduxState } from 'store/reducer';
import * as selectors from 'pages/Planning/store/selectors';
import FormikOnChange from 'components/Form/FormikOnChange';
import {useTranslation} from "react-i18next";
import i18n from "../../../i18n";

type Props = {};

export type VehicleSelectionFormProps = {
	enabled: {
		[key: string]: boolean
	},
}

const AssignedOrdersVehicleSelection: React.FC<Props> = props => {

	const dispatch = useDispatch();

	const { enabledVehicles } = useSelector((state: ReduxState) => ({
		enabledVehicles: selectors.getAllEnabledVehicles(state),
	}))

	const selectedVehicles = {
		enabled: enabledVehicles.reduce((acc, route) => ({
			...acc,
			[route!.vehicle!.id!]: route?.selected,
		}), {})
	};

	const onChange = (values: VehicleSelectionFormProps, vehicleId: number) => {
		const route: IPlanningRoute = {
			enabled: true,	// must be enabled
			selected: values.enabled[vehicleId],
		};

		dispatch(actions.setRoute(route, vehicleId));
	};

	const { t } = useTranslation();

	const onSubmit = (values: VehicleSelectionFormProps) => {
		if (Object.values(values.enabled).every(value => !value)) {
			toast.error(i18n.t('pages.vehicleMustSelected'));
			return;
		}

		dispatch(actions.setStep(PlanningStepsEnum.ASSIGNED_ORDERS));
	}

	return (
		<Formik
			onSubmit={ onSubmit }
			initialValues={ selectedVehicles }
		>
			<Form>
				<Layout className={ stylesLayout.layoutPlanning }>
					{props.children}
					<>
						<div className="d-flex align-items-center justify-content-center">
							<div className="panel">
								<div className="panel-header">
									<h2 className="mb-3">{t('pages.vehiclesOrders')}</h2>
								</div>
								<div className="panel-body">
									<table className="table bg-white table-outline mb-3">
										<tbody>
											{ enabledVehicles.map((route, index) => (
												<tr key={index}>
													<FormikOnChange<VehicleSelectionFormProps>
														onChange={(values: VehicleSelectionFormProps) =>
															onChange(values, route!.vehicle!.id)
														}
													/>
													<td>
														<Field name={`enabled[${route!.vehicle!.id!}]`}>
															{(props: FieldProps) =>
																<Checkbox
																	label={<VehicleDescription vehicle={route?.vehicle!}/>}
																	className="form-check"
																	{...props}
																/>
															}
														</Field>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>


						<Footer>
							<button
								className="btn"
								onClick={() => dispatch(actions.setStep(PlanningStepsEnum.PARAMETERS))}
							>
								{t('pages.backSubmit')}
							</button>
							<button
								className="btn btn-primary"
								type="submit"
							>
								{t('pages.nextSubmit')}
							</button>
						</Footer>
					</>
				</Layout>
			</Form>
		</Formik>
	)


};

export default AssignedOrdersVehicleSelection;