import { combineReducers } from 'redux';

import auth, { IAuthState } from './auth/reducer';
import vehicles, { IVehiclesState } from '../pages/Vehicles/store/reducer';
import orders, { IOrdersState } from '../pages/Orders/store/reducer';
import routes, { IRoutesState } from './route/reducer';
import executionDate, { ExecutionDateState } from './executionDate/reducer';
import planning, { IPlanningState } from 'pages/Planning/store/reducer';
import semitrailers, { ISemitrailersState }  from 'pages/Semitrailers/store/reducer';
import drivers, { IDriversState } from 'pages/Drivers/store/reducer';
import timeWindows, { ITimeWindowsState } from 'pages/TimeWindows/store/reducer';
import shifts, { IShiftsState } from 'pages/Shifts/store/reducer';
import packages, { IPackagesState } from 'pages/Packages/store/reducer';
import vehicleAbilities, { IVehicleAbilitiesState } from 'pages/VehicleAbilities/store/reducer';
import materialTags, { IMaterialTagsState } from 'pages/MaterialTags/store/reducer';
import customers, { ICustomersState } from 'pages/Customers/store/reducer';
import addresses, { IAddressesState } from 'pages/Addresses/store/reducer';
import loggedInUser, { ILoggedInUserState } from './loggedInUser/reducer';
import shift, { IShiftState } from 'store/shift/reducer';
import vehicleRestrictions, { IVehicleRestrictionsState } from 'pages/VehicleRestrictions/store/reducer';
import plan, { IPlanState } from 'store/plan/reducer';


// export enum ReduxStateEnum {
// 	AUTH = 'auth',
// 	VEHICLES = 'vehicles',
// 	ROUTES = 'routes',
// 	ORDERS = 'orders',
// 	EXECUTION_DATE = 'executionDate',
// 	PLANNING = 'planning',
// 	SEMITRAILERS = 'semitrailers',
// 	DRIVERS = 'drivers',
// 	TIME_WINDOWS = 'timeWindows',
// 	SHIFTS = 'shifts',
// 	PACKAGES = 'packages',
// 	VEHICLE_ABILITIES = 'vehicleAbilities',
// 	MATERIAL_TAGS = 'materialTags',
// 	CUSTOMERS = 'customers',
// }

export interface ReduxState {
	auth: IAuthState,
	vehicles: IVehiclesState,
	routes: IRoutesState,
	orders: IOrdersState,
	executionDate: ExecutionDateState,
	planning: IPlanningState,
	semitrailers: ISemitrailersState,
	drivers: IDriversState,
	timeWindows: ITimeWindowsState,
	shifts: IShiftsState,
	packages: IPackagesState,
	vehicleAbilities: IVehicleAbilitiesState,
	vehicleRestrictions: IVehicleRestrictionsState,
	materialTags: IMaterialTagsState,
	customers: ICustomersState,
	addresses: IAddressesState,
	loggedInUser: ILoggedInUserState,
	shift: IShiftState,
	plan: IPlanState,
}

export default function createReducers<ReduxState>() {
	return combineReducers({
		auth,
		vehicles,
		routes,
		orders,
		executionDate,
		planning,
		semitrailers,
		drivers,
		timeWindows,
		shifts,
		packages,
		vehicleAbilities,
		vehicleRestrictions,
		materialTags,
		customers,
		addresses,
		loggedInUser,
		shift,
		plan,
	})
}