import NavLink from 'components/Header/NavLink';
import { matchRoute } from 'routes';
import { RoutesEnum } from 'model/enums/RoutesEnum';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Subnav = () => {

	const { t } = useTranslation();

	return (

	<ul className="navbar-nav border-top overflow-auto">
		<NavLink
			route={ matchRoute(RoutesEnum.PROFILE) }
			label={t('navbar.profile')}
			subnav
		/>
		<NavLink
			route={ matchRoute(RoutesEnum.CUSTOMERS) }
			label={t('navbar.customers')}
			subnav
		/>
		{/*<NavLink*/}
		{/*	route={ matchRoute(RoutesEnum.ADDRESSES) }*/}
		{/*	label="Addresses"*/}
		{/*	subnav*/}
		{/*/>*/}
		<NavLink
			route={ matchRoute(RoutesEnum.VEHICLES) }
			label={t('navbar.vehicles')}
			subnav
		/>
		<NavLink
			route={ matchRoute(RoutesEnum.VEHICLE_ABILITIES) }
			label={t('navbar.vehicleAbilities')}
			subnav
		/>
		<NavLink
			route={ matchRoute(RoutesEnum.VEHICLE_RESTRICTIONS) }
			label={t('navbar.vehicleRestrictions')}
			subnav
		/>
		<NavLink
			route={ matchRoute(RoutesEnum.SEMITRAILERS) }
			label={t('navbar.semitrailers')}
			subnav
		/>
		<NavLink
			route={ matchRoute(RoutesEnum.DRIVERS) }
			label={t('navbar.drivers')}
			subnav
		/>
		{/*<NavLink*/}
		{/*	route={ matchRoute(RoutesEnum.TIME_WINDOWS) }*/}
		{/*	label="Time Windows"*/}
		{/*	subnav*/}
		{/*/>*/}
		<NavLink
			route={ matchRoute(RoutesEnum.SHIFTS) }
			label={t('navbar.shifts')}
			subnav
		/>
		<NavLink
			route={ matchRoute(RoutesEnum.PACKAGES) }
			label={t('navbar.packages')}
			subnav
		/>
		<NavLink
			route={ matchRoute(RoutesEnum.MATERIAL_TAGS) }
			label={t('navbar.materialTags')}
			subnav
		/>
		{/*<NavLink*/}
		{/*	route={ matchRoute(RoutesEnum.PARAMETERS)}*/}
		{/*	label="Parameters"*/}
		{/*	subnav*/}
		{/*/>*/}
	</ul>
	)};

export default Subnav;