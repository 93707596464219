import styles from 'pages/Planning/AssignedOrders/index.module.scss';
import Droppable from 'pages/Planning/AssignedOrders/Droppable';
import React, { useEffect, useMemo, useState } from 'react';
import { IAssignment } from 'model/interfaces/IAssignment';
import SearchFulltext from 'components/SearchFulltext';
import { useSearchedEntity } from 'model/hooks/useSearchedEntity';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IGenericSearchRequest } from 'model/services/Api/interfaces/requests/IGenericSearchRequest';
import { IOrder } from 'model/interfaces/IOrder';
import FiltersButton from 'components/Buttons/FiltersButton';
import OrderFilters from 'components/OrderFilters';
import { isoDayFromUTCDatetime } from 'model/helpers/datetime';
import OrderBadges from 'components/OrderTypeBadge/OrderBadges';
import { OrderTypeBadgeEnum } from 'components/OrderTypeBadge/OrderTypeBadgeEnum';
import { IPlan } from 'model/interfaces/IPlan';
import {useTranslation} from "react-i18next";

type Props = {
	assignments: Partial<IAssignment>[],
	plan: IPlan,
	className?: string,
}

const ListOfOrders = ({ assignments, className, ...props }: Props) => {
	const orders = useMemo(() => assignments.map(assignment => assignment.order!), [assignments]);

	const [ searchedOrders, onSearch, activeFiltersCount, isFiltersLoading ] = useSearchedEntity<IOrder, IGenericSearchRequest>(
		UrlEnum.ORDER,
		orders,
		{
			plan_date: isoDayFromUTCDatetime(props.plan.date),
			plan_shift: props.plan.shift.id,
			assigned: 0,
		}
	);

	const [ areFiltersVisible, setFiltersVisible ] = useState(false);

	const searchOrdersIds = searchedOrders.map(order => order.id);
	const searchedAssignments = assignments.filter(assignment => searchOrdersIds.includes(assignment.order!.id));
	const { t } = useTranslation();

	return (
		<div className={`${styles.orders} ${className || ''}`}>
			<div className="panel pb-1 d-flex flex-column maxh-100 z-index-draggable">
				<div className="panel-header pb-1">
					<div className="row align-items-center pb-2">
						<div className="col">
							<h2 className="m-0">{t('pages.planning.listOrders')}</h2>
						</div>
						<div className="col text-right">
							<FiltersButton
								onClick={() => setFiltersVisible(!areFiltersVisible) }
								activeFiltersCount={ activeFiltersCount }
								isLoading={ isFiltersLoading }
							/>
						</div>
					</div>

					<div className={`bg-gray-200 rounded pt-3 px-3 pb-1 mb-3 ${!areFiltersVisible ? 'd-none' : ''}`}>
						<OrderFilters
							onSearch={onSearch}
						/>
					</div>

					<div className="mb-1">
						<OrderBadges
							orders={ orders }
							onSearch={ onSearch }
							hide={ [OrderTypeBadgeEnum.TOTAL, OrderTypeBadgeEnum.COMPLETED, OrderTypeBadgeEnum.CANCELLED, OrderTypeBadgeEnum.UNASSIGNED ] }
						/>
					</div>

					<SearchFulltext placeholder="" onSearch={ onSearch } />
				</div>
				<Droppable droppableId="0" assignments={searchedAssignments} />
			</div>
		</div>
	);
};

export default ListOfOrders;