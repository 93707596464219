import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { setLocale } from 'yup';

import en from './locales/en.json'
import cs from './locales/cs.json'
import el from './locales/el.json'
import it from './locales/it.json'

setLocale({
    mixed: {
        notType: 'yup.notType',
    },
});

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        translation: en
      },
      cs: {
        translation: cs
      },
      el: {
        translation: el
      },
      it: {
        translation: it
      }
    },
    lng: 'en',
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;