import { createActionCreator } from 'deox';

import { IAuthStateData } from 'store/auth/reducer';
import { IApiError } from 'model/interfaces/IApiError';
import { IApiTokenAuthRequest } from 'model/services/Api/interfaces/requests/IApiTokenAuthRequest';

export enum AuthActionsEnum {
	LOGIN = '@AUTH_LOGIN_STARTED',
	LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS',
	LOGIN_FAIL = '@AUTH_LOGIN_FAIL',
	DESTROY = '@AUTH_DESTROY',
}

export const login = createActionCreator(
	AuthActionsEnum.LOGIN, resolve => (loginValues: IApiTokenAuthRequest) => resolve(loginValues),
);

export const loginSuccess = createActionCreator(
	AuthActionsEnum.LOGIN_SUCCESS, resolve => (data: IAuthStateData) => resolve(data),
);

export const loginFail = createActionCreator(
	AuthActionsEnum.LOGIN_FAIL, resolve => (error: IApiError) => resolve(error),
);

export const destroy = createActionCreator(AuthActionsEnum.DESTROY);
