import { FieldMetaProps } from 'formik';

export const fieldClassName = (meta: FieldMetaProps<any>): string => {
	const baseClass = `form-control`;

	if (meta.touched && meta.error) {
		return `${ baseClass } is-invalid`
	}

	return baseClass;
}
