import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import Footer from 'pages/Planning/AssignedOrders/Footer';
import { ReduxState } from 'store/reducer';
import stylesLayout from 'pages/Layout.module.scss';
import Layout from 'pages/Layout';
import * as selectors from 'pages/Planning/store/selectors';
import * as actions from 'pages/Planning/store/actions';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';
import VehicleRouteCard from 'components/VehicleRouteCard';
import { IRoute } from 'model/interfaces/IRoute';
import DetailMap from 'pages/Homepage/DetailMap';
import Droppable from 'pages/Planning/AssignedOrders/Droppable';
import { usePlanningRerouting } from 'model/hooks/usePlanningRerouting';
import ListOfOrders from 'pages/Planning/AssignedOrders/ListOfOrders';

type Props = {};

const RerouteSingle: React.FC<Props> = (props) => {
	const { route, plan } = useSelector((state: ReduxState) => ({
		route: selectors.getRoute(state),
		plan: state.planning.plan.data!,
	}));

	const [ listOfOrdersHigherZindex, setListOfOrdersHigherZindex ] = useState(false);

	const dispatch = useDispatch();

	const { routeAssignments, onDragEnd, isPristine, setPristine } = usePlanningRerouting([{
		route,
		assignments: route!.assignments!,
	}]);

	if (!route) {
		dispatch(actions.setStep(PlanningStepsEnum.SOLUTION));
		return null;
	}

	const onDragStart = (result: DropResult) => {
		if (result.source.droppableId === '0') {
			setListOfOrdersHigherZindex(true);
		}
	}

	return (
		<DragDropContext
			onDragEnd={ result => {
				onDragEnd(result);
				setListOfOrdersHigherZindex(false);
			} }
			onDragStart={ onDragStart }
		>
			<Layout className={ stylesLayout.layoutPlanningRerouteSingle }>

				<aside className={ `${stylesLayout.aside}` }>
					<div className="panel panel--flex py-1 over-map">
						<>
							<div className="overflow-auto flex-grow-1">
								<div className="panel-body px-0">
									<div className="panel-body overflow-initial pt-3">
										<VehicleRouteCard route={ route as IRoute }/>

										{/*<button className="btn btn-sm">*/}
										{/*	<i className="fa fa-sliders-h mr-2"/>*/}
										{/*	<span>Filters</span>*/}
										{/*</button>*/}

										{/*<div className="my-1">*/}
										{/*	<OrderBadges*/}
										{/*		orders={route.assignments?.map(assignment => assignment.order) ?? [] }*/}
										{/*		hide={[ OrderTypeBadgeEnum.UNASSIGNED, OrderTypeBadgeEnum.COMPLETED, OrderTypeBadgeEnum.CANCELLED ]}*/}
										{/*	/>*/}
										{/*</div>*/}

										{/*<SearchFulltext placeholder="" onSearch={ () => {} }/>*/}
									</div>

									<Droppable droppableId="1" assignments={routeAssignments[1].assignments} />
								</div>
							</div>
						</>
					</div>
				</aside>

				<ListOfOrders
					assignments={ routeAssignments[0].assignments }
					className={ listOfOrdersHigherZindex ? 'z-index-draggable' : '' }
					plan={plan}
				/>

				<DetailMap
					route={route as IRoute}
					plan={plan}
				/>

				<Footer
					back={PlanningStepsEnum.SOLUTION}
					isPristine={isPristine}
					setPristine={setPristine}
					routeAssignments={routeAssignments}
				/>
			</Layout>

		</DragDropContext>

	)


};

export default RerouteSingle;