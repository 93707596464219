import OrderTypeBadge from 'components/OrderTypeBadge/index';
import { OrderTypeBadgeEnum } from 'components/OrderTypeBadge/OrderTypeBadgeEnum';
import { getNoOfOrders } from 'model/helpers/orders';
import { OrderTypeEnum } from 'model/enums/OrderTypeEnum';
import { OrderStatusEnum } from 'model/enums/OrderStatusEnum';
import React, { useState } from 'react';
import { IOrder } from 'model/interfaces/IOrder';
import { IOrderRequest } from 'model/services/Api/interfaces/requests/IOrderRequest';
import { orderTypeBadgeEnumToOrderStatus, orderTypeBadgeEnumToOrderType } from 'components/OrderTypeBadge/helpers';
import { useTranslation } from 'react-i18next';

type Props = {
	orders?: IOrder[],
	hide?: Array<OrderTypeBadgeEnum>,
	showDone?: boolean,
	onSearch?(request: IOrderRequest): void,
	statusName?: 'order_status'|'status'
};

const DONE_STATES = [ OrderStatusEnum.COMPLETED, OrderStatusEnum.CANCELLED ];

const OrderBadges: React.FC<Props> = ({ orders, onSearch, hide = [], showDone = false, ...props }) => {

	const [ selected, setSelected ] = useState<OrderTypeBadgeEnum>();

	const doneTotal = getNoOfOrders(orders, DONE_STATES);
	const totalTotal = getNoOfOrders(orders);

	const doneDeliveries = getNoOfOrders(orders, DONE_STATES, OrderTypeEnum.DELIVERY);
	const totalDeliveries = getNoOfOrders(orders, undefined, OrderTypeEnum.DELIVERY);

	const donePickups = getNoOfOrders(orders, DONE_STATES, OrderTypeEnum.PICKUP);
	const totalPickups = getNoOfOrders(orders, undefined, OrderTypeEnum.PICKUP);

	const doneRequisitions = getNoOfOrders(orders, DONE_STATES, OrderTypeEnum.REQUISITION);
	const totalRequisitions = getNoOfOrders(orders, undefined, OrderTypeEnum.REQUISITION);

	const onClick = (type: OrderTypeBadgeEnum) => {
		const newSelected = type === selected ? undefined : type;
		setSelected(newSelected);

		if (onSearch) {
			const request: IOrderRequest = {};

			request.order_type = orderTypeBadgeEnumToOrderType(newSelected);
			request[props.statusName || 'status'] = orderTypeBadgeEnumToOrderStatus(newSelected);

			onSearch(request);
		}
	}

	const { t } = useTranslation();

	return (
		<>
			{(!hide || !hide.includes(OrderTypeBadgeEnum.TOTAL)) &&
			<OrderTypeBadge
				type={OrderTypeBadgeEnum.TOTAL}
				value={`${showDone ? `${doneTotal} / ` : ''}${totalTotal}`}
				label={t('order.total')}
				unselected={selected !== undefined && selected !== OrderTypeBadgeEnum.TOTAL}
				onClick={onClick}
			/>
			}
			<OrderTypeBadge
				type={ OrderTypeBadgeEnum.DELIVERIES }
				value={ `${showDone ? `${doneDeliveries} / ` : ''}${totalDeliveries}` }
				label={t('order.deliveries')}
				unselected={ selected !== undefined && selected !== OrderTypeBadgeEnum.DELIVERIES }
				onClick={ onClick }
			/>
			<OrderTypeBadge
				type={ OrderTypeBadgeEnum.PICKUPS }
				value={ `${showDone ? `${donePickups} / ` : ''}${totalPickups}` }
				label={t('order.pickups')}
				unselected={ selected !== undefined && selected !== OrderTypeBadgeEnum.PICKUPS }
				onClick={ onClick }
			/>
			<OrderTypeBadge
				type={ OrderTypeBadgeEnum.REQUISITIONS }
				value={ `${showDone ? `${doneRequisitions} / ` : ''}${totalRequisitions}` }
				label={t('order.requisitions')}
				unselected={ selected !== undefined && selected !== OrderTypeBadgeEnum.REQUISITIONS }
				onClick={ onClick }
			/>
			{ (!hide || !hide.includes(OrderTypeBadgeEnum.UNASSIGNED)) &&
			<OrderTypeBadge
				type={ OrderTypeBadgeEnum.UNASSIGNED }
				value={ getNoOfOrders(orders, [OrderStatusEnum.NEW]).toString() }
				label={t('order.unassigned')}
				unselected={ selected !== undefined && selected !== OrderTypeBadgeEnum.UNASSIGNED }
				onClick={ onClick }
			/>
			}

			{ (!hide || !hide.includes(OrderTypeBadgeEnum.COMPLETED)) &&
			<OrderTypeBadge
				type={ OrderTypeBadgeEnum.COMPLETED }
				value={ getNoOfOrders(orders, [OrderStatusEnum.COMPLETED]).toString() }
				label={t('order.completed')}
				unselected={ selected !== undefined && selected !== OrderTypeBadgeEnum.COMPLETED }
				onClick={ onClick }
			/>
			}

			{ (!hide || !hide.includes(OrderTypeBadgeEnum.CANCELLED)) &&
			<OrderTypeBadge
				type={ OrderTypeBadgeEnum.CANCELLED }
				value={ getNoOfOrders(orders, [OrderStatusEnum.CANCELLED]).toString() }
				label={t('order.cancelled')}
				unselected={ selected !== undefined && selected !== OrderTypeBadgeEnum.CANCELLED }
				onClick={ onClick }
			/>
			}
		</>
	)
}

export default OrderBadges;