import { createReducer } from 'deox';

import * as actions from 'pages/Orders/store/actions';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IOrder } from 'model/interfaces/IOrder';

export interface IOrdersState extends IReduxFetchedState<IPaginatedData<IOrder>> {}

const initialState: IOrdersState = {};

export default createReducer(initialState, handle => [
	handle(actions.fetchOrdersFail, (state, { payload }) => ({
		...state,
		loading: false,
		error: { ...payload },
	})),
	handle(actions.fetchOrders, (state) => ({
		...state,
		loading: false,
		error: undefined,
	})),
	handle(actions.fetchOrdersSuccess, (state, { payload }) => ({
		...state,
		data: { ...payload },
		loading: false,
		error: undefined,
	})),
	handle(actions.createOrder, (state) => ({
		...state,
		formLoading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.updateOrder, (state) => ({
		...state,
		formLoading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.formOrderSuccess, (state, { payload }) => ({
		...state,
		formLoading: false,
		formSuccess: { ...payload },
		formError: undefined,
	})),
	handle(actions.formOrderFail, (state, { payload }) => ({
		...state,
		formLoading: false,
		formSuccess: undefined,
		formError: { ...payload },
	})),
	handle(actions.formOrderRestart, (state) => ({
		...state,
		formLoading: false,
		formSuccess: undefined,
		formError: undefined,
	})),
]);
