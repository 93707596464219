import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
	brand: Yup.string().required('pages.vehicles.brandRequired'),
	plate: Yup.string().required('pages.vehicles.plateRequired'),
	engine: Yup.string().required('pages.vehicles.engineRequired'),
	fuel: Yup.string().required('pages.vehicles.fuelRequired'),
	description: Yup.string().required('pages.vehicles.descriptionRequired'),
	capacity_weight: Yup.string().required('pages.vehicles.weightRequired'),
	capacity_volume: Yup.string().required('pages.vehicles.volumeRequired'),
});

export default validationSchema;