import { call, put, race, select, take, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';

import * as actions from 'pages/Orders/store/actions';
import { apiFactory } from 'model/services/Api/Api';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IOrdersResponse } from 'model/services/Api/interfaces/responses/IOrdersResponse';
import { delay } from 'model/helpers/api';
import { POLLING_RATE } from 'model/constants';
import { ReduxState } from 'store/reducer';
import { ActionType } from 'deox';
import { InnerRestrictionTypeEnum } from 'model/enums/InnerRestrictionTypeEnum';
import { IMaterialTag } from 'model/interfaces/IMaterialTag';
import { IOrder } from 'model/interfaces/IOrder';
import { ITimeWindow } from 'model/interfaces/ITimeWindow';
import * as planningActions from 'pages/Planning/store/actions';
import { isoDayFromUTCDatetime } from 'model/helpers/datetime';
import { roundToMaxTwoDecimalPlaces } from 'model/helpers/numbers';
import {useTranslation} from "react-i18next";
import i18n from '../../../i18n';

function * pollOrders(action: ActionType<typeof actions.startPolling>) {
	//
	// NOT CURRENTLY USED - MUST BE FIXED
	//
	// while (true) {
	// 	const store: ReduxState = yield select();
	// 	const api = apiFactory(store);
	// 	const executionDate = action.payload;
	//
	// 	try {
	// 		const response: IOrdersResponse = yield call(api.getRequest, UrlEnum.ORDER, {
	// 			params: {
	// 				plan_date: isoDayFromUTCDatetime(executionDate),
	// 			},
	// 		});
	// 		yield put(actions.fetchOrdersSuccess(response.data));
	// 		yield call(delay, POLLING_RATE);
	// 	} catch (err) {
	// 		console.error(err);
	// 		yield put(actions.fetchOrdersFail(err));
	// 		yield put(actions.stopPolling());
	// 	}
	// }
}

function * fetchOrders() {
	const store: ReduxState = yield select();
	const api = apiFactory(store);
	const executionDate = store.executionDate;
	const shiftId = store.shift.id;

	try {
		const response: IOrdersResponse = yield call(api.getRequest, UrlEnum.ORDER, {
			params: {
				plan_date: isoDayFromUTCDatetime(executionDate),
				plan_shift: shiftId,
			},
		});
		yield put(actions.fetchOrdersSuccess(response.data));
	} catch (err) {
		yield put(actions.fetchOrdersFail(err));
	}
}

function * watchPolling() {
	while (true) {
		const action = yield take(actions.startPolling);
		yield race([call(pollOrders, action), take(actions.stopPolling)]);
	}
}

function * createOrder(action: ActionType<typeof actions.createOrder>) {
	const state: ReduxState = yield select();
	const api = apiFactory(state);
	const { order, fetchAction, planId } = action.payload;

	const payload = preparePayloadForCreateOrUpload(order, planId);

	try {
		yield call(api.postRequest, UrlEnum.ORDER, payload);
		if (fetchAction) {
			yield put(fetchAction());
		}
		// yield all([
		// 	put(actions.fetchOrders()),
		// 	put(planningActions.fetchOrders()),
		// ]);
		yield put(actions.formOrderSuccess({
			message: 'pages.orderCreated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formOrderFail(err));
	}
}

function * updateOrder(action: ActionType<typeof actions.updateOrder>) {
	const state: ReduxState = yield select();
	const api = apiFactory(state);
	const { order, fetchAction, planId } = action.payload;

	const payload = preparePayloadForCreateOrUpload(order, planId);

	try {
		yield call(api.patchRequest, `${UrlEnum.ORDER}${order.id}/`, payload);
		if (fetchAction) {
			yield put(fetchAction());
		}
		// yield all([
		// 	put(actions.fetchOrders()),
		// 	put(planningActions.fetchOrders()),
		// ]);
		yield put(actions.formOrderSuccess({
			message: i18n.t('pages.orderUpdated'),
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formOrderFail(err));
	}
}

function * deleteOrder(action: ActionType<typeof actions.deleteOrder>) {
	const state: ReduxState = yield select();
	const api = apiFactory(state);
	const { order, fetchAction } = action.payload;

	try {
		yield call(api.deleteRequest, `${UrlEnum.ORDER}${order.id}/`);
		if (fetchAction) {
			yield put(fetchAction());
		}
		toast.success(i18n.t('pages.orderDeleted'));

	} catch (err) {
		console.error(err);
		toast.error(i18n.t('pages.orderDelFailed'));
	}
}

function * watchCreateOrder() {
	yield takeEvery(actions.createOrder, createOrder);
}

function * watchUpdateOrder() {
	yield takeEvery(actions.updateOrder, updateOrder);
}

function * watchDeleteOrder() {
	yield takeEvery(actions.deleteOrder, deleteOrder);
}

function * watchFetchOrders() {
	yield takeLatest(actions.fetchOrders, fetchOrders);
}

const ordersSagas: Generator[] = [
	watchPolling(),
	watchFetchOrders(),
	watchCreateOrder(),
	watchUpdateOrder(),
	watchDeleteOrder(),
];

function preparePayloadForCreateOrUpload(_payload: IOrder, planId?: number) {
	const payload = cloneDeep(_payload) as any;
	delete payload.id;
	delete payload.created;
	delete payload.last_updated;
	delete payload.demonstrator;
	delete payload.status.id;
	delete payload.status.description;
	delete payload.status.created;
	delete payload.status.last_updated;
	delete payload.status.history;

	if (payload.restriction_from) {
		if (!payload.restriction_from.time_restriction.restriction_type) {
			payload.restriction_from.time_restriction.restriction_type = InnerRestrictionTypeEnum.ALLOW;
		}
		setTimeWindowsRestrictionDefaults(payload.restriction_from.time_restriction.time_windows);
	}

	if (payload.restriction_to) {
		if (!payload.restriction_to.time_restriction.restriction_type) {
			payload.restriction_to.time_restriction.restriction_type = InnerRestrictionTypeEnum.ALLOW;
		}
		setTimeWindowsRestrictionDefaults(payload.restriction_to.time_restriction.time_windows);
	}

	payload.package = payload.package.id;

	if (payload.status.weight && payload.status.quantity) {
		payload.status.weight = roundToMaxTwoDecimalPlaces(payload.status.weight / payload.status.quantity);
	}

	if (payload.status.volume && payload.status.quantity) {
		payload.status.volume = roundToMaxTwoDecimalPlaces(payload.status.volume / payload.status.quantity);
	}

	if (payload.address_from) {
		payload.address_from = payload.address_from.id;
	}
	if (payload.address_to) {
		payload.address_to = payload.address_to.id;
	}

	if (payload.tags && payload.tags.length > 0) {
		payload.tags = payload.tags.map((tag: IMaterialTag) => tag.id);
	} else {
		payload.tags = [];
	}

	if (!payload.erp_id) {
		delete payload.erp_id;
	}

	if (payload.plan === 0) {
		delete payload.plan;
	}

	if (planId) {
		payload.plan = planId;
	}

	return payload;
}

function setTimeWindowsRestrictionDefaults(timeWindows: ITimeWindow[]) {
	for (const timeWindow of timeWindows) {
		if ( timeWindow.start_time || timeWindow.end_time ) continue;

		timeWindow.start_time = "00:00:00";
		timeWindow.end_time = "00:00:00";
	}
}

export default ordersSagas;
