import React from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { ILoginFormValues } from 'model/interfaces/forms/ILoginFormValues';
import { fieldClassName } from 'model/helpers/form';
import { useTranslation } from 'react-i18next';


type BaseProps = {
	onSubmit: (values: ILoginFormValues) => Promise<void>,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

export const LOGIN_FORM_INITIAL_VALUES: ILoginFormValues = {
	username: '',
	password: '',
};

export const LOGIN_FORM_VALIDATION_SCHEMA = Yup.object().shape({
	username: Yup.string().required('pages.required'),
	password: Yup.string().required('pages.required')
});


const LoginForm: React.FC<Props> = props => {
	const { t } = useTranslation();
	return (
		<>
			<Formik<ILoginFormValues>
				initialValues={ LOGIN_FORM_INITIAL_VALUES }
				validationSchema={ LOGIN_FORM_VALIDATION_SCHEMA }
				onSubmit={ (values, { setSubmitting }) => {
					setSubmitting(true);

					props.onSubmit(values).finally(() => {
						setSubmitting(false);
					});
				} }
				render={ formikBag => (
					<Form>
						<Field
							name="username"
							render={ ({ field, form, meta }: FieldProps) => (
								<div className="form-group">
									<input
										className={ fieldClassName(meta) }
										type="text"
										placeholder={t('pages.username')}
										id="login-username"
										{ ...field }
									/>
								</div>
							) }
						/>
						<Field
							name="password"
							render={ ({ field, form, meta }: FieldProps) => (
								<div className="form-group">
									<input
										className={ fieldClassName(meta) }
										type="password"
										placeholder={t('pages.password')}
										id="login-pass"
										{ ...field }
									/>
								</div>
							) }
						/>
						<div className="row">
							<div className="col-6">
								<button
									className="btn btn-primary"
									onClick={ formikBag.submitForm }
									disabled={ formikBag.isSubmitting }
								>
									{t('pages.signIn')}
								</button>
							</div>
							<div className="col-6 text-right">
								<button
									className="btn btn-link"
									type="button"
								>
									<small>{t('pages.forgotPassword')}</small>
								</button>
							</div>
						</div>
					</Form>
				) }
			/>
		</>
	);
};

export default LoginForm;
