import React from 'react';
import { FieldProps } from 'formik';

import BaseAsyncSelect from 'components/Form/BaseAsyncSelect';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IVehicleRestrictionEndpoint } from 'model/interfaces/IVehicleRestrictionEndpoint';

type BaseProps = {} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const VehicleRestrictionAsyncMultiSelect: React.FC<Props> = props => (
	<BaseAsyncSelect<IVehicleRestrictionEndpoint>
		url={ UrlEnum.VEHICLE_RESTRICTION }
		getOptionLabel={ option => `${option.name}` }
		isMulti
		{ ...props }
	/>
)

export default VehicleRestrictionAsyncMultiSelect;
