import { matchRoutes, RouteConfig } from 'react-router-config';

import Homepage from 'pages/Homepage';
import Login from 'pages/Login';
import { RoutesEnum } from 'model/enums/RoutesEnum';
import Vehicles from 'pages/Vehicles';
import Detail from 'pages/Homepage/Detail';
import Orders from 'pages/Orders';
import Rerouting from 'pages/Rerouting';
import Planning from 'pages/Planning';
import Semitrailers from 'pages/Semitrailers';
import Drivers from 'pages/Drivers';
import TimeWindows from 'pages/TimeWindows';
import Shifts from 'pages/Shifts';
import Packages from 'pages/Packages';
import VehicleAbilities from 'pages/VehicleAbilities';
import MaterialTags from 'pages/MaterialTags';
import Customers from 'pages/Customers';
import Addresses from 'pages/Addresses';
import Profile from 'pages/Profile';
import ReroutingSelected from 'pages/Rerouting/RerouteSelected';
import DetailRerouting from 'pages/Homepage/Rerouting';
import VehicleRestrictions from 'pages/VehicleRestrictions';

const routes: RouteConfig[] = [
	{
		path: RoutesEnum.ORDERS,
		component: Orders,
	},
	{
		path: RoutesEnum.REROUTING_SELECTED,
		component: ReroutingSelected,
	},
	{
		path: RoutesEnum.REROUTING,
		component: Rerouting,
	},
	{
		path: RoutesEnum.PLANNING,
		component: Planning,
	},
	{
		path: RoutesEnum.DETAIL_REROUTING,
		component: DetailRerouting,
	},
	{
		path: RoutesEnum.DETAIL,
		component: Detail,
	},
	{
		path: RoutesEnum.LOGIN,
		component: Login,
	},
	{
		path: RoutesEnum.PROFILE,
		component: Profile,
	},
	{
		path: RoutesEnum.CUSTOMERS,
		component: Customers,
	},
	{
		path: RoutesEnum.DEPOTS,
		component: Vehicles,
	},
	{
		path: RoutesEnum.VEHICLES,
		component: Vehicles,
	},
	{
		path: RoutesEnum.SEMITRAILERS,
		component: Semitrailers,
	},
	{
		path: RoutesEnum.DRIVERS,
		component: Drivers,
	},
	{
		path: RoutesEnum.PARAMETERS,
		component: Vehicles,
	},
	{
		path: RoutesEnum.TIME_WINDOWS,
		component: TimeWindows,
	},
	{
		path: RoutesEnum.SHIFTS,
		component: Shifts,
	},
	{
		path: RoutesEnum.PACKAGES,
		component: Packages,
	},
	{
		path: RoutesEnum.VEHICLE_ABILITIES,
		component: VehicleAbilities,
	},
	{
		path: RoutesEnum.VEHICLE_RESTRICTIONS,
		component: VehicleRestrictions,
	},
	{
		path: RoutesEnum.MATERIAL_TAGS,
		component: MaterialTags,
	},
	{
		path: RoutesEnum.ADDRESSES,
		component: Addresses,
	},
	{
		path: RoutesEnum.HOMEPAGE,
		component: Homepage,
	},
];

export const matchRoute = (path: RoutesEnum) => {
	const matchedRoutes = matchRoutes(routes, path);

	if (matchedRoutes.length === 0) {
		throw new Error(`Route is not defined for path ${path}`); // nikde se nezobrazuje, pouze v consoli
	}

	return matchedRoutes[0].route;
};

export default routes;
