import React from 'react';
import { FieldProps } from 'formik';

import BaseAsyncSelect from 'components/Form/BaseAsyncSelect';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IVehicleAbility } from 'model/interfaces/IVehicleAbility';
import { IMaterialTag } from 'model/interfaces/IMaterialTag';

type BaseProps = {
	isMulti?: boolean
} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const TagAsyncSelect: React.FC<Props> = props => (
	<BaseAsyncSelect<IMaterialTag>
		url={ UrlEnum.MATERIAL_TAG }
		getOptionLabel={ option => `${option.name}` }
		isMulti={props.isMulti ?? false}
		{ ...props }
	/>
);

export default TagAsyncSelect;
