import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from 'pages/Addresses/store/actions';
import { apiFactory } from 'model/services/Api/Api';
import { IAddressesResponse } from 'model/services/Api/interfaces/responses/IAddressesResponse';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { ActionType } from 'deox';
import { ReduxState } from 'store/reducer';
import { IAddress } from 'model/interfaces/IAddress';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';

function * fetchAddresses() {
	const api = apiFactory(yield select());

	try {
		const response: IAddressesResponse = yield call(api.getRequest, UrlEnum.ADDRESS);
		yield put(actions.fetchAddressesSuccess(response.data));
	} catch (err) {
		yield put(actions.fetchAddressesFail(err));
	}
}

function * fetchMoreAddresses() {
	const state: ReduxState = yield select();
	const api = apiFactory(state);

	try {
		const response: IAddressesResponse = yield call(api.getRequest, state.addresses.data!.next ?? '');
		yield put(actions.fetchMoreAddressesSuccess(response.data));
	} catch (err) {
		yield put(actions.fetchMoreAddressesFail(err));
	}
}

function * createAddress(action: ActionType<typeof actions.createAddress>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.postRequest, UrlEnum.ADDRESS, action.payload);
		yield put(actions.fetchAddresses());
		yield put(actions.formAddressesuccess({
			message: 'pages.addressCreated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formAddressFail(err));
	}
}

function * updateAddress(action: ActionType<typeof actions.updateAddress>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.patchRequest, `${UrlEnum.ADDRESS}${action.payload.id}/`, action.payload);
		yield put(actions.fetchAddresses());
		yield put(actions.formAddressesuccess({
			message: 'pages.addressUpdated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formAddressFail(err));
	}
}

function * deleteAddress(action: ActionType<typeof actions.deleteAddress>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.deleteRequest, `${UrlEnum.ADDRESS}${action.payload.id}/`);
		yield put(actions.fetchAddresses());
		yield put(actions.deleteAddressesuccess({
			message: 'pages.addressRemoved',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.deleteAddressFail(err));
	}
}

function * watchFetchAddresses() {
	yield takeLatest(actions.fetchAddresses, fetchAddresses);
}

function * watchFetchMoreAddresses() {
	yield takeLatest(actions.fetchMoreAddresses, fetchMoreAddresses);
}

function * watchDeleteAddress() {
	yield takeEvery(actions.deleteAddress, deleteAddress);
}

function * watchCreateAddress() {
	yield takeEvery(actions.createAddress, createAddress);
}

function * watchUpdateAddress() {
	yield takeEvery(actions.updateAddress, updateAddress);
}

const addressesSagas = [
	watchFetchAddresses(),
	watchFetchMoreAddresses(),
	watchDeleteAddress(),
	watchCreateAddress(),
	watchUpdateAddress(),
];

export default addressesSagas;
