import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';

import * as actions from 'pages/Vehicles/store/actions';
import * as planningActions from 'pages/Planning/store/actions';
import { apiFactory } from 'model/services/Api/Api';
import { IVehiclesResponse } from 'model/services/Api/interfaces/responses/IVehiclesResponse';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { ActionType } from 'deox';
import { ReduxState } from 'store/reducer';
import { IVehicle } from 'model/interfaces/IVehicle';
import { IVehicleAbility } from 'model/interfaces/IVehicleAbility';
import { IVehicleRestrictionEndpoint } from 'model/interfaces/IVehicleRestrictionEndpoint';

function * fetchVehicles() {
	const api = apiFactory(yield select());

	try {
		const response: IVehiclesResponse = yield call(api.getRequest, UrlEnum.VEHICLE);
		yield put(actions.fetchVehiclesSuccess(response.data));
	} catch (err) {
		yield put(actions.fetchVehiclesFail(err));
	}
}

function * fetchVehiclesSuccess() {
	const state: ReduxState = yield select();

	if (state.vehicles.data!.results.length > 0 && state.planning.plan?.data?.id) {
		yield put(planningActions.initRoutes(state.vehicles.data!.results.map(vehicle => ({
			vehicle,
			shift: state.planning.plan.data?.shift,
			enabled: false,		// Vehicles screen
			selected: false,	// Solution screen
			time_start: state.planning.plan.data?.shift.time_start,
			time_end: state.planning.plan.data?.shift.time_end,
		}))));
	}
}

function * createVehicle(action: ActionType<typeof actions.createVehicle>) {
	const api = apiFactory(yield select());

	const payload = preparePayloadForCreateOrUpload(action.payload);

	try {
		yield call(api.postRequest, UrlEnum.VEHICLE, payload);
		yield put(actions.fetchVehicles());
		yield put(actions.formVehicleSuccess({
			message: 'pages.vehicle.created',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formVehicleFail(err));
	}
}

function * updateVehicle(action: ActionType<typeof actions.updateVehicle>) {
	const api = apiFactory(yield select());

	const payload = preparePayloadForCreateOrUpload(action.payload);

	try {
		yield call(api.patchRequest, `${UrlEnum.VEHICLE}${action.payload.id}/`, payload);
		yield put(actions.fetchVehicles());
		yield put(actions.formVehicleSuccess({
			message: 'pages.vehicle.updated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formVehicleFail(err));
	}
}

function preparePayloadForCreateOrUpload(_payload: IVehicle) {
	const payload = cloneDeep(_payload) as any;

	delete payload.id;
	delete payload.demonstrator;

	if (payload.engine) {
		payload.engine = payload.engine.id;
	}

	if (payload.fuel) {
		payload.fuel = payload.fuel.id;
	}

	if (payload.description) {
		payload.description = payload.description.id;
	}

	if (payload.abilities) {
		payload.abilities = payload.abilities.map((ability: IVehicleAbility) => ability.id);
	} else {
		payload.abilities = [];
	}

	if (payload.restrictions) {
		payload.restrictions = payload.restrictions.map((restriction: IVehicleRestrictionEndpoint) => restriction.id);
	} else {
		payload.restrictions = [];
	}

	return payload;
}

function * deleteVehicle(action: ActionType<typeof actions.deleteVehicle>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.deleteRequest, `${UrlEnum.VEHICLE}${action.payload.id}/`);
		yield put(actions.fetchVehicles());
		yield put(actions.deleteVehicleSuccess({
			message: 'pages.vehicle.removed',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.deleteVehicleFail(err));
	}
}

function * watchFetchVehicles() {
	yield takeLatest(actions.fetchVehicles, fetchVehicles);
}

function * watchDeleteVehicle() {
	yield takeEvery(actions.deleteVehicle, deleteVehicle);
}

function * watchCreateVehicle() {
	yield takeEvery(actions.createVehicle, createVehicle);
}

function * watchUpdateVehicle() {
	yield takeEvery(actions.updateVehicle, updateVehicle);
}

function * watchFetchVehiclesSuccess() {
	yield takeEvery(actions.fetchVehiclesSuccess, fetchVehiclesSuccess);
}

const vehiclesSagas = [
	watchFetchVehicles(),
	watchFetchVehiclesSuccess(),
	watchDeleteVehicle(),
	watchCreateVehicle(),
	watchUpdateVehicle(),
];

export default vehiclesSagas;
