import { createActionCreator } from 'deox';
import { IApiError } from 'model/interfaces/IApiError';
import { IPackage } from 'model/interfaces/IPackage';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';

export enum PackageActionsEnum {
	FETCH = '@PACKAGES_FETCH',
	FETCH_SUCCESS = '@PACKAGES_FETCH_SUCCESS',
	FETCH_FAIL = '@PACKAGES_FETCH_FAIL',

	CREATE = '@PACKAGES_CREATE',
	UPDATE = '@PACKAGES_UPDATE',
	FORM_SUCCESS = '@PACKAGES_FORM_SUCCESS',
	FORM_FAIL = '@PACKAGES_FORM_FAIL',
	FORM_RESTART = '@PACKAGES_FORM_RESTART',

	DELETE = '@PACKAGES_DELETE',
	DELETE_SUCCESS = '@PACKAGES_DELETE_SUCCESS',
	DELETE_FAIL = '@PACKAGES_DELETE_FAIL',
}

export const fetchPackages = createActionCreator(PackageActionsEnum.FETCH);

export const fetchPackagesFail = createActionCreator(
	PackageActionsEnum.FETCH_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const fetchPackagesSuccess = createActionCreator(
	PackageActionsEnum.FETCH_SUCCESS,
	resolve => (data: IPaginatedData<IPackage>) => resolve(data),
);

export const createPackage = createActionCreator(
	PackageActionsEnum.CREATE,
	resolve => (packageItem: IPackage) => resolve(packageItem),
);

export const updatePackage = createActionCreator(
	PackageActionsEnum.UPDATE,
	resolve => (packageItem: IPackage) => resolve(packageItem),
);

export const formPackageSuccess = createActionCreator(
	PackageActionsEnum.FORM_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success));

export const formPackageFail = createActionCreator(
	PackageActionsEnum.FORM_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const formPackageRestart = createActionCreator(PackageActionsEnum.FORM_RESTART);

export const deletePackage = createActionCreator(
	PackageActionsEnum.DELETE,
	resolve => (packageItem: IPackage) => resolve(packageItem),
);

export const deletePackageSuccess = createActionCreator(
	PackageActionsEnum.DELETE_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success)
);

export const deletePackageFail = createActionCreator(
	PackageActionsEnum.DELETE_FAIL,
	resolve => (error: IReduxActionState & IApiError) => resolve(error),
);
