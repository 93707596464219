import React from 'react';

import styles from './index.module.scss';
import Progress from './Progress';
import { Link } from 'react-router-dom';
import { RoutesEnum } from 'model/enums/RoutesEnum';
import { IRoute } from 'model/interfaces/IRoute';
import { getCompletedOrderPercentage } from 'model/helpers/assignment';
import EndingPoint from 'components/VehicleRouteCard/EndingPoint';
import StartingPoint from 'components/VehicleRouteCard/StartingPoint';
import NowPoint from 'components/VehicleRouteCard/NowPoint';
import Tippy from '@tippyjs/react';
import VehiclePopover from 'components/VehicleRouteCard/VehiclePopover';
import MoreInfo from 'components/VehicleRouteCard/MoreInfo';
import DriverPopover from 'components/VehicleRouteCard/DriverPopover';

type BaseProps = {
	route: IRoute,
	allowDetailLink?: boolean,
}

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const VehicleRouteCard: React.FC<Props> = ({ route, ...props }) => {
	const vehicle = route.vehicle;

	return (
		<div className={`overflow-hidden ${!props.allowDetailLink ? 'pb-1' : ''}`}>
			<div className={ styles.container }>
				{ props.allowDetailLink &&
				<Link
					to={ RoutesEnum.DETAIL.replace(':id', route.id.toString()) }
					className="stretched-link"
				/>
				}
				<div className="form-row align-items-baseline">
					<div className="col overflow-hidden">
						<Tippy
							content={ <VehiclePopover vehicle={route.vehicle} semitrailer={route.semitrailer} /> }
							trigger="click"
							placement="bottom-start"
							offset={ [0, 0] }
							arrow={ false }
							interactive={ false }
							appendTo={ document.body }
						>
							<h3
								className="text-truncate cursor-pointer"
								title={ `${vehicle.brand} ${vehicle.plate}` }
							>
								{ vehicle.brand } { vehicle.plate }
							</h3>
						</Tippy>
					</div>
					{ route.driver &&
					<Tippy
						content={ <DriverPopover driver={route.driver} /> }
						trigger="click"
						placement="bottom-start"
						offset={ [0, 0] }
						arrow={ false }
						interactive={ false }
						appendTo={ document.body }
					>
						<div
							className="col text-muted text-right text-truncate cursor-pointer"
							title={ `${ route.driver.first_name } ${ route.driver.last_name }` }
						>
							{ route.driver.first_name } { route.driver.last_name }
						</div>
					</Tippy>
					}
				</div>

				<Progress route={route} />

				<div className="form-row align-items-center justify-content-between">
					<div className="col">
						<StartingPoint route={route} />
					</div>
					<div className="col text-center">
						<NowPoint route={route} />
					</div>
					<div className="col text-right">
						<EndingPoint route={route} />
					</div>

				</div>

				{ !props.allowDetailLink && <MoreInfo route={route} /> }
			</div>
		</div>
	);
};

export default VehicleRouteCard;
