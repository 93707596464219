import React from 'react';

import * as constants from './constants';
import stylesLayout from '../Layout.module.scss';
import Layout from 'pages/Layout';
import Grid from 'components/Grid';
import { IMaterialTag } from 'model/interfaces/IMaterialTag';
import * as actions from 'pages/MaterialTags/store/actions';
import initialState from 'pages/MaterialTags/MaterialTagForm/initialState';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import { useAdminGrid } from 'model/hooks/useAdminGrid';
import MaterialTagForm from 'pages/MaterialTags/MaterialTagForm';
import BaseModal from 'components/modals/BaseModal';
import { useSearchedEntity } from 'model/hooks/useSearchedEntity';
import { IDriver } from 'model/interfaces/IDriver';
import { IGenericSearchRequest } from 'model/services/Api/interfaces/requests/IGenericSearchRequest';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { UserTypeEnum } from 'model/enums/UserTypeEnum';
import {useTranslation} from "react-i18next";

type BaseProps = {};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const MaterialTags: React.FC<Props> = props => {
	const { t } = useTranslation();

	const {
		isFormOpened,
		openForm,
		closeForm,
		formInitialValues,
		editAction,
		deleteAction,
	} = useAdminGrid<IMaterialTag>(
		initialState,
		t('pages.material.wantDeleteMaterialTag'),
		actions.deleteMaterialTag,
		actions.fetchMaterialTags,
	);

	const { materialTags, isLoading, error } = useSelector((state: ReduxState) => ({
		materialTags: state.materialTags.data?.results ?? [],
		isLoading: state.materialTags.loading,
		error: state.materialTags.error,
	}));

	const [ searchedMaterialTags, onSearch ] = useSearchedEntity<IMaterialTag, IGenericSearchRequest>(
		UrlEnum.MATERIAL_TAG,
		materialTags,
	);

	return (
		<>
			<BaseModal
				isOpen={ isFormOpened }
				onRequestClose={ closeForm }
				title={t('pages.material.materialTagForm')}
			>
				<MaterialTagForm
					onSuccess={ closeForm }
					initialValues={ formInitialValues }
				/>
			</BaseModal>

			<Layout className={stylesLayout.layoutSettings}>
				<section className={ stylesLayout.content }>
					<Grid<IMaterialTag>
						data={ searchedMaterialTags }
						columns={ constants.COLUMNS }
						rowSelectors={ constants.SELECTORS }
						rowActions={ [editAction, deleteAction] }
						loading={ isLoading }
						searchCallback={ onSearch }
						buttons={ () => (
							<>
								<button
									className="btn btn-primary"
									onClick={ openForm }
								>
									{t('pages.material.addMaterialTag')}
								</button>
							</>
						) }
					/>
				</section>
			</Layout>
		</>
	);
};

export default MaterialTags as React.FC<BaseProps>;
