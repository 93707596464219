const namespace = 'green-your-route';

const states = [
	'auth',
	'executionDate',
	'shift',
	'shifts',
	'loggedInUser',
	'planning',
	'vehicles',
	'orders',
	'routes',
	'plan',
];

export const localStorageSaveConfig = {
	states,
	namespace,
	debounce: 1000,
};

export const localStorageLoadConfig = {
	states,
	namespace,
};
