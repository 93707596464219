import BaseModal from 'components/modals/BaseModal';
import CustomerForm from 'pages/Customers/CustomerForm';
import mapMarker from 'images/map-marker-generic.svg';
import styles from 'pages/Customers/index.module.scss';
import { Marker } from 'react-mapbox-gl';
import React from 'react';
import { Coords } from 'model/types/Coords';
import { ICustomer } from 'model/interfaces/ICustomer';
import {useTranslation} from "react-i18next";

type Props = {
	coordinates: Coords,
	customer: ICustomer,
	number: number,
}

const CustomerMapMarker = (props: Props) => {
	const [ isFormOpened, setIsFormOpened ] = React.useState(false);
	const closeForm = () => setIsFormOpened(false);
	const { t } = useTranslation();

	return (
		<Marker
			coordinates={props.coordinates}
			anchor="bottom"
			className="cursor-pointer"
		>
			<>
				<BaseModal
					isOpen={ isFormOpened }
					onRequestClose={ closeForm }
					title={t('pages.customers.customerForm')}
				>
					<CustomerForm
						onSuccess={ closeForm }
						initialValues={ props.customer }
					/>
				</BaseModal>

				<div className="position-relative" onClick={ () => setIsFormOpened(true) }>
					<img src={mapMarker} alt="" />
					<span className={styles.mapMarkerNumber}>{props.number}</span>
				</div>
			</>
		</Marker>
	);
}

export default CustomerMapMarker;