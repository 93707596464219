import { createActionCreator } from 'deox';

import { IUser } from 'model/interfaces/IUser';
import { IApiError } from 'model/interfaces/IApiError';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';

export enum LoggedInUserActionsEnum {
	FETCH = '@FETCH_LOGGED_IN_USER',
	FETCH_SUCCESS = '@FETCH_LOGGED_IN_USER_SUCCESS',
	FETCH_FAIL = '@FETCH_LOGGED_IN_USER_FAIL',

	UPDATE = '@UPDATE_LOGGED_IN_USER',
	UPDATE_SUCCESS = '@UPDATE_LOGGED_IN_USER_SUCCESS',
	UPDATE_FAIL = '@UPDATE_LOGGED_IN_USER_FAIL',
}

export const fetchLoggedInUser = createActionCreator(LoggedInUserActionsEnum.FETCH);

export const fetchLoggedInUserSuccess = createActionCreator(
	LoggedInUserActionsEnum.FETCH_SUCCESS,
	resolve => (data: IUser) => resolve(data),
);

export const fetchLoggedInUserFail = createActionCreator(
	LoggedInUserActionsEnum.FETCH_FAIL,
	resolve => (error: IApiError) => resolve(error),
);

export const updateLoggedInUser = createActionCreator(
	LoggedInUserActionsEnum.UPDATE,
	resolve => (user: IUser) => resolve(user),
);

export const updateLoggedInUserSuccess = createActionCreator(
	LoggedInUserActionsEnum.UPDATE_SUCCESS,
	resolve => (success: IReduxActionState) => resolve(success)
);

export const updateLoggedInUserFail = createActionCreator(
	LoggedInUserActionsEnum.UPDATE_FAIL,
	resolve => (error: IApiError) => resolve(error)
);