import { IVehicle } from 'model/interfaces/IVehicle';
import { ISemitrailer } from 'model/interfaces/ISemitrailer';
import KeyValueRows from 'components/VehicleRouteCard/KeyValueRows';
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

type VehiclePopoverProps = {
	vehicle: IVehicle,
	semitrailer?: ISemitrailer,
}

const VehiclePopover: React.FC<VehiclePopoverProps> = ({ vehicle, semitrailer }) => {

	const { t } = useTranslation();

	const items = {
		[t('components.vehicle.weight')] : `${vehicle.capacity_weight} kg`,
		[t('components.vehicle.volume')] : <>{vehicle.capacity_volume} m<sup>3</sup></>,
		[t('components.vehicle.comments')] : vehicle.comments,
	};

	return (
		<div className="dropdown-menu show position-static px-3">
			<KeyValueRows items={items} />
			{ semitrailer && <Semitrailer semitrailer={semitrailer} />}
		</div>
	);
};


type SemitrailerProps = {
	semitrailer: ISemitrailer,
}

const Semitrailer: React.FC<SemitrailerProps> = ({ semitrailer }) => {

	const { t } = useTranslation();

	const items = {
		[t('components.vehicle.weight')] : `${semitrailer.capacity_weight} kg`,
		[t('components.vehicle.volume')] : <>{semitrailer.capacity_volume} m<sup>3</sup></>,
	};

	return (
		<>
			<hr />
			<h3 className="mb-1">{t('components.vehicle.semitrailer')}</h3>
			<div>{semitrailer.brand} {semitrailer.plate}</div>

			<KeyValueRows items={items} />
		</>
	);

}

export default VehiclePopover;