import { createActionCreator } from 'deox';
import { IApiError } from 'model/interfaces/IApiError';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IRoute } from 'model/interfaces/IRoute';
import { IRouteAssignmentsState } from 'pages/Rerouting/RerouteSelected';
import { RouteStatusEnum } from 'model/enums/RouteStatusEnum';

export enum RoutesActionsEnum {
	FETCH = '@ROUTES_FETCH',
	FETCH_SUCCESS = '@ROUTES_FETCH_SUCCESS',
	FETCH_FAIL = '@ROUTES_FETCH_FAIL',

	FETCH_ONE = '@ROUTES_FETCH_ONE',
	FETCH_ONE_SUCCESS = '@ROUTES_FETCH_ONE_SUCCESS',
	FETCH_ONE_FAIL = '@ROUTES_FETCH_ONE_FAIL',

	START_POLLING = '@ROUTES_START_POLLING',
	STOP_POLLING = '@ROUTES_STOP_POLLING',

	CREATE_ROUTE = '@ROUTES_CREATE_ROUTE',
	CREATE_ROUTE_SUCCESS = '@ROUTES_CREATE_ROUTE_SUCCESS',
	CREATE_ROUTE_FAIL = '@ROUTES_CREATE_ROUTE_FAIL',

	RECALCULATE = '@ROUTES_RECALCULATE',
	RECALCULATE_CANCEL = '@ROUTES_RECALCULATE_CANCEL',
	RECALCULATE_SAVE = '@ROUTES_RECALCULATE_SAVE',

	SET_LOADING = '@ROUTES_SET_LOADING',
	SET_PREV_STATUS = '@ROUTES_SET_PREV_STATUS',
	SET_SELECTED = '@ROUTES_SET_SELECTED',
	SET_ALL_SELECTED = '@ROUTES_SET_ALL_SELECTED',
	SET_REROUTING_CANCEL = '@ROUTES_SET_REROUTING_CANCEL',
}

export const startPolling = createActionCreator(
	RoutesActionsEnum.START_POLLING
);

export const stopPolling = createActionCreator(RoutesActionsEnum.STOP_POLLING);

export const createRoute = createActionCreator(RoutesActionsEnum.CREATE_ROUTE,
	resolve => (route: Partial<IRoute>) => resolve(route)
);

export const createRouteSuccess = createActionCreator(RoutesActionsEnum.CREATE_ROUTE_SUCCESS);

export const createRouteFail = createActionCreator(
	RoutesActionsEnum.CREATE_ROUTE_FAIL, resolve => (error: IApiError) => resolve(error),
);

export const fetchRoutes = createActionCreator(
	RoutesActionsEnum.FETCH
);

export const fetchRoutesSuccess = createActionCreator(
	RoutesActionsEnum.FETCH_SUCCESS, resolve => (plan: IPaginatedData<IRoute>) => resolve(plan),
);

export const fetchRoutesFail = createActionCreator(
	RoutesActionsEnum.FETCH_FAIL, resolve => (error: IApiError) => resolve(error),
);

export const fetchRoute = createActionCreator(
	RoutesActionsEnum.FETCH_ONE,
	resolve => (routeId: number) => resolve(routeId)
);

export const fetchRouteSuccess = createActionCreator(
	RoutesActionsEnum.FETCH_ONE_SUCCESS, resolve => (route: IRoute) => resolve(route),
);

export const fetchRouteFail = createActionCreator(
	RoutesActionsEnum.FETCH_ONE_FAIL, resolve => (error: IApiError) => resolve(error),
);

export const recalculate = createActionCreator(RoutesActionsEnum.RECALCULATE,
	resolve => (routeAssignments: IRouteAssignmentsState[]) => resolve(routeAssignments)
);

export const cancelRecalculate = createActionCreator(RoutesActionsEnum.RECALCULATE_CANCEL,
	resolve => (routeAssignments: IRouteAssignmentsState[]) => resolve(routeAssignments)
);

export const saveRecalculate = createActionCreator(RoutesActionsEnum.RECALCULATE_SAVE,
	resolve => (routeAssignments: IRouteAssignmentsState[]) => resolve(routeAssignments)
);

export const setLoading = createActionCreator(RoutesActionsEnum.SET_LOADING);

export const setPrevStatus = createActionCreator(RoutesActionsEnum.SET_PREV_STATUS,
	resolve => (prevStatus: RouteStatusEnum, routeIndex: number) => resolve(prevStatus, routeIndex)
);

export const setSelected = createActionCreator(RoutesActionsEnum.SET_SELECTED,
	resolve => (selected: boolean, routeIndex: number) => resolve(selected, routeIndex)
);

export const setAllSelected = createActionCreator(RoutesActionsEnum.SET_ALL_SELECTED,
	resolve => (selected: boolean) => resolve(selected)
);

export const setReroutingCancel = createActionCreator(RoutesActionsEnum.SET_REROUTING_CANCEL,
	resolve => (flag: boolean) => resolve(flag)
);