import React from 'react';
import { uniqueId as lodashUniqueId } from 'lodash';
import { FieldProps } from 'formik';

import InputWrapper from 'components/Form/InputWrapper';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import { fieldClassName } from 'model/helpers/form';

type BaseProps = {} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const TextAreaInput: React.FC<Props> = props => {
	const id = lodashUniqueId(`text-input-${props.field.name}`);

	return (
		<InputWrapper { ...props } id={ id }>
			<textarea
				id={ id }
				className={ fieldClassName(props.meta) }
				placeholder={ props.placeholder }
				{ ...props.field }
			/>
		</InputWrapper>
	);
};

export default TextAreaInput;
