import { createReducer } from 'deox';

import * as actions from 'pages/Customers/store/actions';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';
import { ICustomer } from 'model/interfaces/ICustomer';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IReduxActionState } from 'model/interfaces/IReduxActionState';
import { IApiError } from 'model/interfaces/IApiError';

export interface ICustomersState extends IReduxFetchedState<IPaginatedData<ICustomer>> {
	formSuccess?: IReduxActionState | boolean | undefined,
	formError?: (IReduxActionState & IApiError) | undefined,

	deleteSuccess?: IReduxActionState | boolean,
	deleteError?: IReduxActionState & IApiError,
}

const initialState: ICustomersState = {};

export default createReducer(initialState, handle => [
	handle(actions.fetchCustomers, (state) => ({
		...state,
		loading: true,
	})),
	handle(actions.fetchCustomersSuccess, (state, { payload }) => ({
		...state,
		error: undefined,
		data: { ...payload },
		loading: false,
	})),
	handle(actions.fetchCustomersFail, (state, { payload }) => ({
		...state,
		data: undefined,
		error: { ...payload },
		loading: false,
	})),
	handle(actions.createCustomer, (state) => ({
		...state,
		loading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.updateCustomer, (state) => ({
		...state,
		loading: true,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.formCustomerSuccess, (state, { payload }) => ({
		...state,
		loading: false,
		formSuccess: { ...payload },
		formError: undefined,
	})),
	handle(actions.formCustomerFail, (state, { payload }) => ({
		...state,
		loading: false,
		formSuccess: undefined,
		formError: { ...payload },
	})),
	handle(actions.formCustomerRestart, (state) => ({
		...state,
		loading: false,
		formSuccess: undefined,
		formError: undefined,
	})),
	handle(actions.deleteCustomer, (state) => ({
		...state,
		loading: true,
		deleteSuccess: undefined,
		deleteError: undefined,
	})),
	handle(actions.deleteCustomerSuccess, (state, { payload }) => ({
		...state,
		loading: false,
		deleteSuccess: { ...payload },
		deleteError: undefined,
	})),
	handle(actions.deleteCustomerFail, (state, { payload }) => ({
		...state,
		loading: false,
		success: false,
		error: { ...payload },
	})),
	handle(actions.importCustomers, (state) => ({
		...state,
		loading: true,
		error: undefined
	})),
	handle(actions.importCustomersSuccess, (state) => ({
		...state,
		loading: false,
		error: undefined
	})),
	handle(actions.importCustomersFail, (state, { payload }) => ({
		...state,
		loading: false,
		error: { ...payload }
	})),
]);
