import React from 'react';
import * as Yup from 'yup';
import { IGridCellRender } from 'model/interfaces/grid/IGridCellRender';
import { IVehicle } from 'model/interfaces/IVehicle';

export const COLUMNS: string[] = [
	"pages.vehicles.brand", "pages.vehicles.plate", "pages.vehicles.volume", "pages.vehicles.engine", "pages.vehicles.fuel", "pages.vehicles.weight", ""
];

export const SELECTORS: string[] = [
	"brand", "plate", "capacity_volume", "engine", "fuel", "capacity_weight",
];

export const RENDER: IGridCellRender<IVehicle> = {
	'engine': (row: IVehicle) => {
		return ( <td>{ row.engine?.name }</td>)
	},
	'fuel': (row: IVehicle) => {
		return ( <td>{ row.fuel?.name }</td> );
	},
};

export const VEHICLE_FORM_VALIDATION_SCHEMA = Yup.object({
	plate: Yup.string().max(10).min(1).required(),
	brand: Yup.string().max(20).min(1).required(),
	capacity_weight: Yup.number().required(),
	capacity_volume: Yup.number().required(),
	comments: Yup.string().max(200),
	demonstrator: Yup.number().required(),
	abilities: Yup.array().min(1),
});
