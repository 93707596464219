import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from 'pages/Customers/store/actions';
import { apiFactory } from 'model/services/Api/Api';
import { ICustomersResponse } from 'model/services/Api/interfaces/responses/ICustomersResponse';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { ActionType } from 'deox';
import { ICustomer } from 'model/interfaces/ICustomer';
import { cloneDeep } from 'lodash';
import { AddressTypeEnum } from 'model/enums/AddressTypeEnum';
import { ReduxState } from 'store/reducer';
import { AxiosResponse } from 'axios';
import { IOrderImport } from 'model/services/Api/interfaces/responses/IOrderImport';
import { toast } from 'react-toastify';
import React from 'react';
import i18n from '../../../i18n';

function * fetchCustomers() {
	const api = apiFactory(yield select());

	try {
		const response: ICustomersResponse = yield call(api.getRequest, UrlEnum.CUSTOMER);
		yield put(actions.fetchCustomersSuccess(response.data));
	} catch (err) {
		yield put(actions.fetchCustomersFail(err));
	}
}

function * createCustomer(action: ActionType<typeof actions.createCustomer>) {
	const api = apiFactory(yield select());

	const payload = preparePayloadForCreateOrUpload(action.payload);

	try {
		yield call(api.postRequest, UrlEnum.CUSTOMER, payload);
		yield put(actions.fetchCustomers());
		yield put(actions.formCustomerSuccess({
			message: 'pages.customers.customerCreated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formCustomerFail(err));
	}
}

function * updateCustomer(action: ActionType<typeof actions.updateCustomer>) {
	const api = apiFactory(yield select());

	const payload = preparePayloadForCreateOrUpload(action.payload);

	try {
		yield call(api.patchRequest, `${UrlEnum.CUSTOMER}${action.payload.id}/`, payload);
		yield put(actions.fetchCustomers());
		yield put(actions.formCustomerSuccess({
			message: 'pages.customers.customerUpdated',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.formCustomerFail(err));
	}
}

function * deleteCustomer(action: ActionType<typeof actions.deleteCustomer>) {
	const api = apiFactory(yield select());

	try {
		yield call(api.deleteRequest, `${UrlEnum.CUSTOMER}${action.payload.id}/`);
		yield put(actions.fetchCustomers());
		yield put(actions.deleteCustomerSuccess({
			message: 'pages.customers.customerRemoved',
			toast: true,
		}));

	} catch (err) {
		yield put(actions.deleteCustomerFail(err));
	}
}

function * importCustomers(action: ActionType<typeof actions.importCustomers>) {
	if (!action.payload || !action.payload[0]) {
		console.error("No file uploaded");
		return;
	}

	const store: ReduxState = yield select();
	const api = apiFactory(store);

	const formData = new FormData();
	formData.append('file', action.payload[0]);

	try {
		const response: AxiosResponse<string> = yield call(api.postRequest, UrlEnum.CUSTOMER_IMPORT, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

		yield put(actions.fetchCustomers());

	// 	const importStats: IOrderImport = JSON.parse(response.data);
	// 	toast.success(
	// 		<div>
	// 			Import finished.<br />
	// 	<br />
	// 	New: {importStats.totals.new}<br />
	// 	Update: {importStats.totals.update}<br />
	// 	Delete: {importStats.totals.delete}<br />
	// 	Skip: {importStats.totals.skip}<br />
	// 	Error: {importStats.totals.error}<br />
	// 	Invalid: {importStats.totals.invalid}<br />
	// 	</div>
	// );

		toast.success(i18n.t('pages.customers.customersImported'));

	} catch (err) {
		yield put(actions.importCustomersFail(err));
	}
}

function * watchFetchCustomers() {
	yield takeLatest(actions.fetchCustomers, fetchCustomers);
}

function * watchDeleteCustomer() {
	yield takeEvery(actions.deleteCustomer, deleteCustomer);
}

function * watchCreateCustomer() {
	yield takeEvery(actions.createCustomer, createCustomer);
}

function * watchUpdateCustomer() {
	yield takeEvery(actions.updateCustomer, updateCustomer);
}

function * watchImportCustomers() {
	yield takeLatest(actions.importCustomers, importCustomers);
}

const customersSagas = [
	watchFetchCustomers(),
	watchDeleteCustomer(),
	watchCreateCustomer(),
	watchUpdateCustomer(),
	watchImportCustomers(),
];

function preparePayloadForCreateOrUpload(_payload: ICustomer) {
	const payload = cloneDeep(_payload) as any;

	delete payload.id;
	delete payload.demonstrator;
	delete payload.restrictions;

	if (!payload.erp_id) {
		delete payload.erp_id;
	}

	// We need to add address_type to addresses
	for (const address of payload.addresses) {
		if (!address.address_type) {
			address.address_type = AddressTypeEnum.SERVICE_ADDRESS;
		}

		if (!address.location || address.location.coordinates.length === 0 || !address.location.coordinates[0] || !address.location.coordinates[1]) {
			delete address.location;
		} else {
			address.location.type = 'Point';
		}

		delete address.id;
		delete address.content_type;
		delete address.owner;
		delete address.object_id;
		delete address.restrictions;
		delete address.verification_type;
		delete address.altitude;
		delete address.owner_erp_id;
		delete address.owner_id;
		delete address.owner_name;
		delete address.owner_telephone;
	}

	return payload;
}

export default customersSagas;
