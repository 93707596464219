import { createReducer } from 'deox';

import * as actions from './actions';
import { IReduxFetchedState } from 'model/interfaces/IReduxFetchedState';
import { IPaginatedData } from 'model/interfaces/IPaginatedData';
import { IRoute } from 'model/interfaces/IRoute';
import { RouteStatusEnum } from 'model/enums/RouteStatusEnum';

export interface IRoutesState extends IReduxFetchedState<IPaginatedData<IRoute>> {
	meta: Array<{
		prevStatus: RouteStatusEnum,
		selected: boolean,
	}>,
	isReroutingCancel: boolean,
}

const initialState: IRoutesState = {
	meta: [],
	isReroutingCancel: false,
};

export default createReducer(initialState, handle => [
	handle(actions.fetchRoutes, state => ({
		...state,
		loading: true,
	})),
	handle(actions.fetchRoutesSuccess, (state, { payload }) => ({
		...state,
		error: undefined,
		data: { ...payload },
		loading: false,
	})),
	handle(actions.fetchRoutesFail, (state, { payload }) => ({
		...state,
		data: undefined,
		error: { ...payload },
		loading: false,
	})),
	handle(actions.fetchRoute, state => ({
		...state,
		loading: true,
	})),
	handle(actions.fetchRouteSuccess, (state, { payload }) => {
		if (!state.data?.results) return state;

		const results = [...state.data?.results]
		const index = results.findIndex(route => route.id === payload.id)

		if (index === -1) return state;

		results[index] = payload
		
		return {
			...state,
			error: undefined,
			data: {
				...state.data,
				results
			},
			loading: false,
		}
	}),
	handle(actions.fetchRouteFail, (state, { payload }) => ({
		...state,
		error: { ...payload },
		loading: false,
	})),
	handle(actions.createRoute, (state) => ({
		...state,
		loading: true,
	})),
	handle(actions.createRouteSuccess, (state) => ({
		...state,
		loading: false,
	})),
	handle(actions.createRouteFail, (state) => ({
		...state,
		loading: false,
	})),
	handle(actions.startPolling, (state) => ({
		...state,
		loading: true,
	})),
	handle(actions.setLoading, (state) => ({
		...state,
		loading: true,
	})),
	handle(actions.setPrevStatus, (state, { payload: prevStatus, meta: routeIndex }) => {
		const meta = typeof state.meta !== 'undefined' ? [ ...state.meta ] : [];
		meta[routeIndex] = {
			...meta[routeIndex],
			prevStatus,
		}
		return {
			...state,
			meta,
		};
	}),
	handle(actions.setSelected, (state, { payload: selected, meta: routeIndex }) => {
		const meta = typeof state.meta !== 'undefined' ? [ ...state.meta ] : [];
		meta[routeIndex] = {
			...meta[routeIndex],
			selected,
		}
		return {
			...state,
			meta,
		};
	}),
	handle(actions.setAllSelected, (state, { payload: selected }) => {
		const meta = typeof state.meta !== 'undefined' ? [ ...state.meta ] : [];
		for (const routeIndex in meta) {
			meta[routeIndex] = {
				...meta[routeIndex],
				selected,
			}
		}
		return {
			...state,
			meta,
		};
	}),
	handle(actions.setReroutingCancel, (state, { payload } ) => ({
		...state,
		isReroutingCancel: payload,
	}))
]);
