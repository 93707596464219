import { createReducer } from 'deox';

import * as actions from './actions';
import { IShift } from 'model/interfaces/IShift';

export type IShiftState = IShift;

export default createReducer({}, handle => [
	handle(actions.set, (state, { payload }) => payload),
]);
