import React, { useEffect } from 'react';
import { Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import TextInput from 'components/Form/TextInput';
import { IShift } from 'model/interfaces/IShift';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import * as shiftActions from 'pages/Shifts/store/actions';
import { daysInWeekCheckboxList } from 'model/enums/DaysInWeekEnum';
import DaysInWeekCheckbox from 'components/Form/DaysInWeekCheckbox';
import {useTranslation} from "react-i18next";

type BaseProps = {
	onSuccess: () => void,
	initialValues: IShift,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const ShiftForm: React.FC<Props> = props => {

	const dispatch = useDispatch();

	const createUpdateSuccess = useSelector<ReduxState>(state => state.shifts.formSuccess);

	const onSubmit = async (values: IShift) => {
		if (values.id) {
			dispatch(shiftActions.updateShift(values));
		} else {
			dispatch(shiftActions.createShift(values));
		}
	};

	useEffect(() => {
		if (createUpdateSuccess) {
			dispatch(shiftActions.formShiftRestart());
			props.onSuccess();
		}
	}, [ createUpdateSuccess ]);

	const { t } = useTranslation();
	return (
		<Formik<IShift>
			initialValues={ props.initialValues }
			onSubmit={ onSubmit }
		>
			{ formikBag => (
				<Form>
					<div className="row">
						<div className="col">
							<Field
								name="name"
							>
								{ (props: FieldProps) => <TextInput label={t('pages.nameShift')} {...props} /> }
							</Field>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<div className="form-group">
								<label>{t('pages.shifts.days')}</label>
								<div className="form-row">
									{daysInWeekCheckboxList.map((day) => (
										<Field
											key={day.id}
											name="days"
											value={day}
											component={DaysInWeekCheckbox}
											label={t(day.day)}
										/>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className="form-row">
						<div className="col">
							<Field
								name="time_start"
							>
								{ (props: FieldProps) =>
									<TextInput
										label={t('pages.startTime')}
										placeholder="00:00:00"
										{ ...props }
									/>
								}
							</Field>
						</div>
						<div className="col">
							<Field
								name="time_end"
							>
								{ (props: FieldProps) =>
									<TextInput
										label={t('pages.endTime')}
										placeholder="00:00:00"
										{ ...props }
									/>
								}
							</Field>
						</div>
					</div>
					<div className="text-right">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={ ! formikBag.isValid || formikBag.isSubmitting }
						>
							{t('pages.saveShift')}
						</button>
					</div>
				</Form>
			) }
		</Formik>
	);
};

export default ShiftForm;
