import React from 'react';
import { FieldProps } from 'formik';

import BaseAsyncSelect from 'components/Form/BaseAsyncSelect';
import { CommonInputProps } from 'components/Form/CommonInputProps';
import { UrlEnum } from 'model/services/Api/enums/UrlEnum';
import { IDriver } from 'model/interfaces/IDriver';
import { UserTypeEnum } from 'model/enums/UserTypeEnum';

type BaseProps = {} & CommonInputProps & FieldProps;

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const DriverAsyncSelect: React.FC<Props> = props => (
	<BaseAsyncSelect<IDriver>
		url={ UrlEnum.USER }
		params={{
			user_type: UserTypeEnum.DRIVER
		}}
		formatOptionLabel={ option => (
			<div style={{ lineHeight: 1.1 }}>
				{option.first_name && option.first_name ? `${option.first_name} ${option.last_name}` : '–'}<br />
				{option.address
					? <small className="text-muted">{option.address.address}, {option.address.postal_code}, {option.address.city}</small>
					: <small className="text-muted">–</small>
				}

			</div>
		)}
		{ ...props }
	/>
);

export default DriverAsyncSelect;
