import { IGridRowRender } from 'model/interfaces/grid/IGridRowRender';
import { IPlanningRoute } from 'model/interfaces/IRoute';
import React, { useState } from 'react';
import styles from 'pages/Planning/Vehicles/index.module.scss';
import BaseModal from 'components/modals/BaseModal';
import VehicleDescription from 'pages/Planning/VehicleDescription';
import Form from 'pages/Planning/Solution/Form';
import StartingPoint from 'components/VehicleRouteCard/StartingPoint';
import EndingPoint from 'components/VehicleRouteCard/EndingPoint';
import { getNoOfOrders } from 'model/helpers/assignment';
import { OrderTypeEnum } from 'model/enums/OrderTypeEnum';
import { useDispatch } from 'react-redux';
import * as actions from 'pages/Planning/store/actions';
import { PlanningStepsEnum } from 'pages/Planning/PlanningStepsEnum';

const Row = (props: IGridRowRender<IPlanningRoute>) => {
	const row = props.row;
	const [ isModalOpen, setModalOpen ] = useState(false);
	const dispatch = useDispatch();

	const Button: React.FC = (props) =>
		<button onClick={() => setModalOpen(true)} className={styles.cellButton}>
			{props.children}
		</button>

	return (
		<>
			<BaseModal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)} title="">
				<Form { ...props } />
			</BaseModal>

			<tr>
				<td className="align-middle pr-0" style={{ width: '1px' }}>
					<Button>
						<i className={`${row.selected ? 'fa fa-check-square text-primary' : 'far fa-square text-muted'} fa-2x`} />
					</Button>
				</td>
				<td className="align-middle">
					<VehicleDescription vehicle={row.vehicle!} />
				</td>
				<td className="align-middle">
					<StartingPoint route={row} />
				</td>
				<td className="align-middle text-right">
					<EndingPoint route={row} />
				</td>
				<td className="align-middle text-center">
					{row.total_distance}
				</td>
				<td className="align-middle text-center">
					<span className="badge-order badge-dark">
						{ getNoOfOrders(row.assignments) }
					</span>
				</td>
				<td className="align-middle text-center">
					<span className="badge-order badge-delivery">
						{ getNoOfOrders(row.assignments, undefined, OrderTypeEnum.DELIVERY) }
					</span>
				</td>
				<td className="align-middle text-center">
					<span className="badge-order badge-pickup">
						{ getNoOfOrders(row.assignments, undefined, OrderTypeEnum.PICKUP) }
					</span>
				</td>
				<td className="align-middle text-center">
					<span className="badge-order badge-requisition">
						{ getNoOfOrders(row.assignments, undefined, OrderTypeEnum.REQUISITION) }
					</span>
				</td>
				<td className="align-middle">
					<Button>
						{ row.driver
							? <span>{row.driver?.first_name} {row.driver?.last_name}</span>
							: <span className="text-primary">Set Driver</span>
						}
					</Button>
				</td>
				<td className="align-middle border-left px-0 position-relative">
					<button
						className="btn stretched-link"
						onClick={() => {
							dispatch(actions.setRerouteRouteId(row.id!));
							dispatch(actions.setStep(PlanningStepsEnum.REROUTE_SINGLE));
						}}
					>
						<i className="fa fa-angle-right" />
					</button>
				</td>
			</tr>
		</>
	);
};

export default Row;