import React, { useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import TextInput from 'components/Form/TextInput';
import { IAddress } from 'model/interfaces/IAddress';
// import DemonstratorAsyncSelect from 'components/Form/DemonstratorAsyncSelect';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/reducer';
import * as addressActions from 'pages/Addresses/store/actions';
import AddressFormComponent from 'components/Form/AddressFormComponent';
import {useTranslation} from "react-i18next";

type BaseProps = {
	onSuccess: () => void,
	initialValues: IAddress,
};

type InjectedProps = {};

type Props = BaseProps & InjectedProps;

const AddressForm: React.FC<Props> = props => {

	const dispatch = useDispatch();

	const [ isSubmitDisabled, setSubmitDisabled ] = useState(false);

	const [ formSuccess, formFail, formLoading ] = useSelector((state: ReduxState) => ([
		state.addresses.formSuccess,
		state.addresses.formError,
		state.addresses.formLoading
	]));

	const onSubmit = async (values: IAddress) => {
		if (values.id) {
			dispatch(addressActions.updateAddress(values));
		} else {
			dispatch(addressActions.createAddress(values));
		}
	};

	useEffect(() => {
		if (formSuccess) {
			dispatch(addressActions.formAddressRestart());
			props.onSuccess();
		}
	}, [ formSuccess ]);

	useEffect(() => {
		if (formLoading) {
			setSubmitDisabled(true);
		}
	}, [ formLoading ]);

	useEffect(() => {
		if (formFail) {
			setSubmitDisabled(false);
		}
	}, [ formFail ]);

	const { t } = useTranslation();

	return (
		<Formik<IAddress>
			initialValues={ props.initialValues }
			onSubmit={ onSubmit }
		>
			{ formikBag => (
				<Form>
					<AddressFormComponent showContentTypeAndObjectId />

					<div className="text-right">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={ ! formikBag.isValid || formikBag.isSubmitting || isSubmitDisabled }
						>
							{t('pages.saveAddress')}
						</button>
					</div>
				</Form>
			) }
		</Formik>
	);
};

export default AddressForm;
