import { IGridCellRender } from 'model/interfaces/grid/IGridCellRender';
import React from 'react';
import { IShift } from 'model/interfaces/IShift';

export const COLUMNS: string[] = [
	'pages.shifts.name', 'pages.shifts.days2', 'pages.shifts.startTime', 'pages.shifts.endTime', '',
];

export const SELECTORS: string[] = [
	'name', 'days', 'time_start', 'time_end',
];

export const RENDER: IGridCellRender<IShift> = {
	'days': (row: IShift) => {
		return <td>{ row.days.map(day => day.day).join(', ') }</td>;
	},
};